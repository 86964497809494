export default class Snapshot {
  constructor () {
    this.socio_id = null
    this.empresa_id = null
    this.detalhe_id = null
    this.registro = null
    this.alteracao = null
    this.recibo = null
    this.dados = null
    this.prazo_id = null
    this.empresa_matriz_id = null
    this.desativado = null
    this.comAgendamento = null
  }
}
