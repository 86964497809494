<template>
  <div id="contadorAuditor">
    <alt-datatable
      ref="datatable"
      :pStatus="this.status"
      :pDataSource="this.dataSource"
      :pData="this.contadores"
      :paginacao="false"
      :pQuantidadeDeItems="this.page.total"
      :idDivUtilizadaPaginacao="'contadorAuditor'"
      :noIcon="true"
    >
      <template v-slot:new-item>
        <button class="button is-light" @click="cadastrarNovo">Novo</button>
      </template>
    </alt-datatable>
    <cadastro-Edicao-Contador-Auditor
      v-if="cadastrandoOuEditando"
      :errosValidacao="this.errosValidacao"
      :modalVisivel="this.cadastrandoOuEditando"
      :idEmpresa="this.itemEmpresa.cdempresa"
      :itemContador="this.item"
      :editando="this.editando"
      @cancel="abrirFecharEdicao"
      @save="_atualizarListaAposCadastro"
    />
    <div
      v-if="this.modalConfirmacaoVisivel"
      class="modal animated fadeIn is-active"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div>
            <p class="modal-card-title">Deseja continuar?</p>
          </div>
        </header>
        <main class="modal-content">
          <div class="modal-card-body">
            <p>
              O contador que está sendo excluído está vinculado com empresas.
            </p>
          </div>
        </main>
        <footer class="modal-card-foot is-flex is-justify-content-flex-end">
          <button
            :class="`${
              this.isAwaitDeleteClass ? 'button is-loading' : 'button'
            }`"
            @click="
              async () => {
                await this.excluir(this.contador, true)
              }
            "
          >
            Sim
          </button>
          <button
            class="button is-primary"
            @click="() => (modalConfirmacaoVisivel = false)"
          >
            Não
          </button>
        </footer>
      </div>
    </div>
  </div>
  <ModalMessage ok-caption="Excluir contador/auditor" cancel-type="primary" :message="modalMensagem" :active="mostrarModal" @close="aoFechar" />
</template>

<script>
import Empresa from '../model/empresa.js'
import { contadorService } from '../service/contador-service'
import Contador from './model/contador.js'
import cadastroContadorAuditor from './cadastroEdicaoContadorAuditor.vue'
import AltDatatable from '@/components/alt-component/Datatable/index.vue'
import ModalMessage from '@alterdata/component-vue/ModalMessage'

const MSG_MODAL_CONTADOR_VINCULADO =
  'Deseja excluir o contador/auditor "NOME"? Caso o contador/auditor esteja vinculado em alguma empresa, a sua vinculação também será excluída da(s) empresas(s).'

export default {
  name: 'contadorAuditor',
  components: {
    'cadastro-Edicao-Contador-Auditor': cadastroContadorAuditor,
    'alt-datatable': AltDatatable,
    ModalMessage
  },
  props: {
    itemEmpresa: Empresa,
    errosValidacao: Object
  },
  emits: ['update:ContadorAuditor'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'nome',
            label: 'Nome',
            style: 'width:30%',
            classes: 'is-ellipsis'
          },
          {
            name: 'crc',
            label: 'CRC',
            style: 'width:28%',
            classes: 'is-ellipsis'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: async () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: async (item) => this.excluir(item),
        loadMore: null
      },
      page: [],
      contadores: [],
      cadastrandoOuEditando: false,
      item: new Contador(),
      modalConfirmacaoVisivel: false,
      contador: {},
      showDataTable: true,
      pagina: 0,
      modalMensagem: '',
      mostrarModal: false
    }
  },

  updated () {
    this._tratarPreDelete()
  },

  methods: {
    async carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      try {
        this.contadores = []
        this.contadores = await contadorService.get('/api/v1/contador/todos')
        this.$emit('update:ContadorAuditor', this.contadores)
        this.status = ''
        this.pagina = pagina
      } catch {
        this.status = 'is-danger'
      }
    },

    cadastrarNovo () {
      this.item = new Contador()
      this.editando = false
      this.abrirFecharEdicao()
    },

    editar (contador) {
      this.item = Object.assign(this.item, contador)
      this.editando = true
      this.abrirFecharEdicao()
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
    },

    async _atualizarListaAposCadastro () {
      await this.carregarListagem(this.pagina)
      this.abrirFecharEdicao()
    },

    async excluir (contador) {
      if (contador) {
        this.contador = contador
        this.modalMensagem = MSG_MODAL_CONTADOR_VINCULADO.replace('NOME', contador.nome)
        this.mostrarModal = true
      }
    },

    async aoFechar (event) {
      if (event === 'ok') {
        this.status = 'is-loading'
        const result = await contadorService.delete(
          '/api/v1/contador/excluir',
          this.contador,
          undefined,
          true
        )
        if (result.status === 204) {
          const itemIndex = this.contadores.findIndex(
            (item) => item.id === this.contador.id
          )
          this.$nextTick(() => {
            const contadores = JSON.parse(JSON.stringify(this.contadores))
            contadores.splice(itemIndex, 1)
            this.contadores = []
            this.contadores = contadores
          })
        }
        this.status = ''
      }
      this.mostrarModal = false
    },
    _tratarPreDelete () {
      setTimeout(() => {
        const { datatable } = this.$refs
        if (datatable && datatable.preDelete) {
          datatable.preDelete = (item) => {
            datatable.dataSource.delete(item)
          }
        }
      }, 50)
    }
  },

  created () {
    this.carregarListagem()
  }

}
</script>

<style lang="scss">
.is-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
