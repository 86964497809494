<template>
  <div v-if="this.modalVisivel" id="pesquisaCBO">
    <pesquisa-padrao
      customSearch
      :titulo="'Pesquisa de CBO'"
      :propDataSource="this.dataSource"
      :propItens="this.cbo"
      :pHideButtonMore="this.hideButtonMore"
      :pStatus="this.status"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.$emit('fechar'); this.termoPesquisado = ''; this._carregarListagem()"
      :pQuantidadeDeItems="this.listaCBOsTamanho"
      :nextPage="this._carregarListagem"
      :pPages="totalPages"
      @search="aoPesquisar"
    />
  </div>
</template>

<script>
import PesquisaPadrao from '@packonline/packonline-reutilizaveis-frontend/components/Pesquisa'
import cbos from './cbos.json'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { searchJson } from '@packonline/packonline-reutilizaveis-frontend/Utils/search-json'

export default {
  name: 'pesquisaNaturezaJuridica',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },
  mixin: [mixinPesquisa],
  inject: ['setActiveSection', 'addShortcut'],
  props: {
    modalVisivel: Boolean
  },

  data () {
    return {
      itens: [],
      cbo: [],
      dataSource: {
        columns:
        [
          {
            name: 'id',
            label: 'Código',
            style: 'width:15%'
          },
          {
            name: 'nome',
            label: 'Função',
            style: 'width:85%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      page: [],
      status: '',
      hideButtonMore: false,
      listaCBOsTamanho: '',
      termoPesquisado: '',
      totalPages: 0,
      shortkeys: shortkeys
    }
  },

  methods: {
    async _carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      if (this.termoPesquisado) {
        this.buscar(this.termoPesquisado, pagina)
      } else {
        try {
          this.cbo = []
          this.page = utils.paginate(JSON.parse(JSON.stringify(cbos.data)), pagina + 1, 20)
          this.cbo = this.page
          this.totalPages = undefined
          this.listaCBOsTamanho = cbos.data.length
          this.status = ''
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    buscar (pesquisa, pagina) {
      this.status = 'is-loading'
      try {
        if (pagina === -1) {
          pagina = 0
        }
        this.cbo = []
        const result = searchJson.findBySearch(pesquisa, cbos.data, pagina)
        this.page = result.content
        this.listaCBOsTamanho = result.total
        this.cbo = this.page
        this.totalPages = result.totalPages
        this.status = ''
      } catch (err) {
        this.status = 'is-danger'
      }
    },

    nextPage (pagina) {
      this.status = 'is-loading'
      try {
        this.cbo = []
        this.page = utils.paginate(JSON.parse(JSON.stringify(cbos.data)), pagina + 1, 20)
        this.cbo = this.page
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    carregarItemSelecionado (cbo) {
      this.termoPesquisado = ''
      this.$emit('carregarItemSelecionado', cbo)
    },

    ordenarGridPorCodigo () {
      this.cbo.sort(function (a, b) {
        if (a.nome === b.nome) {
          return 0
        } else {
          return a.nome > b.nome ? 1 : -1
        }
      })
    },
    fecharPesquisa () {
      this.$emit('fechar')
    },
    aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      this._carregarListagem()
    },
    ligarAtalhos () {
      const section = 'pesquisaCBO'
      this.addShortcut(section, this.shortkeys.KEY_BUSCAR, this.focarNaPesquisa)
      this.addShortcut(section, this.shortkeys.KEY_ESC, this.fecharPesquisa)
    }
  },

  created () {
    this._carregarListagem()
  },
  mounted () {
    this.listaCBOsTamanho = cbos.data.length
    this.ligarAtalhos()
  }
}
</script>

<style scoped>

tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}

</style>
