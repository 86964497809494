<template>
  <Modal tabindex="-1" size="normal" width="900px" :active="true" :title="(props.cadastroEmModoDeVisualizacao ? 'Visualizar ' : 'Adicionar ')  + 'histórico do código de retenção do IRRF'" @close="fecharModalCadastroEdicaoHistoricoRetencao">
    <div style="height: 430px" id="cadastro-edicao-historico" ref="cadastroEdicaoHistorico" class="columns is-multiline">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-3" style="width: 10rem">
            <InputDate
              v-model="dataAlteracao"
              :required="!props.cadastroEmModoDeVisualizacao"
              :disabled="props.cadastroEmModoDeVisualizacao"
              label="Data da alteração"
              :message="erros.dataAlteracao"
              :status="getStatus(erros.dataAlteracao)"
              @update:erro="erros.dataAlteracao = $event"
              @blur="validarDataDeAlteracao"
            />
          </div>
          <div class="column is-3">
            <InputNumber
              v-model="historicoRetencaoComputed.codigoRetencaoAtual"
              maxlength="4"
              label="Código de retenção do IRRF"
              no-point
              :required="!props.cadastroEmModoDeVisualizacao"
              :disabled="props.cadastroEmModoDeVisualizacao"
              :message="erros.codigoRetencaoAtual"
              :status="getStatus(erros.codigoRetencaoAtual)"
              :permite-pontos="false"
              @update:erro="erros.codigoRetencaoAtual = $event.message"
              @blur="validarCodigoDeRetencaoAtual"
            />
          </div>
          <div class="column is-6">
            <Pais
              :codigo="historicoRetencaoComputed.codigoPais"
              :message="erros.codigoPais"
              :reagir-a-mudanca-de-prop="true"
              :required="!props.cadastroEmModoDeVisualizacao"
              :disabled="props.cadastroEmModoDeVisualizacao"
              message-erro-empty="Informe o país"
              data-group="pais"
              @update-erros="aoAtualizarErrosPais"
              @update-pais-selecionado="aoAtualizarPais"
              @ao-pressionar-enter="aoPressionarEnterOuTabNoCampoPais"
            />
          </div>
        </div>
      </div>
      <div class="column is-12">
        <FieldSet legend="Informações para residentes no exterior" legend-size="small" >
          <div class="columns">
            <div class="column is-4">
              <ComboBox
                  :key="keyComboBox"
                  v-model="indicativoNIFAtual"
                  label="Indicativo de NIF"
                  :required="habilitarCamposParaExterior && !props.cadastroEmModoDeVisualizacao"
                  :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
                  :options="opcoes.indicativoNIFAtual"
                  :message="erros.indicativoNIFAtual"
                  :status="getStatus(erros.indicativoNIFAtual)"
                  @update:model-value="erros.indicativoNIFAtual = ''"
                  @update:erro="erros.indicativoNIFAtual = $event.message"
                  @change="aoAtualizarIndicativoDeNIFAtual"
                  @blur="validarIndicativoDeNIFAtual"
                />
            </div>
            <div class="column is-4">
              <InputText
                  v-model="historicoRetencaoComputed.numeroNIFAtual"
                  label="NIF"
                  maxlength="30"
                  :required="habilitarCampoNumeroNIFAtual && !props.cadastroEmModoDeVisualizacao"
                  :disabled="!habilitarCampoNumeroNIFAtual || props.cadastroEmModoDeVisualizacao"
                  :message="erros.numeroNIFAtual"
                  :status="getStatus(erros.numeroNIFAtual)"
                  @update:erro="erros.numeroNIFAtual = $event.message"
                  @blur="validarNumeroDeNIFAtual"
                />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <ComboBox
                :key="keyComboBox"
                v-model="formaTributacaoAtual"
                label="Forma de tributação"
                :required="habilitarCamposParaExterior && !props.cadastroEmModoDeVisualizacao"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
                :options="opcoes.formaTributacaoAtual"
                :message="erros.formaTributacaoAtual"
                :status="getStatus(erros.formaTributacaoAtual)"
                @update:model-value="erros.formaTributacaoAtual = ''"
                @update:erro="erros.formaTributacaoAtual = $event.message"
                @blur="validarFormaTributacaoAtual"
              />
            </div>
          </div>
        </FieldSet>
      </div>
      <div class="column is-12">
        <div class="columns is-justify-content-space-between mb-0">
          <div class="column">
            <FieldSet legend="Endereço e contato" legend-size="small" />
          </div>
          <div class="column" style="text-align: end">
            <a
              data-group="link-importar-endereco"
              :style="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao ? 'text-decoration: none; color: #AFB5B7; cursor: default' : 'text-decoration: underline;'"
              @click="importarEnderecoDoSocio">
                Importar endereço e telefone do cadastro do sócio
            </a>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <InputText
                v-model="historicoRetencaoComputed.codigoPostalAtual"
                label="Código postal"
                maxlength="12"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-5">
            <InputText
                v-model="historicoRetencaoComputed.logradouroAtual"
                label="Logradouro"
                maxlength="80"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-2">
            <InputText
                v-model="historicoRetencaoComputed.numeroAtual"
                label="Número"
                maxlength="10"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-3">
            <InputText
                v-model="historicoRetencaoComputed.complementoAtual"
                label="Complemento"
                maxlength="30"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <InputText
                v-model="historicoRetencaoComputed.bairroAtual"
                label="Bairro"
                maxlength="60"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-3">
            <InputText
                v-model="historicoRetencaoComputed.cidadeAtual"
                label="Cidade"
                maxlength="40"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-3">
            <InputText
                v-model="historicoRetencaoComputed.estadoAtual"
                label="Estado"
                maxlength="40"
                :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
              />
          </div>
          <div class="column is-3">
            <InputNumber
              v-model="historicoRetencaoComputed.telefoneAtual"
              maxlength="15"
              label="Telefone"
              no-point
              :disabled="!habilitarCamposParaExterior || props.cadastroEmModoDeVisualizacao"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <ButtonAjuda :title="shortkeys.MSG_KEY_AJUDA" :link-ajuda="LINK_AJUDA" />
      <Button v-if="!props.cadastroEmModoDeVisualizacao" class="is-primary mr-2" caption="Adicionar" :title="shortkeys.MSG_KEY_GRAVAR" @click="salvarHistorico" />
      <Button :caption="props.cadastroEmModoDeVisualizacao ? 'Fechar' : 'Cancelar'" :title="shortkeys.MSG_KEY_ESC" @click="fecharModalCadastroEdicaoHistoricoRetencao" />
    </template>
  </Modal>
</template>
<script setup>
import { computed, defineEmits, defineProps, inject, nextTick, onMounted, ref } from 'vue'

import Button from '@alterdata/component-vue/Button'
import Modal from '@alterdata/component-vue/Modal'
import ComboBox from '@alterdata/component-vue/ComboBox/index.vue'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'

import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import InputNumber from '@packonline/packonline-reutilizaveis-frontend/components/InputNumber.vue'
import FieldSet from '@packonline/packonline-reutilizaveis-frontend/components/FieldSet.vue'
import Pais from '@packonline/packonline-reutilizaveis-frontend/components/Pais/Pais.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'

import HistoricoRetencaoIrrf from '../model/historico-retencao-irrf'

const API_SOCIO = '/api/v1/socio'

const LINK_AJUDA = 'https://ajuda.alterdata.com.br/pod/realizar-cadastros/cadastrar-empresa'

const setActiveSection = inject('setActiveSection')

const addShortcut = inject('addShortcut')

const adicionarNotificacao = inject('adicionarNotificacao')

const { getStatus } = mixin.methods

const emit = defineEmits(['fechar', 'aoAtualizarHistoricosDeRetencaoASeremCriados'])

const ERROS = () => ({
  socioId: '',
  dataAlteracao: '',
  codigoRetencaoAtual: '',
  codigoPais: '',
  indicativoNIFAtual: '',
  numeroNIFAtual: '',
  formaTributacaoAtual: '',
  codigoPostalAtual: '',
  logradouroAtual: '',
  numeroAtual: '',
  complementoAtual: '',
  bairroAtual: '',
  cidadeAtual: '',
  estadoAtual: '',
  telefoneAtual: ''
})

const opcoes = {
  indicativoNIFAtual: ['Beneficiário com NIF', 'Beneficiário dispensado de NIF', 'País não exige NIF'],
  formaTributacaoAtual: [
    'Não retenção do IRRF - Alíquota zero prevista em lei interna',
    'Não retenção do IRRF - Isenção estabelecida em convênio',
    'Não retenção do IRRF - Isenção prevista em lei interna',
    'Não retenção do IRRF - Medida judicial',
    'Não retenção do IRRF - Outras hipóteses',
    'Não retenção do IRRF - Pagamento antecipado do imposto',
    'Retenção do IRRF - Alíquota da tabela progressiva',
    'Retenção do IRRF - Alíquota diferenciada (países com tributação favorecida)',
    'Retenção do IRRF - Alíquota limitada conforme cláusula em convênio',
    'Retenção do IRRF - Alíquota padrão',
    'Retenção do IRRF - Outras hipóteses'
  ]
}

const mapeadores = {
  indicativoNIFAtual: {
    siglaParaSignificado: {
      1: 'Beneficiário com NIF',
      2: 'Beneficiário dispensado de NIF',
      3: 'País não exige NIF'
    },
    significadoParaSigla: {
      'Beneficiário com NIF': 1,
      'Beneficiário dispensado de NIF': 2,
      'País não exige NIF': 3
    }
  },
  formaTributacaoAtual: {
    siglaParaSignificado: {
      10: 'Retenção do IRRF - Alíquota padrão',
      11: 'Retenção do IRRF - Alíquota da tabela progressiva',
      12: 'Retenção do IRRF - Alíquota diferenciada (países com tributação favorecida)',
      13: 'Retenção do IRRF - Alíquota limitada conforme cláusula em convênio',
      30: 'Retenção do IRRF - Outras hipóteses',
      40: 'Não retenção do IRRF - Isenção estabelecida em convênio',
      41: 'Não retenção do IRRF - Isenção prevista em lei interna',
      42: 'Não retenção do IRRF - Alíquota zero prevista em lei interna',
      43: 'Não retenção do IRRF - Pagamento antecipado do imposto',
      44: 'Não retenção do IRRF - Medida judicial',
      50: 'Não retenção do IRRF - Outras hipóteses'
    },
    significadoParaSigla: {
      'Retenção do IRRF - Alíquota padrão': 10,
      'Retenção do IRRF - Alíquota da tabela progressiva': 11,
      'Retenção do IRRF - Alíquota diferenciada (países com tributação favorecida)': 12,
      'Retenção do IRRF - Alíquota limitada conforme cláusula em convênio': 13,
      'Retenção do IRRF - Outras hipóteses': 30,
      'Não retenção do IRRF - Isenção estabelecida em convênio': 40,
      'Não retenção do IRRF - Isenção prevista em lei interna': 41,
      'Não retenção do IRRF - Alíquota zero prevista em lei interna': 42,
      'Não retenção do IRRF - Pagamento antecipado do imposto': 43,
      'Não retenção do IRRF - Medida judicial': 44,
      'Não retenção do IRRF - Outras hipóteses': 50
    }
  }
}

const props = defineProps({
  socemp: {
    type: Object,
    required: true
  },
  codigoDeRetencaoDoQuickView: {
    type: String,
    default: ''
  },
  historicoRetencao: {
    type: HistoricoRetencaoIrrf,
    required: true
  },
  historicoRetencaoAnterior: {
    type: HistoricoRetencaoIrrf,
    required: true
  },
  cadastroEmModoDeVisualizacao: {
    type: Boolean
  }
})

const erros = ref(ERROS())
const cadastroEdicaoHistorico = ref(null)
const habilitarCamposParaExterior = ref(false)
const habilitarCampoNumeroNIFAtual = ref(false)
const keyComboBox = ref(0)

const historicoRetencaoComputed = computed({
  get: () => props.historicoRetencao,
  set: (val) => emit('update:historicoRetencao', val)
})

const dataAlteracao = computed({
  get () {
    return utils.formatarDataParaExibicao(historicoRetencaoComputed.value.dataAlteracao)
  },
  set (val) {
    historicoRetencaoComputed.value.dataAlteracao = utils.formatarCampoDataParaEnvioAoBackend(val)
  }
})

const indicativoNIFAtual = computed({
  get () {
    const { indicativoNIFAtual } = historicoRetencaoComputed.value

    return mapeadores.indicativoNIFAtual.siglaParaSignificado[indicativoNIFAtual]
  },
  set (val) {
    historicoRetencaoComputed.value.indicativoNIFAtual = mapeadores.indicativoNIFAtual.significadoParaSigla[val]
  }
})

const formaTributacaoAtual = computed({
  get () {
    const { formaTributacaoAtual } = historicoRetencaoComputed.value

    return mapeadores.formaTributacaoAtual.siglaParaSignificado[formaTributacaoAtual]
  },
  set (val) {
    historicoRetencaoComputed.value.formaTributacaoAtual = mapeadores.formaTributacaoAtual.significadoParaSigla[val]
  }
})

function validarDataDeAlteracao () {
  HistoricoRetencaoIrrf.validarDataDeAlteracao(historicoRetencaoComputed.value.dataAlteracao, erros.value, props.socemp, props.historicoRetencaoAnterior)
}

function validarCodigoDeRetencaoAtual () {
  if (/^\d+$/.test(historicoRetencaoComputed.value.codigoRetencaoAtual)) {
    historicoRetencaoComputed.value.codigoRetencaoAtual = utils.strZero(historicoRetencaoComputed.value.codigoRetencaoAtual, 4)
  }
  HistoricoRetencaoIrrf.validarCodigoDeRetencaoAtual(historicoRetencaoComputed.value.codigoRetencaoAtual, erros.value)
}

function aoAtualizarErrosPais (event) {
  erros.value.codigoPais = event
  if (erros.value.codigoPais) {
    habilitarCamposParaExterior.value = false
    habilitarCampoNumeroNIFAtual.value = false
    limparCamposAoSelecionarBrasil()
  }

  keyComboBox.value++
}

function aoAtualizarPais (event) {
  historicoRetencaoComputed.value.codigoPais = event.codigo
  if (!historicoRetencaoComputed.value.codigoPais || historicoRetencaoComputed.value.codigoPais === '105') {
    habilitarCamposParaExterior.value = false
    habilitarCampoNumeroNIFAtual.value = false
    limparCamposAoSelecionarBrasil()
  } else {
    habilitarCamposParaExterior.value = true
  }

  keyComboBox.value++
  registrarAtalhosModalHistoricoRetencaoCadastroEdicao()
}

function aoPressionarEnterOuTabNoCampoPais () {
  setTimeout(() => {
    utils.focarNoPrimeiroElementoValido(cadastroEdicaoHistorico, nextTick, 'select[data-group="indicativo-de-nif-select"]')
  }, 50)
}

function limparCamposAoSelecionarBrasil () {
  historicoRetencaoComputed.value.indicativoNIFAtual = ''
  historicoRetencaoComputed.value.numeroNIFAtual = ''
  historicoRetencaoComputed.value.formaTributacaoAtual = ''
  historicoRetencaoComputed.value.codigoPostalAtual = ''
  historicoRetencaoComputed.value.logradouroAtual = ''
  historicoRetencaoComputed.value.numeroAtual = ''
  historicoRetencaoComputed.value.complementoAtual = ''
  historicoRetencaoComputed.value.bairroAtual = ''
  historicoRetencaoComputed.value.cidadeAtual = ''
  historicoRetencaoComputed.value.estadoAtual = ''
  historicoRetencaoComputed.value.telefoneAtual = ''
  erros.value.indicativoNIFAtual = ''
  erros.value.numeroNIFAtual = ''
  erros.value.formaTributacaoAtual = ''
}

function validarIndicativoDeNIFAtual () {
  HistoricoRetencaoIrrf.validarIndicativoDeNIFAtual(historicoRetencaoComputed.value, erros.value)
}

function aoAtualizarIndicativoDeNIFAtual () {
  if (historicoRetencaoComputed.value.indicativoNIFAtual === 1) {
    habilitarCampoNumeroNIFAtual.value = true
  } else {
    habilitarCampoNumeroNIFAtual.value = false
    historicoRetencaoComputed.value.numeroNIFAtual = ''
    erros.value.numeroNIFAtual = ''
  }
}

function validarNumeroDeNIFAtual () {
  HistoricoRetencaoIrrf.validarNumeroDeNIFAtual(historicoRetencaoComputed.value, erros.value)
}

function validarFormaTributacaoAtual () {
  HistoricoRetencaoIrrf.validarFormaTributacaoAtual(historicoRetencaoComputed.value, erros.value)
}

async function importarEnderecoDoSocio () {
  if (habilitarCamposParaExterior.value && !props.cadastroEmModoDeVisualizacao) {
    const res = await service.get(`${API_SOCIO}/${props.socemp.codigoSocio}`)
    if (res) {
      historicoRetencaoComputed.value.codigoPostalAtual = res.basico.enderecoCodigoPostal
      historicoRetencaoComputed.value.logradouroAtual = res.basico.endereco
      historicoRetencaoComputed.value.numeroAtual = res.basico.numero
      historicoRetencaoComputed.value.complementoAtual = res.basico.complemento
      historicoRetencaoComputed.value.bairroAtual = res.basico.bairro
      historicoRetencaoComputed.value.cidadeAtual = res.basico.cidade
      historicoRetencaoComputed.value.telefoneAtual = res.basico.telefone
    }

    if (!res.basico.enderecoCodigoPostal && !res.basico.endereco && !res.basico.numero && !res.basico.complemento &&
    !res.basico.bairro && !res.basico.cidade && !res.basico.telefone) {
      const notificacao = new Toast({
        tipo: 'info',
        titulo: 'Nenhuma informação foi importada.',
        subtitulo: (
          <div>
            <p data-group="txt-subtitulo-toast">
              Os dados de endereço e contato não estão preenchidos no cadastro do sócio.
            </p>
            <br />
          </div>
        ),
        subtituloIsJSX: true
      })
      adicionarNotificacao(notificacao)
    }
  }
}

async function salvarHistorico () {
  const valido = await HistoricoRetencaoIrrf.validar(historicoRetencaoComputed.value, erros.value, props.socemp, props.historicoRetencaoAnterior)
  if (valido) {
    historicoRetencaoComputed.value.id = Math.floor(Math.random() * 1000)
    historicoRetencaoComputed.value.temporario = true
    historicoRetencaoComputed.value.socioId = Number(props.socemp.codigoSocio)
    if (props.historicoRetencaoAnterior) {
      historicoRetencaoComputed.value.codigoRetencaoAnterior = props.historicoRetencaoAnterior.codigoRetencaoAtual
      historicoRetencaoComputed.value.codigoPaisAnterior = props.historicoRetencaoAnterior.codigoPais
    } else {
      historicoRetencaoComputed.value.codigoRetencaoAnterior = props.socemp.codigoRetencaoIrrf
    }
    emit('aoAtualizarHistoricosDeRetencaoASeremCriados', historicoRetencaoComputed.value)
    fecharModalCadastroEdicaoHistoricoRetencao()
  } else {
    utils.focarNoPrimeiroElementoComErro({ elementoPai: cadastroEdicaoHistorico.value })
  }
}

function fecharModalCadastroEdicaoHistoricoRetencao () {
  const section = 'modalHistoricoRetencao'
  setActiveSection(section)
  emit('fechar')
}

function registrarAtalhosModalHistoricoRetencaoCadastroEdicao () {
  const section = 'modalHistoricoRetencaoCadastroEdicao'
  setActiveSection(section)
  addShortcut(section, shortkeys.KEY_GRAVAR, salvarHistorico)
  addShortcut(section, shortkeys.KEY_ESC, fecharModalCadastroEdicaoHistoricoRetencao)
  addShortcut(section, shortkeys.KEY_AJUDA, abrirAjuda)
  addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirAjuda)
}

function abrirAjuda () {
  window.open(LINK_AJUDA)
}

onMounted(() => {
  if (props.codigoDeRetencaoDoQuickView) {
    historicoRetencaoComputed.value.codigoRetencaoAtual = props.codigoDeRetencaoDoQuickView
  }
  registrarAtalhosModalHistoricoRetencaoCadastroEdicao()
  setTimeout(() => {
    utils.focarNoPrimeiroElementoValido(cadastroEdicaoHistorico, nextTick, 'input[data-group="data-da-alteracao-input"]')
  }, 50)
})
</script>
<style>
/* Uso de style global apenas temporário até ajustar os componentes */

#cadastro-edicao-historico div.select {
  width: 100%;
}

#cadastro-edicao-historico div.dropzone {
  padding: 25px 0px 5px 0px;
}
</style>
