<template>
  <div v-if="this.abaVisivel" id="abaGeral" class="pb-1">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data id="inicioAtividade" label="Início da atividade" v-model="this.geral.dataInicioAtividade"
            @keydown.shift.tab.prevent="abaHandler('básico', null, '#email')"
            :modelValue="this.geral.dataInicioAtividade" :status="this._obterStatus(this.erros.dataInicioAtividade)"
            @update:erro="this.erros.dataInicioAtividade = $event" :message="this.erros.dataInicioAtividade" required
            :disabled="this.itemEmpresa.empresaAtiva" :key="updateKey" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data id="fimAtividade" label="Fim da atividade" v-model="this.geral.dataFimAtividade"
            :modelValue="this.geral.dataFimAtividade" :status="this._obterStatus(this.erros.dataFimAtividade)"
            @update:erro="this.erros.dataFimAtividade = $event" :message="this.erros.dataFimAtividade"
            :disabled="this.itemEmpresa.empresaAtiva" :key="updateKey" />
        </div>
        <div v-if="isPessoaFisica()" class="field column is-4" style="padding-bottom: 0rem; margin: 0">
          <input-data id="nascimentoResponsavel" label="Data de nascimento do responsável"
            v-model="this.geral.dtNascimento" :status="this._obterStatus(this.erros.dtNascimento)"
            :message="this.erros.dtNascimento" :disabled="this.itemEmpresa.empresaAtiva" :key="updateKey"
            :required="isPessoaFisica()"
            @update:erro="this.erros.dtNascimento = $event === 'Informe a data' ? 'Informe a data de nascimento do responsável pela empresa' : $event"
            @update:valid="this.$emit('validarDataNascimento', $event)" />
        </div>
        <label class="label column is-12" for="CNAE" style="
            font-size: 1rem;
            padding-bottom: 0rem;
            margin-bottom: 0;
            font-weight: bold;
          ">CNAE</label>
        <div class="field column is-8" style="padding-bottom: 0rem; margin: 0">
          <campo-texto-adicional label="Código" id="CNAEFiscal" widthDiv="25%" input-disabled required
            v-model="this.geral.codigoCNAE" :external-value="this.descricaoCnaeFiscal" :message="this.erros.cnae"
            :status="getStatus(this.erros.cnae)" :disabled="false" @keypress="onlyForRegex($event, /[\d-/]/g)"
            @click="abrirFecharPesquisaCNAE()" @blur="_retornarCNAE(this.geral.codigoCNAE)" />
        </div>
        <div class="column is-1" style="
            padding-bottom: 0rem;
            margin: 0;
            width: 120px;
            padding-top: 0.8rem;
          ">
          <label :disabled="this.itemEmpresa.empresaAtiva" class="label" for="CNAE" :style="this.itemEmpresa.empresaAtiva
              ? 'margin-bottom: 0;font-size: 0.875rem; color:#afb5b7'
              : 'margin-bottom: 0;font-size: 0.875rem;'
            ">Histórico</label>
          <button id="botao-historico" class="button" @click="abrirFecharCnae"
            :disabled="!this.editando || this.itemEmpresa.empresaAtiva">
            Cadastrar
          </button>
        </div>
        <div class="column is-2" style="padding-bottom: 0rem; margin: 0; padding-top: 0.8rem">
          <label :disabled="!this.editando || this.itemEmpresa.empresaAtiva" class="label" for="CNAESecundario" :style="this.itemEmpresa.empresaAtiva
              ? 'margin-bottom: 0;font-size: 0.875rem; color:#afb5b7'
              : 'margin-bottom: 0;font-size: 0.875rem;'
            ">Código secundário</label>
          <button class="button" :disabled="!this.editando || this.itemEmpresa.empresaAtiva"
            @click="abrirFecharCnaeSecundario">
            Cadastrar
          </button>
        </div>
        <label class="label column is-12" for="Outras" style="
            font-size: 1rem;
            padding-bottom: 0rem;
            margin-bottom: 0;
            font-weight: bold;
          ">Outras informações cadastrais</label>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0; width: 180px">
          <campo-texto id="inscricaoMunicipal" label="Inscrição municipal" maxlength="16"
            @update:erro="this.erros.inscricaoMunicipal = $event.message"
            @update:model-value="this.geral.inscricaoMunicipal = $event"
            :status="this.getStatus(this.erros.inscricaoMunicipal)" v-model="this.geral.inscricaoMunicipal"
            :message="this.erros.inscricaoMunicipal" :model-value="this.geral.inscricaoMunicipal"
            :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0; width: 180px">
          <inscricao-estadual-verificador exibe-label @change-inscricao-estadual="this.geral.inscricaoEstadual = $event"
            :uf="this.itemEmpresa.uf" :p-inscricaoEstadual="this.geral.inscricaoEstadual" :erros="this.erros"
            :required="this.editando" :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <div class="field has-addons" style="margin-bottom: 0">
            <campo-texto id="naturezaJuridica" label="Natureza jurídica" maxlength="4"
              @update:erro="this.erros.codigoNaturezaJuridica = $event.message"
              @update:model-value="this.geral.codigoNaturezaJuridica = $event"
              :status="this.getStatus(this.erros.codigoNaturezaJuridica)" :model-value="this.geral.codigoNaturezaJuridica"
              v-model="this.geral.codigoNaturezaJuridica" :value="this.geral.codigoNaturezaJuridica"
              @blur="this._buscarNaturezaJuridicaDigitada()" @keypress="this.onlyNumber()"
              :message="this.erros.codigoNaturezaJuridica" required :disabled="this.itemEmpresa.empresaAtiva" />
            <div class="control" style="padding-top: 1.3rem">
              <a class="button" v-if="this.itemEmpresa.empresaAtiva" disabled>
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a class="button" v-else @click="abrirFecharPesquisaNaturezaJuridica">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </div>
          </div>
        </div>
        <div class="field column is-4" style="
            padding-bottom: 0rem;
            margin: 0;
            padding-top: 2rem;
            padding-left: 0rem;
            width: 327px;
          ">
          <div class="control">
            <campo-texto id="naturezaDescricao" v-model="this.descricaoNatureza" disabled />
          </div>
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <campo-texto id="registrooab" label="Registro na OAB" maxlength="15"
            @update:erro="this.erros.registroOab = $event.message" :status="this.getStatus(this.erros.registroOab)"
            v-model="this.geral.numeroRegistroOAB" @keypress="this.onlyNumber()" :message="this.erros.registroOab"
            :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <label class="required" :style="this.itemEmpresa.empresaAtiva
              ? 'margin-bottom: 0;font-size: 0.875rem; color:#afb5b7'
              : 'margin-bottom: 0;font-size: 0.875rem;'
            ">Valor do capital</label>
          <div class="control">
            <input-valor-monetario :pId="'valorCapital'" :pClass="'input'"
              :class="{ 'is-danger': this.erros.valorCapital }" :pValue="this.geral.valorCapital" :pRequired="true"
              :pMaxlength="12" :pQtdDecimais="2" :pExibePrefixo="false" @change="this._alterouCapital"
              @blur="(event) => this.setValor(event.target.value)" @keypress="onlyNumber($event)"
              :disabled="this.itemEmpresa.empresaAtiva" />
          </div>
          <p class="help is-danger">{{ erros.valorCapital }}</p>
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <campo-texto id="suframa" label="Inscrição suframa" maxlength="9" v-model="this.geral.numeroSuframa"
            @update:erro="this.erros.suframa = $event.message" :message="this.erros.suframa"
            :status="this.getStatus(this.erros.suframa)" :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <ComboBox id="calculoIR" label="Tipo de cálculo do IR" v-model="tipoCalculoIR" :key="comboBoxCalculoIRKey"
            :options="opcoes" :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <label class="label column is-12" for="NireCartorio" style="
            font-size: 1rem;
            padding-bottom: 0rem;
            margin-bottom: 0;
            font-weight: bold;
          ">Nire / Cartório</label>
        <div class="field column is-4" style="padding-bottom: 0rem; margin: 0">
          <campo-texto id="regCartorio" label="Registro em cartório (livro / folha)" maxlength="25"
            @update:erro="this.erros.registroCartorio = $event.message"
            @update:model-value="this.geral.numeroRegistroCartorio = $event"
            :model-value="this.geral.numeroRegistroCartorio" :status="this.getStatus(this.erros.registroCartorio)"
            v-model="this.geral.numeroRegistroCartorio" :message="this.erros.registroCartorio"
            :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <campo-texto id="nire" label="Código nire" maxlength="15" @update:model-value="this.geral.codigoNire = $event"
            @update:erro="this.erros.codigoNire = $event.message" :status="this.getStatus(this.erros.codigoNire)"
            :model-value="this.geral.codigoNire" v-model="this.geral.codigoNire" @change="this._alterouNire"
            :message="this.erros.codigoNire" :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data id="dataNire" label="Data nire ou cartório" v-model="this.geral.dataExpedicaoNire"
            :modelValue="this.geral.dataExpedicaoNire" :status="this._obterStatus(this.erros.dataExpedicaoNire)"
            :message="erros.dataExpedicaoNire" @update:valid="this.$emit('validarDataNire', $event)"
            @update:erro="this.erros.dataExpedicaoNire = $event" :disabled="this.itemEmpresa.empresaAtiva" />
        </div>
        <div class="field column is-2" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="dataNire" :style="this.itemEmpresa.empresaAtiva
              ? 'margin-bottom: 0;font-size: 0.875rem; color:#afb5b7'
              : 'margin-bottom: 0;font-size: 0.875rem;'
            ">Histórico de nire</label>
          <button class="button" @click="abrirFecharHistoricoNire"
            :disabled="!this.editando || this.itemEmpresa.empresaAtiva">
            Cadastrar
          </button>
        </div>
        <div :class="this.tamanhoAreaRegistroJunta" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="junta" style="font-size: 1rem; font-weight: bold">Registro na junta comercial</label>
          <div class="columns">
            <div class="field column is-6">
              <campo-texto id="numeroJunta" label="Número" maxlength="12"
                :model-value="this.geral.numeroRegistroJuntaComercial" :status="this.getStatus(this.erros.numeroJunta)"
                v-model="this.geral.numeroRegistroJuntaComercial" :message="this.erros.numeroJunta"
                :disabled="this.itemEmpresa.empresaAtiva" @update:model-value="this.geral.numeroRegistroJuntaComercial = $event
                  " @update:erro="this.erros.numeroJunta = $event.message" />
            </div>
            <div class="column is-6">
              <input-data id="dataJunta" label="Data" v-model="this.geral.dataRegistroJuntaComercial"
                :modelValue="this.geral.dataRegistroJuntaComercial" :status="this._obterStatus(this.erros.dataRegistroJuntaComercial)
                  " @update:valid="this.$emit('validarDataJunta', $event)"
                @update:erro="this.erros.dataRegistroJuntaComercial = $event" :message="erros.dataRegistroJuntaComercial"
                :disabled="this.itemEmpresa.empresaAtiva"
                @keydown.prevent.enter.exact="abaHandler('complementar', 'contábil', '#endereco')" />
            </div>
          </div>
        </div>
        <!-- O trecho abaixo será incluído em outra aba, em um outro processo -->
        <!-- <div :class="this.tamanhoAreaEntradaSaidaEscritorio" style="padding-bottom: 0rem;margin: 0;">
              <label class="label" for="junta" style="font-size:1rem;">Entrada/Saída da empresa no escritório</label>
              <div class="columns">
                <div class="field column is-6">
                  <div class="control">
                    <input-data id="entrada" label='Entrada' v-model="this.geral.escritorioDataEntrada"
                      :modelValue="this.geral.escritorioDataEntrada" :status="this._obterStatus(this.erros.escritorioDataEntrada)"
                      @update:erro="this.erros.escritorioDataEntrada=$event" />
                  </div>
                </div>
                <div class="field column is-6">
                  <div class="control">
                      <input-data id="saida" label='Saída' v-model="this.geral.escritorioDataSaida"
                        :modelValue="this.geral.escritorioDataSaida" :status="this._obterStatus(this.erros.escritorioDataSaida)"
                        @update:erro="this.erros.escritorioDataSaida=$event" />
                  </div>
                </div>
              </div>
            </div> -->
        <!-- <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        </div> -->
      </div>
    </div>
    <pesquisa-natureza :modalVisivel="abrirConsultaNaturezas" @fechar="this.abrirFecharPesquisaNaturezaJuridica"
      @carregarItemSelecionado="_preencherNaturezaJuridicaSelecionada" />
    <pesquisa-cnae :modalVisivel="abrirConsultaCNAES" @fechar="this.abrirFecharPesquisaCNAE"
      @carregarItemSelecionado2="_preencherCNAESelecionado" />
    <cadastro-edicao-cnae :modalVisivel="this.abrirCadastroCnae" :itemEmpresa="this.itemEmpresa" :cnae="this.itemCnae"
      :cadastrandoPelaAbaGeral="true" @cancel="_cancelarCadastroDeCnae()" @salvarHistoricoCNAE="_salvarHistoricoCNAE()" />
    <modalAviso v-if="this.avisoVisivel" :textoAviso="this.textoAviso" @on-ok="this._abreFechaAviso" />
  </div>
</template>

<script>
import Empresa from './model/empresa.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import PesquisaNatureza from './NaturezaJuridica/pesquisaNaturezaJuridica.vue'
import PesquisaCNAE from './Cnae/pesquisaCnae.vue'
import cadastroEdicaoCnae from './Cnae/cadastroEdicaoCnae.vue'
import EmpresaHistorico from './Cnae/model/empresa-historico.js'
import { maska } from 'maska'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { service } from '../../service.js'
import modalAviso from '../../components/ModalAviso/index.vue'
import InputValorMonetario from '../../components/InputValorMonetario/index.vue'
import InscricaoEstadualVerificador from '../../components/InscricaoEstadual/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import ComboBox from '@alterdata/component-vue/ComboBox/index.vue'
import InputAddon from '../../components/InputAddon/index.vue'

export default {
  name: 'abaGeral',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    montarObjetoCnae: Function,
    montarObjetoSocemp: Function,
    montarObjetoHistoricoNire: Function,
    itemEmpresa: Empresa,
    errosValidacao: Object,
    editando: Boolean,
    exibindoCadastroSecundario: Boolean,
    cnaeAlterado: String,
    itemDadosReceita: Object
  },
  directives: {
    maska
  },
  components: {
    modalAviso,
    'inscricao-estadual-verificador': InscricaoEstadualVerificador,
    'pesquisa-natureza': PesquisaNatureza,
    'pesquisa-cnae': PesquisaCNAE,
    'cadastro-edicao-cnae': cadastroEdicaoCnae,
    'input-data': InputDate,
    'campo-texto': InputText,
    'input-valor-monetario': InputValorMonetario,
    'campo-texto-adicional': InputAddon,
    ComboBox
  },

  mixins: [mixin],
  inject: ['abaHandler'],
  data () {
    return {
      updateKey: 0,
      geral: {
        dataInicioAtividade: utils.formatarDataParaExibicao(
          this.itemEmpresa.dataInicioAtividade
        ),
        dataFimAtividade: utils.formatarDataParaExibicao(
          this.itemEmpresa.dataFimAtividade
        ),
        dtNascimento: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtNascimento
        ),
        inscricaoMunicipal: this.itemEmpresa.inscricaoMunicipal,
        inscricaoEstadual: this.itemEmpresa.inscricaoEstadual,
        numeroRegistroOAB: this.itemEmpresa.numeroRegistroOAB,
        valorCapital: this.itemEmpresa.valorCapital,
        numeroSuframa: this.itemEmpresa.numeroSuframa,
        numeroRegistroCartorio: this.itemEmpresa.numeroRegistroCartorio,
        codigoNire: this.itemEmpresa.codigoNire,
        dataExpedicaoNire: utils.formatarDataParaExibicao(
          this.itemEmpresa.dataExpedicaoNire
        ),
        codigoNaturezaJuridica: this.itemEmpresa.codigoNaturezaJuridica,
        codigoCNAE: this.itemEmpresa.codigoCNAE,
        numeroRegistroJuntaComercial:
          this.itemEmpresa.numeroRegistroJuntaComercial,
        dataRegistroJuntaComercial: utils.formatarDataParaExibicao(
          this.itemEmpresa.dataRegistroJuntaComercial
        ),
        escritorioDataEntrada: utils.formatarDataParaExibicao(
          this.itemEmpresa.escritorioDataEntrada
        ),
        escritorioDataSaida: utils.formatarDataParaExibicao(
          this.itemEmpresa.escritorioDataSaida
        ),
        tipocalculoImpostoRenda: this.itemEmpresa.tipocalculoImpostoRenda
      },
      erros: this.errosValidacao,
      abrirListagemHistoricoNire: false,
      abrirListagemCnaeSecundario: false,
      abrirListagemCnae: false,
      colunas: [
        { nomeColuna: 'codigo', descricaoColuna: 'Código' },
        { nomeColuna: 'descricao', descricaoColuna: 'Descrição' }
      ],
      naturezas: [],
      abrirConsultaNaturezas: false,
      abrirConsultaCNAES: false,
      abrirCadastroCnae: false,
      itemCnae: new EmpresaHistorico(),
      itemSocemp: {
        id: null,
        vlcapital: null
      },
      tamanhoAreaRegistroJunta: 'column is-6',
      tamanhoAreaEntradaSaidaEscritorio: 'column is-6',
      cnaeAnterior: null,
      avisoVisivel: false,
      textoAviso: null,
      historicoNire: {
        codigo: this.itemEmpresa.codigoNire,
        data: this.itemEmpresa.dataExpedicaoNire
      },
      descricaoCnaeFiscal: this.itemEmpresa.codigoCNAE
        ? this._retornarCNAE(this.itemEmpresa.codigoCNAE)
        : null,
      descricaoNatureza: this.itemEmpresa.codigoNaturezaJuridica
        ? this._retornarNaturezaJuridica(
          this.itemEmpresa.codigoNaturezaJuridica
        )
        : null,
      opcoes: [
        'Simples',
        'Imunes',
        'Lucro presumido',
        'Lucro real',
        'Isentas',
        'MEI',
        'Pessoa física'
      ],
      tiposCalculoIR: ['S', 'I', 'L', 'R', 'T', 'M', 'X'],
      tipoCalculoIR: '',
      comboBoxCalculoIRKey: 0
    }
  },
  watch: {
    geral: {
      handler: function (val, old) {
        try {
          if (val.dataInicioAtividade) {
            this.erros.dataInicioAtividade = null
          }
          if (val.codigoNaturezaJuridica) {
            this.erros.codigoNaturezaJuridica = null
          }
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    itemDadosReceita: {
      handler: async function (val, old) {
        if (val.contribuinte) {
          this.geral.dataInicioAtividade = utils.formatarDataParaExibicao(
            val.contribuinte.inicioAtividade
          )
          this.updateKey += 1
          this.geral.valorCapital = val.contribuinte.capitalSocial
          this.geral.codigoNaturezaJuridica = val.contribuinte.naturezaJuridica
            .substr(0, 5)
            .replace('-', '')
          this.geral.codigoCNAE =
            val.contribuinte.atividadePrincipal.codigoCNAE.replace(/[^\d]+/g, '')
          await this._buscarNaturezaJuridicaDigitada()
          this.descricaoCnaeFiscal = val.contribuinte.atividadePrincipal.descricao
        } else {
          this.geral.dataInicioAtividade = ((new Date(this.formataDataDadosPublicos(val.abertura)).toISOString()).substring(0, 10))
          this.updateKey += 1
          this.geral.valorCapital = val.capital_social
          this.geral.codigoNaturezaJuridica = val.natureza_juridica
            .substr(0, 5)
            .replace('-', '')
          const cnae = []
          val.atividade_principal.forEach((p) => {
            cnae.push(p.code)
            cnae.push(p.text)
          })
          this.geral.codigoCNAE =
            cnae[0].replace(/[^\d]+/g, '')
          await this._buscarNaturezaJuridicaDigitada()
          this.descricaoCnaeFiscal = cnae[1]
        }
      },
      deep: true
    },
    itemCnae: {
      handler: function (val, old) {
        try {
          if (val.dataInicial && val.empresaID) {
            this.montarObjetoCnae(val)
          }
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    itemSocemp: {
      handler: function (val, old) {
        try {
          this.montarObjetoSocemp(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    exibindoCadastroSecundario: {
      handler: function (val, old) {
        try {
          this._ajustarTamanhoCamposParaAberturaDaTelaSecundaria(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    cnaeAlterado: {
      handler: function (val, old) {
        try {
          this.geral.codigoCNAE = val
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    tipoCalculoIR (val) {
      if (val) {
        this.geral.tipocalculoImpostoRenda =
          this.tiposCalculoIR[this.opcoes.indexOf(val)]
      }
    }
  },
  methods: {

    formataDataDadosPublicos (dateString) {
      const parts = dateString.split('/')
      return parts[1] + '/' + parts[0] + '/' + parts[2]
    },

    abrirFecharHistoricoNire () {
      this.$emit(
        'onAbrirCadastroHistoricoNire',
        'Cadastro do histórico do NIRE'
      )
      this._ajustarTamanhoCamposParaAberturaDaTelaSecundaria()
    },

    abrirFecharCnaeSecundario () {
      this.$emit('onAbrirCadastroCnaeSecundario', 'Cadastro CNAE secundário')
      this._ajustarTamanhoCamposParaAberturaDaTelaSecundaria()
    },

    abrirFecharCnae () {
      this.$emit('onAbrirCadastroHistoricoCnae', 'Cadastro CNAE')
      this._ajustarTamanhoCamposParaAberturaDaTelaSecundaria()
    },

    abrirFecharPesquisaNaturezaJuridica () {
      this.abrirConsultaNaturezas = !this.abrirConsultaNaturezas
      if (!this.abrirConsultaNaturezas) {
        this._selecionarCampoNaturezaJuridicaAoFecharPesquisaDeNaturezaJuridica()
      }
    },

    _preencherNaturezaJuridicaSelecionada (item) {
      this.geral.codigoNaturezaJuridica = item.codigo
      this.descricaoNatureza = item.descricao
      this.abrirFecharPesquisaNaturezaJuridica()
    },

    abrirFecharPesquisaCNAE () {
      this.abrirConsultaCNAES = !this.abrirConsultaCNAES
      if (!this.geral.codigoCNAE) {
        this.erros.cnae = 'Informe o CNAE'
      }
      if (!this.abrirConsultaCNAES) {
        this._selecionarProximoCampoHabilitadoAoFecharPesquisaCNAE()
      }
    },

    _preencherCNAESelecionado (item) {
      this.cnaeAnterior = this.geral.codigoCNAE
      this.descricaoCnaeAnterior = this.descricaoCnaeFiscal
      this.itemCnae.cnae = item.chamada.replace(/[^\d]+/g, '')
      this.geral.codigoCNAE = item.chamada.replace(/[^\d]+/g, '')
      this.descricaoCnaeFiscal = item.descricao
      this.abrirFecharPesquisaCNAE()
      if (this.itemEmpresa.cdempresa) {
        this._abrirFecharCadastroCnae()
      }
      if (!this.geral.codigoCNAE) {
        this.erros.cnae = 'Informe o CNAE'
      } else {
        this.erros.cnae = ''
      }
    },

    _cancelarCadastroDeCnae () {
      this._abrirFecharCadastroCnae()
      this.geral.codigoCNAE = this.cnaeAnterior
      this.descricaoCnaeFiscal = this.descricaoCnaeAnterior
      if (!this.geral.codigoCNAE) {
        this.erros.cnae = 'Informe o CNAE'
      }
    },

    _abrirFecharCadastroCnae () {
      this.abrirCadastroCnae = !this.abrirCadastroCnae
    },
    _salvarHistoricoCNAE (itemCnae) {
      this._abrirFecharCadastroCnae()
      Object.assign(this.itemCnae, itemCnae)
      this._selecionarProximoCampoHabilitadoAoFecharPesquisaCNAE()
    },

    _alterouCapital (e) {
      let valor = e.target.value
      valor = valor.replace(/\.|/g, '')
      this.itemSocemp.id = this.itemEmpresa.cdempresa
      this.itemSocemp.vlcapital = valor.toString().replace(',', '.')
    },

    _selecionarProximoCampoHabilitadoAoFecharPesquisaCNAE () {
      if (this.editando) {
        const button = document.querySelector('button#botao-historico')
        if (button) {
          button.focus()
        }
      } else {
        const input = document.querySelector('input#inscricaoMunicipal')
        if (input) {
          input.focus()
        }
      }
    },

    _selecionarCampoNaturezaJuridicaAoFecharPesquisaDeNaturezaJuridica () {
      const input = document.querySelector('input#naturezaJuridica')
      if (input) {
        input.focus()
      }
    },

    _ajustarTamanhoCamposParaAberturaDaTelaSecundaria (valor) {
      if (valor) {
        this.tamanhoAreaRegistroJunta = 'column is-12'
        this.tamanhoAreaEntradaSaidaEscritorio = 'column is-12'
      } else {
        this.tamanhoAreaRegistroJunta = 'column is-6'
        this.tamanhoAreaEntradaSaidaEscritorio = 'column is-6'
      }
    },

    async _buscarNaturezaJuridicaDigitada () {
      const resposta = await this._retornarNaturezaJuridica(
        this.geral.codigoNaturezaJuridica
      )
      if (!resposta) {
        this.textoAviso =
          'O código digitado não pertence a tabela de natureza jurídica.'
        this.descricaoNatureza = null
        this._abreFechaAviso()
      }
    },

    async _retornarNaturezaJuridica (codigo) {
      const resposta = await service.get('/api/v1/natureza-juridica', codigo)
      if (resposta) {
        this.descricaoNatureza = resposta.descricao
        return true
      } else {
        this.descricaoNatureza = null
        return null
      }
    },

    async _retornarCNAE (codigo) {
      try {
        if (String(codigo).trim()) {
          const resposta =
            await service.get('/api/v1/cnae/chamada', codigo)
          if (resposta) {
            this.descricaoCnaeFiscal = resposta.descricao
            this.erros.cnae = ''
            this.geral.codigoCNAE = codigo
            return true
          }
        } else {
          this.erros.cnae = 'Informe o CNAE'
          this.descricaoCnaeFiscal = null
          return null
        }
      } catch (err) {
        return false
      }
    },

    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    _abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    _alterouNire () {
      this.montarObjetoHistoricoNire(this.historicoNire)
    },

    _obterStatus (value) {
      return !value ? '' : 'error'
    },

    setValor (valor) {
      valor = valor.replace(/\.|/g, '')
      this.geral.valorCapital = valor.toString().replace(',', '.')
    },

    isPessoaFisica () {
      if (this.itemEmpresa.numeroCGC && utils.clearMaskNumber(this.itemEmpresa.numeroCGC).length >= 12) {
        this.dtNascimento = null
      }
      return !!(
        this.itemEmpresa.numeroCGC &&
        utils.clearMaskNumber(this.itemEmpresa.numeroCGC).length === 11
      )
    }
  },
  mounted () {
    this.tipoCalculoIR =
      this.opcoes[this.tiposCalculoIR.indexOf(this.geral.tipocalculoImpostoRenda)]
    this.updateKey += 1
    this.comboBoxCalculoIRKey++
  }
}
</script>

<style></style>
