<template>
  <div v-show="this.abaVisivel" id="abaSped">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div
          style="
            background-color: lightgoldenrodyellow;
            padding: 20px;
            padding-left: 1px;
            padding-right: 120px;
            margin-left: 0.8rem;
          "
          v-if="!this.temContador"
        >
          <p style="font-size: 14px; margin-left: 20px">
            Não existe nenhum auditor cadastrado. Clique em
            <button class="button is-light" style="bottom: 4px" data-group="botao-novo-auditor">
              Novo auditor
            </button>
            para cadastrar um e
          </p>
          <p style="font-size: 14px; margin-left: 20px">
            vincula-lo ao SPED-ECD dessa empresa.
          </p>
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="informatica" style="font-size: 1rem"
            ><strong>Informações da auditoria</strong></label
          >
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <cadastro-auditor
            :itemAuditor="this.itemAuditor"
            :itemEmpresa="this.itemEmpresa"
            :pContadores="this.contadores"
            @armazenarVinculo="this.armazenarVinculo"
            :auditores="this.auditoresParaValidar"
            :errosValidacao="this.errosValidacao"
            ref="cadastro"
          />
          <div
            class="container"
            style="text-align: center; padding-top: 0rem; margin-left: 1rem"
          >
            <Button
              class="button is-light"
              :disabled="this.itemEmpresa.empresaAtiva"
              @click="abrirFecharContadorAuditor"
              :title="shortkeys.MSG_KEY_NOVO"
              data-group="botao-novo-auditor"
            >
              Novo auditor
            </Button>
          </div>
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="informatica" style="font-size: 1rem"
            ><strong>Auditores vinculados</strong></label
          >
        </div>
        <alt-auditor
          ref="listagem"
          :itemEmpresa="this.itemEmpresa"
          :montarObjeto="this.montarObjeto"
          :editandoAuditor="this.editandoAuditor"
          :listaVinculosNovosEditados="this.listaVinculosNovosEditados"
          :pContadores="this.contadores"
          @armazenarVinculoASerExcluido="this.armazenarVinculoASerExcluido"
          :listagemVis="this.alterarListagemVisivel"
          @auditores="this.auditoresParaValidar = $event"
          @editAuditor="this.$emit('editAuditor', $event)"
          @editarAuditor="this.$emit('editarAuditor', $event, $event)"
        />
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label style="font-size: 1rem" class="label" for="junta"
            ><strong>Porte da empresa</strong></label
          >
          <radio-grupo name="rsvd" v-model="this.sped.tipoPorteEmpresa">
            <radio-button
              :disabled="this.itemEmpresa.empresaAtiva"
              :style="this.itemEmpresa.empresaAtiva ? 'color:#afb5b7' : ''"
              id="pequeno"
              caption="Pequeno"
              value="0"
            />
            <radio-button
              :disabled="this.itemEmpresa.empresaAtiva"
              :style="this.itemEmpresa.empresaAtiva ? 'color:#afb5b7' : ''"
              id="grande"
              caption="Grande"
              value="1"
            />
          </radio-grupo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empresa from './model/empresa.js'
import auditorIndependente from './AuditorIndependente/index.vue'
import cadastroVinculoAuditor from './AuditorIndependente/cadastroVinculoAuditor.vue'
import Radio from 'alterdata-componente/src/RadioGroup/index.vue'
import RadioButton from 'alterdata-componente/src/Radio/index.vue'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import AuditorIndependente from './AuditorIndependente/model/auditor-independente.js'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import Button from '@alterdata/component-vue/Button'

export default {
  name: 'abaSped',
  components: {
    'alt-auditor': auditorIndependente,
    'cadastro-auditor': cadastroVinculoAuditor,
    'radio-grupo': Radio,
    'radio-button': RadioButton,
    Button
  },
  inject: ['setActiveSection', 'addShortcut', 'direcionaAjuda', 'clickCancelar', 'clickSalvar'],
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    itemAuditor: AuditorIndependente,
    editandoAuditor: Boolean,
    listaVinculosNovosEditados: Array,
    errosValidacao: Object,
    pContadores: Array
  },
  data () {
    return {
      sped: {
        tipoPorteEmpresa: this.itemEmpresa.tipoPorteEmpresa
      },
      abrirListagemAuditor: false,
      contadores: null,
      temContador: null,
      alterarListagemVisivel: null,
      auditoresParaValidar: null,
      shortkeys: shortkeys
    }
  },
  mounted () {
    this.ligarAtalhosSped()
  },
  watch: {
    sped: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    pContadores (val, old) {
      this.contadores = val
    }
  },
  methods: {
    preventNav (bValue) {
      this.$root.dadosAlterados = bValue
    },
    abrirFecharAuditor () {
      this.abrirListagemAuditor = !this.abrirListagemAuditor
    },
    armazenarVinculo (event) {
      this.$emit('armazenarVinculo', event)
      this.alterarListagemVisivel = true
      this.preventNav(true)
    },
    armazenarVinculoASerExcluido (event) {
      this.$emit('armazenarVinculoASerExcluido', event)
      this.preventNav(true)
    },
    abrirFecharContadorAuditor () {
      this.$emit(
        'onAbrirCadastroContadorAuditor',
        'Cadastro de contador/auditor'
      )
    },
    editAuditor (event) {
      this.$emit('editAuditor', event)
    },
    editarAuditor (eventA, eventB) {
      this.$emit('editarAuditor', eventA, eventB)
    },
    ligarAtalhosSped () {
      const section = 'abaComplementarSped'
      this.addShortcut(section, shortkeys.KEY_ESC, this.clickCancelar)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this.clickSalvar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.abrirFecharContadorAuditor)
    }
  },
  async beforeCreate () {
    try {
      this.contadores = await service.get('/api/v1/contador/todos')
      this.contadores.length > 0
        ? (this.temContador = true)
        : (this.temContador = false)
    } catch {
      this.contadores = null
    }
  }
}
</script>
