<template>
  <div v-if="this.abaVisivel" id="abaObservacao">
    <div class="container" style="text-align: left;width:900px; height:300px">
      <div class="columns is-multiline">
        <div class="column is-12" style="padding-left: 0">
          <div class="field column is-12" style="padding-bottom: 0rem;margin: 0">
            <label class="label" :style="this.itemEmpresa.empresaAtiva ? 'font-size: 15px; color:#afb5b7': 'font-size: 15px'" for="observacao">Observação</label>
            <div class="control">
              <textarea
              ref="textarea"
                @keydown.prevent.enter.exact="abaHandler('logomarca', null, '#input-file-input')"
                @keydown.prevent.ctrl.enter="adicionarQuebraDeLinha"
                @keydown.prevent.shift.enter="adicionarQuebraDeLinha"
                @keydown.shift.tab.prevent="abaHandler('complementar', 'manad', '#fimInformatica' )"
                :disabled="this.itemEmpresa.empresaAtiva" :class="{'is-danger': this.getStatus(this.erros.observacao) === 'error'}" id="observacao" maxlength="1000" class="textarea" v-model="this.observacao.observacao" :style="this.itemEmpresa.empresaAtiva? 'height:210px; resize: none': 'height:210px'"></textarea>
              <p class="help is-danger">{{ this.erros.observacao }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empresa from './model/empresa.js'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'

export default {
  name: 'abaObservacao',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    errosValidacao: Object
  },
  mixins: [mixin],
  inject: ['abaHandler'],
  data () {
    return {
      observacao: {
        observacao: this.itemEmpresa.observacao
      },
      erros: this.errosValidacao
    }
  },
  updated () {
    if (this.abaVisivel) {
      this.abaHandler('observacao', null, '#observacao')
    }
  },
  methods: {
    adicionarQuebraDeLinha () {
      if (!this.observacao.observacao) {
        this.observacao.observacao = ''
      }
      this.observacao.observacao += '\n'
    }
  },
  watch: {
    observacao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    }
  },
  unmounted () {
    this.erros.observacao = ''
  }
}
</script>
