<template>
  <div v-if="this.modalVisivel" id="pesquisaNaturezaJuridica">
    <pesquisa-padrao
      customSearch
      :titulo="'Pesquisa de naturezas jurídicas'"
      :propDataSource="this.dataSource"
      :propItens="this.naturezas"
      :pHideButtonMore="this.hideButtonMore"
      :pStatus="this.status"
      :pQuantidadeDeItems="this.page.total"
      :nextPage="this._carregarListagem"
      p-sort="codigo"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.$emit('fechar'); this.termoPesquisa = ''"
      @search="aoPesquisar"
    />
  </div>
</template>

<script>
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import PesquisaPadrao from '@packonline/packonline-reutilizaveis-frontend/components/Pesquisa'

export default {
  name: 'pesquisaNaturezaJuridica',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },

  props: {
    modalVisivel: Boolean
  },

  data () {
    return {
      itens: [],
      dataSource: {
        columns: [
          {
            name: 'codigo',
            label: 'Código',
            style: 'width:15%'
          },
          {
            name: 'descricao',
            label: 'Descrição',
            style: 'width:85%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      page: [],
      status: '',
      hideButtonMore: false
    }
  },

  methods: {
    async _carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        try {
          this.page = await service.get(`/api/v1/natureza-juridica?page=${pagina}`)
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this.naturezas = this.page.content
          this.status = ''
          this.ordenarGridPorCodigo()
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async nextPage (pagina) {
      this.status = 'is-loading'
      try {
        this.naturezas = []
        this.page = await service.get(
          `/api/v1/natureza-juridica?page=${pagina}`
        )
        this.naturezas = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    carregarItemSelecionado (natureza) {
      this.$emit('carregarItemSelecionado', natureza)
    },

    formatarObrigaCCusto (obrigaCentroCusto) {
      obrigaCentroCusto === 't'
        ? (obrigaCentroCusto = 'Sim')
        : (obrigaCentroCusto = 'Não')
      return obrigaCentroCusto
    },

    ordenarGridPorCodigo () {
      this.naturezas.sort(function (a, b) {
        if (a.codigo === b.codigo) {
          return 0
        } else {
          return a.codigo > b.codigo ? 1 : -1
        }
      })
    },

    abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },

    async buscar (search, pagina) {
      this.status = 'is-loading'
      try {
        this.naturezas = []
        this.page = await service.get(
          `/api/v1/natureza-juridica/search?q=${encodeURIComponent(search)}&page=${pagina}&sort=codigo`
        )
        this.naturezas = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    }
  },

  watch: {
    async modalVisivel (val) {
      if (val) {
        await this.aoPesquisar('')
      }
    }
  },

  created () {
    this.termoPesquisado = ''
    this._carregarListagem()
  }
}
</script>

<style scoped>
tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}
</style>
