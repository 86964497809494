<template>
  <Modal tabindex="-1" size="normal" width="900px" :active="true" title="Histórico de códigos de retenção do IRRF" @close="fecharModalHistoricoRetencao">
    <div id='historico-retencao'>
      <DataTableBasico
        v-if="status != 'is-empty'"
        ref="dataTableBasico"
        custom-sort
        p-hide-button-more
        idDataTable="historico-retencao"
        custom-style="height: 75% !important;"
        div-style="height: 54vh"
        :p-search="false"
        :p-show-buttons="false"
        :p-data="historicosDeRetencao.content"
        :p-data-source="dataSource"
        :p-status="status"
        :p-quantidade-de-items="historicosDeRetencao.total"
        :p-pages="historicosDeRetencao.totalPages"
        :p-sorted-by="sortColumn.replace(',', ' ')"
        :modal-delecao-visivel="() => false"
        :next-page="proximaPagina"
        @sort="mudarOrdenacao"
      >
        <template #new-item>
          <Button class="is-primary" caption="Adicionar histórico" :title="shortkeys.MSG_KEY_NOVO" @click="adicionarHistoricoRetencao" />
        </template>
        <template #btns="{ item }">
          <div class="buttons is-right">
            <button data-group="editar-lancamento" class="button is-small is-pulled-right" type="button" @click="dataSource.select(item)">
              <span class="icon"><i class="fas fa-eye"></i></span>
            </button>
            <button data-group="excluir-lancamento" class="button is-small is-pulled-right" type="button" @click="dataSource.delete(item)">
              <span class="icon"><i class="fas fa-trash"></i></span>
            </button>
          </div>
        </template>
      </DataTableBasico>
      <div v-else>
        <div style="margin-bottom: 15px">
          <Button caption="Adicionar histórico" type="primary" :title="shortkeys.MSG_KEY_NOVO" :is-wide="false" @click="adicionarHistoricoRetencao" />
        </div>
        <div style="background-color: whitesmoke; border-color: lightgray; border-width: 1px; border-style: solid; padding: 110px; padding-inline: 200px">
          <p style="font-size: 14px; text-align: center">Você ainda não possui nenhum histórico de retenção cadastrado.</p>
          <p style="font-size: 14px; margin-top: 20px; text-align: center">
            Faça o cadastro clicando em
            <Button caption="Adicionar histórico" type="primary" style="bottom: 4px" :title="shortkeys.MSG_KEY_NOVO" :is-wide="false" @click="adicionarHistoricoRetencao" />
          </p>
        </div>
      </div>
    </div>
    <template #footer>
      <ButtonAjuda :title="shortkeys.MSG_KEY_AJUDA" :link-ajuda="LINK_AJUDA" />
      <Button caption="Fechar" :title="shortkeys.MSG_KEY_ESC" @click="fecharModalHistoricoRetencao" />
    </template>
    <CadastroEdicao
            v-if="showModalCadastroEdicao"
            :socemp="props.socemp"
            :codigo-de-retencao-do-quick-view="codigoDeRetencaoDoQuickView"
            :historico-retencao="historico"
            :historico-retencao-anterior="historicoAnterior"
            :cadastro-em-modo-de-visualizacao="cadastroEmModoDeVisualizacao"
            @ao-atualizar-historicos-de-retencao-a-serem-criados="_aoAtualizarHistoricosDeRetencaoASeremCriados"
            @fechar="showModalCadastroEdicao = false"
          />
    <ModalMessage
      ref="modalMessage"
      ok-caption="Excluir histórico"
      cancel-type="primary"
      :active="modalExclusaoHistoricoRetencao"
      :message="mensagemModalExclusao"
      @keydown.prevent.stop.enter.exact
      @keydown.prevent.stop.tab.exact
      @close="validarAcaoModalExclusao"
  />
  </Modal>
</template>
<script setup>
import { defineEmits, defineProps, inject, nextTick, onMounted, reactive, ref } from 'vue'

import Button from '@alterdata/component-vue/Button'
import Modal from '@alterdata/component-vue/Modal'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'

import { searchJson } from '@packonline/packonline-reutilizaveis-frontend/Utils/search-json'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast.js'
import Paises from '@packonline/packonline-reutilizaveis-frontend/Utils/Listas/Paises.json'

import CadastroEdicao from './CadastroEdicao.vue'

import HistoricoRetencaoIrrf from '../model/historico-retencao-irrf'

const LINK_AJUDA = 'https://ajuda.alterdata.com.br/pod/realizar-cadastros/cadastrar-empresa'

const API_HISTORICO_RETENCAO = '/api/v1/historico-retencao'

const setActiveSection = inject('setActiveSection')

const addShortcut = inject('addShortcut')

const adicionarNotificacao = inject('adicionarNotificacao')

const emit = defineEmits(['fechar', 'aoAtualizarHistoricosDeRetencaoASeremCriados', 'aoAtualizarHistoricosDeRetencaoASeremExcluidos'])

const props = defineProps({
  socemp: {
    type: Object,
    required: true
  },
  codigoDeRetencaoDoQuickView: {
    type: String,
    default: ''
  },
  historicosDeRetencao: {
    type: Array,
    required: true
  },
  showModalHistoricoRetencaoIrrfCadastroEdicao: {
    type: Boolean
  }
})

const columns = ref([
  {
    name: 'dataAlteracao',
    label: 'Alteração',
    format: (value) => utils.formatarDataParaExibicaoNoDataTable(value),
    style: 'width:12%;text-align: center;',
    styleItems: 'text-align:center'
  },
  {
    name: 'codigoRetencaoAnterior',
    label: 'Código anterior',
    format: (value) => value.toString().padStart(4, '0'),
    style: 'width:16%;'
  },
  {
    name: 'codigoRetencaoAtual',
    label: 'Código atual',
    format: (value) => value.toString().padStart(4, '0'),
    style: 'width:16%;'
  },
  {
    name: 'codigoPaisAnterior',
    label: 'País anterior',
    format: formatarPaisParaExibicao,
    style: 'width:22%;'
  },
  {
    name: 'codigoPais',
    label: 'País atual',
    format: formatarPaisParaExibicao,
    style: 'width:22%;'
  },
  {
    name: 'btns',
    slot: true
  }
])

const dataSource = reactive({
  columns: columns,
  itensSelected: [],
  itensDisabled: [],
  selected: null,
  save: (item) => console.log('[save] ' + item),
  cursor: null,
  filter: () => console.log('[filter] '),
  loadMore: null,
  select: visualizarHistoricoRetencao,
  delete: preExcluirHistoricoRetencao
})

const PAGE = {
  content: [],
  total: 0,
  totalPages: 0
}

const historico = ref(new HistoricoRetencaoIrrf())
const historicoAnterior = ref(new HistoricoRetencaoIrrf())
const historicosDeRetencao = ref(PAGE)
const pagina = ref(0)
const search = ref('')
const status = ref('is-empty')
const sortColumn = ref('dataAlteracao,desc')
const itemPreExclusao = ref(null)
const dataTableBasico = ref(null)
const modalMessage = ref(null)
const mensagemModalExclusao = ref('')
const modalExclusaoHistoricoRetencao = ref(false)
const showModalCadastroEdicao = ref(false)
const cadastroEmModoDeVisualizacao = ref(false)
const codigoDeRetencaoDoQuickView = ref('')

function carregar () {
  setTimeout(() => {
    status.value = 'is-loading'

    historicosDeRetencao.value = searchJson.findBySearch(search.value, props.historicosDeRetencao, pagina.value, 20, sortColumn.value)

    if (historicosDeRetencao.value.total) {
      status.value = ''
    } else {
      status.value = 'is-empty'
    }
  }, 0)
}

function proximaPagina (value) {
  if (value === -1) {
    value = 0
  }
  pagina.value = value
  carregar()
}

function mudarOrdenacao (sort) {
  if (sort.column !== 'btns') {
    pagina.value = 0
    historicosDeRetencao.value = PAGE
    sortColumn.value = sort.column + ',' + sort.order
    carregar()
  }
}

function formatarPaisParaExibicao (codigo) {
  if (codigo) {
    const index = Paises.findIndex((pais) => pais.codigo === codigo)
    if (index !== -1) {
      return `${codigo.toString().padStart(3, '0')} - ${Paises[index].nome}`
    }
  }
}

function adicionarHistoricoRetencao () {
  cadastroEmModoDeVisualizacao.value = false
  historico.value = new HistoricoRetencaoIrrf()
  historicoAnterior.value = [...props.historicosDeRetencao].sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao))[0]
  showModalCadastroEdicao.value = true
  codigoDeRetencaoDoQuickView.value = ''
}

function _aoAtualizarHistoricosDeRetencaoASeremCriados (value) {
  emit('aoAtualizarHistoricosDeRetencaoASeremCriados', value)
  historicosDeRetencao.value = PAGE
  pagina.value = 0
  carregar()
}

async function visualizarHistoricoRetencao (item) {
  cadastroEmModoDeVisualizacao.value = true
  historico.value = utils.cloneDeep(item)

  if (!historico.value.temporario) {
    const res = await service.get(`${API_HISTORICO_RETENCAO}/${item.id}`)
    if (res) {
      historico.value.indicativoNIFAtual = res.indicativoNIFAtual
      historico.value.numeroNIFAtual = res.numeroNIFAtual
      historico.value.formaTributacaoAtual = res.formaTributacaoAtual
      historico.value.codigoPostalAtual = res.codigoPostalAtual
      historico.value.logradouroAtual = res.logradouroAtual
      historico.value.numeroAtual = res.numeroAtual
      historico.value.complementoAtual = res.complementoAtual
      historico.value.bairroAtual = res.bairroAtual
      historico.value.cidadeAtual = res.cidadeAtual
      historico.value.estadoAtual = res.estadoAtual
      historico.value.telefoneAtual = res.telefoneAtual
    }
  }
  showModalCadastroEdicao.value = true
}

function preExcluirHistoricoRetencao (item) {
  itemPreExclusao.value = item
  const historicoAtual = [...props.historicosDeRetencao].sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao))[0]

  if (itemPreExclusao.value.dataAlteracao === historicoAtual.dataAlteracao) {
    registrarAtalhosModalDeExclusao()
    mensagemModalExclusao.value = `Deseja excluir o histórico de alteração do código de retenção de IRRF de "${utils.formatarDataParaExibicaoNoDataTable(itemPreExclusao.value.dataAlteracao)}"?`
    modalExclusaoHistoricoRetencao.value = true
    nextTick(() => {
      modalMessage.value.buttonFocus({ type: 'cancel' })
    })
  } else {
    itemPreExclusao.value = null
    adicionarNotificacao(
      new Toast({
        tipo: 'warning',
        titulo: 'Para excluir um histórico é necessário remover os mais recentes primeiro.',
        subtitulo:
        `
          <div>
            <p>
              Para preservar a integridade das informações a alteração deve ser feita do mais recente para o mais antigo.
            </p>
            <br />
          </div>
        `
      })
    )
  }
}

function validarAcaoModalExclusao (event) {
  if (event === 'ok') {
    excluirHistoricoRetencao()
  }
  dataTableBasico.value.$refs.datatable.focus()
  modalExclusaoHistoricoRetencao.value = false
  itemPreExclusao.value = null
  setActiveSection('modalHistoricoRetencao')
}

async function excluirHistoricoRetencao () {
  emit('aoAtualizarHistoricosDeRetencaoASeremExcluidos', itemPreExclusao.value)
  itemPreExclusao.value = null
  historicosDeRetencao.value = PAGE
  pagina.value = 0
  carregar()
}

function fecharModalHistoricoRetencao () {
  const section = 'quickviewCadastroVinculoSocios'
  setActiveSection(section)
  emit('fechar')
}

function registrarAtalhosModalHistoricoRetencao () {
  const section = 'modalHistoricoRetencao'
  setActiveSection(section)
  addShortcut(section, shortkeys.KEY_NOVO, adicionarHistoricoRetencao)
  addShortcut(section, shortkeys.KEY_ESC, fecharModalHistoricoRetencao)
  addShortcut(section, shortkeys.KEY_AJUDA, abrirAjuda)
  addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirAjuda)
}

function registrarAtalhosModalDeExclusao () {
  const section = 'modalExclusaoHistoricoRetencaoRetencao'
  setActiveSection(section)
  addShortcut(section, shortkeys.KEY_ESC, () => {
    modalExclusaoHistoricoRetencao.value = false
    setActiveSection('modalHistoricoRetencao')
  })
  addShortcut(section, shortkeys.KEY_AJUDA, abrirAjuda)
  addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirAjuda)
}

function abrirAjuda () {
  window.open(LINK_AJUDA)
}

onMounted(() => {
  carregar()
  registrarAtalhosModalHistoricoRetencao()
  if (props.showModalHistoricoRetencaoIrrfCadastroEdicao) {
    adicionarHistoricoRetencao()
    codigoDeRetencaoDoQuickView.value = props.codigoDeRetencaoDoQuickView
  }
})
</script>
