<template>
  <div v-if="this.modalVisivel" id="pesquisaCnae">
    <pesquisa-padrao
      customSearch
      titulo="Pesquisa de CNAE's"
      :propDataSource="this.dataSource"
      :propItens="this.cnaes"
      :pHideButtonMore="this.hideButtonMore"
      :pStatus="this.status"
      :pQuantidadeDeItems="this.page.total"
      :nextPage="this._carregarListagem"
      :customHighlighters="this.customHighlighters"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.$emit('fechar')"
      @search="aoPesquisar"
    />
  </div>
</template>

<script>
import { service } from '../../../service.js'
import PesquisaPadrao from '../../../components/Pesquisa'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

export default {
  name: 'pesquisaNaturezaJuridica',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },

  props: {
    modalVisivel: Boolean
  },

  data () {
    return {
      cnaes: [],
      dataSource: {
        columns: [
          {
            name: 'chamada',
            label: 'Código',
            style: 'width:15%'
          },
          {
            name: 'descricao',
            label: 'Descrição',
            style: 'width:85%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem()
      },
      page: [],
      status: '',
      pagina: 0,
      hideButtonMore: false,
      termoPesquisado: ''
    }
  },

  methods: {
    async _carregarListagem (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.page = await service.get(`/api/v1/cnae?page=${pagina}`)
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this.cnaes = this.page.content
          this.status = ''
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async buscar (search, pagina) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.cnaes = []
          const page = await service.get(
            `/api/v1/cnae/search?q=${search}&page=${pagina}&sort=chamada`
          )
          this.page = page
          this.pagina = pagina
          this.cnaes = this.page.content
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    carregarItemSelecionado (cnae) {
      this.$emit('carregarItemSelecionado2', cnae)
    },

    estiloTabela (item) {
      return { color: '#0100fe' }
    },

    abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },

    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    }
  },

  watch: {
    async modalVisivel (val) {
      if (val) {
        await this.aoPesquisar('')
      }
    }
  },

  created () {
    this.termoPesquisado = ''
    this._carregarListagem()
  }
}
</script>

<style scoped>
tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}
</style>
