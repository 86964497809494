<template>
  <div v-show="this.abaVisivel" id="abaBasico">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div
          class="column is-12"
          style="padding-bottom: 0rem; margin: 0; padding-top: 0rem"
        >
          <label class="label" for="config" style="font-size: 1rem"
            ><strong>Configurações contábeis</strong></label
          >
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin: 0; padding-top: 0rem"
        >
          <campo-texto
            id="endereco"
            label="Endereço completo"
            class="input"
            v-model="this.contabil.enderecoCompleto"
            :modelValue="this.contabil.enderecoCompleto"
            @keydown.shift.tab.prevent="abaHandler('complementar', 'geral', '#dataJunta' )"
            maxlength="150"
            @change="this.onlyEspaco"
            :message="this.erros.enderecoCompleto"
            :status="this.getStatus(this.erros.enderecoCompleto)"
            @update:erro="this.erros.enderecoCompleto = $event.message"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="sociedade" style="font-size: 1rem"
            ><strong>Sociedade empresária</strong></label
          >
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data
            id="data"
            v-model="this.contabil.dtconvempresa"
            :modelValue="this.contabil.dtconvempresa"
            :status="this.erros.dataconvempresa ? 'error' : ''"
            @update:erro="this.erros.dataconvempresa = $event"
            :naoPermiteDataFutura="true"
            @update:valid="this.$emit('validarDataConversao', $event)"
            label="Data de conversão"
            :message="this.erros.dataconvempresa"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="susep" style="font-size: 1rem"
            ><strong>Susep</strong></label
          >
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="codigoSusep"
            class="input"
            label="Código de inscrição"
            maxlength="5"
            v-model="this.contabil.codigoSusep"
            :modelValue="this.contabil.codigoSusep"
            @change="this.onlyEspaco"
            :message="this.erros.codigoSusep"
            :status="this.getStatus(this.erros.codigoSusep)"
            @update:erro="this.erros.codigoSusep = $event.message"
            :disabled="this.itemEmpresa.empresaAtiva"
            @keydown.prevent.enter.exact="abaHandler('complementar', 'manad', '#inicioContabilidade' )"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empresa from './model/empresa.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'

export default {
  name: 'abaContabil',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    editando: Boolean,
    errosValidacao: Object
  },
  components: {
    'input-data': InputDate,
    'campo-texto': InputText

  },
  mixins: [mixin],
  inject: ['abaHandler'],
  data () {
    return {
      contabil: {
        lancamentoAutomatico: this.itemEmpresa.lancamentoAutomatico,
        centroDeCusto: this.itemEmpresa.centroDeCusto,
        dtconvempresa: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtconvempresa
        ),
        codigoSusep: this.itemEmpresa.codigoSusep,
        enderecoCompleto: this.itemEmpresa.enderecoCompleto
      },
      erros: this.errosValidacao
    }
  },
  watch: {
    contabil: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    itemEmpresa: {
      handler: function (val, old) {
        if (val.enderecoCompleto) {
          this.contabil.enderecoCompleto = this.itemEmpresa.enderecoCompleto
        }
      },
      deep: true
    }
  },
  methods: {
    onlyEspaco (e) {
      if (e.target.value.trim().length === 0) {
        e.target.value = null
      }
    }
  },
  created () {
    this.contabil.lancamentoAutomatico = true
    this.contabil.centroDeCusto = true
  }
}
</script>

<style>
.label {
  font-size: 0.8rem;
}
</style>
