import cbos from './cbos.json'

export const buscarCBO = {
  executar (codigo) {
    const listaCBOS = JSON.parse(JSON.stringify(cbos.data))
    const cboEncontrado = {
      id: null,
      nome: null
    }
    Object.assign(cboEncontrado, listaCBOS.find(cbo => cbo.id === codigo))
    return cboEncontrado
  }
}
