import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import paisUtils from '@packonline/packonline-reutilizaveis-frontend/components/Pais/pais-utils'

class HistoricoRetencaoIrrf {
  constructor (
    historicoRetencaoIrrf = {
      socioId: null,
      dataAlteracao: null,
      codigoRetencaoAtual: null,
      codigoRetencaoAnterior: null,
      codigoPais: '',
      codigoPaisAnterior: '',
      indicativoNIFAtual: '',
      numeroNIFAtual: '',
      formaTributacaoAtual: '',
      codigoPostalAtual: '',
      logradouroAtual: '',
      numeroAtual: '',
      complementoAtual: '',
      bairroAtual: '',
      cidadeAtual: '',
      estadoAtual: '',
      telefoneAtual: ''
    }
  ) {
    this.socioId = historicoRetencaoIrrf.socioId
    this.dataAlteracao = historicoRetencaoIrrf.dataAlteracao
    this.codigoRetencaoAtual = historicoRetencaoIrrf.codigoRetencaoAtual
    this.codigoPais = historicoRetencaoIrrf.codigoPais
    this.indicativoNIFAtual = historicoRetencaoIrrf.indicativoNIFAtual
    this.numeroNIFAtual = historicoRetencaoIrrf.numeroNIFAtual
    this.formaTributacaoAtual = historicoRetencaoIrrf.formaTributacaoAtual
    this.codigoPostalAtual = historicoRetencaoIrrf.codigoPostalAtual
    this.logradouroAtual = historicoRetencaoIrrf.logradouroAtual
    this.numeroAtual = historicoRetencaoIrrf.numeroAtual
    this.complementoAtual = historicoRetencaoIrrf.complementoAtual
    this.bairroAtual = historicoRetencaoIrrf.bairroAtual
    this.cidadeAtual = historicoRetencaoIrrf.cidadeAtual
    this.estadoAtual = historicoRetencaoIrrf.estadoAtual
    this.telefoneAtual = historicoRetencaoIrrf.telefoneAtual
  }

  static async validar (historicoRetencaoIrrf, erros, socemp, historicoRetencaoAnterior) {
    this.validarDataDeAlteracao(historicoRetencaoIrrf.dataAlteracao, erros, socemp, historicoRetencaoAnterior)
    this.validarCodigoDeRetencaoAtual(historicoRetencaoIrrf.codigoRetencaoAtual, erros)
    this.validarPais(historicoRetencaoIrrf.codigoPais, erros)
    this.validarIndicativoDeNIFAtual(historicoRetencaoIrrf, erros)
    this.validarNumeroDeNIFAtual(historicoRetencaoIrrf, erros)
    this.validarFormaTributacaoAtual(historicoRetencaoIrrf, erros)

    return !utils.isObjectNotBlank(erros)
  }

  static validarDataDeAlteracao (dataAlteracao, erros, socemp, historicoRetencaoAnterior) {
    const dataDeAlteracaoValida = utils.isDataDigitadaValida(dataAlteracao)
    const dataAdmissao = utils.formatarCampoDataParaEnvioAoBackend(socemp.dataAdmissao)

    if (dataDeAlteracaoValida) {
      if (dataAlteracao < dataAdmissao) {
        erros.dataAlteracao = 'A data deve ser igual ou superior a data de entrada do sócio'
      } else if (historicoRetencaoAnterior) {
        const dataUltimoHistorico = utils.formatarCampoDataParaEnvioAoBackend(historicoRetencaoAnterior.dataAlteracao)
        if (dataAlteracao <= dataUltimoHistorico) {
          erros.dataAlteracao = 'A data deve ser superior aos históricos já cadastrados'
        }
      } else {
        erros.dataAlteracao = ''
      }
    } else {
      erros.dataAlteracao = 'Informe uma data válida'
    }
  }

  static validarCodigoDeRetencaoAtual (codigoRetencaoAtual, erros) {
    if (!codigoRetencaoAtual) {
      erros.codigoRetencaoAtual = 'Informe o código de retenção do IRRF'
    } else {
      erros.codigoRetencaoAtual = ''
    }
  }

  static validarPais (codigoPais, erros) {
    erros.codigoPais = paisUtils.validarPais({ codigo: codigoPais }, 'Informe o país', true)
  }

  static validarIndicativoDeNIFAtual (historicoRetencaoIrrf, erros) {
    const { codigoPais, indicativoNIFAtual } = historicoRetencaoIrrf
    if (!erros.codigoPais && codigoPais !== '105' && !indicativoNIFAtual) {
      erros.indicativoNIFAtual = 'Informe o indicativo de NIF'
    } else {
      erros.indicativoNIFAtual = ''
    }
  }

  static validarNumeroDeNIFAtual (historicoRetencaoIrrf, erros) {
    const { indicativoNIFAtual, numeroNIFAtual } = historicoRetencaoIrrf
    if (indicativoNIFAtual && indicativoNIFAtual === 1 && !numeroNIFAtual) {
      erros.numeroNIFAtual = 'Informe o NIF'
    } else {
      erros.numeroNIFAtual = ''
    }
  }

  static validarFormaTributacaoAtual (historicoRetencaoIrrf, erros) {
    const { codigoPais, formaTributacaoAtual } = historicoRetencaoIrrf
    if (!erros.codigoPais && codigoPais !== '105' && !formaTributacaoAtual) {
      erros.formaTributacaoAtual = 'Informe a forma de tributação'
    } else {
      erros.formaTributacaoAtual = ''
    }
  }
}

export default HistoricoRetencaoIrrf
