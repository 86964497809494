import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import { integracaoeContador } from '../../../IntegracaoeContador/integracao-e-contador.js'

class Operacoes {
  armazenarNaListaOVinculoDeSocio (objeto, listaSociosVinculadosParaMandarParaOBackend, cdempresa) {
    if (objeto.codigoSocio) {
      this.removerVinculoDaLista(objeto, listaSociosVinculadosParaMandarParaOBackend)
    }
    objeto.idEmpresaSocio = parseInt(cdempresa, 10)
    objeto.dataAdmissao = utils.formatarCampoDataParaEnvioAoBackend(objeto.dataAdmissao)
    objeto.dataDemissao = utils.formatarCampoDataParaEnvioAoBackend(objeto.dataDemissao)
    objeto.stEntraRais = objeto.stEntraRais ? 'S' : null
    objeto.codigoIndex = objeto.codigoIndex === 'R$' ? null : objeto.codigoIndex
    listaSociosVinculadosParaMandarParaOBackend.push(objeto)
  }

  armazenarNaListaOVinculoDeSocioASerExcluido (objeto, listaSociosVinculadosParaExcluirNoBackend, listaSociosVinculadosParaMandarParaOBackend) {
    if (objeto.codigoSocio) {
      this.removerVinculoDaLista(objeto, listaSociosVinculadosParaMandarParaOBackend)
    }
    listaSociosVinculadosParaExcluirNoBackend.push(objeto)
  }

  atualizarListaDeVinculos (empresa, listaSociosVinculadosParaMandarParaOBackend) {
    listaSociosVinculadosParaMandarParaOBackend.forEach(item => {
      if (item.alCapital) {
        let valorLocal = item.alCapital.toString().replace(',', '.')
        valorLocal = (empresa.valorCapital * (valorLocal / 100)).toFixed(2)
        item.valorCapitalSocio = valorLocal.toString().replace(',', '.')
      }
      if (item.idEmpresaSocio !== parseInt(empresa.cdempresa, 10)) {
        item.idEmpresaSocio = parseInt(empresa.cdempresa, 10)
      }
    })
  }

  removerVinculoDaLista (objeto, listaSociosVinculadosParaMandarParaOBackend) {
    const itemIndex = listaSociosVinculadosParaMandarParaOBackend.findIndex(item => item.codigoSocio === objeto.codigoSocio)
    if (itemIndex > -1) {
      listaSociosVinculadosParaMandarParaOBackend.splice(itemIndex, 1)
    }
  }

  async salvarVinculoDeSociosEmpresa (listaSociosVinculadosParaMandarParaOBackend, paramIntegrar) {
    if (listaSociosVinculadosParaMandarParaOBackend.length > 0) {
      await service.save('/api/v1/socemp/vinculos', listaSociosVinculadosParaMandarParaOBackend)
      if (paramIntegrar) {
        integracaoeContador.integrarPorSocio(listaSociosVinculadosParaMandarParaOBackend)
      }
    }
  }

  excluirNoBackEndVinculosDeSocios (listaSociosVinculadosParaExcluirNoBackend, paramIntegrar) {
    listaSociosVinculadosParaExcluirNoBackend.forEach(async item => {
      await service.delete('/api/v1/socemp', item, parseInt(item.idEmpresaSocio, 10) + '-' + item.codigoSocio)
      if (paramIntegrar) {
        integracaoeContador.desintegrarPorSocio(item)
      }
    })
  }

  async enviarAlteracoesDoHistoricoDeRetencaoAoBackend (cdempresa, historicosDeRetencaoASeremCriados, historicosDeRetencaoASeremExcluidos) {
    this.excluirHistoricosDeRetencaoNoBackend(cdempresa, historicosDeRetencaoASeremExcluidos)
    this.criarHistoricosDeRetencaoNoBackend(cdempresa, historicosDeRetencaoASeremCriados)
  }

  async criarHistoricosDeRetencaoNoBackend (cdempresa, historicosDeRetencaoASeremCriados) {
    if (historicosDeRetencaoASeremCriados.length > 0) {
      const historicosDeRetencaoASeremCriadosBackend = historicosDeRetencaoASeremCriados.map((item) => {
        item.dataAlteracao = utils.formatarCampoDataParaEnvioAoBackend(item.dataAlteracao)
        delete item.temporario
        return item
      })

      await service.post(`/api/v1/historico-retencao/por-empresa/${cdempresa}`, historicosDeRetencaoASeremCriadosBackend)
    }
  }

  async excluirHistoricosDeRetencaoNoBackend (cdempresa, historicosDeRetencaoASeremExcluidos) {
    if (historicosDeRetencaoASeremExcluidos.length > 0) {
      const historicosDeRetencaoASeremExcluidosBackend = historicosDeRetencaoASeremExcluidos.map((item) => {
        item.dataAlteracao = utils.formatarCampoDataParaEnvioAoBackend(item.dataAlteracao)
        return item
      })

      await service.deleteByList(`/api/v1/historico-retencao/por-empresa/${cdempresa}`, historicosDeRetencaoASeremExcluidosBackend)
    }
  }
}
export const operacoesVinculo = new Operacoes()
