<template>
  <div v-if="this.modalVisivel" id="listagemHistoricoNire">
    <section class="modal-card-body">
      <div class="container" style="width:106%;">
        <alt-datatable :pStatus="this.status" :pDataSource="this.dataSource" :pData="this.historicos"
          :pHideButtonMore="this.hideButtonMore" :paginacao="this.paginacao"
          :idDivUtilizadaPaginacao="'listagemHistoricoNire'" :noIcon="true">
          <template v-slot:new-item>
            <button class="button is-light" @click="_cadastrarNovo">Novo</button>
          </template>
        </alt-datatable>
      </div>
    </section>
    <cadastro-Edicao-Historico-Nire :modalVisivel="this.abrirCadastroEdicao" :idEmpresa="this.itemEmpresa.cdempresa"
      :historico="this.item" @cancel="this.abrirCadastroEdicao = false" @save="_atualizarListaAposCadastro"/>
  </div>
</template>

<script>
import Empresa from '../model/empresa.js'
import HistoricoNire from './model/historico-nire.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import cadastroHistoricoNire from './cadastroEdicaoHistoricoNire.vue'
import { service } from '../../../service.js'
import AltDatatable from '../../../components/alt-component/Datatable/index.vue'

export default {
  name: 'historicoNire',
  components: {
    'cadastro-Edicao-Historico-Nire': cadastroHistoricoNire,
    'alt-datatable': AltDatatable
  },

  props: {
    modalVisivel: Boolean,
    itemEmpresa: Empresa
  },

  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'numero',
            label: 'Código',
            style: 'width:30%'
          },
          {
            name: 'data',
            label: 'Data',
            format: this._retornaDataFormatada,
            style: 'width:20%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this._editar(item),
        delete: (item) => this._excluir(item),
        loadMore: null
      },
      historicos: this.itemEmpresa.historicosNire,
      abrirCadastroEdicao: false,
      item: new HistoricoNire(),
      editando: false,
      status: '',
      hideButtonMore: false,
      paginacao: false
    }
  },

  methods: {

    _cadastrarNovo () {
      this.editando = false
      this.item.data = null
      this.item.numero = null
      this.item.empresaID = null
      this.item.id = null
      this._abrirFecharCadastroEdicaoHistoricoNire()
    },

    _retornarDataFormatada (data) {
      if (data) {
        const retorno = utils.formatarDataParaExibicao(data)
        if (retorno) {
          return retorno.substring(8, 10) + '/' + retorno.substring(5, 7) + '/' + retorno.substring(0, 4)
        }
      }
    },

    _abrirFecharCadastroEdicaoHistoricoNire () {
      this.abrirCadastroEdicao = !this.abrirCadastroEdicao
    },

    _atualizarListaAposCadastro (historico) {
      if (!this.editando) {
        this.historicos.push(historico)
      } else {
        const itemIndex = this.historicos.findIndex(item => item.id === historico.id)
        this.historicos.splice(itemIndex, 1, historico)
      }
      this.editando = false
      this._abrirFecharCadastroEdicaoHistoricoNire()
    },

    async _excluir (historico) {
      const resposta = await service.delete('/api/v1/historico-nire', historico)
      if (resposta.ok) {
        const itemIndex = this.historicos.findIndex(item => item.id === historico.id)
        this.historicos.splice(itemIndex, 1)
      }
    },

    _editar (historico) {
      Object.assign(this.item, historico)
      this.editando = true
      this.item.data = utils.formatarDataParaExibicao(this.item.data)
      this._abrirFecharCadastroEdicaoHistoricoNire()
    },

    _retornaDataFormatada (data) {
      return utils.formatarDataParaExibicaoNoDataTable(data)
    }
  }
}
</script>

<style>

</style>
