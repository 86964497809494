<template>
  <div class="container" style="text-align: left">
    <div class="columns is-multiline">
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label class="label" for="nascimento" style="font-size: 1rem" >
          <strong>Dados do nascimento</strong>
        </label>
      </div>
      <div class="field column is-2">
        <div class="control">
          <input-data
            id="dataNascimento"
            v-model="this.declaracoes.dataNascimento"
            required
            label="Data"
            :data-group="'data-input-socio'"
            :model-value="this.declaracoes.dataNascimento"
            :status="this.getStatus(this.erros.dataNascimento)"
            :naoPermiteDataFutura="true"
            :message="this.erros.dataNascimento"
            @update:erro="this.erros.dataNascimento = $event.message"
          />
        </div>
      </div>
      <div class="column is-4">
        <municipio-uf
          :pUf="this.declaracoes.ufNascimento"
          :pCdMunicipio="this.declaracoes.codigoIBGENascimento"
          :pNmMunicipio="this.municipio"
          :pIdMunicipio="this.idMunicipio"
          :pExibeMunicipio="true"
          :pErros="this.erros"
          :pMod="this.modulo"
          @carregarObjSelecionado="this.carregarObjSelecionado"
          @limparCampos="this.clearCamposEndereco"
          :erro="'municipioDeclaracoes'"
        />
      </div>
      <div
        class="field column is-1"
        style="padding: 0; padding-top: 12px; margin-right: 20px"
      >
        <input-texto
          id="ibge"
          v-model="this.declaracoes.codigoIBGENascimento"
          disabled
          label="Código IBGE"
        />
      </div>
      <p class="help is-danger">{{ this.erros.codigoIBGENascimento }}</p>
      <div class="column is-4">
        <label class="label" style="margin-bottom: 0;" for="nacionalidade">Nacionalidade</label>
        <div class="field has-addons">
          <div
            id="nacionalidade"
            :class="
              !this.erros.nacionalidadeCodigo ? 'select' : 'select is-danger'
            "
          >
            <select v-model="this.declaracoes.nacionalidadeCodigo">
              <option
                v-for="nac in this.nacionalidades"
                :key="nac.cd"
                :value="nac.cd"
              >
                {{ nac.cd }} - {{ nac.nacionalidade }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.nacionalidadeCodigo }}</p>
      </div>
      <div
        class="field column is-4"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <pesquisa-pais
          pCdId="paisENascimento"
          id="paisESocialNascimento"
          tamanhoInput="20%"
          tamanhoNmPais="115%"
          erro="paisCodigoESocialNascimento"
          :pCdPais="this.declaracoes.paisCodigoESocialNascimento"
          :pNmPais="this.paisNascimento"
          :jsonPaises="this.jPaises"
          :pUf="this.declaracoes.ufNascimento"
          :errosValidacao="this.erros"
          :tipoPais="'País'"
          required
          @carregarObjetoPais="this.carregarObjetoPais"
          @mudarUf="this.declaracoes.ufNascimento = $event"
        />
      </div>
      <div class="field column is-2">
        <div class="control">
          <input-data
            id="dataChegada"
            :key="this.atualizarValorDataChegada"
            v-model="this.declaracoes.dataChegada"
            @update:valid="this.$emit('validarDataChegada', $event)"
            :model-value="this.declaracoes.dataChegada"
            :status="this._obterStatus(this.erros.dataChegada)"
            :disabled="!this._isExterior(this.declaracoes.ufNascimento)"
            @update:erro="this.erros.dataChegada = $event"
            label="Data de chegada"
            :message="this.erros.dataChegada"
          />
        </div>
      </div>
      <div class="field column is-2">
        <div class="control" style="margin-right: -0.2rem">
          <input-data
            id="dataNaturalizacao"
            :key="this.atualizarValorDataNaturalizacao"
            v-model="this.declaracoes.dataNaturalizacao"
            @update:valid="this.$emit('validarDataNaturalizacao', $event)"
            :model-value="this.declaracoes.dataNaturalizacao"
            :status="this._obterStatus(this.erros.dataNaturalizacao)"
            :disabled="!this._isExterior(this.declaracoes.ufNascimento)"
            @update:erro="this.erros.dataNaturalizacao = $event"
            label="Data de naturalização"
            :message="this.erros.dataNaturalizacao"
          />
        </div>
      </div>
      <div class="column is-4">
        <input
          type="checkbox"
          name="casadoComBrasileiro"
          id="casado"
          style="margin-top: 5px"
          v-model="this.declaracoes.casadoBrasil"
          :disabled="!this._isExterior(this.declaracoes.ufNascimento)"
          @change="
            this.declaracoes.casadoBrasil === false
              ? (this.declaracoes.filhosBrasil = null)
              : ''
          "
        />
        <label
          for="casado"
          :style="this.declaracoes.ufNascimento !== 'EX' ? 'color: #ccc;' : ''"
        >
          Casado com brasileiro(a)</label
        >
        <div style="padding-top: 10px">
          <input
            type="checkbox"
            name="filhosComBrasileiro"
            id="filhos"
            v-model="this.declaracoes.filhosBrasil"
            :disabled="
              this.declaracoes.casadoBrasil === false ||
              this.declaracoes.casadoBrasil === null
            "
          />
          <label
            for="filhos"
            :style="
              this.declaracoes.casadoBrasil === false ||
              this.declaracoes.casadoBrasil === null
                ? 'color: #ccc;'
                : ''
            "
          >
            Filhos com brasileiro(a)</label
          >
        </div>
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label class="label" for="e-social" style="font-size: 1rem" >
          <strong>E-Social</strong>
        </label>
      </div>
      <div style="padding: 20px; padding-top: 15px; padding-left: 10px">
        <div style="margin-bottom: 10px">
          <input-label label="Sexo" required />
        </div>
        <input
          type="radio"
          name="sexo"
          id="masculino"
          value="M"
          v-model="this.declaracoes.sexo"
        />
        <label for="masculino" style="margin-right: 12px"> Masculino</label>
        <input
          type="radio"
          name="sexo"
          id="feminino"
          value="F"
          v-model="this.declaracoes.sexo"
        />
        <label for="feminino"> Feminino</label>
        <p class="help is-danger">{{ this.erros.sexo }}</p>
      </div>
      <div
        class="field column is-3"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <input-label label="Raça e cor" required />
        <div class="field has-addons">
          <div
            id="raca-cor"
            :class="!this.erros.racaCor ? 'select' : 'select is-danger'"
          >
            <select v-model="this.declaracoes.racaCor">
              <option v-for="cor in this.raca" :key="cor.cd" :value="cor.cd">
                {{ cor.raca }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.racaCor }}</p>
      </div>
      <div
        class="field column is-6"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <input-label label="Grau de instrução" required />
        <div class="field has-addons">
          <div
            id="instrucao"
            :class="!this.erros.grauDeInstrucao ? 'select' : 'select is-danger'"
          >
            <select v-model="this.declaracoes.grauDeInstrucao">
              <option
                v-for="grau in this.instrucao"
                :key="grau.cd"
                :value="grau.cd"
              >
                {{ grau.desc }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.grauDeInstrucao }}</p>
      </div>
      <div
        class="field column is-5"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <input-texto
          id="mae"
          maxlength="80"
          label="Nome da mãe"
          v-model="this.declaracoes.nomeMae"
          :message="erros.nomeMae"
          :status="this.getStatus(erros.nomeMae)"
          @update:erro="erros.nomeMae = $event.message"
        />
      </div>
      <div class="field column is-5" style="padding-bottom: 0rem; margin: 0">
        <input-texto
          id="mae"
          maxlength="80"
          v-model="this.declaracoes.nomePai"
          label="Nome da pai"
          :message="erros.nomePai"
          :status="this.getStatus(erros.nomePai)"
          :model-value="this.declaracoes.nomePai"
          @update:erro="erros.nomePai = $event.message"
          @update:model-value="this.declaracoes.nomePai = $event"
        />
      </div>
      <div class="column is-12">
        <div class="columns">
          <div class="column is-5">
            <pesquisa-pais
              id="paisESocialNacionalidade"
              erro="paisCodigoESocialNacionalidade"
              tamanho-input="20%"
              tamanhoNmPais="145%"
              :pCdId="'paisENacionalidade'"
              :pCdPais="this.declaracoes.paisCodigoESocialNacionalidade"
              :pNmPais="this.paisNacionalidade"
              :jsonPaises="this.jPaises"
              required
              :errosValidacao="this.erros"
              :tipoPais="'País de nacionalidade'"
              @carregarObjetoPaisComNome="this.carregarObjetoPaisNacionalidade"
            />
          </div>
        </div>
      </div>
      <div
        class="field column is-2"
        style="padding-bottom: 0rem; margin: 0; padding-right: 0"
      >
        <input-label label="Tempo de residência" :required="!!this.declaracoes.paisCodigoESocialNacionalidade && this.declaracoes.paisCodigoESocialNacionalidade !== 105" />
        <div class="field has-addons">
          <div
            id="residencia"
            :class="!this.erros.tempoResidencia ? 'select' : 'select is-danger'"
          >
            <select
              v-model="this.declaracoes.tempoResidencia"
              @change="this.resetarCondicao"
              :disabled="
                this.paisNacionalidade?.toUpperCase() === 'BRASIL' ||
                this.paisNacionalidade === null ||
                this.declaracoes.paisCodigoESocialNacionalidade === null
              "
            >
              <option
                v-for="tempo in this.residencia"
                :key="tempo.cd"
                :value="tempo.cd"
              >
                {{ tempo.desc }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.tempoResidencia }}</p>
      </div>
      <div
        v-if="this.declaracoes.tempoResidencia === null"
        class="field column is-9"
        style="padding-bottom: 0rem; margin: 0; padding-left: 0"
      >
        <input-label label="Condição" :required="!!this.declaracoes.paisCodigoESocialNacionalidade && this.declaracoes.paisCodigoESocialNacionalidade !== 105" />
        <div class="field has-addons">
          <div
            id="condicaoDisable"
            :class="!this.erros.condicao ? 'select' : 'select is-danger'"
          >
            <select
              v-model="this.declaracoes.condicao"
              :disabled="this.declaracoes.tempoResidencia === null"
              style="min-width: 700px; max-width: 100%;"
            >
              <option
                v-for="condicao in this.condicoes"
                :key="condicao.cd"
                :value="condicao.cd"
              >
                {{ condicao.desc }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.condicao }}</p>
      </div>
      <div
        v-else
        class="field column is-9"
        style="padding-bottom: 0rem; margin: 0; padding-left: 0"
      >
        <input-label label="Condição" :required="!!this.declaracoes.paisCodigoESocialNacionalidade && this.declaracoes.paisCodigoESocialNacionalidade !== 105" />
        <div class="field has-addons">
          <div
            id="condicao"
            :class="!this.erros.condicao ? 'select' : 'select is-danger'"
          >
            <select v-model="this.declaracoes.condicao">
              <option
                v-for="condicao in this.condicoes"
                :key="condicao.cd"
                :value="condicao.cd"
              >
                {{ condicao.desc }}
              </option>
            </select>
          </div>
        </div>
        <p class="help is-danger">{{ this.erros.condicao }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Socio from './model/socios.js'
import pesquisaMunicipioUF from '../../components/PesquisaMunicipioUF/index.vue'
import pesquisaPais from '../../components/PesquisaPais/index.vue'
import jsonPaises from '@packonline/packonline-reutilizaveis-frontend/Utils/Listas/Paises.json'
import jsonNacionalidades from './json/nacionalidade.json'
import jsonPaisesBasico from './json/paises.json'
import { utils } from '../../util/utils'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import InputLabel from '@alterdata/component-vue/InputBase/InputLabel.vue'

import { mixin } from '../../util/mixin.js'

export default {
  name: 'abaDeclaracoes',
  components: {
    'municipio-uf': pesquisaMunicipioUF,
    'pesquisa-pais': pesquisaPais,
    'input-data': InputDate,
    'input-texto': InputText,
    'input-label': InputLabel
  },
  props: {
    errosValidacao: Object,
    itemSocio: Socio,
    montarObjeto: Function
  },
  mixins: [mixin],
  data () {
    return {
      declaracoes: {
        dataNascimento: utils.formatarDataParaExibicao(
          this.itemSocio.declaracoes.dataNascimento
        ),
        ufNascimento: this.itemSocio.declaracoes.ufNascimento,
        codigoIBGENascimento: this.itemSocio.declaracoes.codigoIBGENascimento,
        paisCodigoNascimento: this.itemSocio.declaracoes.paisCodigoNascimento,
        nacionalidadeCodigo: this.itemSocio.declaracoes.nacionalidadeCodigo,
        dataChegada: utils.formatarDataParaExibicao(
          this.itemSocio.declaracoes.dataChegada
        ),
        dataNaturalizacao: utils.formatarDataParaExibicao(
          this.itemSocio.declaracoes.dataNaturalizacao
        ),
        casadoBrasil: this.itemSocio.declaracoes.casadoBrasil,
        filhosBrasil: this.itemSocio.declaracoes.filhosBrasil,
        sexo: this.itemSocio.declaracoes.sexo,
        racaCor: this.itemSocio.declaracoes.racaCor,
        grauDeInstrucao: this.itemSocio.declaracoes.grauDeInstrucao,
        nomeMae: this.itemSocio.declaracoes.nomeMae,
        nomePai: this.itemSocio.declaracoes.nomePai,
        paisCodigoESocialNascimento:
          this.itemSocio.declaracoes.paisCodigoESocialNascimento,
        paisCodigoESocialNacionalidade:
          this.itemSocio.declaracoes.paisCodigoESocialNacionalidade,
        tempoResidencia: this.itemSocio.declaracoes.tempoResidencia,
        condicao: this.itemSocio.declaracoes.condicao
      },
      raca: [
        { cd: 1, raca: 'Indígena' },
        { cd: 2, raca: 'Branca' },
        { cd: 3, raca: 'Negra' },
        { cd: 6, raca: 'Amarela (de origem japonesa, chinesa, coreana, etc)' },
        {
          cd: 8,
          raca: 'Parda (ou declarada como mulata, cabloca, cafuza, mameluca, ou mestiça de negro com pessoa de outra cor ou raça)'
        },
        { cd: 9, raca: 'Não informado' }
      ],
      instrucao: [
        { cd: 1, desc: 'Analfabeto' },
        {
          cd: 2,
          desc: 'Até a 4ª série incompleta do ensino fundamental (antigo 1º grau ou primário), ou que tenha se alfabetizado sem ter frequentado escola regular.'
        },
        {
          cd: 3,
          desc: '4ª série completa do ensino fundamental (antigo 1º grau ou primário)'
        },
        {
          cd: 4,
          desc: 'Da 5ª a 8ª série do ensino fundamental (antigo 1º grau ou ginásio)'
        },
        {
          cd: 5,
          desc: 'Ensino fundamental completo (antigo 1º grau, primário ou ginásio)'
        },
        {
          cd: 6,
          desc: 'Ensino médio incompleto (antigo 2º grau, secundário ou colegial)'
        },
        {
          cd: 7,
          desc: 'Ensino médio completo (antigo 2º grau, secundário ou colegial)'
        },
        { cd: 8, desc: 'Educação superior incompleta' },
        { cd: 9, desc: 'Educação superior completa' },
        { cd: 10, desc: 'Pós Graduação' },
        { cd: 11, desc: 'Mestrado' },
        { cd: 12, desc: 'Doutorado' }
      ],
      residencia: [
        { cd: 1, desc: 'Indeterminado' },
        { cd: 2, desc: 'Determinado' }
      ],
      condicoes: null,
      municipio: null,
      idMunicipio: null,
      erros: this.errosValidacao,
      modulo: 'cadastro-socio',
      nacionalidades: jsonNacionalidades.data,
      pais: null,
      paisNascimento: null,
      paisNacionalidade: null,
      atualizarValorDataChegada: 0,
      atualizarValorDataNaturalizacao: 0
    }
  },
  computed: {
    jPaises () {
      return {
        data: jsonPaises.map(pais => ({
          cod: Number(pais.codigo),
          pais: pais.nome
        }))
      }
    },
    jPaisesBasico () {
      return jsonPaisesBasico
    }
  },
  methods: {
    resetarCondicao () {
      this.declaracoes.condicao = null
    },

    carregarObjSelecionado (municipio) {
      this.municipio = municipio.nmMunicipio
      this.declaracoes.ufNascimento = municipio.uf
      this.declaracoes.codigoIBGENascimento = municipio.cdMunicipio
      this.idMunicipio = municipio.idMunicipio
    },

    clearCamposEndereco () {
      this.municipio = null
      this.declaracoes.codigoIBGENascimento = null
      this.idMunicipio = null
    },
    clearCamposEstrangeiro () {
      this.declaracoes.dataChegada = null
      this.declaracoes.dataNaturalizacao = null
      this.declaracoes.casadoBrasil = null
      this.declaracoes.filhosBrasil = null
    },
    carregarObjetoPais (paisCodigo) {
      const codigos1 = []
      const codigos2 = []
      this.jPaises.data.forEach((p) => {
        codigos1.push(p.cod)
      })
      this.jPaisesBasico.data.forEach((p) => {
        codigos2.push(p.cod)
      })

      if (codigos1.includes(paisCodigo) && !codigos2.includes(paisCodigo)) {
        this.declaracoes.paisCodigoNascimento = 999
      } else {
        this.declaracoes.paisCodigoNascimento = paisCodigo
      }

      this.declaracoes.paisCodigoESocialNascimento = paisCodigo

      if (this.declaracoes.paisCodigoNascimento === 105) {
        this.declaracoes.dataChegada = null
        this.declaracoes.dataNaturalizacao = null
        this.atualizarValorDataChegada += 1
        this.atualizarValorDataNaturalizacao += 1
      }
    },

    carregarObjetoPaisNacionalidade (pais) {
      this.paisNacionalidade = pais.nmPais
      this.declaracoes.paisCodigoESocialNacionalidade = pais.cdPais
    },
    recarregarAba () {
      const aba = this.declaracoes.tempoResidencia
      this.declaracoes.tempoResidencia = null
      this.declaracoes.tempoResidencia = aba
      if (!this.declaracoes.paisCodigoESocialNacionalidade) {
        this.declaracoes.paisCodigoESocialNacionalidade = null
      }
    },

    _obterStatus (value) {
      return !value ? '' : 'error'
    },

    _isExterior (value) {
      return value === 'EX'
    }
  },
  watch: {
    declaracoes: {
      handler: function (val, old) {
        if (val.tempoResidencia === 2) {
          this.condicoes = [
            { cd: 2, desc: 'Solicitante de refúgio' },
            {
              cd: 3,
              desc: 'Permanência no Brasil em razão de reunião familiar'
            },
            {
              cd: 4,
              desc: 'Beneficiado pelo acordo entre os países do Mercosul'
            },
            {
              cd: 5,
              desc: 'Dependente de agente diplomático e/ou consular de países que mantêm acordo de reciprocidade para o exercício de atividade remunerada no Brasil'
            },
            {
              cd: 6,
              desc: 'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa'
            },
            { cd: 7, desc: 'Outra condição' }
          ]
        } else if (val.tempoResidencia === 1) {
          this.condicoes = [
            { cd: 1, desc: 'Refugiado' },
            {
              cd: 3,
              desc: 'Permanência no Brasil em razão de reunião familiar'
            },
            {
              cd: 4,
              desc: 'Beneficiado pelo acordo entre os países do Mercosul'
            },
            {
              cd: 6,
              desc: 'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa'
            },
            { cd: 7, desc: 'Outra condição' }
          ]
        }
        if (val.paisCodigoNascimento === 105) {
          this.clearCamposEstrangeiro()
        }
        if (val.ufNascimento) {
          this.erros.ufNascimento = ''
        }
        if (val.codigoIBGENascimento) {
          this.erros.codigoIBGENascimento = ''
        }
        if (val.nacionalidadeCodigo) {
          this.erros.nacionalidadeCodigo = ''
        }
        if (val.casadoBrasil) {
          this.erros.casadoBrasil = ''
        }
        if (val.filhosBrasil) {
          this.erros.filhosBrasil = ''
        }
        if (val.sexo) {
          this.erros.sexo = ''
        }
        if (val.racaCor) {
          this.erros.racaCor = ''
        }
        if (val.grauDeInstrucao) {
          this.erros.grauDeInstrucao = ''
        }
        if (val.nomeMae) {
          this.erros.nomeMae = ''
        }
        if (val.nomePai) {
          this.erros.nomePai = ''
        }
        if (val.tempoResidencia) {
          this.erros.tempoResidencia = ''
        }
        if (val.condicao) {
          this.erros.condicao = ''
        }

        this.montarObjeto(val, 'declarações')
      },
      deep: true
    },
    municipio: {
      handler: function (val) {
        if (val) {
          this.erros.municipioDeclaracoes = ''
        }
      }
    },
    paisNacionalidade: {
      handler: function (val) {
        if (!val || val?.toUpperCase() === 'BRASIL') {
          this.declaracoes.tempoResidencia = null
          this.declaracoes.condicao = null
        }
      }
    },
    dataChegada: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.dataChegada = ''
        }
      }
    },
    dataNaturalizacao: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.dataNaturalizacao = ''
        }
      }
    },
    dataNascimento: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.dataNascimento = ''
        }
      }
    },
    'declaracoes.ufNascimento' () {
      this.atualizarValorDataNaturalizacao++
      this.atualizarValorDataChegada++
    }
  },
  created () {
    this.recarregarAba()
  }
}
</script>

<style>
</style>
