<template>
    <Modal :title="this.novoCad ? 'Indexador (Cadastro)' : 'Indexador (Edição)'" @close="this.sairSemSalvar()" :active="true" id="cadastroEdicaoIndexador">
          <div class="columns is-multiline">
            <div v-if="!this.novoCad" class="field column is-3">
              <input-texto
                id="codigo"
                maxlength="2"
                label="Código"
                v-model="this.indexador.codigo"
                disabled
                :message="erros.codigo"
                :status="this.getStatus(erros.codigo)"
                @update:erro="erros.codigo = $event.message"
              />
            </div>
            <div id="inputNome" class="field column is-9">
              <input-texto
                id="nome"
                maxlength="15"
                v-model="this.indexador.nome"
                label="Descrição"
                :status="this.getStatus(erros.nome)"
                :message="erros.nome"
                @update:erro="erros.nome = $event.message"
              />
            </div>
          </div>
          <template v-slot:footer>
            <ButtonAjuda :linkAjuda="this.linkAjuda" :title="shortkeys.MSG_KEY_AJUDA" style="margin-right: auto" />
            <Button class="button is-primary" @click="this._salvarIndexador" :title="shortkeys.MSG_KEY_GRAVAR" caption="Gravar" style="margin-right: 1rem"/>
            <Button class="button" :title="shortkeys.MSG_KEY_ESC" @click="this.cancelar()" caption="Cancelar"/>
          </template>
      </Modal>
</template>

<script>

import { indexadorService as service } from './service/indexador-service.js'
import Indexador from './model/indexador.js'

import InputText from '@alterdata/component-vue/InputText'

import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

const ERROS = {
  nome: '',
  codigo: ''
}

export default {
  props: {
    item: Indexador,
    novoCadastro: Boolean
  },
  components: {
    'input-texto': InputText,
    ButtonAjuda,
    Button,
    Modal
  },

  mixins: [mixin, mixinModalSaida, mixinRouterGuardian],
  inject: ['setActiveSection', 'addShortcut', 'setRootData', 'rootData'],
  data () {
    return {
      indexador: this.item,
      indexadorOriginal: null,
      novoCad: this.novoCadastro,
      erros: ERROS,
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Indexadores',
      listagemVisivel: false
    }
  },

  computed: {
    modalSaida () {
      return this.rootData.mostrarModalDadosAlterados
    },
    dadosAlteradosRoot () {
      return this.rootData.dadosAlterados
    },
    verificandoDadosAlteradosRoot () {
      return this.rootData.verificandoDadosAlterados
    }
  },

  mounted () {
    this.indexadorOriginal = utils.cloneDeep(this.item)
    this.focarNoPrimeiroInput('inputNome')
    this._definirAtalhosCadastroIndexadores()
    this.setRootData((data) => {
      data.parametrosModalSalvarMudancas.eventoSalvar = this._salvarIndexador
      data.parametrosModalSalvarMudancas.eventoSair = this.sairSemSalvar
      data.mostrarModalDadosAlterados = false
      data.dadosAlterados = false
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!this.$root.mostrarModalDadosAlterados) {
      this.beforeRoute(to, from, next)
      this.$root.rotaPara = to.fullPath
    } else {
      next()
    }
  },

  unmounted () {
    this.setActiveSection('listagemIndexadores')
  },

  methods: {
    async _salvarIndexador () {
      try {
        Indexador.validar(this.indexador)
        service
          .save('/api/v1/indexadores', this.indexador, this.novoCad)
          .then(this.responseToSave)
      } catch (e) {
        Object.assign(this.erros, JSON.parse(e.message))
      }
      this.verificarSeExisteRotaNaSession()
      this.dadosInalterados()
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    sairSemSalvar () {
      this.dadosInalterados()
      this.erros.nome = ''
      this.verificarSeExisteRotaNaSession()
      this.$emit('cancel')
    },

    cancelar () {
      this.verificarModificaoNoItem()
      if (this.dadosAlteradosRoot) {
        this.rootData.mostrarModalDadosAlterados = true
      } else {
        this.erros.nome = ''
        this.$emit('cancel')
      }
    },
    verificarModificaoNoItem () {
      const indexador = JSON.parse(JSON.stringify(utils.cloneDeep(this.indexador)))
      const indexadorOriginal = JSON.parse(JSON.stringify(utils.cloneDeep(this.indexadorOriginal)))

      const existeModificacao = !utils.isEqual(indexador, indexadorOriginal)
      if (existeModificacao) {
        this.setRootData((data) => {
          data.dadosAlterados = true
          data.mostrarModalDadosAlterados = true
        })
      } else {
        this.setRootData((data) => {
          data.dadosAlterados = false
          data.mostrarModalDadosAlterados = false
        })
      }
    },

    _definirAtalhosCadastroIndexadores () {
      const section = 'cadastroIndexadores'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarIndexador)
      this.addShortcut(section, shortkeys.KEY_ESC, this.cancelar)
      this.addShortcut(section, shortkeys.KEY_MENUC, () => this.cancelar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }
  },

  watch: {
    verificandoDadosAlteradosRoot: {
      handler (val) {
        if (val) {
          this.verificarModificaoNoItem()
        }
      }
    },
    indexador: {
      handler: function (val, old) {
        try {
          this.erros = ERROS

          if (val.nome) {
            this.erros.nome = ''
          }
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    modalSaida (val) {
      if (!val) {
        this.setActiveSection('cadastroIndexadores')
      }
    }
  }
}
</script>
