<template>
  <div v-show="this.abaVisivel" id="abaComplementar">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div class="field column is-12">
          <div class="tabs">
            <ul>
              <li v-bind:class="this.abaGeralVisivel ? 'is-active' : null">
                <a @click="_ativarAba('geral')" data-group="aba-complementar-geral">Geral</a>
              </li>
              <li v-bind:class="this.abaContabilVisivel ? 'is-active' : null">
                <a @click="_ativarAba('contábil')" data-group="aba-complementar-contabil"
                  >Informações para o contábil</a
                >
              </li>
              <li v-bind:class="this.abaManadVisivel ? 'is-active' : null">
                <a @click="_ativarAba('manad')" data-group="aba-complementar-manad">MANAD</a>
              </li>
              <li v-bind:class="this.abaSpedVisivel ? 'is-active' : null">
                <a @click="_ativarAba('sped')" data-group="aba-complementar-sped">Sped - ECD</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="field column is-12">
          <abaGeral
            :abaVisivel="abaGeralVisivel"
            :montarObjeto="montarObjeto"
            :itemEmpresa="itemEmpresa"
            :montarObjetoCnae="montarObjetoCnae"
            :montarObjetoSocemp="montarObjetoSocemp"
            :errosValidacao="errosValidacao"
            @onAbrirCadastroHistoricoNire="this._abrirCadastroHistoricoNire"
            @onAbrirCadastroHistoricoCnae="this._abrirCadastroHistoricoCnae"
            @onAbrirCadastroCnaeSecundario="this._abrirFecharCnaeSecundario"
            @validarDataJunta="this.$emit('validarDataJunta', $event)"
            @validarDataNire="this.$emit('validarDataNire', $event)"
            @validarDataNascimento="this.$emit('validarDataNascimento', $event)"
            :editando="this.editando"
            :exibindoCadastroSecundario="this.exibindoCadastroSecundario"
            :cnaeAlterado="this.cnaeAlterado"
            :montarObjetoHistoricoNire="montarObjetoHistoricoNire"
            :itemDadosReceita="this.itemDadosReceita"
          />
          <abaContabil
            :abaVisivel="abaContabilVisivel"
            :montarObjeto="montarObjeto"
            :itemEmpresa="itemEmpresa"
            :editando="this.editando"
            :errosValidacao="errosValidacao"
            @validarDataConversao="this.$emit('validarDataConversao', $event)"
          />
          <abaManad
            :abaVisivel="abaManadVisivel"
            :montarObjeto="montarObjeto"
            :itemEmpresa="itemEmpresa"
            :errosValidacao="errosValidacao"
            @validarDataIniServCont="
              this.$emit('validarDataIniServCont', $event)
            "
            @validarDataFimServCont="
              this.$emit('validarDataFimServCont', $event)
            "
            @validarDataIniServInfo="
              this.$emit('validarDataIniServInfo', $event)
            "
            @validarDataFimServInfo="
              this.$emit('validarDataFimServInfo', $event)
            "
          />
          <abaSped
            :itemAuditor="this.itemAuditor"
            :abaVisivel="abaSpedVisivel"
            :montarObjeto="montarObjeto"
            :itemEmpresa="itemEmpresa"
            :listaVinculosNovosEditados="this.listaVinculosNovosEditados"
            :errosValidacao="this.errosValidacao"
            :editandoAuditor="this.editandoAuditor"
            :pContadores="this.contadores"
            @armazenarVinculo="this.$emit('armazenarVinculo', $event)"
            @armazenarVinculoASerExcluido="
              this.$emit('armazenarVinculoASerExcluido', $event)
            "
            @editAuditor="this.$emit('editAuditor', $event)"
            @editarAuditor="this.$emit('editarAuditor', $event, $event)"
            @onAbrirCadastroContadorAuditor="_abrirFecharContadorAuditor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import abaGeral from './abaGeral.vue'
import abaContabil from './abaContabil.vue'
import abaManad from './abaManad.vue'
import abaSped from './abaSped.vue'
import Empresa from './model/empresa.js'
import AuditorIndependente from './AuditorIndependente/model/auditor-independente'

export default {
  name: 'abaComplementar',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    montarObjetoCnae: Function,
    montarObjetoSocemp: Function,
    montarObjetoHistoricoNire: Function,
    itemEmpresa: Empresa,
    itemAuditor: AuditorIndependente,
    errosValidacao: Object,
    abaASerAtivada: String,
    editando: Boolean,
    editandoAuditor: Boolean,
    exibindoCadastroSecundario: Boolean,
    cnaeAlterado: String,
    listaVinculosNovosEditados: Array,
    contadores: Array,
    itemDadosReceita: Object
  },
  inject: ['setActiveSection', 'addShortcut', 'direcionaAjuda'],
  components: {
    abaGeral,
    abaContabil,
    abaManad,
    abaSped
  },

  data () {
    return {
      abaGeralVisivel: true,
      abaContabilVisivel: false,
      abaManadVisivel: false,
      abaSpedVisivel: false
    }
  },

  methods: {
    _atribuirFalseParaTodasAsAbas () {
      this.abaGeralVisivel = false
      this.abaContabilVisivel = false
      this.abaManadVisivel = false
      this.abaSpedVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'geral':
          this.abaGeralVisivel = true
          this.$emit('mudarAbaComp', aba)
          this.setActiveSection('abaComplementar')
          break

        case 'contábil':
          this.abaContabilVisivel = true
          this.$emit('mudarAbaComp', aba)
          this.setActiveSection('abaComplementar')
          break

        case 'manad':
          this.abaManadVisivel = true
          this.$emit('mudarAbaComp', aba)
          this.setActiveSection('abaComplementar')
          break

        case 'sped':
          this.abaSpedVisivel = true
          this.$emit('mudarAbaComp', aba)
          this.setActiveSection('abaComplementarSped')
          break
      }
    },

    _abrirCadastroHistoricoNire (titulo) {
      this.$emit('onAbrirCadastroHistoricoNire', titulo)
    },

    _abrirCadastroHistoricoCnae (titulo) {
      this.$emit('onAbrirCadastroHistoricoCnae', titulo)
    },

    _abrirFecharCnaeSecundario (titulo) {
      this.$emit('onAbrirCadastroCnaeSecundario', titulo)
    },

    _abrirFecharContadorAuditor (titulo) {
      this.$emit('onAbrirCadastroContadorAuditor', titulo)
    }
  },
  watch: {
    abaASerAtivada: {
      handler: function (val, old) {
        if (val) {
          this._ativarAba(val)
        }
      },
      deep: true
    }
  }
}
</script>
