import { utils } from './utils'

export class SearchInJson {
  /**
   * @param {string} search
   * @param {array<Object>} arr
   * @param {number} page
   * @param {number} maximumSize
   * @returns
   */
  findBySearch (search, arr, page, maximumSize = 20) {
    if (arr instanceof Array) {
      const arrFilter = arr.filter(item => this._verifyMatch(item, search))
      const arrSplit = this._splitArr(arrFilter, maximumSize)
      const removeArrEmpty = this._removeArrEmpty(arrSplit)
      const arrFind = removeArrEmpty[page]
      if (arrFind) {
        return { content: arrFind, total: arrFilter.length, totalPages: removeArrEmpty.length }
      } else {
        return { content: [], total: 0, totalPages: 0 }
      }
    }
  }

  _removeArrEmpty (arr) {
    const newArr = []
    for (const element of arr) {
      if (element.length > 0) {
        newArr.push(element)
      }
    }
    if (newArr.length === 0) {
      return [[]]
    }
    return newArr
  }

  _buildArrRoot (arr, maximumSize) {
    const maximumArrs = Math.ceil(Math.fround(arr.length / maximumSize)) || 1
    return Array.from(Array(maximumArrs).keys(), () => [])
  }

  _splitArr (arr, maximumSize) {
    const arrRoot = this._buildArrRoot(arr, maximumSize)
    let countItem = 0
    let arrCurrent = 0
    for (const element of arr) {
      if (countItem < maximumSize) {
        arrRoot[arrCurrent][countItem] = element
        countItem++
      } else {
        arrCurrent++
        countItem = 0
      }
    }
    return arrRoot
  }

  _verifyMatch (item, search) {
    const itemEntries = Object.entries(item)
    for (const element of itemEntries) {
      const elementNotAccents = utils.removeAcentos(String(element[1]).toLowerCase())
      const searchNotAccents = utils.removeAcentos(String(search).trim().toLowerCase())
      if (elementNotAccents.includes(searchNotAccents)) {
        return true
      }
    }
    return false
  }
}

export const searchInJson = new SearchInJson()
