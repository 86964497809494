<template>
  <div>
  <div class="columns is-multiline" >
    <div class="field column is-1" style="padding-bottom: 0rem; margin: 0;">
      <campo-texto label="Código" :value="formataEmpresa()" maxlength="5" :disabled="true" data-cy="codigo-empresa"/>
    </div>
    <div class="field column is-2" style="padding-bottom: 0rem; margin: 0">
      <campo-texto label="CNPJ/CPF" :value="formataCnpj()" :disabled="true" data-cy="cnpj-cpf" />
    </div>
    <div class="field column" style="padding-bottom: 0rem; margin: 0">
      <campo-texto label="Nome" :value="this.itens.nomeCompleto" :disabled="true" data-cy="nome-empresa" data-group="nome-empresa"/>
    </div>
  </div>
  <h3 class="subtitle" >Módulos vinculados à empresa</h3><br>
  <div class="columns" style="margin-top: -2.5%">
    <div class="column is-vcentered">
      <Card titulo="Cadastro inicial" icone="building" cor-do-icone="#444343" :check="true" @click="_editarCadastroInicial" :data-cy="cad-wphd"/>
    </div>
    <div class="column is-vcentered">
      <Card titulo="Contábil" icone="file-invoice-dollar" cor-do-icone="#ff914d" :check="checkContabil" :desabilitado="empresaInativa" @click="irParaContabil" :data-cy="cad-contabil" :componenteAguardandoCarregamento="!this.requisicaoIntegracaoRealizada"/>
    </div>
    <div class="column is-vcentered">
      <Card titulo="DP" icone="id-badge" cor-do-icone="#5271ff" :check="checkDP" @click="irParaDP" :desabilitado="empresaInativa" :data-cy="cad-dp" :componenteAguardandoCarregamento="!this.requisicaoIntegracaoRealizada"/>
    </div>
    <div class="column is-vcentered">
      <Card titulo="Fiscal" icone="receipt" cor-do-icone="#8c52ff" :check="checkFiscal" @click="irParaFiscal" :desabilitado="empresaInativa" :data-cy="cad-fiscal" :componenteAguardandoCarregamento="!this.requisicaoIntegracaoRealizada"/>
    </div>
  </div>
  <br>
  <div  class="is-flex is-align-items-center is-justify-content-center" style="height: auto">
        <div v-if="(!empresaInativa)">
          <div v-if="!checkContabil && !checkDP && !checkFiscal">
              <div class="box is-flex is-align-items-center is-justify-content-center" style="margin-top: -2%; background-color: lightgoldenrodyellow; width: 100%;
              height: 100%;">
              <p class="is-size-6 has-text-centered" data-cy="msgCadastroIncial">O cadastro inicial, com os dados básicos da empresa, foi gravado com sucesso.<br>
                Para vinculá-las aos módulos de trabalho, clique no botão "vincular ao módulo" correspondente a opção desejada.<br>
                Você pode realizar isso depois, mas lembre-se que para usar os recursos do módulo para esta empresa, essa operação será obrigatória.
                </p>
          </div>
        </div>
        </div>
          <div v-if="empresaInativa">
            <div class="box is-flex is-align-items-center is-justify-content-center" style="background-color: lightgoldenrodyellow; width: 100%;  height: 100%;">
              <p class="is-size-6 has-text-centered" data-cy="msgEmpresaDesativada">A empresa está desativada no cadastro inicial. Para visualizar os dados dos demais módulos, é necessário ativar a empresa primeiro.
                </p>
            </div>
        </div>
    </div>
    <br>
    <div>
      <div>
        <footer class="modal-card-foot is-justify-content-flex-end" >
          <button class="button is-secondary" @click="this._cancelarCards()" title="Atalho: ESC" data-cy="fechar-cards">Fechar</button>
        </footer>
      </div>
    </div>
  </div>

</template>
<script>
import InputText from '@alterdata/component-vue/InputText/index.vue'
import Empresa from '../model/empresa.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import Card from './Card.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import { env } from '../../../env'
import { validadorDeInscricaoFederal } from '@packonline/packonline-reutilizaveis-frontend/Utils/InscricaoFederal.js'
import SistemasIntegrados from '../../../models/SistemasIntegrados'
import { service } from '../../../service.js'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'

export default {
  data () {
    return {
      pCodigoEmpresa: '',
      pCpfCnpj: '',
      pNomeEmpresa: '',
      sistemas: new SistemasIntegrados(),
      requisicaoIntegracaoRealizada: false
    }
  },
  async created () {
    this.sistemas = await service.get('/api/v1/gerais/retornar-integracao-sistemas')
    this.requisicaoIntegracaoRealizada = true
  },
  mixins: [mixin, mixinModalSaida],
  components: {
    'campo-texto': InputText,
    Card
  },
  props: {
    itens: Empresa,
    checkCadastrosBasicos: Boolean,
    checkContabil: Boolean,
    checkDP: Boolean,
    checkFiscal: Boolean,
    habilitaCadastrosBasicos: Boolean,
    habilitaContabil: Boolean,
    habilitaDP: Boolean,
    habilitaFiscal: Boolean,
    mensagemNovaEmpresa: Boolean,
    vinculaContabil: Boolean,
    vinculaDP: Boolean,
    vinculaFiscal: Boolean,
    empresaInativa: Boolean
  },
  methods: {
    _cancelarCards () {
      this.dadosInalterados()
      this.$router.push('/cadastros/empresas')
      this.$emit('cancelaCard')
    },
    _editarCadastroInicial () {
      this.$emit('editarCadastroInicial')
    },
    formataEmpresa () {
      return utils.strZero(this.itens.cdempresa, 5)
    },
    formataCnpj () {
      try {
        return validadorDeInscricaoFederal.retornarCPFCNPJFormatado(utils.clearMaskNumber(this.itens.numeroCGC))
      } catch (error) {
        return true
      }
    },
    mostrarAlerta (pModulo, pDescricao, vinculado) {
      let mensTitulo = ''
      let mensSubTitulo = ''
      if (vinculado) {
        mensTitulo = 'As informações do módulo ' + pModulo + ' ainda não estão disponíveis no Pack Online.'
        mensSubTitulo = 'Para realizar a edição, acesse o Alterdata Pack e clique no ícone ' + pDescricao + '.<br><br>'
      } else {
        mensTitulo = 'As informações do módulo ' + pModulo + ' ainda não estão disponíveis no Pack Online.'
        mensSubTitulo = 'Para realizar o vínculo, acesse o Alterdata Pack e clique no ícone ' + pDescricao + '.<br><br>'
      }
      const notificao = new Toast({ tipo: 'info', titulo: mensTitulo, subtitulo: mensSubTitulo })
      this.adicionarNotificacao(notificao)
    },
    irParaContabil () {
      if (this.sistemas.contabil) {
        if (this.itens && this.itens.cdempresa) {
          this.$root.dadosAlterados = false
          const url = `${env.contabil}/empresa-contabil/${utils.strZero(this.itens.cdempresa, 5)}`
          window.location.href = url
        }
      } else {
        this.mostrarAlerta('Contábil', 'Contábil', this.checkContabil)
      }
    },
    irParaDP () {
      if (this.sistemas.dp) {
        if (this.itens && this.itens.cdempresa) {
          this.$root.dadosAlterados = false
          const url = `${env.dp}/empresa-dp/${utils.strZero(this.itens.cdempresa, 5)}`
          window.location.href = url
        }
      } else {
        this.mostrarAlerta('DP', 'Departamento pessoal', this.checkDP)
      }
    },
    irParaFiscal () {
      if (this.sistemas.fiscal) {
      } else {
        this.mostrarAlerta('Fiscal', 'Escrita fiscal', this.checkFiscal)
      }
    }
  }
}

</script>

<style scoped>

.icon-check {
  color: #00804b;
}

.cor-cadastro-inicial {
  color: #444343;
}

.cor-contabil {
  color: #ff914d;
}

.cor-dp {
  color: #5271ff;
}

.cor-fiscal {
  color: #8c52ff;
}
.pos{
  position: fixed;
  bottom: 0;
  width: auto;
  height: 100%;
  padding: 20px;
}
</style>
