<template>
    <aside class="menu" v-for="(item, index) in this.itens" :key="index" :style="this.pMod === 'cadastro-socio'? 'position: relative; width: 110%':''">
        <div v-if="item.titulo" class="menu-drop">
            <a @click="() => this.toogleCollapse(item, index)" class='collapsable'>
                <p class="menu-label is-hidden-touch">
                    {{ item.titulo }}
                </p>
            </a>
        <br/>
        </div>
        <ul
            :class="item.active ? 'menu-list' : 'menu-list is-hidden'"
            v-for="(link, index) in item.links" :key="index">
            <li v-if="(this.linksComPendencias.includes(link.url) && this.quantidadeDePendencias > 0)">
                <span id="span-badge" >
                    <alt-badge-dp :valor="this.quantidadeDePendencias">
                    </alt-badge-dp>
                </span>
                <a :class="this.activeLink === link.url ? 'ativo' : ''" @click="() => this.setActiveLink(link.url, link)" :disabled="link.isDisabled">
                    {{ link.descricao }}
                </a>
            </li>
            <li v-else>
                <a :class="this.activeLink === link.url ? 'ativo' : ''"  :data-group="dataGroupMenu(link.url)"  @click="() => this.setActiveLink(link.url)" :disabled="link.isDisabled">
                    {{ link.descricao }}
                </a>
            </li>
        </ul>
    </aside>

</template>
<script>
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils.js'

export default {
  name: 'menu-vertical',
  props: {
    pItens: {
      typeof: [],
      default: [
        {
          titulo: 'Dados Cadastrais',
          active: true,
          links: [
            { descricao: 'Informações Iniciais', url: 'informacoes-iniciais', validacao: { isValido: false } },
            { descricao: 'Endereço', url: 'endereco', validacao: { isValido: false } },
            { descricao: 'Documentos', url: 'documentos', validacao: { isValido: false } }
          ]
        },
        {
          titulo: 'Dados Contratuais',
          active: true,
          links: [
            { descricao: 'Admissão', url: 'admissao', validacao: { isValido: false } },
            { descricao: 'Informações da Folha', url: 'informacoes-da-folha', validacao: { isValido: false } },
            { descricao: 'Carga Horária', url: 'carga-horaria' }
          ]
        },
        {
          titulo: 'Informações Adicionais',
          active: true,
          links: [
            { descricao: 'Controle de Afastamentos', url: 'afastamento' }
          ]
        }
      ]
    },
    pActiveLink: String,
    pMod: String
  },
  data () {
    return {
      itens: this.pItens,
      activeLink: this.pActiveLink,
      linksComPendencias: [],
      quantidadeDePendencias: 0
    }
  },
  methods: {
    setActiveLink (url) {
      this.activeLink = url
      this.announce()
      this.$emit('ativarAba', url)
    },
    announce () {
      document.dispatchEvent(
        new CustomEvent('pack-online-menu-vertical-announce', {
          detail: {
            activeLink: this.activeLink
          },
          composed: true
        })
      )
    },
    dataGroupMenu (url) {
      return 'data-menu-vertical-' + utils.removeAcentos(url)
    },
    toogleCollapse (item, index) {
      this.itens[index].active = !item.active
      this.itens = Object.assign([], this.itens)
    }
  },
  watch: {
    pItens: {
      immediate: true,
      handler () {
        this.itens = this.pItens
      },
      deep: true
    },
    pActiveLink: {
      handler: function (val, old) {
        this.setActiveLink(val)
      },
      deep: true
    }
  },
  mounted () {
    this.setActiveLink(this.pActiveLink)
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../node_modules/@alterdata/theme-bulma/sass/base/variables";

  li {
    background-color: #ffff;
    border-block-end: 1px solid rgb(194, 194, 194);
  }

  .menu-drop {
    background-color: #efefef !important;
    padding-top: 15px !important;
    padding-left: 15px !important;
  }

  .collapsable {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .menu {
    height: 100%;
    width: 100%;
    border-top: 1px solid #dbe2e5;
  }

  .menu-list {
    li {
      border-style: solid;
      border-width: 0 1px 1px 1px;
      border-color: #dbe2e5;
    }

    a {
        color: #6e7173;
        border-left: 8px solid #dbe2e5;
        border-radius: 0;
        padding: 13px 0px 13px 24px;
        margin: 0px 0px 0px 0px;

        &.ativo{
            color: $primary;
            border-color: $primary;

            &:hover{
                color: $primary;
            }
        }
        &:hover{
            color: #ce5269;
            border-color: #ce5269;
            background-color: white;
        }
    }
  }

  #span-badge {
    position: relative;
    display: block;
    text-align: center;
  }
</style>
