<template>
  <input-texto
    id="cdpais"
    label="País"
    maxlength="3"
    width="20%"
    v-model="this.pais.cdPais"
    :status="this.getStatus(erros.paisCodigo)"
    style="margin-right: 25px"
    :message="erros.paisCodigo"
    @update:erro="erros.paisCodigo = $event.message"
    @blur="this.carregarNomePais()"
    @keypress="this.onlyNumber($event, true)"
  >
    <template
      v-slot:addon
      :style="
        this.tamanhoTelaMaior
          ? 'right: 50px; position: absolute'
          : 'right: 40px; position: absolute'
      "
    >
      <div class="is-flex">
        <a
          class="button is-outlined"
          @click="this.abrirPesquisaPais()"
          :class="{
            'is-danger': this.getStatus(erros.paisCodigo) === 'error',
            'is-info': this.getStatus(erros.paisCodigo) === 'info',
          }"
        >
          <span class="icon"><span class="fas fa-search"></span></span>
        </a>
        <div class="control ml-2">
          <input-texto
            id="pais"
            class="input"
            width="138%"
            v-model="this.pais.nmPais"
            disabled
          />
        </div>
      </div>
    </template>
  </input-texto>
  <pesquisa
    v-if="this.modalVisivel"
    customSearch
    :titulo="'Pesquisa de países'"
    :propDataSource="this.dataSource"
    :propItens="this.paises"
    :pHideButtonMore="this.hideButtonMore"
    :pStatus="this.status"
    @carregarItemSelecionado="this.carregarItemSelecionado"
    @fechar="this.fecharPesquisaPais"
    :pQuantidadeDeItems="this.listaPaisesTamanho"
    :nextPage="this.carregar"
    :pPages="totalPages"
    @search="aoPesquisar"
  />
</template>

<script>
import pesquisa from '../Pesquisa/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { utils } from '../../util/utils'
import { mixin } from '../../util/mixin'
import { mixinPesquisaPais } from './mixinPesquisaPais'

export default {
  components: {
    pesquisa,
    'input-texto': InputText
  },
  props: {
    pCdPais: Number,
    pUf: String,
    jsonPaises: Object,
    posicaoCod: String,
    posicaoNm: String,
    pModulo: String,
    errosValidacao: Object
  },
  mixins: [mixin, mixinPesquisaPais],
  data () {
    return {
      pais: {
        cdPais: this.pCdPais,
        nmPais: null
      },
      modalVisivel: false,
      erros: this.errosValidacao,
      dataSource: {
        columns: [
          {
            name: 'cod',
            label: 'Código',
            style: 'width:20%'
          },
          {
            name: 'pais',
            label: 'País',
            style: 'width:80%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(this.pais.cdPais),
        loadMore: null
      },
      paises: [],
      hideButtonMore: false,
      status: '',
      page: [],
      uf: this.pUf,
      tamanhoTelaMaior: false,
      listaPaisesTamanho: '',
      termoPesquisado: ''
    }
  },

  methods: {
    abrirPesquisaPais () {
      this.carregar()
      this.modalVisivel = true
    },

    fecharPesquisaPais () {
      this.termoPesquisado = ''
      this.modalVisivel = false
      this.carregar()
    },
    carregarNomePais () {
      if (this.pais.cdPais) {
        const response = utils.findCountry(
          this.pais.cdPais,
          this.jsonPaises.data
        )
        if (response === undefined) {
          this.pais.nmPais = null
          this.erros.paisCodigo = 'País não encontrado'
        } else {
          this.pais.nmPais = response.pais
          this.pais.cdPais = response.cod
        }
      } else {
        this.pais.nmPais = null
      }
    },
    buscarBrasil () {
      var response = JSON.parse(JSON.stringify(this.jsonPaises.data)).filter(
        (e) => {
          return e.pais === 'BRASIL'
        }
      )[0]
      this.pais.nmPais = response.pais
      this.pais.cdPais = response.cod
    },
    carregarItemSelecionado (pais) {
      this.pais.cdPais = pais.cod
      this.pais.nmPais = pais.pais
      this.fecharPesquisaPais()
    },
    clearCamposPais () {
      this.pais.cdPais = null
      this.pais.nmPais = null
    },
    organizarPosicao () {
      if (this.pModulo === 'cadastro-socio') {
        if (window.innerWidth >= 1707) {
          this.tamanhoTelaMaior = true
          this.$emit(
            'mudarPosCod',
            'position: relative; left: 670px; bottom: 40px;'
          )
        }
        if (window.innerWidth > 1366 && window.innerWidth < 1518) {
          this.tamanhoTelaMaior = true
          this.$emit(
            'mudarPosNm',
            'position: relative; left:1px; padding-right: 45px;'
          )
          this.$emit(
            'mudarPosCod',
            'position: relative; left: 650px; bottom: 40px;'
          )
        }
        if (window.innerWidth <= 1366) {
          this.tamanhoTelaMaior = false
          this.$emit(
            'mudarPosNm',
            'position: relative; left: 5px; padding-right: 50px;'
          )
          this.$emit(
            'mudarPosCod',
            'position: relative; left: 575px; bottom: 40px;'
          )
        }
        if (window.innerWidth < 1366) {
          this.tamanhoTelaMaior = false
          this.$emit(
            'mudarPosNm',
            'position: relative; left: 10px; padding-right: 50px;'
          )
          this.$emit(
            'mudarPosCod',
            'position: relative; left: 515px; bottom: 40px'
          )
        }
        if (window.innerWidth < 1200) {
          this.tamanhoTelaMaior = false
          this.$emit(
            'mudarPosCod',
            'position: relative; left: 445px; bottom: 40px'
          )
        }
      }
    }
  },
  watch: {
    pais: {
      immediate: true,
      handler () {
        this.$emit('carregarObjetoPais', this.pais.cdPais)
        if (this.pais.nmPais !== 'BRASIL') {
          if (this.pais.nmPais !== null) {
            this.$emit('mudarUf', 'EX')
          }
        } else if (this.pUf === 'EX') {
          this.$emit('mudarUf', null)
        }
      },
      deep: true
    },
    pUf: {
      handler () {
        if (this.pUf !== 'EX') {
          this.buscarBrasil()
        } else {
          if (this.pais.cdPais === null || this.pais.nmPais === 'BRASIL') {
            this.clearCamposPais()
          }
        }
      },
      deep: true
    },
    errosValidacao: {
      handler () {
        if (
          this.erros.inscricaoCpfCnpj !== '' &&
          this.erros.inscricaoCpfCnpj !== null
        ) {
          this.$emit('mudarPosCod', this.posicaoCod + 'bottom: 100px;')
        }
        if (
          this.erros.municipioBasicoSocio !== '' &&
          this.erros.municipioBasicoSocio !== null
        ) {
          this.$emit('mudarPosCod', this.posicaoCod + 'bottom: 58px;')
        } else {
          this.$emit('mudarPosCod', this.posicaoCod + 'bottom: 40px;')
        }

        if (this.erros.inscricaoCpfCnpj && this.erros.municipioBasicoSocio) {
          this.$emit('mudarPosCod', this.posicaoCod + 'bottom: 50px;')
        }
      },
      deep: true
    }
  },
  mounted () {
    this.carregarNomePais()
    if (this.pModulo === 'cadastro-socio') {
      this.organizarPosicao()
    }
  },
  created () {
    window.addEventListener('resize', this.organizarPosicao)
  }
}
</script>

<style scoped></style>
