<template>
  <div id="listagemContador">
    <section class="p-2">
      <alt-contador
        :itemEmpresa="this.itemEmpresa"
        :errosValidacao="this.errosValidacao"
        @update:ContadorAuditor="this.$emit('update:ContadorAuditor', $event)"
      ></alt-contador>
    </section>
  </div>
</template>

<script>
import AltContador from './index.vue'
import Empresa from '../model/empresa.js'

export default {
  components: {
    'alt-contador': AltContador
  },
  props: {
    modalVisivel: Boolean,
    itemEmpresa: Empresa,
    errosValidacao: Object
  },
  emits: ['update:ContadorAuditor']
}
</script>

<style lang="scss" scoped></style>
