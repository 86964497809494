import { Service } from '../../../service.js'

class SociosService extends Service {
  async patch (url, dto) {
    const init = {
      headers: this.headers,
      method: 'PATCH',
      body: JSON.stringify(dto)
    }
    return fetch(`${this.api}${url}/${dto.cdsocio}`, init).then(response => response)
  }

  async save (url, dto, editando) {
    if (!editando) {
      return this.post(url, dto)
    }
    return this.patch(url, dto)
  }
}

export const sociosService = new SociosService()
