<template>
  <div v-show="this.abaVisivel" id="abaBasico">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div
          class="column is-12"
          style="padding-bottom: 0rem; margin: 0; padding-top: 0rem"
        >
          <label class="label" for="contabilidade" style="font-size: 1rem"
            ><strong
              >Período de prestação do serviço de contabilidade</strong
            ></label
          >
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data
            id="inicioContabilidade"
            v-model="this.manad.dtiniservcont"
            :modelValue="this.manad.dtiniservcont"
            :status="this.erros.datainiservcont ? 'error' : ''"
            @blur="verificaDataInicioFim()"
            label="Início"
            @update:valid="this.$emit('validarDataIniServCont', $event)"
            @update:erro="this.erros.datainiservcont = $event"
            :message="this.erros.datainiservcont"
            :disabled="this.itemEmpresa.empresaAtiva"
            @keydown.shift.tab.prevent="abaHandler('complementar', 'contábil', '#codigoSusep' )"
          />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data
            id="fimContabilidade"
            v-model="this.manad.dtfimservcont"
            :modelValue="this.manad.dtfimservcont"
            :status="this.erros.datafimservcont ? 'error' : ''"
            @blur="verificaDataInicioFim()"
            label="Fim"
            @update:valid="this.$emit('validarDataFimServCont', $event)"
            @update:erro="this.erros.datafimservcont = $event"
            :message="this.erros.datafimservcont"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="informatica" style="font-size: 1rem"
            ><strong
              >Período de prestação do serviço de informática</strong
            ></label
          >
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data
            id="inicioInformatica"
            v-model="this.manad.dtiniservinfo"
            :modelValue="this.manad.dtiniservinfo"
            :status="this.erros.datainiservinfo ? 'error' : ''"
            @blur="verificaDataInicioFim()"
            label="Início"
            @update:valid="this.$emit('validarDataIniServInfo', $event)"
            @update:erro="this.erros.datainiservinfo = $event"
            :message="this.erros.datainiservinfo"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <input-data
            id="fimInformatica"
            v-model="this.manad.dtfimservinfo"
            :modelValue="this.manad.dtfimservinfo"
            :status="this.erros.datafimservinfo ? 'error' : ''"
            label="Fim"
            @update:valid="this.$emit('validarDataFimServInfo', $event)"
            @update:erro="this.erros.datafimservinfo = $event"
            @blur="verificaDataInicioFim()"
            :message="this.erros.datafimservinfo"
            :disabled="this.itemEmpresa.empresaAtiva"
            @keydown.prevent.enter.exact="abaHandler('observacao', null, '#observacao' )"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empresa from './model/empresa.js'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

export default {
  name: 'abaManad',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    errosValidacao: Object
  },
  components: {
    'input-data': InputDate
  },
  inject: ['abaHandler'],
  data () {
    return {
      manad: {
        dtiniservcont: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtiniservcont
        ),
        dtfimservcont: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtfimservcont
        ),
        dtiniservinfo: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtiniservinfo
        ),
        dtfimservinfo: utils.formatarDataParaExibicao(
          this.itemEmpresa.dtfimservinfo
        ),
        escritorioDataSaida: utils.formatarDataParaExibicao(
          this.itemEmpresa.escritorioDataSaida
        ),
        escritorioDataEntrada: utils.formatarDataParaExibicao(
          this.itemEmpresa.escritorioDataEntrada
        )
      },
      erros: this.errosValidacao
    }
  },
  methods: {
    // Data de início não pode ser maior que a data de fim
    verificaDataInicioFim () {
      const ERRO_DATA_INICIO =
        'A data inicial deve ser menor ou igual a data final'
      const ERRO_DATA_FIM =
        'A data final deve ser maior ou igual a data inicial'

      if (this.manad.dtiniservcont && this.manad.dtfimservcont) {
        if (this.manad.dtiniservcont > this.manad.dtfimservcont) {
          this.erros.datainiservcont = ERRO_DATA_INICIO
          this.erros.datafimservcont = ERRO_DATA_FIM
        } else {
          this.erros.datainiservcont = ''
          this.erros.datafimservcont = ''
        }
      }

      if (this.manad.dtiniservinfo && this.manad.dtfimservinfo) {
        if (this.manad.dtiniservinfo > this.manad.dtfimservinfo) {
          this.erros.datainiservinfo = ERRO_DATA_INICIO
          this.erros.datafimservinfo = ERRO_DATA_FIM
        } else {
          this.erros.datainiservinfo = ''
          this.erros.datafimservinfo = ''
        }
      }
    }
  },
  watch: {
    manad: {
      handler: function (val, old) {
        try {
          if (val.dtiniservcont) {
            val.escritorioDataEntrada = val.dtiniservcont
          } else {
            val.escritorioDataEntrada = null
          }

          if (val.dtfimservcont) {
            val.escritorioDataSaida = val.dtfimservcont
          } else {
            val.escritorioDataSaida = null
          }

          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    'manad.dtiniservcont': {
      handler (val) {
        if (val) {
          this.erros.datainiservcont = ''
        }
      }
    },
    'manad.dtfimservcont': {
      handler (val) {
        if (val) {
          this.erros.datafimservcont = ''
        }
      }
    },
    'manad.dtiniservinfo': {
      handler (val) {
        if (val) {
          this.erros.datainiservinfo = ''
        }
      }
    },
    'manad.dtfimservinfo': {
      handler (val) {
        if (val) {
          this.erros.datafimservinfo = ''
        }
      }
    }
  }
}
</script>

<style>
.label {
  font-size: 0.8rem;
}
</style>
