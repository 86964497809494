<template>
  <Modal
    :active="this.modalVisivel"
    :title="registryOrEdit"
    width="46.9%"
    @close="this.$emit('cancel')"
  >
    <div class="columns is-multiline" style="height: 62vh">
      <div class="field column is-8">
        <input-texto
          label="Nome"
          id="nome"
          required
          name="nomeContador"
          v-model="this.contador.nome"
          maxlength="50"
          :status="this.getStatus(this.erros.nomeContador)"
          :message="this.erros.nomeContador"
          @update:erro="this.erros.nomeContador = $event.message"
          @blur="handleBlurNome()"
        />
      </div>
      <div class="field column is-4">
        <validador-cpf-cnpj
          id="numeroCPF"
          type="CPF"
          name="numeroCPF"
          v-model="this.contador.cpf"
          :status="this.getStatus(this.erros.numeroCPF)"
          :message="this.erros.numeroCPF"
          @update:erro="this.erros.numeroCPF = $event.message"
        />
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label
          class="label"
          for="rg"
          style="font-size: 1rem; font-weight: bold"
          >RG</label
        >
      </div>
      <div class="field column is-4">
        <input-texto
          id="numero"
          label="Número"
          v-model="this.contador.rg"
          :status="this.getStatus(this.erros.numeroRG)"
          :message="this.erros.numeroRG"
          maxlength="15"
          @update:erro="this.erros.numeroRG = $event.message"
          @keypress="this.onlyNumber(e, true)"
        />
      </div>
      <div class="field column is-4">
        <input-texto
          id="orgao"
          label="Orgão expedidor"
          v-model="this.contador.orgaoExpedidorRG"
          :status="this.getStatus(this.erros.orgaoRG)"
          :message="this.erros.orgaoRG"
          maxlength="15"
          @update:erro="this.erros.orgaoRG = $event.message"
        />
      </div>
      <div class="field column is-4">
        <input-data
          id="emissao"
          class="input"
          label="Emissão"
          name="dataEmissaoRG"
          v-model="this.contador.dataEmissaoRG"
          :status="this.getStatus(this.erros.dataEmissaoRG)"
          :message="this.erros.dataEmissaoRG"
          @update:erro="this.erros.dataEmissaoRG = $event"
        />
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label
          class="label"
          for="crc"
          style="font-size: 1rem; font-weight: bold"
          >CRC</label
        >
      </div>
      <div class="field column is-5">
        <input-texto
          id="numeroCRC"
          label="Número"
          v-model="this.contador.crc"
          :status="this.getStatus(this.erros.numeroCRC)"
          :message="this.erros.numeroCRC"
          maxlength="20"
          @update:erro="this.erros.numeroCRC = $event.message"
        />
      </div>
      <div class="field column is-4" style="padding-left: 0">
        <label
          class="label"
          for="tipo"
          style="font-size: 0.9rem; padding-left: 0.8rem"
          >Tipo</label
        >
        <radio-grupo
          name="tipo"
          class="is-flex is-justify-content-space-around"
          v-model="this.contador.qualificacao"
        >
          <radio-button id="contador" caption="Contador" value="C" />
          <radio-button id="tecnico" caption="Técnico" value="T" />
        </radio-grupo>
      </div>
      <div class="field column is-3">
        <label
          class="label m-0"
          for="ufContador"
          style="font-size: 0.9rem"
          >UF</label
        >
        <div class="field has-addons">
          <div class="select">
            <select
              name="ufContador"
              id="ufContador"
              v-model="this.contador.ufCRC"
            >
              <option v-for="uf in this.UFs" :key="uf">
                {{ uf.nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label
          class="label"
          for="certidao"
          style="font-size: 1rem; font-weight: bold"
          >Certidão de regularidade profissional</label
        >
      </div>
      <div class="field column is-8">
        <input-texto
          id="certidaoNumero"
          label="Número"
          v-model="this.contador.dhp"
          :status="this.getStatus(this.erros.certidaoNumero)"
          :message="this.erros.certidaoNumero"
          maxlength="50"
          @update:erro="this.erros.certidaoNumero = $event.message"
        />
      </div>
      <div class="field column is-4">
        <input-data
          id="validadeDHP"
          label="Validade"
          class="input"
          name="validadeDHP"
          v-model="this.contador.validadeDHP"
          :status="this.getStatus(this.erros.validadeDHP)"
          :message="this.erros.validadeDHP"
          @update:erro="this.erros.validadeDHP = $event"
        />
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label
          class="label"
          for="infoAuditor"
          style="font-size: 1rem; font-weight: bold"
          >Informações de auditor</label
        >
      </div>
      <div class="field column is-8">
        <input-texto
          id="codigoCVM"
          label="Código CVM"
          v-model="this.contador.codigoCVMAuditor"
          :status="this.getStatus(this.erros.codigoCVMAuditor)"
          :message="this.erros.codigoCVMAuditor"
          maxlength="5"
          @update:erro="this.erros.codigoCVMAuditor = $event.message"
          @keypress="this.onlyNumber(e, true)"
        />
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label
          class="label"
          for="contato"
          style="font-size: 1rem; font-weight: bold"
        >Contato</label>
      </div>
      <div class="field column is-4">
        <input-texto
          id="telefone"
          label="Telefone"
          name="telefoneContador"
          v-model="this.contador.telefone"
          :status="this.getStatus(this.erros.telefoneContador)"
          :message="this.erros.telefoneContador"
          maxlength="11"
          @update:erro="this.erros.telefoneContador = $event.message"
          @keypress="this.onlyNumber(e, true)"
          @blur="this.isTelefone($event, erros, 'telefoneContador')"
        />
      </div>
      <div class="field column is-8">
        <input-texto
          id="email"
          label="Email"
          maxlength="60"
          name="emailContador"
          v-model="this.contador.email"
          :status="this.getStatus(this.erros.emailContador)"
          :message="this.erros.emailContador"
          @update:erro="this.erros.emailContador = $event.message"
          @keypress="this.onlyEmail"
          @blur="this.isEmail($event, erros, 'emailContador')"
        />
      </div>
    </div>

    <template #footer>
      <ButtonAjuda style="margin-right: auto;"/>
      <Button type="primary" caption="Gravar" @click="this.handleClickSave" style="margin-right: 1rem"/>
      <Button type="secondary" caption="Cancelar" @click="this.clickCancelar" />
    </template>
  </Modal>
</template>

<script>
import Contador from './model/contador.js'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import RadioGroup from 'alterdata-componente/src/RadioGroup/index.vue'
import RadioButton from 'alterdata-componente/src/Radio/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import jsonUfs from '@/components/PesquisaMunicipioUF/ufs.json'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import ValidadorCpfCnpj from '@/components/ValidadorCpfCnpj/index.vue'
import { CPF_INVALIDO, INFORME_CONTADOR } from '@/util/message.js'
import { validadorDeInscricaoFederal } from '@packonline/packonline-reutilizaveis-frontend/Utils/InscricaoFederal.js'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import Button from '@alterdata/component-vue/Button'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'

export default {
  name: 'cadastroEdicaoContadorAuditor',
  components: {
    'input-data': InputDate,
    'input-texto': InputText,
    'radio-grupo': RadioGroup,
    'radio-button': RadioButton,
    'validador-cpf-cnpj': ValidadorCpfCnpj,
    Modal,
    Button,
    ButtonAjuda
  },

  props: {
    modalVisivel: Boolean,
    idEmpresa: String,
    itemContador: Contador,
    errosValidacao: Object,
    editando: Boolean
  },

  mixins: [mixinRouterGuardian, mixin],

  data () {
    return {
      contador: this.itemContador,
      contadorOriginal: null,
      erros: this.errosValidacao,
      UFs: jsonUfs.data,
      listagemVisivel: false
    }
  },
  computed: {
    registryOrEdit () {
      return `${this.editando ? 'Edição' : 'Cadastro'} contador / auditor`
    }
  },

  methods: {
    async _salvarContador () {
      if (this._validarInformacoesCadastro()) {
        await this._enviarParaBackend()
      }
    },

    async _enviarParaBackend () {
      this._formatarCamposParaEnvioAoBackend()
      const res = await service.save('/api/v1/contador', this.contador)
      await this.responseToSave(res)
    },

    async responseToSave (response) {
      this.jsonResposta = await response.json()
      if (response.status === 422) {
        this.erros = this.jsonResposta
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: this.jsonResposta.message })
      } else {
        this._clicarSalvar()
      }
    },

    _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      let ocorreuErro = false
      this.erros = this.errosValidacao
      const errosValidar = Contador.validar(
        this.contador,
        this.validadores,
        this.editando,
        this.errosValidacao
      )
      Object.assign(this.erros, errosValidar)
      if (this.erros.cadastroContador) {
        ocorreuErro = true
      }
      return !ocorreuErro
    },

    _clicarSalvar () {
      this.$emit('save', this.jsonResposta)
    },

    _validityCPF () {
      if (this.contador.cpf) {
        const result = validadorDeInscricaoFederal.validar(
          String(this.contador.cpf)
        )
        if (!result) {
          this.erros.numeroCPF = CPF_INVALIDO
        }
      }
    },

    handleClickSave () {
      const erros = [
        'nomeContador',
        'numeroCPF',
        'numeroRG',
        'orgaoRG',
        'dataEmissaoRG',
        'numeroCRC',
        'certidaoNumero',
        'validadeDHP',
        'codigoCVMAuditor',
        'telefoneContador',
        'emailContador',
        'cadastroContador'
      ]
      const possuiErro = this.checkErrors(this.erros, erros, [
        this.handleBlurNome,
        this._validityCPF
      ])

      if (possuiErro) {
        document.querySelector(`input[name="${possuiErro}"]`).focus()
      } else {
        this._enviarParaBackend()
      }
    },
    clickCancelar () {
      this.verificarModificaoNoItem()
      if (this.$root.dadosAlterados) {
        this.$root.mostrarModalDadosAlterados = true
      } else {
        this.$root.mostrarModalDadosAlterados = false
        this.$emit('cancel')
      }
    },
    clickSair () {
      this.$root.mostrarModalDadosAlterados = false
      this.$emit('cancel')
    },
    _formatarCamposParaEnvioAoBackend () {
      this.contador.dataEmissaoRG = utils.formatarCampoDataParaEnvioAoBackend(
        this.contador.dataEmissaoRG
      )
      this.contador.validadeDHP = utils.formatarCampoDataParaEnvioAoBackend(
        this.contador.validadeDHP
      )
      this.contador.cpf = utils.clearMaskNumber(this.contador.cpf)
    },

    _limparRegistroDeErros () {
      this.erros.nomeContador = ''
      this.erros.numeroCPF = ''
      this.erros.numeroRG = ''
      this.erros.orgaoRG = ''
      this.erros.dataEmissaoRG = ''
      this.erros.numeroCRC = ''
      this.erros.certidaoNumero = ''
      this.erros.validadeDHP = ''
      this.erros.codigoCVMAuditor = ''
      this.erros.telefoneContador = ''
      this.erros.emailContador = ''
      this.erros.cadastroContador = ''
    },

    _formatDate () {
      this.contador.dataEmissaoRG = this.contador.dataEmissaoRG
        ? utils.formatarDataParaExibicao(this.contador.dataEmissaoRG)
        : this.contador.dataEmissaoRG
      this.contador.validadeDHP = this.contador.validadeDHP
        ? utils.formatarDataParaExibicao(this.contador.validadeDHP)
        : this.contador.validadeDHP
    },

    handleBlurNome () {
      if (!this.contador.nome) {
        this.erros.nomeContador = INFORME_CONTADOR
      } else {
        this.erros.nomeContador = ''
      }
    },

    verificarModificaoNoItem () {
      const isEqual = utils.isEqual(this.contador, this.contadorOriginal)
      if (!isEqual) {
        this.$root.dadosAlterados = true
      } else {
        this.$root.dadosAlterados = false
      }
    }
  },

  mounted () {
    this._limparRegistroDeErros()
    const cardTitle = document.querySelector('.alt-modal-card-title')
    if (cardTitle) {
      cardTitle.style.marginBottom = 0
    }
    this.contadorOriginal = utils.cloneDeep(this.contador)
  },
  created () {
    this._formatDate()
    this.$root.mostrarModalDadosAlterados = false
    this.$root.dadosAlterados = false
    if (!this.$root.parametrosModalSalvarMudancas) {
      this.$root.parametrosModalSalvarMudancas = {}
    } else {
      const { parametrosModalSalvarMudancas } = this.$root
      parametrosModalSalvarMudancas.eventoSalvar = this.handleClickSave
      parametrosModalSalvarMudancas.eventoSair = this.clickSair
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.$root.mostrarModalDadosAlterados) {
      this.beforeRoute(to, from, next)
      this.$root.rotaPara = to.fullPath
    } else {
      next()
    }
  }
}
</script>

<style scoped></style>
