<template>
  <div v-if="this.modalVisivel" id="cadastroEdicao">
    <page-title>{{!this.editando ? "Cadastro" : "Edição"}} de empresas</page-title>
    <div v-if="cardAtivo" class="column is-12">
      <progress v-if="isLoading" class="progress is-small is-primary" max="100">15%</progress>
      <cards-de-vinculo v-else
        @cancelaCard="_fecharCards"
        @editarCadastroInicial="_fecharCardeMostrarEdicao"
        :itens="this.item"
        :checkCadastrosBasicos = "true"
        :checkContabil="vinculos.wcont"
        :checkDP="vinculos.wdp"
        :checkFiscal="vinculos.wfiscal"
        :mensagemNovaEmpresa="this.editando"
        :empresaInativa="this.statusAtual === 'Inativa'"
       />
    </div>
    <div class="columns" v-else>
      <div class="column is-2">
        <menu-vertical v-if="modalVisivel" :pItens="this.itensMenuVertical" :pActiveLink="this.activeLink"
          @ativarAba="this._ativarAba"></menu-vertical>
      </div>
      <div :class="(abaSociosVinculadosVisivel ? 'column' : 'columns') + ' is-multiline'">
        <div :class="this.tamanhoTela">
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="field column is-12">
                <abaBasico :abaVisivel="abaBasicoVisivel" :montarObjeto="_montarObjetoEmpresa" :itemEmpresa="item"
                  :errosValidacao="erros" :editando="this.editando" @verificarCodigo="this.codigo = $event"
                  :montarObjetoApiCnpj="_montarObjetoApiCnpj"
                  @navToAbaGeral="abaHandler('complementar', 'geral', '#inicioAtividade')" />
                <abaComplementar :itemDadosReceita="this.itemDadosReceita" :abaVisivel="abaComplementarVisivel"
                  :montarObjeto="_montarObjetoEmpresa" :itemEmpresa="item" :montarObjetoCnae="_montarObjetoCnae"
                  :montarObjetoSocemp="_montarObjetoSocemp" :errosValidacao="erros"
                  :abaASerAtivada="this.abaASerAtivadaNaAbaComplementares" :editando="this.editando"
                  :exibindoCadastroSecundario="this.exibirCadastroSecundario" :cnaeAlterado="this.novoCNAE"
                  :itemAuditor="this.itemAuditor" :editandoAuditor="this.editandoAuditor"
                  :montarObjetoHistoricoNire="_montarObjetoHistoricoNire" :listaVinculosNovosEditados="this.listaAuditoresVinculadosParaMandarParaOBackend
                    " :contadores="this.contadores" @editAuditor="this.editandoAuditor = $event"
                  @mudarAbaComp="this.abaASerAtivadaNaAbaComplementares = $event"
                  @onAbrirCadastroHistoricoNire="this._abrirCadastroHistoricoNire"
                  @onAbrirCadastroHistoricoCnae="this._abrirCadastroHistoricoCnae"
                  @onAbrirCadastroCnaeSecundario="this._abrirFecharCnaeSecundario" @onAbrirCadastroContadorAuditor="this._abrirFecharContadorAuditor
                    " @validarDataConversao="this.validadores.validadorDataConversao = $event
    " @validarDataIniServCont="this.validadores.validadorDataIniServCont = $event
    " @validarDataFimServCont="this.validadores.validadorDataFimServCont = $event
    " @validarDataIniServInfo="this.validadores.validadorDataIniServInfo = $event
    " @validarDataFimServInfo="this.validadores.validadorDataFimServInfo = $event
    " @validarDataNire="this.validadores.validadorDataNire = $event" @validarDataNascimento="this.validadores.validadorDataNascimento = $event
    " @validarDataJunta="this.validadores.validadorDataJunta = $event"
                  @armazenarVinculo="this._armazenarNaListaOVinculoDeAuditor"
                  @armazenarVinculoASerExcluido="this._excluirAuditor" @editarAuditor="this._editarVinculoAuditor" />
                <abaObservacao :abaVisivel="abaObservacaoVisivel" :montarObjeto="_montarObjetoEmpresa" :itemEmpresa="item"
                  :errosValidacao="erros" />
                <abaLogomarca :abaVisivel="abaLogomarcaVisivel" :montarObjeto="_montarObjetoEmpresa" :itemEmpresa="item"
                  :errosValidacao="erros" />
                <abaUsuarios :abaVisivel="abaUsuariosVisivel" :itemEmpresa="item" />
                <abaSociosVinculados
                  :abaVisivel="abaSociosVinculadosVisivel"
                  :itemEmpresa="item"
                  :exibindoCadastroSecundario="this.exibirCadastroSecundario"
                  :listaVinculosNovosEditados="this.listaSociosVinculadosParaMandarParaOBackend"
                  @atualizarHistoricosDeRetencaoDoBackend="this.historicosDeRetencao = $event"
                  @onAbrirCadastroVinculoSocio="this._abrirCadastroVinculoSocios"
                  @armazenarVinculoASerExcluido="this._armazenarNaListaOVinculoDeSocioASerExcluido"
                />
              </div>
            </div>
          </section>
          <footer class="modal-card-foot is-justify-content-flex-end">
            <div class="buttons">
              <button v-if="!this.exibirFechar" class="button is-primary" data-group="btn-gravar-empresa" :key="botaoGravar" :title="shortkeys.MSG_KEY_GRAVAR" @click="this._salvarEmpresa">
                Gravar
              </button>
              <alt-button class="button" :title="shortkeys.MSG_KEY_ESC" data-group="btn-fechar-empresa" @click="cancelaOuSai">
                {{ this.exibirFechar ? 'Fechar' : 'Cancelar'}}
              </alt-button>
            </div>
          </footer>
        </div>
        <div :class="this.tamanhoCadastroSecundario">
          <cadastro-Secundario v-if="this.exibirCadastroSecundario"
          :quickview="false" :tituloTela="this.tituloCadastroSecundario"
            @fecharCadastroSecundario="this._fecharCadastroSecundario"
            :ocultarBotaoFechar="this.ocultarBotaoFecharCadastroSecundario">
            <tela-Listagem-Historico v-if="this.abrirListagemHistoricoNire"
              :modalVisivel="this.abrirListagemHistoricoNire" :itemEmpresa="item" />
            <tela-Listagem-Cnae-Historico v-if="abrirListagemHistoricoCnae" :modalVisivel="abrirListagemHistoricoCnae"
              :itemEmpresa="item" @salvarCNAEEmpresa="_salvarCNAEEmpresa" />
            <tela-Listagem-Cnae v-if="this.abrirListagemCnaeSecundario" :modalVisivel="this.abrirListagemCnaeSecundario"
              :itemEmpresa="item" @fechar="abrirFecharCnaeSecundario" />
            <tela-Listagem-Auditor v-if="this.abrirCadastroContadorAuditor" :errosValidacao="this.erros"
              :itemEmpresa="item" @update:ContadorAuditor="handleUpdateContadorAuditor" @fechar="abrirFecharAuditor" />
          </cadastro-Secundario>
        </div>
      </div>
      <modal-confirmacao v-if="this.confirmacaoValorCapitalVisivel" @on-sim="_abreFechaConfirmacaoValorCapital(true)"
        @on-nao="_abreFechaConfirmacaoValorCapital(false)" :textoConfirmacao="this.textoConfirmacao" />
      <modalAviso v-if="this.avisoVisivel" :textoAviso="this.textoConfirmacao" @on-ok="this.abreFechaAviso" />
      <Modal title="Confirmação" :active="this.abrirModalConfirmacao" @close="this.abrirModalConfirmacao = false">
        <p>
          O CNPJ/CPF e/ou Inscrição Estadual não foram preenchidos, a empresa
          ficará com status à concluir. Deseja continuar?
        </p>
        <template v-slot:footer>
          <alt-button class="button is-primary" @click="this._enviarParaBackend" caption="Sim"
            style="margin-right: 1rem" />
          <alt-button class="button" @click="this.abrirModalConfirmacao = false" caption="Não" />
        </template>
      </Modal>
      <Feedbacker modulo="Cadastro de empresas" :env="env"/>
    </div>
  </div>
</template>

<script>
import MenuVertical from '../../components/MenuVertical/index.vue'
import abaBasico from './abaBasico.vue'
import abaComplementar from './abaComplementar.vue'
import abaObservacao from './abaObservacao.vue'
import abaLogomarca from './abaLogomarca.vue'
import abaUsuarios from './abaUsuarios.vue'
import { empresaService } from './service/empresa-service.js'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import Empresa from './model/empresa.js'
import EmpresaHistorico from './Cnae/model/empresa-historico.js'
import modalConfirmacao from '../../components/ModalConfirmacao/index.vue'
import modalAviso from '../../components/ModalAviso/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import CadastroSecundario from '../../components/CadastroSecundario/cadastroSecundario.vue'
import historicoNire from './HistoricoNire/index.vue'
import cnaeHistorico from './Cnae/index.vue'
import cnaeSecundario from './CnaeSecundario/index.vue'
import HistoricoNire from './HistoricoNire/model/historico-nire.js'
import abaSociosVinculados from './VinculoSocioEmpresa/index.vue'
import CadastroVinculoSocio from './VinculoSocioEmpresa/cadastroVinculo.vue'
import { operacoesVinculo } from './VinculoSocioEmpresa/operacoesVinculoSocioEmpresa.js'
import { operacoesVinculoAuditor } from './AuditorIndependente/operacoesAuditorIndependente'
import AuditorIndependente from './AuditorIndependente/model/auditor-independente'
import contadorAuditor from './ContadorAuditor/cardContador.vue'
import Socemp from './VinculoSocioEmpresa/model/socemp'
import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'
import { integracaoeContador } from '../../IntegracaoeContador/integracao-e-contador.js'
import Feedbacker from '@packonline/packonline-reutilizaveis-frontend/components/Feedbacker/index.vue'
import { env } from '../../env'
import CardsDeVinculo from './CardsDeVinculo/index.vue'
import PageTitle from '../../components/PageTitle/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'

const ERROS = {
  abaBasico: '',
  abaGeral: '',
  abaContabil: '',
  abaManad: '',
  abaSped: '',
  cnpj: '',
  telefone: '',
  email: '',
  fax: '',
  nome: '',
  cnae: '',
  valorCapital: null,
  porteEmpresa: null,
  stutilizanmcompleto: null,
  dataInicioAtividade: null,
  dataFimAtividade: null,
  dataExpedicaoNire: null,
  escritorioDataSaida: null,
  suframa: null,
  inscricaoEstadual: null,
  inscricaoMunicipal: null,
  registroOab: null,
  registroCartorio: null,
  codigoNire: null,
  numeroJunta: null,
  codigoNaturezaJuridica: null
}

export default {
  name: 'cadastroEdicao',
  components: {
    abaBasico,
    abaComplementar,
    abaObservacao,
    abaLogomarca,
    abaUsuarios,
    modalConfirmacao,
    modalAviso,
    abaSociosVinculados,
    Modal,
    'alt-button': Button,
    'cadastro-Secundario': CadastroSecundario,
    'menu-vertical': MenuVertical,
    'tela-Listagem-Historico': historicoNire,
    'tela-Listagem-Cnae-Historico': cnaeHistorico,
    'tela-Listagem-Cnae': cnaeSecundario,
    'tela-Listagem-Auditor': contadorAuditor,
    'cards-de-vinculo': CardsDeVinculo,
    Feedbacker,
    'page-title': PageTitle
  },
  mixins: [mixin],
  inject: ['setActiveSection', 'addShortcut', 'direcionaAjuda'],
  props: {
    modalVisivel: Boolean,
    editando: Boolean,
    item: Empresa,
    itemAuxiliar: Empresa
  },
  data () {
    return {
      paraEditar: false,
      cardAtivo: false,
      salvo: false,
      cargaInicial: 0,
      itemAux: this.itemAuxiliar,
      itemDadosReceita: null,
      abaBasicoVisivel: true,
      abaComplementarVisivel: false,
      abaObservacaoVisivel: false,
      abaLogomarcaVisivel: false,
      abaUsuariosVisivel: false,
      abaSociosVinculadosVisivel: false,
      empresa: new Empresa(),
      erros: ERROS,
      itemCnae: new EmpresaHistorico(),
      itemSocempCadastro: new Socemp(),
      itemAuditor: null,
      confirmacaoVisivel: false,
      confirmacaoValorCapitalVisivel: false,
      textoConfirmacao: '',
      abaASerAtivadaNaAbaComplementares: null,
      abrirModalConfirmacao: false,
      avisoVisivel: false,
      itemSocemp: {
        id: null,
        vlcapital: null
      },
      jsonResposta: null,
      exibirCadastroSecundario: false,
      activeLink: 'básico',
      itensMenuVertical: [
        {
          active: true,
          links: [
            {
              descricao: 'Básico',
              url: 'básico',
              validacao: { isValido: false }
            },
            {
              descricao: 'Complementar',
              url: 'complementar',
              validacao: { isValido: false }
            },
            {
              descricao: 'Observação',
              url: 'observacao',
              validacao: { isValido: false }
            },
            {
              descricao: 'Logomarca',
              url: 'logomarca',
              validacao: { isValido: false }
            },
            {
              descricao: 'Sócios vinculados',
              url: 'sócios',
              validacao: { isValido: false }
            }
          ]
        }
      ],
      abrirListagemHistoricoNire: false,
      abrirListagemHistoricoCnae: false,
      abrirListagemCnaeSecundario: false,
      abrirCadastroVinculoSocios: false,
      tamanhoTela: 'column is-12',
      tituloCadastroSecundario: null,
      podeAtualizar: true,
      novoCNAE: null,
      codigo: null,
      historicoNireOriginal: {
        codigo: null,
        data: null
      },
      tamanhoCadastroSecundario: 'column is-4',
      editandoAuditor: false,
      indexadores: [],
      listaSociosVinculadosParaMandarParaOBackend: [],
      listaSociosVinculadosParaExcluirNoBackend: [],
      listaAuditoresVinculadosParaMandarParaOBackend: [],
      listaAuditoresVinculadosParaExcluirNoBackend: [],
      tempListaSocios: [],
      tempListaAuditor: [],
      ocultarBotaoFecharCadastroSecundario: false,
      listaSociosVinculados: [],
      listaAuditoresVinculados: [],
      validadores: {
        validadorDataConversao: null,
        validadorDataIniServCont: null,
        validadorDataFimServCont: null,
        validadorDataIniServInfo: null,
        validadorDataFimServInfo: null,
        validadorDataNire: null,
        validadorDataJunta: null
      },
      abrirCadastroContadorAuditor: false,
      contadores: [],
      exibirFechar: null,
      checaVinculoContabil: false,
      vinculos: {},
      NovaEmpresaAtivarCard: false,
      statusAtual: '',
      isLoading: false,
      empresaGravada: false,
      shortkeys: shortkeys,
      env: env,
      historicosDeRetencao: [],
      historicosDeRetencaoOriginal: [],
      historicosDeRetencaoASeremCriados: [],
      historicosDeRetencaoASeremCriadosOriginal: [],
      historicosDeRetencaoASeremExcluidos: [],
      historicosDeRetencaoASeremExcluidosOriginal: []
    }
  },
  watch: {
    item: {
      handler: function (val, old) {
        if (this.cargaInicial >= 3) {
          this._montarObjetoEmpresa(val)
        }
        if (this.listaSociosVinculadosParaMandarParaOBackend.length > 0) {
          this._atualizarListaDeVinculos(val)
        }

        if (this.listaAuditoresVinculadosParaMandarParaOBackend.length > 0) {
          this._atualizarListaDeVinculosAuditor(val)
        }

        if (this.exibirFechar === true && this.item.empresaAtiva === false) {
          this.exibirFechar = false
        }
      },
      immediate: true,
      deep: true
    },
    cardAtivo (val) {
      if (val) {
        this.$router.push(`/cadastros/empresa/${utils.strZero(this.item.cdempresa, 5)}`)
        this.checaVinculos()
        this.$root.exibirFooter = false
        this.ligarAtalhosCards()
      } else {
        this.$root.exibirFooter = true
      }
    },
    modalVisivel (val) {
      if (val && this.editando && this.item.status === 'Ativa') {
        this.cardAtivo = true
      } else if (val && this.editando && (this.item.status === 'Inativa' || this.item.status === 'Encerrada')) {
        this.statusAtual = this.item.status
        this.cardAtivo = true
      } else {
        this.cardAtivo = false
      }
    }
  },
  provide () {
    return {
      abaHandler: this.abaHandler,
      clickCancelar: this.cancelaOuSai,
      clickSalvar: this._salvarEmpresa
    }
  },
  computed: {
    dadosAlteradosRoot () {
      return this.$root.dadosAlterados
    }
  },
  methods: {
    preventNav (bValue) {
      this.$root.dadosAlterados = bValue
    },
    _montarObjetoApiCnpj (objeto) {
      this.itemDadosReceita = objeto
    },
    abaHandler (toAba, toSubAba, elementFocus) {
      this.activeLink = toAba
      this.abaASerAtivadaNaAbaComplementares = toSubAba
      if (elementFocus) {
        setTimeout(() => {
          try {
            this.focarNextInput(elementFocus)
          } catch (e) {
            return false
          }
        }, 30)
      }
    },

    async _salvarEmpresaDuranteModalDadosAlterados () {
      this._salvarEmpresa()
      if (await this._validarInformacoesCadastro()) {
        this.$parent.abrirFecharEdicao()
        this.$root.mostrarModalDadosAlterados = false
        if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
          const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
          sessionStorage.removeItem('rotaClicadaNoMenuLateral')
          this.$router.push(`${rotaClicadaNoMenuLateral}`)
        }
      }
    },

    async _salvarEmpresa () {
      this.exibirFechar = null
      Object.assign(this.empresa, this.item)
      this.salvo = true
      if (this.empresa.empresaAtiva === true) {
        this.empresa.st_dc = false
        integracaoeContador.desintegrar(
          parseInt(this.item.cdempresa).toString(),
          this.empresa
        )
        await this._enviarParaBackend()
        return
      }
      this.empresa.logradouro = `${this.empresa.logradouro}, ${this.empresa.numero}`
      if (await this._validarInformacoesCadastro()) {
        if (!this.editando && (!this.item.numeroCGC || !this.item.inscricaoEstadual)) {
          this.abrirModalConfirmacao = true
        } else {
          this.isLoading = true
          await this._enviarParaBackend()
          this.empresaGravada = true
          this.cardAtivo = true
          this.isLoading = false
          this.$root.dadosAlterados = false
          this.$nextTick(() => {
            this.setActiveSection('telaCards')
          })
        }
      }
    },

    async _enviarParaBackend () {
      this.abrirModalConfirmacao = false
      this._formatarCamposParaEnvioAoBackend()
      empresaService
        .save('/api/v1/empresa', this.empresa, this.editando)
        .then(this.responseToSave)
    },

    async responseToSave (response) {
      this.jsonResposta = await response.json()

      if (response.ok) {
        if (this.editando && this.itemCnae.empresaID) {
          this.itemCnae.dataInicial = new Date(
            this.itemCnae.dataInicial
          ).toISOString()
          await service.save('/api/v1/empresa-historico', this.itemCnae)
        }
        if (
          this.editando &&
          this.historicoNireOriginal.codigo &&
          this.historicoNireOriginal.codigo !== this.empresa.codigoNire
        ) {
          this._salvarHistoricoNireAlterado()
        }

        this._excluirNoBackEndVinculosDeSocios(this.empresa.st_dc)

        this._salvarVinculoDeSociosEmpresa(this.empresa.st_dc)

        this._excluirNoBackEndVinculosDeAuditores()

        this._salvarVinculoDeAuditoresEmpresa()

        this._enviarAlteracoesDoHistoricoDeRetencaoAoBackend()

        if (this.editando && this.itemSocemp.id) {
          this.confirmacaoValorCapitalVisivel = true
          this.textoConfirmacao =
            'Capital Social foi alterado! - Deseja atualizar o valor de capital dos sócios?'
        } else {
          await this.executarIntegracao()
          this._clicarSalvar()
          this.listagemVisivel = false
          this.$root.dadosAlterados = false
        }
      } else if (response.status === 422) {
        this.erros = this.jsonResposta
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: this.jsonResposta.message })
      }
    },
    async executarIntegracao () {
      this._formataObjetoAuxiliar()
      this.itemAux.logradouro = `${this.itemAux.logradouro}, ${this.itemAux.numero}`
      if (this.itemAux.numeroCGC) {
        this.itemAux.numeroCGC = this.itemAux.numeroCGC.replace(/[^0-9]+/g, '')
        this.empresa.numeroCGC = this.empresa.numeroCGC.replace(/[^0-9]+/g, '')
      }
      this.itemAux.cdempresa = parseInt(this.itemAux.cdempresa, 10)
      this.empresa.cdempresa = parseInt(this.empresa.cdempresa, 10)
      if (
        !utils.isEquivalent(this.itemAux, this.empresa) &&
        this.item.st_dc === true
      ) {
        try {
          integracaoeContador._integrarBureau()
          integracaoeContador._integrarEmpresa(this.item.cdempresa)
        } catch (error) {
          throw new Error(error.message)
        }
      }
      this.cargaInicial = 0
    },
    _atribuirFalseParaTodasAsAbas () {
      this.abaBasicoVisivel = false
      this.abaComplementarVisivel = false
      this.abaObservacaoVisivel = false
      this.abaLogomarcaVisivel = false
      this.abaSociosVinculadosVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'básico':
          this.abaBasicoVisivel = true
          this.abaHandler('básico', null, null)
          this.ligarAtalhosAbas('abaBasico')
          break
        case 'complementar':
          this.abaComplementarVisivel = true
          this.abaHandler('complementar', 'geral', '#inicioAtividade')
          this.ligarAtalhosAbas('abaComplementar')
          break
        case 'observacao':
          this.abaObservacaoVisivel = true
          this.ligarAtalhosAbas('aba')
          break
        case 'logomarca':
          this.abaLogomarcaVisivel = true
          this.ligarAtalhosAbas('aba')
          break
        case 'sócios':
          this.abaSociosVinculadosVisivel = true
          this.ligarAtalhosAbas('aba')
          break
      }
    },
    _montarObjetoEmpresa (objeto) {
      Object.assign(this.item, objeto)
    },

    _montarObjetoCnae (objeto) {
      Object.assign(this.itemCnae, objeto)
    },

    _montarObjetoSocemp (objeto) {
      Object.assign(this.itemSocemp, objeto)
    },

    async _validarInformacoesCadastro () {
      try {
        this._limparRegistroDeErros()
        let ocorreuErro = false
        this.abaASerAtivadaNaAbaComplementares = undefined
        this.erros = ERROS
        const resultadoValidacao = await Empresa.validar(
          this.empresa,
          this.codigo,
          this.editando,
          this.validadores
        )
        Object.assign(this.erros, resultadoValidacao)
        if (this.erros.abaBasico || this.erros.municipioBasico) {
          this._ativarAba('básico')
          this.ligarAtalhosAbas('abaBasico')
          ocorreuErro = true
        } else {
          if (this.erros.abaGeral) {
            ocorreuErro = true
            this._ativarAba('complementar')
            this.abaASerAtivadaNaAbaComplementares = 'geral'
            this.ligarAtalhosAbas('abaComplementar')
          } else {
            if (this.erros.abaContabil) {
              if (this.erros.stutilizanmcompleto) {
                this.avisoVisivel = true
                this.textoConfirmacao = this.erros.stutilizanmcompleto
                ocorreuErro = true
                this._ativarAba('complementar')
                this.abaASerAtivadaNaAbaComplementares = 'contábil'
                this.ligarAtalhosAbas('abaComplementar')
              } else {
                ocorreuErro = true
                this._ativarAba('complementar')
                this.abaASerAtivadaNaAbaComplementares = 'contábil'
                this.ligarAtalhosAbas('abaComplementar')
              }
            } else {
              if (this.erros.abaManad) {
                ocorreuErro = true
                this._ativarAba('complementar')
                this.abaASerAtivadaNaAbaComplementares = 'manad'
                this.ligarAtalhosAbas('abaComplementar')
              } else {
                if (this.erros.abaSped) {
                  if (this.erros.porteEmpresa) {
                    this.confirmacaoVisivel = true
                    this.textoConfirmacao = this.erros.porteEmpresa
                    ocorreuErro = true
                    this._ativarAba('complementar')
                    this.abaASerAtivadaNaAbaComplementares = 'sped'
                    this.setActiveSection('abaComplementarSped')
                  }
                } else {
                  if (this.erros.abaObservacao) {
                    ocorreuErro = true
                    this._ativarAba('observacao')
                    this.ligarAtalhosAbas('aba')
                  }
                }
              }
            }
          }
        }
        if (ocorreuErro) {
          this.escondeModal()
          this.dadosAlterados()
        }
        return !ocorreuErro
      } catch (ex) {
        return false
      }
    },
    _formataObjetoAuxiliar () {
      this.itemAux.dataInicioAtividade =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.itemAux.dataInicioAtividade
        )
      this.itemAux.dataFimAtividade = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dataFimAtividade
      )
      this.itemAux.dataRegistroJuntaComercial =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.itemAux.dataRegistroJuntaComercial
        )
      this.itemAux.escritorioDataEntrada =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.itemAux.escritorioDataEntrada
        )
      this.itemAux.escritorioDataSaida =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.itemAux.escritorioDataSaida
        )
      this.itemAux.dataExpedicaoNire =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.itemAux.dataExpedicaoNire
        )
      this.itemAux.dtconvempresa = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtconvempresa
      )
      this.itemAux.dtiniservcont = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtiniservcont
      )
      this.itemAux.dtfimservcont = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtfimservcont
      )
      this.itemAux.dtiniservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtiniservinfo
      )
      this.itemAux.dtfimservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtfimservinfo
      )
      this.itemAux.dtNascimento = utils.formatarCampoDataParaEnvioAoBackend(
        this.itemAux.dtNascimento
      )
      this.itemAux.numeroCGC = this.item.numeroCGC ? this.item.numeroCGC.replace(/[^0-9]+/g, '') : ''
      this.itemAux.valorCapital = this.itemAux.valorCapital
        ? this.itemAux.valorCapital.toString().replace(',', '.')
        : null
      this.itemAux.cdempresa = utils.strZero(this.itemAux.cdempresa, 5)
    },
    _formatarCamposParaEnvioAoBackend () {
      this.empresa.dataInicioAtividade =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.empresa.dataInicioAtividade
        )
      this.empresa.dataFimAtividade = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dataFimAtividade
      )
      this.empresa.dataRegistroJuntaComercial =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.empresa.dataRegistroJuntaComercial
        )
      this.empresa.escritorioDataEntrada =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.empresa.escritorioDataEntrada
        )
      this.empresa.escritorioDataSaida =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.empresa.escritorioDataSaida
        )
      this.empresa.dataExpedicaoNire =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.empresa.dataExpedicaoNire
        )
      this.empresa.dtconvempresa = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtconvempresa
      )
      this.empresa.dtiniservcont = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtiniservcont
      )
      this.empresa.dtfimservcont = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtfimservcont
      )
      this.empresa.dtiniservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtiniservinfo
      )
      this.empresa.dtfimservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtfimservinfo
      )
      this.empresa.dtNascimento = utils.formatarCampoDataParaEnvioAoBackend(
        this.empresa.dtNascimento
      )
      if (this.empresa.numeroCGC) {
        this.empresa.numeroCGC = this.empresa.numeroCGC.replace(/[^0-9]+/g, '')
      }
      this.empresa.valorCapital = this.empresa.valorCapital
        ? this.empresa.valorCapital.toString().replace(',', '.')
        : null
      this.empresa.cdempresa = parseInt(this.item.cdempresa, 10)
    },

    async _abreFechaConfirmacaoValorCapital (resposta) {
      if (resposta) {
        await service.save('/api/v1/atualiza-valor-capital', this.itemSocemp)
      }
      this.confirmacaoValorCapitalVisivel = !this.confirmacaoValorCapitalVisivel
      this._clicarSalvar()
    },

    abreFechaAviso () {
      this._ativarAba('complementar')
      this.avisoVisivel = !this.avisoVisivel
    },

    _limparRegistroDeErros () {
      this.erros.abaBasico = ''
      this.erros.abaGeral = ''
      this.erros.abaContabil = ''
      this.erros.abaManad = ''
      this.erros.abaSped = ''
      this.erros.cdempresa = ''
      this.erros.cnpj = ''
      this.erros.cdMunicipio = ''
      this.erros.telefone = ''
      this.erros.email = ''
      this.erros.fax = ''
      this.erros.nome = ''
      this.erros.cnae = ''
      this.erros.nomeFantasia = ''
      this.erros.descricaoAtividade = ''
      this.erros.valorCapital = null
      this.erros.porteEmpresa = null
      this.erros.stutilizanmcompleto = null
      this.erros.dataInicioAtividade = null
      this.erros.dataFimAtividade = null
      this.erros.dataExpedicaoNire = null
      this.erros.escritorioDataSaida = null
      this.erros.cep = ''
      this.erros.logradouro = ''
      this.erros.bairro = ''
      this.erros.uf = ''
      this.erros.numero = ''
      this.erros.suframa = null
      this.erros.inscricaoEstadual = null
      this.erros.inscricaoMunicipal = null
      this.erros.registroOab = null
      this.erros.registroCartorio = null
      this.erros.codigoNire = null
      this.erros.numeroJunta = null
      this.erros.codigoNaturezaJuridica = null
      this.erros.dataconvempresa = null
      this.erros.datainiservcont = null
      this.erros.datafimservcont = null
      this.erros.datainiservinfo = null
      this.erros.datafimservinfo = null
      this.erros.ano = null
      this.erros.auditor = null
      this.erros.nomeAuditor = null
      this.erros.dataRegistroJuntaComercial = null
      this.erros.complemento = ''
      this.erros.dtNascimento = null
    },

    _limparValidadores () {
      this.validadores.validadorDataConversao = null
      this.validadores.validadorDataFimServCont = null
      this.validadores.validadorDataFimServInfo = null
      this.validadores.validadorDataIniServCont = null
      this.validadores.validadorDataIniServInfo = null
      this.validadores.validadorDataJunta = null
      this.validadores.validadorDataNire = null
    },

    _fecharTela () {
      this._fecharCadastroSecundario()
      this.itemSocemp.id = null
      this.itemSocemp.vlcapital = null
      this._limparValidadores()
      this._limparRegistroDeErros()
      this.listaSociosVinculadosParaMandarParaOBackend = []
      this.listaAuditoresVinculadosParaMandarParaOBackend = []
      this.listaAuditoresVinculadosParaExcluirNoBackend = []
      this.editandoAuditor = false
    },
    _clicarCancelar () {
      this._fecharTela()
      this.exibirFechar = null
      this.cargaInicial = 0
      this.dadosInalterados()
      this.paraEditar = false
      this.$emit('cancel', true)
    },
    _fecharCards () {
      this._clicarCancelar()
    },
    cancelaEdicaoeMostraCards () {
      this.dadosInalterados()
      Object.assign(this.item, this.itemAux)
      this.paraEditar = false
      this.cardAtivo = true
      this._limparDadosDosHistoricosDeRetencao()
    },
    _fecharCardeMostrarEdicao () {
      this.paraEditar = true
      this.cardAtivo = false
      this._ativarAba('básico')
    },

    _clicarSalvar () {
      this._fecharTela()
      window.onbeforeunload = null
      window.onunload = null
      this.paraEditar = false
      this.listagemVisivel = false
      this.$emit('save', this.jsonResposta)
      Object.assign(this.item, this.jsonResposta)
      Object.assign(this.itemAux, this.item)
      try {
        const url = `/api/v1/empresa-cadastros-vinculados/${this.jsonResposta.cdempresa}`
        service.get(url).then(res => {
          this.statusAtual = res.status
          if (res.status === 'À concluir') {
            this.$emit('close')
          } else if (res.status === 'Inativa') {
            this.exibirFechar = false
            this.statusAtual = res.status
            this.$emit('close')
          }
        })
      } catch (err) {
        return false
      }
    },
    _abrirCadastroHistoricoNire (titulo) {
      this._fecharTodosOsCadastrosSecundarios()
      this.abrirListagemHistoricoNire = !this.exibirCadastroSecundario
      this.exibirCadastroSecundario = !this.exibirCadastroSecundario
      this.tituloCadastroSecundario = titulo
      this._ajustarTamanhoDaTela()
    },

    _abrirCadastroHistoricoCnae (titulo) {
      this._fecharTodosOsCadastrosSecundarios()
      this.abrirListagemHistoricoCnae = !this.exibirCadastroSecundario
      this.exibirCadastroSecundario = !this.exibirCadastroSecundario
      this.tituloCadastroSecundario = titulo
      this._ajustarTamanhoDaTela()
    },

    _abrirFecharCnaeSecundario (titulo) {
      this._fecharTodosOsCadastrosSecundarios()
      this.abrirListagemCnaeSecundario = !this.exibirCadastroSecundario
      this.exibirCadastroSecundario = !this.exibirCadastroSecundario
      this.tituloCadastroSecundario = titulo
      this._ajustarTamanhoDaTela()
    },

    _abrirFecharContadorAuditor (titulo) {
      this._fecharTodosOsCadastrosSecundarios()
      this.abrirCadastroContadorAuditor = !this.abrirCadastroContadorAuditor
      this.exibirCadastroSecundario = !this.exibirCadastroSecundario
      this.tituloCadastroSecundario = titulo
      this._ajustarTamanhoDaTela('column is-7', 'column is-5')
    },

    _abrirCadastroVinculoSocios (
      titulo,
      itemSocempParametro,
      indexadores,
      listaSociosVinculados
    ) {
      this.itemSocempCadastro = itemSocempParametro
      this.abrirCadastroVinculoSocios = !this.exibirCadastroSecundario
      this.editandoTeste = itemSocempParametro.codigoSocio
      this.indexadores = indexadores
      this.ocultarBotaoFecharCadastroSecundario = true
      this.listaSociosVinculados = listaSociosVinculados
      this.botaoNovoVinculoPress = !this.botaoNovoVinculoPress
      this.historicosDeRetencaoOriginal = utils.cloneDeep(this.historicosDeRetencao)
      this.historicosDeRetencaoASeremCriadosOriginal = utils.cloneDeep(this.historicosDeRetencaoASeremCriados)
      this.historicosDeRetencaoASeremExcluidosOriginal = utils.cloneDeep(this.historicosDeRetencaoASeremExcluidos)
      const { paramentrosQuickView } = this.$root
      if (!paramentrosQuickView.componenteSlotQuickView) {
        this.limparParametrosQuickView()
        paramentrosQuickView.componenteSlotQuickView = (
          <CadastroVinculoSocio
            itemEmpresa={this.item}
            itemSocemp={this.itemSocempCadastro}
            editando={this.editandoTeste}
            indexadores={this.indexadores}
            historicosDeRetencao={this.historicosDeRetencao}
            listaSociosVinculados={this.listaSociosVinculados}
            onArmazenarVinculo={this._armazenarNaListaOVinculoDeSocio}
            onAtualizarHistoricosDeRetencaoASeremCriados={this._aoAtualizarHistoricosDeRetencaoASeremCriados}
            onAtualizarHistoricosDeRetencaoASeremExcluidos={this._aoAtualizarHistoricosDeRetencaoASeremExcluidos}
            onCancel={this._fecharCadastroVinculoSocio}/>
        )
        paramentrosQuickView.eventoCancelar = this._fecharCadastroVinculoSocio
        paramentrosQuickView.ocultarBotaoGravar = true
        paramentrosQuickView.tituloTela = titulo
        paramentrosQuickView.estilo = 'position: fixed;top: 85%;right: 15px;width: 30%;z-index: 1000;'
        paramentrosQuickView.minimizado = false
        paramentrosQuickView.minimizavel = false
        paramentrosQuickView.tituloBotaoFechar = 'Cancelar'
        paramentrosQuickView.ocultarBotaoContinuarCadastrando = true
        paramentrosQuickView.larguraQuickView = '35vw'
        paramentrosQuickView.atalhoBotaoFechar = shortkeys.MSG_KEY_ESC
        paramentrosQuickView.atalhoBotaoGravar = shortkeys.MSG_KEY_GRAVAR
      } else {
        this.limparParametrosQuickView()
      }
    },

    _editarVinculoAuditor (itemAuditorParam, listaAuditoresVinculados) {
      this.editandoAuditor = true
      this.itemAuditor = itemAuditorParam
      this.listaAuditoresVinculados = listaAuditoresVinculados
    },

    _ajustarTamanhoDaTela (tamanho, tamanhoTelaCadastroSecundario) {
      if (this.exibirCadastroSecundario) {
        if (!tamanho) {
          this.tamanhoTela = 'column is-7'
          this.tamanhoCadastroSecundario = 'column is-5'
        } else {
          this.tamanhoTela = tamanho
          this.tamanhoCadastroSecundario = tamanhoTelaCadastroSecundario
        }
      } else {
        this.tamanhoTela = 'column is-12'
      }
    },

    _fecharTodosOsCadastrosSecundarios () {
      this.abrirListagemHistoricoNire = false
      this.abrirListagemHistoricoCnae = false
      this.abrirListagemCnaeSecundario = false
      this.abrirCadastroVinculoSocios = false
      this.abrirCadastroContadorAuditor = false
      this.ocultarBotaoFecharCadastroSecundario = false
      const { paramentrosQuickView } = this.$root
      paramentrosQuickView.componenteSlotQuickView = null
    },

    _fecharCadastroSecundario () {
      this.exibirCadastroSecundario = false
      this.botaoNovoVinculoPress = false
      this._fecharTodosOsCadastrosSecundarios()
      this._ajustarTamanhoDaTela()
      const { paramentrosQuickView } = this.$root
      paramentrosQuickView.componenteSlotQuickView = null
    },

    _fecharCadastroVinculoSocio () {
      this._fecharCadastroSecundario()
      this.historicosDeRetencao = this.historicosDeRetencaoOriginal
      this.historicosDeRetencaoASeremCriados = this.historicosDeRetencaoASeremCriadosOriginal
      this.historicosDeRetencaoASeremExcluidos = this.historicosDeRetencaoASeremExcluidosOriginal
    },

    async _salvarCNAEEmpresa (codigoCNAE) {
      const empresaCnae = new Empresa()
      empresaCnae.cdempresa = this.item.cdempresa
      empresaCnae.nome = this.item.nome
      empresaCnae.telefone = this.item.telefone
      empresaCnae.codigoCNAE = codigoCNAE
      await this._enviarParaBackendAtualizacaoCnaeDaEmpresa(empresaCnae).then(
        () => {
          this.novoCNAE = codigoCNAE
        }
      )
    },

    async _enviarParaBackendAtualizacaoCnaeDaEmpresa (empresaCnae) {
      empresaService.save(
        '/api/v1/empresa-atualiza-cnae',
        empresaCnae,
        this.editando
      )
    },

    _montarObjetoHistoricoNire (objeto) {
      Object.assign(this.historicoNireOriginal, objeto)
    },

    async _salvarHistoricoNireAlterado () {
      try {
        const historicoNire = new HistoricoNire()
        historicoNire.empresaID = this.empresa.cdempresa
        historicoNire.data = utils.formatarCampoDataParaEnvioAoBackend(
          this.historicoNireOriginal.data
        )
        historicoNire.numero = this.historicoNireOriginal.codigo
        service.save('/api/v1/historico-nire', historicoNire)
      } catch (e) {
        Object.assign(this.erros, JSON.parse(e.message))
      }
    },

    _armazenarNaListaOVinculoDeSocio (objeto) {
      operacoesVinculo.armazenarNaListaOVinculoDeSocio(
        objeto,
        this.listaSociosVinculadosParaMandarParaOBackend,
        this.item.cdempresa
      )
      this._fecharCadastroSecundario()
      Object.assign(this.historicosDeRetencaoOriginal, this.historicosDeRetencao)
      Object.assign(this.historicosDeRetencaoASeremCriadosOriginal, this.historicosDeRetencaoASeremCriados)
      Object.assign(this.historicosDeRetencaoASeremExcluidosOriginal, this.historicosDeRetencaoASeremExcluidos)
    },

    _atualizarListaDeVinculos (empresa) {
      operacoesVinculo.atualizarListaDeVinculos(
        empresa,
        this.listaSociosVinculadosParaMandarParaOBackend
      )
    },

    _salvarVinculoDeSociosEmpresa (paramIntegrar) {
      operacoesVinculo.salvarVinculoDeSociosEmpresa(
        this.listaSociosVinculadosParaMandarParaOBackend,
        paramIntegrar
      )
    },

    _excluirNoBackEndVinculosDeSocios (paramIntegrar) {
      operacoesVinculo.excluirNoBackEndVinculosDeSocios(
        this.listaSociosVinculadosParaExcluirNoBackend,
        paramIntegrar
      )
    },

    _armazenarNaListaOVinculoDeSocioASerExcluido (objeto) {
      operacoesVinculo.armazenarNaListaOVinculoDeSocioASerExcluido(
        objeto,
        this.listaSociosVinculadosParaExcluirNoBackend,
        this.listaSociosVinculadosParaMandarParaOBackend
      )
    },

    _excluirAuditor (objeto) {
      if (objeto.id) {
        this._armazenarNaListaOVinculoDeAuditorASerExcluido(objeto)
      } else if (objeto.tempId) {
        operacoesVinculoAuditor.removerVinculoDaLista(
          objeto,
          this.listaAuditoresVinculadosParaMandarParaOBackend
        )
      }
    },

    _armazenarNaListaOVinculoDeAuditor (objeto) {
      const obj = new AuditorIndependente()
      Object.assign(obj, objeto)
      operacoesVinculoAuditor.armazenarNaListaOAuditorIndependente(
        obj,
        this.listaAuditoresVinculadosParaMandarParaOBackend,
        this.item.cdempresa,
        this.editandoAuditor
      )
    },

    _atualizarListaDeVinculosAuditor (empresa) {
      operacoesVinculoAuditor.atualizarListaDeVinculos(
        empresa,
        this.listaAuditoresVinculadosParaMandarParaOBackend
      )
    },

    _salvarVinculoDeAuditoresEmpresa () {
      operacoesVinculoAuditor.salvarVinculoDeAuditoresEmpresa(
        this.listaAuditoresVinculadosParaMandarParaOBackend
      )
    },

    _excluirNoBackEndVinculosDeAuditores () {
      operacoesVinculoAuditor.excluirNoBackEndVinculosDeAuditores(
        this.listaAuditoresVinculadosParaExcluirNoBackend
      )
    },

    _armazenarNaListaOVinculoDeAuditorASerExcluido (objeto) {
      operacoesVinculoAuditor.armazenarNaListaOVinculoDeAuditorASerExcluido(
        objeto,
        this.listaAuditoresVinculadosParaExcluirNoBackend,
        this.listaAuditoresVinculadosParaMandarParaOBackend
      )
    },

    handleUpdateContadorAuditor (event) {
      this.contadores = event
    },

    _aoAtualizarHistoricosDeRetencaoASeremCriados (value) {
      this.historicosDeRetencaoASeremCriados.push(value)
      this.historicosDeRetencao.push(value)
    },

    _aoAtualizarHistoricosDeRetencaoASeremExcluidos (value) {
      if (!value.temporario) {
        this.historicosDeRetencaoASeremExcluidos.push(value)
      }

      const index = this.historicosDeRetencao.findIndex(item => item.id === value.id)

      if (index !== -1) {
        this.historicosDeRetencao.splice(index, 1)
      }
    },

    _limparDadosDosHistoricosDeRetencao () {
      this.historicosDeRetencao = []
      this.historicosDeRetencaoOriginal = []
      this.historicosDeRetencaoASeremCriados = []
      this.historicosDeRetencaoASeremCriadosOriginal = []
      this.historicosDeRetencaoASeremExcluidos = []
      this.historicosDeRetencaoASeremExcluidosOriginal = []
    },

    _enviarAlteracoesDoHistoricoDeRetencaoAoBackend () {
      operacoesVinculo.enviarAlteracoesDoHistoricoDeRetencaoAoBackend(this.item.cdempresa, this.historicosDeRetencaoASeremCriados, this.historicosDeRetencaoASeremExcluidos)
      this._limparDadosDosHistoricosDeRetencao()
    },

    escondeModal () {
      this.$root.mostrarModalDadosAlterados = false
      this.$root.dadosAlterados = false
    },

    dadosAlterados () {
      this.$root.dadosAlterados = true
    },

    dadosInalterados () {
      this.$root.dadosAlterados = false
      this.$root.mostrarModalDadosAlterados = false
    },

    async checaVinculos () {
      try {
        if (this.item.cdempresa) {
          this.vinculos = await service.get(`/api/v1/empresa/checa-vinculo/${this.item.cdempresa}`)
        }
      } catch (err) {
        return false
      }
    },

    pegaStatusAtual (cdEmpresa) {
      try {
        const url = `/api/v1/empresa-cadastros-vinculados/${cdEmpresa}`
        service.get(url).then(res => {
          this.statusAtual = res.status
        })
      } catch (er) {
        return false
      }
    },

    cancelaOuSai () {
      this.$parent.verificarModificaoNoItem()

      if (this.dadosAlteradosRoot) {
        this.$root.mostrarModalDadosAlterados = true
      } else {
        if (this.exibirFechar || ((!this.exibirFechar && this.item.status === 'À concluir') || !this.editando)) {
          this._clicarCancelar()
          this.$parent.listagemVisivel = true
        } else {
          this.cancelaEdicaoeMostraCards()
        }
      }
    },

    ligarAtalhosCards () {
      const section = 'telaCards'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this._fecharCards)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },

    ligarAtalhosAbas (section = '') {
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this.cancelaOuSai)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarEmpresa)
    }
  },
  updated () {
    if (this.statusAtual) {
      this.pegaStatusAtual(this.item.cdempresa)
    }
    if (this.exibirFechar !== false && this.modalVisivel) {
      this.exibirFechar = this.item.empresaAtiva
    }
    const inputs = document.querySelectorAll('#cadastroEdicao input:not(:disabled):not([type="hidden"])')
    let primeiroInputVisivel
    for (const element of inputs) {
      const input = element
      if (input.offsetParent !== null) {
        primeiroInputVisivel = input
        break
      }
    }
    if (primeiroInputVisivel) {
      this.focarNoPrimeiroInput('cadastroEdicao')
    }
    if (!this.modalVisivel) {
      this.abaObservacaoVisivel = false
      this.activeLink = 'básico'
    }
  },

  mounted () {
    this.statusAtual = undefined
    this.botaoNovoVinculoPress = false
    this.cargaInicial = 0
    this.cadastrandoOuEditando = null
    this.listagemVisivel = null
    this._limparRegistroDeErros()
    this.$root.parametrosModalSalvarMudancas.eventoSalvar = this._salvarEmpresaDuranteModalDadosAlterados
    this.$root.parametrosModalSalvarMudancas.eventoSair = () => {
      this.$parent.abrirFecharEdicao()
      this.$parent.item = new Empresa()
      this.$parent.itemAux = new Empresa()
      this.$root.mostrarModalDadosAlterados = false
    }
    this.paraEditar = false
    this.checaVinculos()
    this.statusAtivo = this.item.status
  },

  unmounted () {
    this.escondeModal()
    this.empresaGravada = false
    this.abaObservacaoVisivel = false
    this.abaHandler('básico', null, null)
  }
}
</script>

<style></style>
