import { env } from '../../env.js'

export class CepService {
  constructor () {
    this.microservice = env.cepApi
    this.endpoint = '/ws'
  }

  async list (cep) {
    let queryString = '/' + cep + '/json'
    queryString = encodeURI(queryString)

    const init = {
      headers: {
      }
    }
    const response = await fetch(this.microservice + this.endpoint + queryString, init)
    if (response.ok) {
      const json = await response.json()
      if (json && json.erro) {
        return null
      } else {
        return json
      }
    } else {
      console.error(response.status)
      return {}
    }
  }
}
export const cepService = new CepService()
