<template>
  <div class="container" style="text-align: left">
    <div class="filed">
      <input-texto
        :id="this.pCdId"
        :width="tamanhoInput"
        :message="this.errosValidacao[this.erro]"
        :label="this.tipoPais"
        :status="this.getStatus(this.errosValidacao[this.erro])"
        :required="required"
        maxlength="3"
        type="text"
        v-model="this.pais.cdPais"
        @update:erro="handleErro"
        @blur="this.carregarNomePais()"
        @keypress="this.onlyNumber($event, true)"
      >
        <template v-slot:addon>
          <div class="is-flex">
            <a
              class="button is-outlined"
              :class="{
                'is-danger':
                  this.getStatus(this.errosValidacao[this.erro]) === 'error',
                'is-info':
                  this.getStatus(this.errosValidacao[this.erro]) === 'info'
              }"
              @click="this.abrirPesquisaPais()"
            >
              <span class="icon"><span class="fas fa-search"></span></span>
            </a>
            <div class="control ml-2">
              <input-texto
                id="pais"
                v-model="this.pais.nmPais"
                :width="tamanhoNmPais"
                disabled
              />
            </div>
          </div>
        </template>
      </input-texto>
    </div>
  </div>
  <pesquisa
    v-if="this.modalVisivel"
    customSearch
    :titulo="'Pesquisa de países'"
    :propDataSource="this.dataSource"
    :propItens="this.paises"
    :pHideButtonMore="this.hideButtonMore"
    :pStatus="this.status"
    @carregarItemSelecionado="this.carregarItemSelecionado"
    @fechar="this.fecharPesquisaPais"
    :pQuantidadeDeItems="this.listaPaisesTamanho"
    :nextPage="this.carregar"
    :pPages="totalPages"
    @search="aoPesquisar"
  />
</template>

<script>
import pesquisa from '../Pesquisa/index.vue'

import InputText from '@alterdata/component-vue/InputText'

import { utils } from '../../util/utils'
import { mixin } from '../../util/mixin'
import { mixinPesquisaPais } from './mixinPesquisaPais'

export default {
  components: {
    pesquisa,
    'input-texto': InputText
  },
  props: {
    tamanho: Number,
    tamanhoInput: {
      type: String,
      default: '100%'
    },
    tamanhoNmPais: {
      type: String,
      default: '100%'
    },
    erro: String,
    tipoPais: String,
    pCdPais: Number,
    pNmPais: String,
    pUf: String,
    jsonPaises: Object,
    errosValidacao: Array,
    pCdId: {
      type: String,
      default: 'cdpais'
    },
    required: {
      type: Boolean
    }
  },
  mixins: [mixin, mixinPesquisaPais],
  data () {
    return {
      pais: {
        cdPais: this.pCdPais,
        nmPais: this.pNmPais
      },
      modalVisivel: false,
      dataSource: {
        columns: [
          {
            name: 'cod',
            label: 'Código',
            style: 'width:20%'
          },
          {
            name: 'pais',
            label: 'País',
            style: 'width:80%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(this.pais.cdPais),
        loadMore: null
      },
      paises: [],
      hideButtonMore: false,
      status: '',
      page: [],
      uf: this.pUf,
      erros: this.errosValidacao,
      listaPaisesTamanho: '',
      termoPesquisado: ''
    }
  },

  methods: {

    abrirPesquisaPais () {
      this.carregar()
      this.modalVisivel = true
    },

    fecharPesquisaPais () {
      this.modalVisivel = false
      this.carregarNomePais()
      this.termoPesquisado = ''
      this.carregar()
    },
    carregarNomePais () {
      if (this.pais.cdPais) {
        this.erros[this.erro] = ''
        const response = utils.findCountry(
          this.pais.cdPais,
          this.jsonPaises.data
        )
        if (response === undefined) {
          this.pais.nmPais = null
          if (this.pais.cdPais) {
            this.erros[this.erro] = 'País não encontrado'
          }
        } else {
          this.pais.nmPais = response.pais
          this.pais.cdPais = response.cod
        }
      } else {
        this.pais.nmPais = null
      }
    },
    buscarBrasil () {
      var response = JSON.parse(JSON.stringify(this.jsonPaises.data)).filter(
        (e) => {
          return e.pais?.toUpperCase() === 'BRASIL'
        }
      )[0]
      this.pais.nmPais = response.pais
      this.pais.cdPais = response.cod
    },
    carregarItemSelecionado (pais) {
      this.pais.cdPais = pais.cod
      this.pais.nmPais = pais.pais
      this.fecharPesquisaPais()
    },
    clearCamposPais () {
      this.pais.cdPais = null
      this.pais.nmPais = null
    },
    handleErro (event) {
      this.erros[this.erro] = event.message
    }
  },
  watch: {
    pais: {
      immediate: true,
      handler () {
        this.$emit('carregarObjetoPais', this.pais.cdPais)
        this.$emit('carregarObjetoPaisComNome', this.pais)
        if (this.pais.nmPais?.toUpperCase() !== 'BRASIL') {
          if (this.pais.nmPais !== null) {
            this.$emit('mudarUf', 'EX')
          }
        } else if (this.pUf === 'EX') {
          this.$emit('mudarUf', null)
        }
      },
      deep: true
    },
    pUf: {
      handler () {
        if (this.pUf !== 'EX') {
          this.buscarBrasil()
        } else {
          if (this.pais.cdPais === null || this.pais.nmPais?.toUpperCase() === 'BRASIL') {
            this.clearCamposPais()
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.carregarNomePais()
  }
}
</script>

<style scoped>
</style>
