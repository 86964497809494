<template>
  <Modal title="Relatório de sócios" @close="this.cancelar()" :active="true" id="gerarRelatorioSocios">
        <div class="columns is-multiline is-flex is-flex-direction-column">
          <div class="field p-3">
            <p class="mb-2">Pesquise e insira o(s) sócio(s) para gerar o relatório:</p>

            <alt-datatable
              :pDataSource="this.dataSource"
              :pData="this.data"
              :pStatus="this.status"
              :noDelete="true"
              :noEdit="true"
              :is-multiple-selection="true"
              :input-search-fullwidth="true"
              :custom-style="customStyleDataTable"
            />
            <p v-if="this.exibirMensagemDeErroSocios" class="error-message is-size-7" data-group="modal-txt-mensagem-erro">Selecione ao menos um sócio para gerar relatório.</p>
          </div>
          <div class="column is-5">
            <p>Ordem do relatório</p>
            <radio-grupo class="is-flex is-justify-content-space-around" v-model="this.ordemSelecionadaDoRelatorio">
              <radio-button caption="Por código" value="codigo" />
              <radio-button caption="Por nome" value="nome" />
            </radio-grupo>
          </div>
        </div>
        <template v-slot:footer>
          <ButtonAjuda style="margin-right: auto;"/>
          <Button class="button is-primary" id="gerar" caption="Gerar" style="margin-right: 1rem" @click="this.imprimirRelatorio()"/>
          <Button class="button" id="cancelar" @click="this.cancelar()" caption="Cancelar"/>
        </template>
    </Modal>
</template>

<script>

import Modal from '@alterdata/component-vue/Modal/index.vue'
import ButtonAjuda from '../../components/alt-component/ButtonAjuda/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import RadioGroup from '@alterdata/component-vue/RadioGroup'
import Radio from '@alterdata/component-vue/Radio'
import DataTable from '@alterdata/component-vue/DataTable'
import { service } from '../../service.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

export default {
  components: {
    'radio-grupo': RadioGroup,
    'radio-button': Radio,
    'alt-datatable': DataTable,
    Modal,
    ButtonAjuda,
    Button
  },

  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'cdsocio',
            label: 'Código',
            format: this._formatarCodigo
          },
          {
            name: 'nmsocio',
            label: 'Nome'
          }
        ],
        selected: null,
        itensSelected: [],
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      status: 'is-empty',
      data: [],
      page: [],
      ordemSelecionadaDoRelatorio: 'codigo',
      exibirMensagemDeErroSocios: false
    }
  },

  computed: {
    customStyleDataTable () {
      const style = this.data.length > 0 ? 'height: 200px' : ''
      return `${style}`
    }

  },

  methods: {
    _formatarCodigo (codigo) {
      return utils.strZero(codigo, 5)
    },

    cancelar () {
      this.$emit('cancel')
    },

    async _carregarListagem () {
      this.status = 'is-loading'
      try {
        this.data = []
        this.data = await service.get('/api/v1/socio/simplificado')
        this.status = ''
      } catch (e) {
        this.status = 'is-danger'
      }
    },

    imprimirRelatorio () {
      if (this.verificarSeHaSociosSelecionados()) {
        const listagemCodigoSocios = 'ids=' + this.dataSource.itensSelected.map(socio => socio.cdsocio)
        const ordem = 'ordem=' + this.ordemSelecionadaDoRelatorio
        const urlDoRelatorio = `/socios/relatorios?${ordem}&${listagemCodigoSocios}`

        const abaRelatorio = document.createElement('a')
        abaRelatorio.href = urlDoRelatorio
        abaRelatorio.target = '_blank'
        abaRelatorio.style.display = 'none'

        document.body.appendChild(abaRelatorio)
        abaRelatorio.click()
        setTimeout(() => {
          document.body.removeChild(abaRelatorio)
        }, 100)
      }
    },

    verificarSeHaSociosSelecionados () {
      if (this.dataSource.itensSelected.length > 0) {
        this.exibirMensagemDeErroSocios = false

        return true
      } else {
        this.exibirMensagemDeErroSocios = true

        return false
      }
    }
  },

  created () {
    this._carregarListagem()
  }
}

</script>

<style>

.error-message {
  color: red;
}

</style>
