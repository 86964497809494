<template>
  <Modal title="CNAE secundário" :active="this.modalVisivel" id="cadastroEdicaoCnaeSecundario" @close="this._cancelar">
          <div class="columns is-multiline">
            <div class="field column is-6">
              <input-data
                id="dataNire"
                v-model="this.itemCNAE.referencia"
                label="Referência"
                type="month"
                :message="erros.data"
                :status="erros.data ? 'error' : ''"
                @update:erro="erros.data = $event"
              />
            </div>
            <div class="field column is-6">
              <input-texto
                id="codigo"
                maxlength="7"
                label="CNAE"
                v-model="this.itemCNAE.cnae"
                :message="erros.codigo"
                :status="this.getStatus(erros.codigo)"
                @update:erro="erros.codigo = $event.message"
              >
                <template v-slot:addon>
                  <a class="button" @click="abrirFecharPesquisaCNAE">
                    <span class="icon"
                      ><span class="fas fa-search"></span
                    ></span>
                  </a>
                </template>
              </input-texto>
            </div>
          </div>
          <template v-slot:footer>
            <ButtonAjuda style="margin-right: auto;"/>
            <Button class="button is-primary" @click="this._salvarCnae" caption="Gravar" style="margin-right: 1rem"/>
            <Button class="button" @click="this._cancelar" caption="Cancelar"/>
          </template>
      </Modal>
    <pesquisa-cnae
      :modalVisivel="abrirConsultaCNAES"
      @fechar="this.abrirFecharPesquisaCNAE"
      @carregarItemSelecionado2="_preencherCNAESelecionado"
    />
</template>

<script>
import { service } from '../../../service.js'
import EmpresaCnaeSecundario from './model/empresa-cnae-secundario.js'
import PesquisaCNAE from '../Cnae/pesquisaCnae.vue'

import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'

import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import ButtonAjuda from '../../../components/alt-component/ButtonAjuda/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'

const ERROS = {
  codigo: '',
  data: ''
}

export default {
  name: 'cadastroEdicaoHistoricoNire',
  components: {
    'pesquisa-cnae': PesquisaCNAE,
    'input-texto': InputText,
    'input-data': InputDate,
    Modal,
    ButtonAjuda,
    Button
  },

  props: {
    modalVisivel: Boolean,
    idEmpresa: String,
    cnae: EmpresaCnaeSecundario
  },

  mixins: [mixin],

  data () {
    return {
      itemCNAE: this.cnae,
      abrirConsultaCNAES: false,
      erros: ERROS
    }
  },

  methods: {
    async _salvarCnae () {
      try {
        this.itemCNAE.empresaID = this.idEmpresa
        this.itemCNAE.referencia = this.formatar(this.itemCNAE.referencia)
        if (this._validarInformacoesCadastro()) {
          service
            .save('/api/v1/cnae-secundario', this.itemCNAE)
            .then(this.responseToSave)
        }
      } catch (e) {
        Object.assign(this.erros, JSON.parse(e.message))
      }
    },

    handleBluer () {
      alert(this.itemCNAE.referencia.getFullYear())
      this.erros.data =
        this.itemCNAE.referencia.getFullYear() < 1900
          ? 'Informe uma data válida'
          : this.erros.data
    },

    _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      this.erros = ERROS
      let ocorreuErro = false
      Object.assign(
        this.erros,
        EmpresaCnaeSecundario.validar(
          this.itemCNAE.cnae,
          this.itemCNAE.referencia
        )
      )
      if (this.erros.codigo || this.erros.data) {
        ocorreuErro = true
      }

      return !ocorreuErro
    },

    _limparRegistroDeErros () {
      this.erros.codigo = ''
      this.erros.data = ''
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    formatar (data) {
      if (data && this.checarData(new Date(data))) {
        return new Date(data).toISOString()
      }
    },

    checarData (data) {
      return data instanceof Date && !isNaN(data)
    },

    abrirFecharPesquisaCNAE () {
      this.abrirConsultaCNAES = !this.abrirConsultaCNAES
    },

    _preencherCNAESelecionado (item) {
      this.itemCNAE.cnae = item.chamada.replace(/[^\d]+/g, '')
      this.abrirFecharPesquisaCNAE()
    },

    _cancelar () {
      this._limparRegistroDeErros()
      this.$emit('cancel')
    }
  },
  watch: {
    itemCNAE: {
      handler: function (val, old) {
        if (val && val.referencia) {
          this.itemCNAE.referencia =
            val.referencia.substring(0, 4) +
            '-' +
            val.referencia.substring(5, 7)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
</style>
