<template>
  <div class="tabs is-fullwidth">
    <ul>
      <li :class="{ 'is-active': abaAberta === 0 }">
        <a style="font-size: 14px" @click="_ativarAba('básico')" data-group="aba-basico">
          Básico
        </a>
      </li>
      <li :class="{ 'is-active': abaAberta === 1 }">
        <a style="font-size: 14px" @click="_ativarAba('receita federal')" data-group="aba-receita-federal">
          Receita federal
        </a>
      </li>
      <li :class="{ 'is-active': abaAberta === 2 }">
        <a style="font-size: 14px" @click="_ativarAba('procurador')" data-group="aba-procurador"> Procurador </a>
      </li>
    </ul>
  </div>
  <div id="vinculoSocioEmpresa">
    <div class="scroll-y">
      <basico
        v-if="basicoVisivel"
        ref="basico"
        :montarObjeto="_montarObjetoSocio"
        :errosValidacao="erros"
        :itemEmpresa="this.itemEmpresa"
        :itemSocemp="this.itemSocemp"
        :itemSocio="this.socio"
        :editando="editando2"
        :indexadoresParam="this.indexadores"
        :historicos-de-retencao="this.historicosDeRetencao"
        :listaSociosVinculados="this.listaSociosVinculados"
        @ao-atualizar-historicos-de-retencao-a-serem-criados="_aoAtualizarHistoricosDeRetencaoASeremCriados"
        @ao-atualizar-historicos-de-retencao-a-serem-excluidos="_aoAtualizarHistoricosDeRetencaoASeremExcluidos"
        @mudarPagina="_ativarAba"
      />
      <aba-receita-federal
        v-if="receitaFederalVisivel"
        ref="receita"
        :montarObjeto="_montarObjetoSocio"
        :errosValidacao="erros"
        :itemEmpresa="this.itemEmpresa"
        :itemSocemp="this.socemp"
        :itemSocio="this.socio"
        :editando="editando2"
        @mudarPagina="_ativarAba"
      />
      <aba-procurador
        v-if="procuradorVisivel"
        ref="procurador"
        :errosValidacao="erros"
        :itemEmpresa="this.itemEmpresa"
        :itemSocemp="this.socemp"
        :itemSocio="this.socio"
        :editando="editando2"
        @mudarPagina="_ativarAba"
      />
      <div class="columns is-multiline">
        <div class="column is-12"></div>
        <div class="column is-12"></div>
      </div>
    </div>
  </div>
  <div class="button-morph">
    <Button
      id="quickView-btn-gravar"
      v-if="!botaoOculto"
      :class="botaoSalvarPrimary ? 'is-primary' : ''"
      caption="Gravar "
      :title="shortkeys.MSG_KEY_GRAVAR"
      data-group="btn-gravar-quickview"
      :is-wide="true"
      class="enter-allowed"
      style="width: 6.56rem"
      @click="_salvarSocio"
    />
  </div>
  <modalAviso
    v-if="this.avisoVisivel"
    :textoAviso="this.textoAviso"
    @on-ok="this._abreFechaAviso"
  />
</template>

<script>
import basico from './abaBasico.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { maska } from 'maska'
import { service } from '../../../service.js'
import Socemp from './model/socemp.js'
import Empresa from '../model/empresa.js'
import modalAviso from '../../../components/ModalAviso/index.vue'
import abaReceitaFederal from './abaReceitaFederal.vue'
import abaProcurador from './abaProcurador.vue'
import Socio from '../../Socios/model/socios'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import Button from '@alterdata/component-vue/Button'
const ERROS = {
  abaBasicoSocemp: '',
  abaReceitaFederal: '',
  abaProcurador: '',
  codigoSocio: '',
  alCapital: '',
  valorCapitalSocio: '',
  valorProLabore: '',
  dataAdmissao: '',
  dataDemissao: '',
  codigoRetencaoIrrf: '',
  numeroCboNovo: '',
  motivo_Desligamento: ''
}

export default {
  props: {
    modalVisivel: Boolean,
    itemSocemp: Socemp,
    itemEmpresa: Empresa,
    editando: Boolean,
    indexadores: Array,
    historicosDeRetencao: Array,
    listaSociosVinculados: Array
  },
  mixins: [mixin],
  inject: ['setActiveSection', 'addShortcut'],
  emits: ['cancel', 'atualizarHistoricosDeRetencaoASeremCriados', 'atualizarHistoricosDeRetencaoASeremExcluidos'],
  updated () {
    this.focarNoPrimeiroInput('vinculoSocioEmpresa')
  },
  components: {
    basico,
    modalAviso,
    abaReceitaFederal,
    abaProcurador,
    Button
  },
  directives: {
    maska
  },
  data () {
    return {
      basicoVisivel: true,
      receitaFederalVisivel: false,
      procuradorVisivel: false,
      jsonResposta: null,
      erros: ERROS,
      socemp: this.itemSocemp,
      editando2: this.editando,
      textoAviso: null,
      avisoVisivel: false,
      socio: new Socio(),
      abaAberta: 0,
      shortkeys: shortkeys,
      botaoOculto: false
    }
  },
  watch: {},

  methods: {
    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    _abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    _retornarCodigoFormartado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },

    _atribuirFalseParaTodasAsAbas () {
      this.basicoVisivel = false
      this.receitaFederalVisivel = false
      this.procuradorVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'básico':
          this.abaAberta = 0
          this.basicoVisivel = true
          break
        case 'receita federal':
          this.abaAberta = 1
          this.receitaFederalVisivel = true
          break
        case 'procurador':
          this.abaAberta = 2
          this.procuradorVisivel = true
          break
      }
      this.activeLink = aba
    },

    _clicarCancelar () {
      this._limparRegistroDeErros()
      this.$emit('cancel')
    },

    _montarObjetoSocio (objeto) {
      this.$root.dadosAlterados = true
      Object.assign(this.socemp, objeto)
    },

    _avisoExcedente () {
      this.adicionarNotificacao(
        new Toast({
          tipo: 'warning',
          titulo: 'Valor informado inválido.',
          subtitulo: this.textoAviso
        })
      )
    },

    async _salvarSocio () {
      if (this._validarInformacoesCadastro()) {
        this.socemp.nrCpfProcurador = utils.clearMaskNumber(this.socemp.nrCpfProcurador)
        if (this.socemp.alCapitalVotante) {
          if (this.socemp.alCapitalVotante.toString().includes(',')) {
            this.socemp.alCapitalVotante = parseFloat(
              this.socemp.alCapitalVotante.replace(',', '.')
            )
          } else {
            this.socemp.alCapitalVotante = parseFloat(
              this.socemp.alCapitalVotante.toFixed(2)
            )
          }
        }

        if (this.erros.codigoSocioNaoExistente) {
          this.erros.codigoSocio = this.erros.codigoSocioNaoExistente
        } else if (this.erros.numeroCBOInexistente) {
          this.erros.numeroCboNovo = this.erros.numeroCBOInexistente
        } else {
          this.socemp.codigoSocio = parseInt(this.socemp.codigoSocio)
          this.$emit('armazenarVinculo', this.socemp)
        }
      }
    },

    _limparRegistroDeErros () {
      this.erros.abaBasico = ''
      this.erros.codigoSocio = ''
      this.erros.alCapital = ''
      this.erros.valorCapitalSocio = ''
      this.erros.valorProLabore = ''
      this.erros.dataAdmissao = ''
      this.erros.dataDemissao = ''
      this.erros.codigoRetencaoIrrf = ''
      this.erros.numeroCboNovo = ''
      this.erros.motivo_Desligamento = ''
      this.erros.nrCpfProcurador = ''
      this.erros.nmProcurador = ''
      this.erros.alCapitalVotante = ''
      this.erros.ecfEcdQualificacaoCodigo = ''
      this.erros.stQualificacaoSped = ''
      this.erros.qualificacaoSpedComplemento = ''
    },

    _validarInformacoesCadastro () {
      this._limparRegistroDeErros()

      let ocorreuErro = false
      this.erros = ERROS
      Object.assign(
        this.erros,
        Socemp.validar(this.socemp, this.itemEmpresa, this.socio)
      )

      if (this.erros.abaBasicoSocemp) {
        this._ativarAba('básico')
        this.abaAberta = 0
        ocorreuErro = true
      } else {
        if (this.erros.abaReceitaFederal) {
          this._ativarAba('receita federal')
          this.abaAberta = 1
          ocorreuErro = true
        } else {
          if (this.erros.abaProcurador) {
            this._ativarAba('procurador')
            this.abaAberta = 2
            ocorreuErro = true
          } else {
            this.textoAviso = Socemp.validarSomatorioTotalDasPorcentagensDosValoresDeCapital(
              this.socemp.codigoSocio,
              this.listaSociosVinculados,
              this.socemp.alCapital,
              this.editando ? '1' : '0',
              this.socemp.nomeSocio
            )
            if (this.textoAviso) {
              this._avisoExcedente()
              ocorreuErro = true
            }
          }
        }
      }
      return !ocorreuErro
    },

    transferirBotaoParaFooter () {
      const buttonsContainer = document.querySelector('.buttons.is-right')
      const existingDiv = document.querySelector('.button-morph')
      const cancelButton = document.querySelector(
        '.buttons.is-right button[data-group="btn-cancelar-quickview"]'
      )
      buttonsContainer.insertBefore(existingDiv, cancelButton)
    },

    definirAtalhoQuickView () {
      const section = 'quickviewCadastroVinculoSocios'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this._clicarCancelar)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarSocio)
    },

    _aoAtualizarHistoricosDeRetencaoASeremCriados (value) {
      this.$emit('atualizarHistoricosDeRetencaoASeremCriados', value)
    },

    _aoAtualizarHistoricosDeRetencaoASeremExcluidos (value) {
      this.$emit('atualizarHistoricosDeRetencaoASeremExcluidos', value)
    }
  },

  async mounted () {
    this.botaoOculto = false
    this.definirAtalhoQuickView()
    this.$nextTick(() => {
      this.transferirBotaoParaFooter()
    })
    const resposta = await service.get('/api/v1/socio', this.socemp.codigoSocio)
    if (resposta) {
      this.socio = resposta
    }
  },

  beforeUnmount () {
    this.botaoOculto = true
  },
  unmounted () {
    this._limparRegistroDeErros()
  }
}
</script>

<style scoped>
.scroll-y {
  padding: 10px 2px;
}

.tabs.is-small.is-fullwidth {
  margin-left: -25px;
  margin-bottom: 0;
}
</style>
