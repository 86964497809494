<template>
  <div class="field column is-12 p-0">
    <feriados />
  </div>
</template>

<script>
import feriados from '../../Feriados/index.vue'

export default {
  components: {
    feriados
  }
}
</script>

<style>
</style>
