<template>
  <div id="abaBasicoVinculoSocioEmpresa">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <input type="text" hidden />
        <div
          class="field column is-5"
          @blur="this._buscarSocioDigitado()"
          style="padding-bottom: 0rem; margin: 0"
        >
          <div class="field has-addons" style="margin-bottom: 0">
            <div class="control">
              <campo-texto
                id="codSocio"
                label="Código"
                maxlength="5"
                v-model="this.objeto.codigoSocio"
                :value="this.objeto.codigoSocio"
                @keypress="this.onlyNumber()"
                @blur="this._buscarSocioDigitado()"
                @update:erro="this.erros.codigoSocio = $event.message"
                :status="this.getStatus(this.erros.codigoSocio)"
                :message="this.erros.codigoSocio"
                required
                :disabled="this.editando"
              />
            </div>
            <div class="control" style="padding-top: 1.3rem">
              <a
                class="button is-outlined"
                data-group="socio-pesquisa"
                :class="{
                  'is-danger':
                    this.getStatus(this.erros.codigoSocio) === 'error',
                  'is-info': this.getStatus(this.erros.codigoSocio) === 'info'
                }"
                @click="this._abrirFecharPesquisaSocios()"
              >
                <span  class="icon"><span class="fas fa-search"></span></span>
              </a>
            </div>
            <div v-if="!this.editando" class="control" style="padding-top: 1.3rem">
              <a
                class="button is-outlined"
                data-group="socio-incluir"
                @click="this._cadastrarSocio(null)"
              >
                <span class="icon"><span class="fas fa-plus"></span></span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="field column is-7"
          style="
            padding-bottom: 0rem;
            margin: 0;
            padding-top: 2rem;
            padding-left: 0rem;
            padding-right: 0rem;
          "
        >
          <div class="control">
            <input
              id="socioNome"
              class="input"
              data-group="socio-input"
              v-model="this.objeto.nomeSocio"
              disabled
            />
          </div>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Informações gerais</label
          >
        </div>
        <div
          class="field column is-6"
          style="padding-bottom: 0rem; margin-bottom: 0"
        >
          <input-data
            id="admissao"
            label="Admissão"
            v-model="this.objeto.dataAdmissao"
            :modelValue="this.objeto.dataAdmissao"
            :status="this._obterStatus(this.erros.dataAdmissao)"
            @update:erro="this.erros.dataAdmissao = $event"
            :message="this.erros.dataAdmissao"
            @blur="this._validarDataAdmissao()"
            required
          />
        </div>
        <div
          class="field column is-6"
          style="padding-bottom: 0rem; margin-bottom: 0"
        >
          <input-data
            id="desligamento"
            label="Desligamento"
            v-model="this.objeto.dataDemissao"
            :modelValue="this.objeto.dataDemissao"
            :status="this._obterStatus(this.erros.dataDemissao)"
            @update:erro="this.erros.dataDemissao = $event"
            :message="this.erros.dataDemissao"
            @blur="this._validarDataDemissao()"
          />
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label
            class="label"
            for="motivos"
            style="font-size: 0.875rem; margin-bottom: 0"
            >Motivo do desligamento</label
          >
          <div id="motivos" class="select">
            <select
              v-model="this.objeto.motivo_Desligamento"
              :disabled="!this.habilitarMotivoDesligamento"
              data-group="socio-motivo-desligamento"
            >
              <option data-group="socio-motivo-desligamento-vazio"></option>
              <option
                v-for="m in this.motivosDesligamento"
                :key="m.codigo"
                v-bind:value="m.codigo"
                :data-group="'socio-motivo-desligamento-' + m.codigo"
              >
                {{ m.motivo }}
              </option>
            </select>
          </div>
          <p class="help is-danger"></p>
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <div class="control">
            <InputNumber
              v-model="this.objeto.codigoRetencaoIrrf"
              id="retencaoIR"
              label="Código de retenção do IRRF"
              maxlength="4"
              required
              no-point
              :permite-pontos="false"
              :status="this.getStatus(this.erros.codigoRetencaoIrrf)"
              :message="this.erros.codigoRetencaoIrrf"
              @update:erro="this.erros.codigoRetencaoIrrf = $event.message"
              @keypress="this.onlyNumber()"
              @blur="this.validarIR()"
            >
            <template #addon>
                <alt-button
                  caption=""
                  icon-only="history"
                  :tabindex="-1"
                  type="secondary"
                  :disabled="!this.cadastroVinculadoAEmpresaNoBackend"
                  :class="[
                    'is-outlined',
                    'mr-1',
                    {
                      'is-danger': this.erros.codigoRetencaoIrrf
                    }
                  ]"
                  @click="_abreModalHistoricoRetencaoIrrf"
                />
              </template>
          </InputNumber>
          <HistoricoRetencaoIrrf
            v-if="showModalHistoricoRetencaoIrrf"
            default-shortcuts
            :socemp="this.objeto"
            :codigo-de-retencao-do-quick-view="this.objeto.codigoRetencaoIrrf"
            :historicos-de-retencao="this.historicosDeRetencaoPorSocio"
            :show-modal-historico-retencao-irrf-cadastro-edicao="this.showModalHistoricoRetencaoIrrfCadastroEdicao"
            @ao-atualizar-historicos-de-retencao-a-serem-criados="_aoAtualizarHistoricosDeRetencaoASeremCriados"
            @ao-atualizar-historicos-de-retencao-a-serem-excluidos="_aoAtualizarHistoricosDeRetencaoASeremExcluidos"
            @fechar="_aoFecharModalHistoricoRetencaoIrrf"
          />
          </div>
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <div class="field has-addons" style="margin-bottom: 0rem; margin: 0">
            <div class="control">
              <campo-texto
                id="cbo"
                label="CBO 2002"
                maxlength="6"
                v-model="this.objeto.numeroCboNovo"
                :value="this.objeto.numeroCboNovo"
                v-on:blur="_buscarCBODigitado"
                @keypress="this.onlyNumber()"
                @update:erro="this.erros.numeroCboNovo = $event.message"
                :status="this.getStatus(this.erros.numeroCboNovo)"
                :message="this.erros.numeroCboNovo"
                @blur="this.validarCBO()"
                required
              />
            </div>
            <div class="control" style="padding-top: 1.3rem">
              <a
                class="button is-outlined"
                data-group="cbo-pesquisa"
                :class="{
                  'is-danger':
                    this.getStatus(this.erros.numeroCboNovo) === 'error',
                  'is-info': this.getStatus(this.erros.numeroCboNovo) === 'info'
                }"
                @click="_abrirFecharPesquisaCBO"
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Informações do vínculo</label
          >
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp"
              value="S"
              data-group="radio-socio"
              v-model="this.objeto.tipoIdentificadorResponsavel"
            />
            Sócio
          </label>
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp"
              data-group="radio-diretor"
              value="D"
              v-model="this.objeto.tipoIdentificadorResponsavel"
            />
            Diretor
          </label>
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp"
              value="P"
              data-group="radio-presidente"
              v-model="this.objeto.tipoIdentificadorResponsavel"
            />
            Presidente
          </label>
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox" style="padding-right: 2.5rem">
            <input type="checkbox" v-model="this.objeto.stGerente"  data-group="checkbox-administrador"/>
            Administrador
          </label>
          <label class="checkbox">
            <input type="checkbox" v-model="this.objeto.stResponsavelCgc" data-group="checkbox-responsavel" />
            Responsável CNPJ
          </label>
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox" style="font-size: 0.848rem">
            <input type="checkbox" v-model="this.objeto.stEntraRais"  data-group="checkbox-proprietario-rais" />
            Considerar sócio no número de proprietários para a RAIS
          </label>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Capital social</label
          >
        </div>

        <div class="field column is-4" style="padding-bottom: 0rem; margin: 0">
          <label class="required">Percentual</label>
          <div class="control">
            <input-valor-monetario
              class="is-outlined"
              :class="{
                'is-danger': this.getStatus(this.erroPercentual) === 'error',
                'is-info': this.getStatus(this.erroPercentual) === 'info'
              }"
              :pId="'porcentagemCapital'"
              :pClass="'input'"
              :pRequired="true"
              :pValue="this.objeto.alCapital"
              :pMaxlength="5"
              :pQtdDecimais="2"
              :pExibePrefixo="false"
              @update:erro="this.erroPercentual = $event"
              @blur="(event) => this.setValorPorcentagem(event.target.value)"
              data-group="valor-monetario-input"
            />
          </div>
          <p class="help is-danger">{{ this.erroPercentual }}</p>
        </div>

        <div class="field column is-8" style="padding-bottom: 0rem; margin: 0">
          <label class="required">Valor</label>
          <div class="control">
            <input-valor-monetario
              :pId="'tvalorCapital'"
              :pClass="'input'"
              :pValue="this.objeto.valorCapitalSocio"
              :pRequired="true"
              :pMaxlength="12"
              :pQtdDecimais="2"
              :pExibePrefixo="false"
              @blur="(event) => this.setValorCapital(event.target.value)"
              data-group="valor-capital-input"
            />
          </div>
          <p class="help is-danger">{{}}</p>
        </div>

        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Pró-labore</label
          >
        </div>
        <div class="field column is-7" style="margin: 0">
          <label class="required">Valor</label>
          <div class="control">
            <input-valor-monetario
              :pId="'valorProLabore'"
              :pClass="'input'"
              :pValue="this.objeto.valorProLabore"
              :pRequired="true"
              :pMaxlength="12"
              :pQtdDecimais="2"
              :pExibePrefixo="false"
              @blur="(event) => this.setValorProLabore(event.target.value)"
              data-group="valor-prolabore-input"
            />
          </div>
          <p class="help is-danger">{{}}</p>
        </div>
        <div class="field column is-5" style="margin: 0">
          <label
            class="label"
            for="indexador"
            style="font-size: 0.875rem; margin-bottom: 0"
            >Indexador</label
          >
          <div id="indexador" class="select">
            <select v-model="this.objeto.codigoIndex" data-group="combobox-indexador" @keydown.prevent.enter="$emit('mudar-pagina', 'receita federal')">
              <option data-group="combobox-indexador-vazio">R$</option>
              <option
                v-for="i in this.indexadores"
                v-bind:key="i.codigo"
                v-bind:value="i.codigo"
                :data-group="'combobox-indexador-'+ i.codigo"
              >
                {{ i.nome }}
              </option>
            </select>
          </div>
          <p class="help is-danger"></p>
        </div>
      </div>
    </div>
    <PesquisaSocio
      ref="pesquisaSocio"
      :modalVisivel="abrirConsultaSocios"
      @fechar="this._abrirFecharPesquisaSocios"
      @carregarItemSelecionado="_preencherSocioSelecionado"
      :botaoCadastro="this._retornarPropriedadesBotaoCadastrarSocio()"
    />
    <pesquisa-cbo
      :modalVisivel="abrirConsultaCBO"
      @fechar="this._abrirFecharPesquisaCBO"
      @carregarItemSelecionado="_preencherCBOSelecionado"
    />
    <modalAviso
      v-if="this.avisoVisivel"
      :textoAviso="this.textoAviso"
      @on-ok="this._abreFechaAviso"
    />
    <CadastroSocio
      v-if="this.cadastroSocioVisivel"
      :fecharModal="_fecharModalCadastroDeSocio"
      :proximoCodigo="this.proximoCodigoSocio"
      @recuperarCodigoDeSocioSalvo="this._preencherCodigoSocioSalvo"
    />

    <Modal
      title="Confirmação"
      :active="this.showConfirmacaoCadastrarSocio"
      @close="this.showConfirmacaoCadastrarSocio = false"
    >
      <p>
        Você não possui nenhum sócio cadastrado com este código. Deseja cadastrar agora?
      </p>
      <template v-slot:footer>
        <alt-button
          class="button is-primary"
          @click="this._cadastrarSocio(this.objeto.codigoSocio)"
          caption="Sim"
          style="margin-right: 1rem"
        />
        <alt-button
          class="button"
          @click="this.showConfirmacaoCadastrarSocio = false"
          caption="Não"
        />
      </template>
    </Modal>

  </div>
</template>

<script>
import PesquisaCBO from '../../../components/CBO/pesquisaCBO.vue'
import { buscarCBO } from '../../../components/CBO/buscarCBO.js'
import Socemp from './model/socemp.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InputValorMonetario from '../../../components/InputValorMonetario/index.vue'
import PesquisaSocio from '../../Socios/PesquisaSocio/pesquisaSocio.vue'
import Empresa from '../model/empresa.js'
import { service } from '../../../service.js'
import modalAviso from '../../../components/ModalAviso/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import Socio from '../../Socios/model/socios'
import InputText from '@alterdata/component-vue/InputText'
import InputNumber from '@packonline/packonline-reutilizaveis-frontend/components/InputNumber.vue'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import CadastroSocio from './CadastroDeSocio/modalCascaCadastroDeSocio.vue'
import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'
import HistoricoRetencaoIrrf from './components/HistoricoRetencaoIrrf/HistoricoRetencaoIrrf.vue'

export default {
  name: 'abaBasico',
  components: {
    'pesquisa-cbo': PesquisaCBO,
    'input-data': InputDate,
    'input-valor-monetario': InputValorMonetario,
    'campo-texto': InputText,
    'alt-button': Button,
    PesquisaSocio,
    modalAviso,
    CadastroSocio,
    Modal,
    InputNumber,
    HistoricoRetencaoIrrf
  },
  props: {
    errosValidacao: Object,
    itemSocemp: Socemp,
    itemSocio: Socio,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    editando: Boolean,
    indexadoresParam: Array,
    historicosDeRetencao: Array,
    listaSociosVinculados: Array
  },
  emits: ['aoAtualizarHistoricosDeRetencaoASeremCriados', 'aoAtualizarHistoricosDeRetencaoASeremExcluidos'],
  mixins: [mixin],
  inject: ['setActiveSection'],
  data () {
    return {
      erros: this.errosValidacao,
      erroPercentual: null,
      motivosDesligamento: [
        {
          codigo: '1',
          motivo:
            '01 - Exoneração do diretor não empregado sem justa causa, por deliberação da assembleia, dos sócios cotistas ou da autoridade competente'
        },
        {
          codigo: '2',
          motivo:
            '02 - Término de mandato do diretor não empregado que não tenha sido reconduzido ao cargo'
        },
        {
          codigo: '3',
          motivo: '03 - Exoneração a pedido de diretor não empregado'
        },
        {
          codigo: '4',
          motivo:
            '04 - Exoneração do diretor não empregado por culpa reciproca ou força maior'
        },
        { codigo: '5', motivo: '05 - Morte do diretor não empregado' },
        {
          codigo: '6',
          motivo:
            '06 - Exoneração do diretor não empregado por falência, encerramento ou supressão de parte da empresa'
        },
        { codigo: '99', motivo: '99 - Outros' }
      ],
      abrirConsultaSocios: false,
      abrirConsultaCBO: false,
      numeroCBONovo: null,
      indexadores: this.indexadoresParam,
      textoAviso: null,
      avisoVisivel: false,
      habilitarMotivoDesligamento: this.itemSocemp.dataDemissao,
      objeto: {
        idEmpresaSocio: this.itemSocemp.idEmpresaSocio,
        codigoSocio:
          this.itemSocemp.codigoSocio != null
            ? this._retornarCodigoFormatado(this.itemSocemp.codigoSocio)
            : this.itemSocemp.codigoSocio,
        alCapital: this.itemSocemp.alCapital,
        valorCapitalSocio: this.itemSocemp.valorCapitalSocio,
        stGerente: this.itemSocemp.stGerente,
        stResponsavelCgc: this.itemSocemp.stResponsavelCgc,
        valorProLabore: this.itemSocemp.valorProLabore,
        codigoIndex: this.itemSocemp.codigoIndex
          ? this.itemSocemp.codigoIndex
          : 'R$',
        dataAdmissao: utils.formatarDataParaExibicao(
          this.itemSocemp.dataAdmissao
        ),
        dataDemissao: utils.formatarDataParaExibicao(
          this.itemSocemp.dataDemissao
        ),
        codigoPhdDos: this.itemSocemp.codigoPhdDos,
        tipoIdentificadorResponsavel: this.itemSocemp
          .tipoIdentificadorResponsavel
          ? this.itemSocemp.tipoIdentificadorResponsavel
          : 'S',
        stEntraRais: this.itemSocemp.stEntraRais === 'S',
        codigoRetencaoIrrf: this.itemSocemp.codigoRetencaoIrrf,
        numeroCboNovo: this.itemSocemp.numeroCboNovo,
        motivo_Desligamento: this.itemSocemp.motivo_Desligamento,
        nomeSocio: this.itemSocemp.nomeSocio
      },
      cadastroSocioVisivel: false,
      proximoCodigoSocio: null,
      showConfirmacaoCadastrarSocio: false,
      showModalHistoricoRetencaoIrrf: false,
      showModalHistoricoRetencaoIrrfCadastroEdicao: false,
      cadastroVinculadoAEmpresaNoBackend: false,
      historicosDeRetencaoPorSocio: []
    }
  },
  watch: {
    objeto: {
      handler: function (val, old) {
        if (!val.dataDemissao) {
          val.motivo_Desligamento = null
          this.habilitarMotivoDesligamento = false
        }
        this.montarObjeto(val)
      },
      deep: true
    },
    abrirConsultaSocios (val) {
      val ? this.setActiveSection('pesquisaSocio') : this.setActiveSection('quickviewCadastroVinculoSocios')
    },
    abrirConsultaCBO (val) {
      val ? this.setActiveSection('pesquisaCBO') : this.setActiveSection('quickviewCadastroVinculoSocios')
    }
  },
  methods: {
    _validarDataAdmissao () {
      if (this.objeto.dataAdmissao) {
        Socemp.validarDataAdmissao(
          this.objeto.dataAdmissao,
          this.itemEmpresa.dataInicioAtividade,
          this.erros
        )
      }
    },

    _validarDataDemissao () {
      if (this.objeto.dataDemissao) {
        if (
          !Socemp.validarDataDemissao(
            this.objeto.dataDemissao,
            this.objeto.dataAdmissao,
            this.itemEmpresa.dataInicioAtividade,
            this.erros
          )
        ) {
          this.habilitarMotivoDesligamento = false
        } else {
          this.habilitarMotivoDesligamento = true
        }
      } else {
        this.habilitarMotivoDesligamento = false
      }
    },

    _abrirFecharPesquisaCBO () {
      this.abrirConsultaCBO = !this.abrirConsultaCBO
    },

    _preencherCBOSelecionado (item) {
      this.erros.numeroCBOInexistente = null
      this.erros.numeroCboNovo = null
      this.objeto.numeroCboNovo = item.id
      this._abrirFecharPesquisaCBO()
    },

    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    _buscarCBODigitado () {
      if (this.objeto.numeroCboNovo) {
        const profissao = buscarCBO.executar(this.objeto.numeroCboNovo).nome
        if (!profissao) {
          this.textoAviso = 'O código CBO digitado não foi encontrado'
          this.erros.numeroCboNovo = this.textoAviso
          this.erros.numeroCBOInexistente = this.textoAviso
        }
      }
    },

    _abrirFecharPesquisaSocios () {
      this.abrirConsultaSocios = !this.abrirConsultaSocios && !this.editando
    },

    _preencherSocioSelecionado (item) {
      this.erros.codigoSocioNaoExistente = null
      this.erros.codigoSocio = null
      this.objeto.codigoSocio = item.cdsocio
      this.objeto.codigoSocio = this._retornarCodigoFormatado(
        this.objeto.codigoSocio
      )
      this.objeto.nomeSocio = item.nmsocio
      this._abrirFecharPesquisaSocios()
      if (this._checarSeSocioJaEstaVinculadoAEmpresa()) {
        this.textoAviso = 'Este sócio já esta vinculado à empresa'
        this.objeto.nomeSocio = null
        this.objeto.codigoSocio = null
        this.erros.codigoSocio = this.textoAviso
      } else {
        Object.assign(this.itemSocio, item)
      }
    },

    _retornarCodigoFormatado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },

    async _buscarSocioDigitado () {
      if (!this.objeto.codigoSocio) {
        this.erros.codigoSocio = 'Informe o código do sócio'
        return
      }
      if (!this._checarSeSocioJaEstaVinculadoAEmpresa()) {
        try {
          const resposta = await service
            .get('/api/v1/socio', this.objeto.codigoSocio)
            .then()

          this.objeto.codigoSocio = this._retornarCodigoFormatado(
            this.objeto.codigoSocio
          )

          if (resposta) {
            this.objeto.nomeSocio = resposta.nmsocio
            Object.assign(this.itemSocio, resposta)
            this.erros.codigoSocio = ''
            this.erros.codigoSocioNaoExistente = ''
            await this._verificarSeOcadastroEstaVinculadoAEmpresaNoBackend()
            return true
          } else {
            this.textoAviso = 'O código de sócio digitado não foi encontrado'
            this.objeto.codigoSocio = this._retornarCodigoFormatado(
              this.objeto.codigoSocio
            )
            this.erros.codigoSocio = this.textoAviso
            this.erros.codigoSocioNaoExistente = this.erros.codigoSocio
            this.showConfirmacaoCadastrarSocio = true
            this.cadastroVinculadoAEmpresaNoBackend = false

            return
          }
        } catch (error) {
          this.erros.codigoSocio =
            'O código de sócio digitado não foi encontrado'
          this.objeto.codigoSocio = this._retornarCodigoFormatado(
            this.objeto.codigoSocio
          )
          this.erros.codigoSocioNaoExistente = this.erros.codigoSocio
          this.showConfirmacaoCadastrarSocio = true
          this.cadastroVinculadoAEmpresaNoBackend = false
        }
      } else {
        this.textoAviso = 'Este sócio já esta vinculado à empresa'
        this.erros.codigoSocio = this.textoAviso
        this.cadastroVinculadoAEmpresaNoBackend = false
      }
    },

    setValorPorcentagem (valor) {
      this._calcularValorDoCapitalSociaAPartirDaPorcentagem(valor)
      valor = valor.replace(/\.|/g, '')
      this.objeto.alCapital = valor.toString().replace(',', '.')
      this._validarSomatorioTotalDasPorcentagensDosValoresDeCapital()
    },

    setValorCapital (valor) {
      this._calcularPorcentagemDoCapitalSociaAPartirDoValor(valor)
      valor = valor.replace(/\.|/g, '')
      if (parseFloat(valor) > 0) {
        this.objeto.valorCapitalSocio = valor.toString().replace(',', '.')
      }
    },

    _calcularPorcentagemDoCapitalSociaAPartirDoValor (valor) {
      if (parseFloat(valor) > 0) {
        let valorLocal = valor.toString().replace(',', '.')
        valorLocal = parseFloat(this._removerTodosExcetoUltimo(valorLocal, '.'))
        valorLocal = parseFloat(
          ((valorLocal * 100) / this.itemEmpresa.valorCapital)
            .toString()
            .substr(0, 5)
        ).toFixed(2)
        this.objeto.alCapital = valorLocal.toString().replace(',', '.')
      } else {
        this.objeto.alCapital = 0
      }
    },

    setValorProLabore (valor) {
      valor = valor.replace(/\.|/g, '')
      this.objeto.valorProLabore = valor.toString().replace(',', '.')
    },

    _abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    _obterStatus (value) {
      return !value ? '' : 'error'
    },

    _calcularValorDoCapitalSociaAPartirDaPorcentagem (valor) {
      if (parseFloat(valor.toString().replace(',', '.')) > 0) {
        let valorLocal = parseFloat(valor.toString().replace(',', '.'))
        valorLocal = (
          this.itemEmpresa.valorCapital *
          (valorLocal / 100)
        ).toFixed(2)
        this.objeto.valorCapitalSocio = valorLocal.toString().replace(',', '.')
      } else {
        this.objeto.valorCapitalSocio = null
      }
    },

    _removerTodosExcetoUltimo (string, token) {
      const parts = string.split(token)
      return parts.length === 1
        ? string
        : parts.slice(0, -1).join('') + token + parts.slice(-1)
    },

    _validarSomatorioTotalDasPorcentagensDosValoresDeCapital () {
      this.erroPercentual = ''
      const valorMaior = Number(this.objeto.alCapital)
      if (valorMaior > 100) {
        this.erroPercentual = 'Informe um número entre 1 e 100'
        return
      }

      this.textoAviso =
        Socemp.validarSomatorioTotalDasPorcentagensDosValoresDeCapital(
          this.objeto.codigoSocio,
          this.listaSociosVinculados,
          this.objeto.alCapital,
          this.editando ? '1' : '0',
          this.objeto.nomeSocio
        )

      if (this.textoAviso) {
        this.adicionarNotificacao(
          new Toast({
            tipo: 'warning',
            titulo: 'Valor informado inválido.',
            subtitulo: this.textoAviso
          })
        )
      }
    },

    _checarSeSocioJaEstaVinculadoAEmpresa () {
      const socioJaVinculado = this.listaSociosVinculados.filter(
        (item) => item.codigoSocio === parseInt(this.objeto.codigoSocio, 10)
      )
      return socioJaVinculado.length > 0
    },

    validarIR () {
      if (!this.objeto.codigoRetencaoIrrf) {
        this.erros.codigoRetencaoIrrf = 'Informe o código de retenção'
      } else {
        this.objeto.codigoRetencaoIrrf = utils.strZero(this.objeto.codigoRetencaoIrrf, 4)
        this._obterHistoricoDeRetencaoDoIrrfPorSocio()
        const historicoAtual = this.historicosDeRetencaoPorSocio.sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao))[0]
        if (this.cadastroVinculadoAEmpresaNoBackend && historicoAtual.codigoRetencaoAtual !== this.objeto.codigoRetencaoIrrf) {
          this.showModalHistoricoRetencaoIrrfCadastroEdicao = true
          this._abreModalHistoricoRetencaoIrrf()
        }
      }
    },

    validarCBO () {
      if (!this.objeto.numeroCboNovo) {
        this.erros.numeroCboNovo = 'Informe o o número do CBO'
      }
    },

    _fecharModalCadastroDeSocio () {
      this.cadastroSocioVisivel = false
    },

    async _cadastrarSocio (codigo) {
      let cod = null
      if (codigo) {
        cod = codigo
        this._abreFechaConfirmacao()
      } else {
        cod = await Socio.proximoCodigo()
      }
      this.proximoCodigoSocio = utils.strZero(cod.toString(), 5)
      this.cadastroSocioVisivel = true
    },

    _preencherCodigoSocioSalvo (codigo) {
      this.abrirConsultaSocios = false
      this.objeto.codigoSocio = codigo
      this._buscarSocioDigitado()
    },

    _abreFechaConfirmacao () {
      this.showConfirmacaoCadastrarSocio = !this.showConfirmacaoCadastrarSocio
    },

    _retornarPropriedadesBotaoCadastrarSocio () {
      const obj = {
        metodoCadastrar: null,
        captionBotao: null,
        datagroup: 'btn-novo-socio-pesquisa'
      }

      obj.metodoCadastrar = this._cadastrarSocio
      obj.captionBotao = 'Novo sócio'

      return obj
    },

    async _verificarSeOcadastroEstaVinculadoAEmpresaNoBackend () {
      if (this.itemSocemp.codigoSocio) {
        try {
          const url = `/api/v1/socemp/${this.itemEmpresa.cdempresa}/${this.itemSocemp.codigoSocio}`
          await service.get(url)
          this.cadastroVinculadoAEmpresaNoBackend = true
        } catch (error) {
          this.cadastroVinculadoAEmpresaNoBackend = false
        }
      } else {
        this.cadastroVinculadoAEmpresaNoBackend = false
      }
    },

    _obterHistoricoDeRetencaoDoIrrfPorSocio () {
      this.historicosDeRetencaoPorSocio = this.historicosDeRetencao.filter(item => item.socioId === Number(this.objeto.codigoSocio))
    },

    _abreModalHistoricoRetencaoIrrf () {
      this.showModalHistoricoRetencaoIrrf = true
      this._obterHistoricoDeRetencaoDoIrrfPorSocio()
    },

    _aoFecharModalHistoricoRetencaoIrrf () {
      this._obterHistoricoDeRetencaoDoIrrfPorSocio()
      const historicoAtual = this.historicosDeRetencaoPorSocio.sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao))[0]
      if (historicoAtual) {
        this.objeto.codigoRetencaoIrrf = historicoAtual.codigoRetencaoAtual
      }
      this.showModalHistoricoRetencaoIrrf = false
      this.showModalHistoricoRetencaoIrrfCadastroEdicao = false
    },

    _aoAtualizarHistoricosDeRetencaoASeremCriados (value) {
      this.$emit('aoAtualizarHistoricosDeRetencaoASeremCriados', value)
      this.objeto.codigoRetencaoIrrf = value.codigoRetencaoAtual
      this._obterHistoricoDeRetencaoDoIrrfPorSocio()
    },

    _aoAtualizarHistoricosDeRetencaoASeremExcluidos (value) {
      this.$emit('aoAtualizarHistoricosDeRetencaoASeremExcluidos', value)
      this.objeto.codigoRetencaoIrrf = value.codigoRetencaoAnterior
      this._obterHistoricoDeRetencaoDoIrrfPorSocio()
    }
  },

  async mounted () {
    await this._verificarSeOcadastroEstaVinculadoAEmpresaNoBackend()
  }
}
</script>

<style scoped>
</style>
