<template>
  <div class="field column is-12 p-0">
    <indexador />
  </div>
</template>

<script>
import indexador from '../../Indexador/index.vue'

export default {
  components: {
    indexador
  }
}
</script>

<style>
</style>
