<template>
<div v-if="this.abaVisivel" id="abaUsuarios">
    <div class="container" style="text-align: left;">
        <div class="columns is-multiline">
            <div class="panel table-container .isVisibleOverflow">
            <table class="table" style="width: 100%; table-layout: fixed;overflow: hidden;text-align: left;">
                <thead>
                    <tr>
                        <th style="width: 80px;">
                          Permitido
                          <br>
                          <label class="form-checkbox">
                          <input :disabled="this.itemEmpresa.empresaAtiva" type="checkbox" v-model="selectAll" @click="_salvarAlteracaoUsuarioEmBloco">
                          </label>
                        </th>
                        <th style="width: 300px;">Nome</th>
                        <th style="width: 100px;">Login</th>
                        <th style="width: 130px;">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(usuario) of usuarios" v-bind:key="usuario.idusuario">
                        <td><label class="form-checkbox">
                          <input :disabled="this.itemEmpresa.empresaAtiva" type="checkbox" v-model="usuario.usuarioPermitido" @click="_salvarAlteracaoUsuario(usuario)">
                          </label></td>
                        <td>{{ usuario.nmusuario }}</td>
                        <td>{{ usuario.nmcurto }}</td>
                        <td>{{ usuario.email }}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Empresa from './model/empresa.js'
import { service } from '../../service.js'

const ERROS = {
  codigo: '',
  nome: '',
  mascara: ''
}
export default {
  name: 'abaUsuarios',
  props: {
    abaVisivel: Boolean,
    itemEmpresa: Empresa
  },
  data () {
    return {
      usuarios: this.itemEmpresa.usuarios,
      erros: ERROS,
      selectAll: true
    }
  },

  created () {
    this._checarSeTodosOsRegistrosEstaoSelecionados()
  },

  methods: {
    _checarSeTodosOsRegistrosEstaoSelecionados () {
      this.selectAll = true
      for (const i in this.usuarios) {
        if (!this.usuarios[i].usuarioPermitido) {
          this.selectAll = false
          break
        }
      }
    },

    async _salvarAlteracaoUsuarioEmBloco () {
      if (!this.selectAll) {
        const array = this._montarObjetoParaAlteracaoEmBloco()
        service.save('/api/v1/usuarioempresa', array).then(this.responseToSave)
      } else {
        for (const i in this.usuarios) {
          this.usuarios[i].usuarioPermitido = false
          await this._excluirVinculo(this.usuarios[i])
        }
      }
    },

    _montarObjetoParaAlteracaoEmBloco () {
      const usuarioArray = []
      for (const i in this.usuarios) {
        const usuarioEmpresa = {
          idusuarioempresa: null,
          idusuario: null,
          idempresa: null
        }
        this.usuarios[i].usuarioPermitido = true
        usuarioEmpresa.idusuarioempresa = this.usuarios[i].idVinculoUsuarioEmpresa
        usuarioEmpresa.idusuario = this.usuarios[i].idusuario
        usuarioEmpresa.idempresa = parseInt(this.itemEmpresa.cdempresa, 10)
        usuarioArray.push(usuarioEmpresa)
      }
      return usuarioArray
    },

    _salvarAlteracaoUsuario (usuario) {
      const usuarioEmpresa = {
        idusuarioempresa: null,
        idusuario: null,
        idempresa: null
      }
      if (!usuario.usuarioPermitido) {
        const usuarioArray = []
        usuarioEmpresa.idusuarioempresa = usuario.idVinculoUsuarioEmpresa
        usuarioEmpresa.idusuario = usuario.idusuario
        usuarioEmpresa.idempresa = parseInt(this.itemEmpresa.cdempresa, 10)
        usuarioArray.push(usuarioEmpresa)
        service.save('/api/v1/usuarioempresa', usuarioArray).then(this.responseToSave)
      } else {
        this._excluirVinculo(usuario)
        this.selectAll = false
      }
    },

    async responseToSave (response) {
      const json = await response.json()
      if (response.ok) {
        await this._atualizarListaDeUsuarios(json)
        this._checarSeTodosOsRegistrosEstaoSelecionados()
      }
      if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    async _excluirVinculo (usuario) {
      const usuarioDelete = {
        id: usuario.idVinculoUsuarioEmpresa
      }
      await service.delete('/api/v1/usuarioempresa', usuarioDelete)
      usuario.idVinculoUsuarioEmpresa = null
    },

    async _atualizarListaDeUsuarios (usuariosSalvos) {
      for (const i in this.usuarios) {
        const retorno = usuariosSalvos.filter(usu => usu.idusuario === this.usuarios[i].idusuario)
        if (retorno.length > 0) {
          this.usuarios[i].idVinculoUsuarioEmpresa = retorno[0].idusuarioempresa
        }
      }
    }
  }
}
</script>
