<template>
  <div :id="wrapperId" class="simple-typeahead">
    <campo-texto
      :id="inputId"
      class="input"
      type="text"
      :placeholder="placeholder"
      v-model="input"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.tab.prevent="selecionarSelecaoAtual"
      autocomplete="off"
      :label="this.label"
      :message="this.message"
      :status="this.status"
      :icon="this.icon"
      @update:erro="this.erros.nomeAuditor = $event.message"
      :disabled="this.disabled"
    />
    <div
      v-if="listaVisivel || isInputFocado"
      style="position: absolute; top: 55px"
      class="simple-typeahead-list"
    >
      <div class="simple-typeahead-list-header" v-if="$slots['list-header']">
        <slot name="list-header"></slot>
      </div>
      <div
        class="simple-typeahead-list-item"
        :class="{
          'simple-typeahead-list-item-active': indiceDeSelecaoAtual == index
        }"
        v-for="(item, index) in itensFiltrados"
        :key="index"
        @mousedown.prevent
        @click="selecionarItem(item)"
        @mouseenter="indiceDeSelecaoAtual = index"
      >
        <span
          class="simple-typeahead-list-item-text"
          :data-text="valorExibido(item)"
          v-if="$slots['list-item-text']"
        >
          <slot
            name="list-item-text"
            :item="item"
            :valorExibido="valorExibido"
            :negritoAoDigitar="negritoAoDigitar"
          >
          </slot>
        </span>
        <span
          class="simple-typeahead-list-item-text"
          :data-text="valorExibido(item)"
          v-html="negritoAoDigitar(valorExibido(item))"
          v-else
        >
        </span>
      </div>
      <div
        class="simple-typeahead-list-item"
        v-if="this.itensFiltrados.length === 0"
      >
        <span class="simple-typeahead-list-item-text">
          <slot name="list-item"> Nenhum resultado encontrado </slot>
        </span>
      </div>
      <div class="simple-typeahead-list-footer" v-if="$slots['list-footer']">
        <slot name="list-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'

export default defineComponent({
  emits: ['onInput', 'onFocus', 'onBlur', 'selecionarItem'],
  components: {
    'campo-texto': InputText
  },
  props: {
    id: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    itemPadrao: {
      default: null
    },
    valorExibido: {
      type: Function,
      default (item) {
        return item
      }
    },
    minInputLength: {
      type: Number,
      default: 2,
      validator: (prop) => {
        return prop >= 0
      }
    },
    inputValue: {
      type: String,
      default: ''
    },
    limpar: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    editando: {
      type: Object,
      default: null
    },
    errosValidacao: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean
    }
  },
  mounted () {
    if (this.itemPadrao !== undefined && this.itemPadrao !== null) {
      this.selecionarItem(this.itemPadrao)
    }
  },
  data () {
    return {
      inputId:
        this.id || `simple_typeahead_${(Math.random() * 1000).toFixed()}`,
      input: this.inputValue,
      isInputFocado: false,
      indiceDeSelecaoAtual: 0,
      erros: this.errosValidacao
    }
  },
  methods: {
    onInput () {
      if (
        this.listaVisivel &&
        this.indiceDeSelecaoAtual >= this.itensFiltrados.length
      ) {
        this.indiceDeSelecaoAtual = (this.itensFiltrados.length || 1) - 1
      }
      this.$emit('onInput', {
        input: this.input,
        items: this.itensFiltrados
      })
    },
    onFocus () {
      this.isInputFocado = true
      this.$emit('onFocus', {
        input: this.input,
        items: this.itensFiltrados
      })
    },
    onBlur () {
      this.isInputFocado = false
      this.$emit('onBlur', {
        input: this.input,
        items: this.itensFiltrados
      })
    },
    onArrowDown ($event) {
      if (
        this.listaVisivel &&
        this.indiceDeSelecaoAtual < this.itensFiltrados.length - 1
      ) {
        this.indiceDeSelecaoAtual++
      }
      this.scrollSelecaoParaVisao()
    },
    onArrowUp ($event) {
      if (this.listaVisivel && this.indiceDeSelecaoAtual > 0) {
        this.indiceDeSelecaoAtual--
      }
      this.scrollSelecaoParaVisao()
    },
    scrollSelecaoParaVisao () {
      setTimeout(() => {
        const lista = document.querySelector(
          `#${this.wrapperId} .simple-typeahead-list`
        )
        const listaAtiva = document.querySelector(
          `#${this.wrapperId} .simple-typeahead-list-item.simple-typeahead-list-item-active`
        )
        if (
          !(
            listaAtiva.offsetTop >= lista.scrollTop &&
            listaAtiva.offsetTop + listaAtiva.offsetHeight <
              lista.scrollTop + lista.offsetHeight
          )
        ) {
          let scrollTo = 0
          if (listaAtiva.offsetTop > lista.scrollTop) {
            scrollTo =
              listaAtiva.offsetTop +
              listaAtiva.offsetHeight -
              lista.offsetHeight
          } else if (listaAtiva.offsetTop < lista.scrollTop) {
            scrollTo = listaAtiva.offsetTop
          }
          lista.scrollTo(0, scrollTo)
        }
      })
    },
    selecionarSelecaoAtual () {
      if (this.selecaoAtual) {
        this.selecionarItem(this.selecaoAtual)
      }
    },
    selecionarItem (item) {
      this.input = this.valorExibido(item)
      this.indiceDeSelecaoAtual = 0
      document.getElementById(this.inputId).blur()
      this.$emit('selecionarItem', item)
    },
    escapeRegExp (string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    },
    removerAcento (text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    negritoAoDigitar (text) {
      const regexp = new RegExp(`(${this.escapeRegExp(this.input)})`, 'ig')
      return text.replace(regexp, '<strong>$1</strong>')
    },
    limparCampo () {
      this.input = ''
      this.$emit('limparCampo', false)
    }
  },
  watch: {
    limpar: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.limparCampo()
        }
      },
      deep: true
    },
    editando: {
      handler: function (val) {
        if (val) {
          if (this.editando.nome) {
            this.input = this.editando.nome
          }
          this.$emit('atribuir', this.editando)
        }
      },
      deep: true
    }
  },
  computed: {
    wrapperId () {
      return `${this.inputId}_wrapper`
    },
    itensFiltrados () {
      const regexp = new RegExp(this.escapeRegExp(this.input), 'i')
      return this.items
        .filter((item) => this.valorExibido(item).match(regexp))
        .sort(function (a, b) {
          var nomeA = a.nome.toLowerCase()
          var nomeB = b.nome.toLowerCase()
          if (nomeA < nomeB) {
            return -1
          }
          if (nomeA > nomeB) {
            return 1
          }
          return 0
        })
    },
    listaVisivel () {
      return (
        this.isInputFocado &&
        this.input.length >= this.minInputLength &&
        this.itensFiltrados.length
      )
    },
    selecaoAtual () {
      return this.listaVisivel &&
        this.indiceDeSelecaoAtual < this.itensFiltrados.length
        ? this.itensFiltrados[this.indiceDeSelecaoAtual]
        : undefined
    }
  }
})
</script>

<style scoped>
.simple-typeahead {
  position: relative;
  width: 100%;
}
.simple-typeahead > input {
  margin-bottom: 0;
}
.simple-typeahead .simple-typeahead-list {
  position: absolute;
  width: 100%;
  border: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 0.1rem solid #d1d1d1;
  z-index: 9;
}
.simple-typeahead .simple-typeahead-list .simple-typeahead-list-header {
  background-color: #fafafa;
  padding: 0.6rem 1rem;
  border-bottom: 0.1rem solid #d1d1d1;
  border-left: 0.1rem solid #d1d1d1;
  border-right: 0.1rem solid #d1d1d1;
}
.simple-typeahead .simple-typeahead-list .simple-typeahead-list-footer {
  background-color: #fafafa;
  padding: 0.6rem 1rem;
  border-left: 0.1rem solid #d1d1d1;
  border-right: 0.1rem solid #d1d1d1;
}
.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item {
  cursor: pointer;
  background-color: #fafafa;
  padding: 0.6rem 1rem;
  border-bottom: 0.1rem solid #d1d1d1;
  border-left: 0.1rem solid #d1d1d1;
  border-right: 0.1rem solid #d1d1d1;
}
.simple-typeahead
  .simple-typeahead-list
  .simple-typeahead-list-item:last-child {
  border-bottom: none;
}
.simple-typeahead
  .simple-typeahead-list
  .simple-typeahead-list-item.simple-typeahead-list-item-active {
  background-color: #e1e1e1;
}
</style>
