<template>
    <Modal title="Informe o nire" :active="this.modalVisivel" id="cadastroEdicaoHistoricoNire" @close="this._cancelar">
          <div class="columns is-multiline">
            <div class="field column is-6">
              <input-texto
                id="codigo"
                label="Código"
                v-model="this.itemHistorico.numero"
                maxlength="11"
                :status="this.getStatus(erros.codigo)"
                :message="erros.codigo"
                @update:erro="erros.codigo = $event.message"
                @keypress="somenteNumeros"
              />
            </div>
            <div class="field column is-6">
              <input-data
                label="Data"
                id="dataNire"
                class="input"
                v-model="this.itemHistorico.data"
                :model-value="this.itemHistorico.data"
                :message="erros.data"
                :status="erros.data ? 'error' : ''"
                @update:valid="this.validadorHistorico = $event"
                @update:erro="erros.data = $event"
              />
            </div>
          </div>
          <template v-slot:footer>
            <ButtonAjuda style="margin-right: auto;"/>
            <Button class="button is-primary" @click="this._salvarHistorico" caption="Gravar" style="margin-right: 1rem"/>
            <Button class="button" @click="this._cancelar" caption="Cancelar"/>
          </template>
      </Modal>
</template>

<script>
import { service } from '../../../service.js'
import HistoricoNire from './model/historico-nire.js'
// import { utils } from '../../../util/utils.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import ButtonAjuda from '../../../components/alt-component/ButtonAjuda/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'

const ERROS = {
  codigo: '',
  data: ''
}

export default {
  name: 'cadastroEdicaoHistoricoNire',
  components: {
    'input-data': InputDate,
    'input-texto': InputText,
    Modal,
    ButtonAjuda,
    Button
  },

  props: {
    modalVisivel: Boolean,
    idEmpresa: String,
    historico: HistoricoNire
  },

  mixins: [mixin],

  data () {
    return {
      itemHistorico: this.historico,
      erros: ERROS,
      validadorHistorico: null
    }
  },

  methods: {
    async _salvarHistorico () {
      try {
        this.itemHistorico.empresaID = this.idEmpresa
        this.itemHistorico.data = this.formatar(this.itemHistorico.data)
        if (this._validarInformacoesCadastro()) {
          service
            .save('/api/v1/historico-nire', this.itemHistorico)
            .then(this.responseToSave)
        }
      } catch (e) {
        Object.assign(this.erros, JSON.parse(e.message))
      }
    },

    _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      this.erros = ERROS
      let ocorreuErro = false
      Object.assign(
        this.erros,
        HistoricoNire.validar(
          this.itemHistorico.numero,
          this.itemHistorico.data,
          this.validadorHistorico
        )
      )
      if (this.erros.codigo || this.erros.data) {
        ocorreuErro = true
      }

      return !ocorreuErro
    },

    _limparRegistroDeErros () {
      this.erros.codigo = ''
      this.erros.data = ''
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    formatar (data) {
      if (data && this.checarData(new Date(data))) {
        return new Date(data).toISOString()
      }
    },

    checarData (data) {
      return data instanceof Date && !isNaN(data)
    },

    somenteNumeros (evt) {
      utils.onlyNumber(evt)
    },

    _cancelar () {
      this._limparRegistroDeErros()
      this.$emit('cancel')
    }
  }
}
</script>

<style>
</style>
