export default class Indexador {
  constructor (codigo = '') {
    this.codigo = codigo
    this.nome = ''
  }

  static validar ({ nome }) {
    const erros = {}

    if (!nome) {
      erros.nome = 'Deve ser informado'
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }
}
