export default function carregarSocioAoEditar (socio, item) {
  socio.inscricaoCpfCnpj = item.inscricaoCpfCnpj
  socio.cdsocio = item.cdsocio
  socio.nmsocio = item.nmsocio
  socio.basico.endereco = item.basico.endereco
  socio.basico.bairro = item.basico.bairro
  socio.basico.cidade = item.basico.cidade
  socio.basico.cep = item.basico.cep
  socio.basico.uf = item.basico.uf
  socio.basico.codigoIBGE = item.basico.codigoIBGE
  socio.basico.telefone = item.basico.telefone
  socio.basico.email = item.basico.email
  socio.basico.paisCodigo = item.basico.paisCodigo
  socio.basico.complemento = item.basico.complemento
  socio.basico.numero = item.basico.numero
  socio.basico.tipo = item.basico.tipo
  socio.basico.usuariosWmail = item.basico.usuariosWmail

  socio.complementar.numeroIdentidade = item.complementar.numeroIdentidade
  socio.complementar.orgaoExpeditorIdentidade = item.complementar.orgaoExpeditorIdentidade
  socio.complementar.dataEmissaoIdentidade = item.complementar.dataEmissaoIdentidade
  socio.complementar.nomeResponsavel = item.complementar.nomeResponsavel
  socio.complementar.cpfResponsavel = item.complementar.cpfResponsavel
  socio.complementar.dataValidadeCNH = item.complementar.dataValidadeCNH
  socio.complementar.expedicaoCNH = item.complementar.expedicaoCNH
  socio.complementar.orgaoEmissorCNH = item.complementar.orgaoEmissorCNH
  socio.complementar.numeroCNH = item.complementar.numeroCNH
  socio.complementar.ufCNH = item.complementar.ufCNH
  socio.complementar.categoriaCNH = item.complementar.categoriaCNH
  socio.complementar.ufCTPS = item.complementar.ufCTPS
  socio.complementar.serieCTPS = item.complementar.serieCTPS
  socio.complementar.numeroCTPS = item.complementar.numeroCTPS
  socio.complementar.residenciaRecursoFGTS = item.complementar.residenciaRecursoFGTS
  socio.complementar.residenciaPropria = item.complementar.residenciaPropria
  socio.complementar.numeroCBONovo = item.complementar.numeroCBONovo
  socio.complementar.nomeProfissao = item.complementar.nomeProfissao
  socio.complementar.deficienteReabilitado = item.complementar.deficienteReabilitado
  socio.complementar.deficiente = item.complementar.deficiente
  socio.complementar.deficienteTipo = item.complementar.deficienteTipo
  socio.complementar.estadoCivil = item.complementar.estadoCivil
  socio.complementar.regimeMatrimonio = item.complementar.regimeMatrimonio
  socio.complementar.numeroDependentes = item.complementar.numeroDependentes
  socio.complementar.qualificacaoRepresentanteLegal = item.complementar.qualificacaoRepresentanteLegal

  socio.declaracoes.dataNascimento = item.declaracoes.dataNascimento
  socio.declaracoes.ufNascimento = item.declaracoes.ufNascimento
  socio.declaracoes.codigoIBGENascimento = item.declaracoes.codigoIBGENascimento
  socio.declaracoes.paisCodigoNascimento = item.declaracoes.paisCodigoNascimento
  socio.declaracoes.nacionalidadeCodigo = item.declaracoes.nacionalidadeCodigo
  socio.declaracoes.dataChegada = item.declaracoes.dataChegada
  socio.declaracoes.dataNaturalizacao = item.declaracoes.dataNaturalizacao
  socio.declaracoes.casadoBrasil = item.declaracoes.casadoBrasil
  socio.declaracoes.filhosBrasil = item.declaracoes.filhosBrasil
  socio.declaracoes.sexo = item.declaracoes.sexo
  socio.declaracoes.racaCor = item.declaracoes.racaCor
  socio.declaracoes.grauDeInstrucao = item.declaracoes.grauDeInstrucao
  socio.declaracoes.nomeMae = item.declaracoes.nomeMae
  socio.declaracoes.nomePai = item.declaracoes.nomePai
  socio.declaracoes.paisCodigoESocialNascimento = item.declaracoes.paisCodigoESocialNascimento
  socio.declaracoes.paisCodigoESocialNacionalidade = item.declaracoes.paisCodigoESocialNacionalidade
  socio.declaracoes.tempoResidencia = item.declaracoes.tempoResidencia
  socio.declaracoes.condicao = item.declaracoes.condicao

  socio.observacao.observacoes = item.observacao.observacoes
}
