<template>
  <div id="modalAviso">
    <div class= "modal animated fadeIn is-active">
      <div class="modal-background"></div>
        <div class="modal-content" id="modalAviso">
          <header class="modal-card-head">
            <div class="icon-text">
              <span class="icon"><span class="fas fa-exclamation-triangle fa-lg"></span></span>
              <span>Aviso</span>
            </div>
          </header>
          <section class="modal-card-body">
            <div class="content" id="textoAviso" style="white-space: pre-line;">
              <p>{{this.textoAviso}}</p>
            </div>
          </section>
          <footer class="modal-card-foot is-justify-content-center">
            <div class="buttons">
              <button class="button is-primary" id="btnOk" @click="this.$emit('on-ok')">OK</button>
            </div>
          </footer>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aviso',
  props: {
    textoAviso: String
  }
}
</script>
<style scoped>
  #textoAviso{
    padding-bottom: 0rem;
    margin: 0
  }
  #modalAviso{
    width: 700px
  }
  #btnOk{
    width: 100px
  }
</style>
