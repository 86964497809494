export default class SociosAuxiliar {
  constructor (codigo = null) {
    this.id = null
    this.inscricaoCpfCnpj = null
    this.cdsocio = codigo
    this.nmsocio = null
    this.basico = {
      endereco: null,
      bairro: null,
      cidade: null,
      cep: null,
      uf: null,
      codigoIBGE: null,
      telefone: null,
      email: null,
      paisCodigo: null,
      complemento: null,
      numero: null,
      tipo: null,
      usuariosWmail: null
    }
    this.complementar = {
      numeroIdentidade: null,
      orgaoExpeditorIdentidade: null,
      dataEmissaoIdentidade: null,
      nomeResponsavel: null,
      cpfResponsavel: null,
      dataValidadeCNH: null,
      expedicaoCNH: null,
      orgaoEmissorCNH: null,
      numeroCNH: null,
      ufCNH: null,
      categoriaCNH: null,
      ufCTPS: null,
      serieCTPS: null,
      numeroCTPS: null,
      residenciaRecursoFGTS: null,
      residenciaPropria: null,
      numeroCBONovo: null,
      nomeProfissao: null,
      deficienteReabilitado: null,
      deficiente: null,
      deficienteTipo: null,
      estadoCivil: null,
      regimeMatrimonio: null,
      numeroDependentes: null,
      qualificacaoRepresentanteLegal: null
    }
    this.declaracoes = {
      dataNascimento: null,
      ufNascimento: null,
      codigoIBGENascimento: null,
      paisCodigoNascimento: null,
      nacionalidadeCodigo: null,
      dataChegada: null,
      dataNaturalizacao: null,
      casadoBrasil: null,
      filhosBrasil: null,
      sexo: null,
      racaCor: null,
      grauDeInstrucao: null,
      nomeMae: null,
      nomePai: null,
      paisCodigoESocialNascimento: null,
      paisCodigoESocialNacionalidade: null,
      tempoResidencia: null,
      condicao: null
    }
    this.observacao = {
      observacoes: null
    }
  }
}
