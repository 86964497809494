<template>
  <div
    class="alt-box"
    :class="{ 'is-disabled': desabilitado}"
    :style="{ borderColor: corDoIcone }"
  >
    <span v-show="check" class="alt-icon">
      <i
        :class="{ 'fas fa-check-circle fa-4x icon-check': check }" :data-cy ="nomeIcone"
        aria-hidden="true"
      ></i>
    </span>
    <div class="is-flex is-flex-direction-column">
      <div
        class="is-flex is-justify-content-center is-align-content-center pb-3"
        :class="{ 'is-not-check': !check }"
      >
        <p
          class="subtitle is-5"
          v-text="titulo"
          :style="{ color: corDoIcone }"
        ></p>
      </div>
      <div
        class="is-flex is-justify-content-center is-align-content-center"
        :class="{ 'is-not-check': !check }"
      >
        <i :class="classesIcone" :style="{ color: corDoIcone }"></i>
      </div>
      <div class="is-flex is-justify-content-center is-align-content-flex-end">
        <Button
          :class="componenteAguardandoCarregamento ? 'is-loading': 'alt-button is-medium'"
          :caption="check ? 'Editar' : 'Vincular ao módulo'"
          :disabled="desabilitado"
          :data-cy="id"
          :style="{ background: corDoIcone, color: '#ffffff' }"
          @click="$emit('click')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { utils } from '../../../util/utils'
import Button from '@alterdata/component-vue/Button'

defineEmits(['click'])

const props = defineProps({
  titulo: {
    type: String,
    default: 'Título'
  },
  corDoIcone: {
    type: String,
    default: ''
  },
  icone: {
    type: String,
    default: ''
  },
  desabilitado: Boolean,
  check: Boolean,
  componenteAguardandoCarregamento: {
    type: Boolean,
    default: false
  }
})

const id = computed(() => {
  return `botaoVincular${utils.removeAcentos(props.titulo).replace('.', '').replace(' ', '-')}`
})

const nomeIcone = computed(() => {
  return `checkIconeVinculo${utils.removeAcentos(props.titulo).replace('.', '').replace(' ', '-')}`
})

const classesIcone = computed(() => {
  return `fas fa-${props.icone} fa-5x`
})
</script>

<style lang="scss" scoped>
@use '@alterdata/theme-bulma';

.icon-check {
  color: #00804b;
}

.alt-box {
  @extend .box;
  box-shadow: none;
  border: 3px solid;
  height: 166px;
  width: 100%;
  position: relative;

  &::before{
    opacity: 1;
  }
}

.alt-button {
  @extend .button;
  position: absolute;
  bottom: -20px;
  opacity: 1 !important;

  &:disabled {
    opacity: 1;
  }
}

.alt-icon {
  @extend .icon;
  background-color: #ffff;
  position: absolute;
  right: -5px;
  top: 0;
}

.is-disabled {
  filter: grayscale(100);
  opacity: 0.5;
  cursor: not-allowed;
}

.is-not-check {
  opacity: 0.5;
}
</style>

<!-- border, boxShadow: 'none' -->
