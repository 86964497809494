<template>
  <div class="container" style="text-align: left">
    <div class="columns is-multiline">
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label class="label" for="endereco" style="font-size: 1rem"
          ><strong>Endereço</strong></label
        >
      </div>
      <div class="column is-12" style="padding-left: 0">
        <endereco-postal
          :pEndereco="this.endereco"
          :pInputsMaxLength="this.inputsMaxLength"
          :pModulo="'cadastro-socio'"
          :errosValidacao="this.erros"
          :erroMunicipio="'municipioBasicoSocio'"
          :data-group="'socio'"
          @carregarObjeto="this.carregarObjetoEndereco"
          @erroCEP="this.erros.cep = $event"
        />
      </div>
      <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
        <label class="label" for="endereco" style="font-size: 1rem"><strong>Contato</strong></label>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-3">
            <input-texto
              id="telefone"
              maxlength="20"
              label="Telefone"
              v-model="this.basico.telefone"
              :status="this.getStatus(this.erros.telefone)"
              :message="this.erros.telefone"
              @update:erro="this.erros.telefone = $event.message"
              @keypress="this.onlyNumber()"
            />
          </div>
          <div class="column is-6">
            <input-texto
              id="email"
              maxlength="50"
              label="E-mail"
              v-model="this.basico.email"
              :status="this.getStatus(this.erros.email)"
              :message="this.erros.email"
              @update:erro="this.erros.email = $event.message"
              @keypress="this.isEmail()"
            />
          </div>
        </div>
      </div>
      <div class="column is-6">
        <input-texto
          id="wmail"
          label="E-mail para envio de relatórios"
          maxlength="50"
          v-model="this.basico.usuariosWmail"
          :status="this.getStatus(this.erros.usuariosWmail)"
          :message="this.erros.usuariosWmail"
          @update:erro="this.erros.usuariosWmail = $event.message"
          @keypress="this.isEmail()"
          @keydown.prevent.enter.exact="$emit('navToAbaComplementar')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Socio from './model/socios.js'
import { utils } from '../../util/utils.js'
import { mixin } from '../../util/mixin.js'
import enderecoPostal from '../../components/EnderecoPostal/index.vue'
import jsonPaises from './json/paises.json'
import { maska } from 'maska'

import InputText from '@alterdata/component-vue/InputText'

export default {
  name: 'abaBasico',
  components: {
    'endereco-postal': enderecoPostal,
    'input-texto': InputText
  },
  directives: {
    maska
  },
  props: {
    montarObjeto: Function,
    itemSocio: Socio,
    errosValidacao: Object
  },
  mixins: [mixin],
  emits: {
    navToAbaComplementar: null
  },
  data () {
    return {
      basico: {
        endereco: this.itemSocio.basico.endereco,
        bairro: this.itemSocio.basico.bairro,
        cidade: this.itemSocio.basico.cidade,
        cep: this.itemSocio.basico.cep,
        uf: this.itemSocio.basico.uf,
        codigoIBGE: this.itemSocio.basico.codigoIBGE,
        telefone: this.itemSocio.basico.telefone,
        email: this.itemSocio.basico.email,
        paisCodigo: this.itemSocio.basico.paisCodigo,
        complemento: this.itemSocio.basico.complemento,
        numero: this.itemSocio.basico.numero,
        tipo: this.itemSocio.basico.tipo,
        usuariosWmail: this.itemSocio.basico.usuariosWmail
      },
      erroLogradouroOuNumero: false,
      endereco: {
        cep: this.itemSocio.basico.cep,
        logradouro: this.itemSocio.basico.endereco,
        numero: this.itemSocio.basico.numero,
        complemento: this.itemSocio.basico.complemento,
        bairro: this.itemSocio.basico.bairro,
        codigoIBGE: this.itemSocio.basico.codigoIBGE,
        uf: this.itemSocio.basico.uf,
        localidade: this.itemSocio.basico.cidade,
        logradouroTipo: this.itemSocio.basico.tipo
      },
      inputsMaxLength: {
        logradouro: 40,
        numero: 10,
        bairro: 20,
        complemento: 30
      },
      erros: this.errosValidacao,
      posicaoCodPais: 'position: relative; left: 660px; bottom: 80px;',
      posicaoNmPais: 'position: relative; right: 70px; padding-right: 65px;'
    }
  },
  computed: {
    logradouro: function () {
      return this.endereco.logradouro
    },
    numero: function () {
      return this.endereco.numero
    },
    jPaises () {
      return jsonPaises
    }
  },
  watch: {
    basico: {
      handler: function (val, old) {
        this.montarObjeto(val, 'básico')
      },
      deep: true
    },
    'basico.uf' () {
      this.erros.inscricaoEstadual = ''
    }
  },
  methods: {
    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    isEmail (e) {
      return utils.emailFormat(e)
    },

    carregarObjetoEndereco (endereco) {
      Object.assign(this.endereco, endereco)
      this.montarObjetoBasicoComEndereco()
    },
    carregarObjetoPais (paisCodigo) {
      this.basico.paisCodigo = paisCodigo
    },
    montarObjetoBasicoComEndereco () {
      this.basico.cep = this.endereco.cep
      this.basico.endereco = this.endereco.logradouro
      this.basico.numero = this.endereco.numero
      this.basico.complemento = this.endereco.complemento
      this.basico.bairro = this.endereco.bairro
      this.basico.uf = this.endereco.uf
      this.basico.cidade = this.endereco.localidade
      this.basico.tipo = this.endereco.logradouroTipo
      this.basico.codigoIBGE = this.endereco.codigoIBGE
    },
    carregarNumeroLogradouro () {
      if (
        this.itemSocio.basico.endereco &&
        this.itemSocio.basico.endereco.includes(',')
      ) {
        var el = this.itemSocio.basico.endereco.split(',')
        if (el.length > 1) {
          this.basico.endereco = el[0]
          this.basico.numero = el[1].replace(' ', '')
          this.endereco.logradouro = el[0]
          this.endereco.numero = el[1].replace(' ', '')
        }
      }
    }
  },
  mounted () {
    this.carregarNumeroLogradouro()
  }
}
</script>

<style></style>
