<template>
  <div :class="this.isBorder ? 'panel' : ''">
    <div class="panel-block">
      <div id="form-container" class="columns is-multiline">
        <div class="field column is-2" style="margin: 0">
          <input-texto
            id="longo"
            placeholder="Buscar CEP"
            label="CEP"
            maxlength="9"
            v-model="this.endereco.cep"
            v-maska="'#####-###'"
            :class="
              errosValid.cep === 'Cep não localizado' ? 'input is-info' : ''
            "
            icon-left="search"
            :status="
              errosValid.cep === 'Cep não localizado'
                ? 'info'
                : this.getStatus(errosValid.cep)
            "
            :message="errosValid.cep"
            :required="this.endereco.uf !== 'EX'"
            @update:erro="this.errosValid.cep = $event.message"
            @blur="this.verificaCEP"
            @change="(event) => this.search(event)"
            :disabled="this.disabled"
            :data-group=" dataGroup ? 'cep-input-' + dataGroup : 'cep-input'"
          />
        </div>
        <div
          v-if="this.modulo === 'cadastro-empresa'"
          class="field column is-8"
          style="margin: 0"
        >
          <input-texto
            id="logradouro"
            v-model="this.endereco.logradouro"
            label="Logradouro"
            :status="
              this.errosValid.logradouro
                ? this.errosValid.logradouro.length > 60
                  ? 'info'
                  : 'error'
                : ''
            "
            :message="this.errosValid.logradouro"
            :class="
              this.errosValid.logradouro
                ? this.errosValid.logradouro.length > 60
                  ? 'input is-info'
                  : 'input is-danger'
                : 'input'
            "
            :maxlength="
              this.endereco.logradouro.length + this.endereco.numero.length !==
              38
                ? this.pInputsMaxLength.logradouro
                : this.endereco.logradouro.length
            "
            @blur="
              !this.endereco.logradouro
                ? (this.errosValid.logradouro = 'Informe o logradouro')
                : ''
            "
            @update:erro="this.errosValid.logradouro = $event.message"
            @keypress="this.$emit('exitLogradouro')"
            required
            :disabled="this.disabled"
          />
        </div>
        <div
          v-if="this.modulo === 'cadastro-socio'"
          class="field column is-10"
          style="margin: 0"
        >
          <div
            class="columns is-multiline"
            style="padding-bottom: 0rem; margin: 0"
          >
            <div style="margin-right: 27px">
              <combo-box
                id="logTipo"
                v-model="this.logradouroTipo"
                label="Logradouro"
                :key="a++"
                :options="this.logradouros"
                list-by="nome"
              />
            </div>
            <div
              class="field column"
              style="padding-top: 0; padding-left: 0; margin: 0; margin-top: 1.3rem;"
            >
              <input-texto
                id="logradouro"
                width="99%"
                v-model="this.endereco.logradouro"
                :status="this.getStatus(this.errosValid.logradouro)"
                :maxlength="this.pInputsMaxLength.logradouro"
                :message="errosValid.logradouro"
                @update:erro="errosValid.logradouro = $event.message"
                @blur="this.$emit('exitLogradouro')"
                required
              />
            </div>
          </div>
        </div>

        <div
          v-if="this.modulo === 'cadastro-empresa'"
          class="field column is-2"
          style="margin: 0"
        >
          <input-texto
            id="numero"
            v-model="this.endereco.numero"
            label="Número"
            :status="
              this.errosValid.logradouro.length > 60 ||
              this.errosValid.numero.length > 60
                ? 'info'
                : ''
            "
            :class="
              this.errosValid.numero
                ? this.errosValid.logradouro.length > 60 ||
                  this.errosValid.numero.length > 60
                  ? 'input is-info'
                  : 'input'
                : 'input'
            "
            :maxlength="
              this.endereco.logradouro.length + this.endereco.numero.length !==
              38
                ? this.pInputsMaxLength.numero
                : this.endereco.numero.length
            "
            :message="errosValid.numero"
            @update:erro="errosValid.numero = $event.message"
            @keypress="this.$emit('exitNumero')"
            :disabled="this.disabled"
          />
        </div>

        <div
          v-if="this.modulo === 'cadastro-socio'"
          class="field column is-2"
          style="margin: 0"
        >
          <input-texto
            id="numero"
            v-model="this.endereco.numero"
            label="Número"
            :maxlength="this.pInputsMaxLength.numero"
            :status="this.getStatus(errosValid.numero)"
            :message="errosValid.numero"
            @update:erro="errosValid.numero = $event.message"
            @keypress="this.$emit('exitNumero')"
          />
        </div>

        <div
          :class="
            this.modulo === 'cadastro-socio'
              ? 'field column is-5'
              : 'field column is-12'
          "
          style="margin: 0"
        >
          <input-texto
            id="complemento"
            v-model="this.endereco.complemento"
            label="Complemento"
            :maxlength="this.pInputsMaxLength.complemento"
            :status="this.getStatus(errosValid.complemento)"
            :message="errosValid.complemento"
            @update:erro="errosValid.complemento = $event.message"
            :disabled="this.disabled"
          />
        </div>

        <div
          v-if="this.modulo === 'cadastro-socio'"
          :class="
            this.modulo === 'cadastro-socio'
              ? 'field column is-5'
              : 'field column is-4'
          "
          style="padding-right: 30px; margin: 0"
        >
          <input-texto
            id="bairro"
            v-model="this.endereco.bairro"
            label="Bairro"
            :maxlength="this.pInputsMaxLength.bairro"
            :status="this.getStatus(errosValid.bairro)"
            :message="errosValid.bairro"
            @update:erro="errosValid.bairro = $event.message"
          />
        </div>
        <div
          v-if="this.modulo === 'cadastro-empresa'"
          :class="
            this.modulo === 'cadastro-socio'
              ? 'field column is-5'
              : 'field column is-4'
          "
          style="margin: 0"
        >
          <input-texto
            id="bairro"
            v-model="this.endereco.bairroCompleto"
            label="Bairro"
            :maxlength="this.pInputsMaxLength.bairro"
            :status="this.getStatus(errosValid.bairro)"
            :message="errosValid.bairro"
            @update:erro="errosValid.bairro = $event.message"
            :disabled="this.disabled"
          />
        </div>
        <div class="field column is-4" style="margin: 0">
          <municipio-uf
            :pUf="this.endereco.uf"
            :pCdMunicipio="this.endereco.codigoIBGE"
            :pNmMunicipio="this.endereco.localidade"
            :pIdMunicipio="this.endereco.idMunicipio"
            :pExibeMunicipio="true"
            :pErros="this.errosValid"
            :pMod="this.modulo"
            @carregarObjSelecionado="this.carregarObjSelecionado"
            @limparCampos="this.clearCamposEndereco"
            :key="municipioUfKey"
            :erro="this.erroMunicipio"
            :requerido="this.municipio_required"
            :disabled="this.disabled"
          />
        </div>
        <div class="field column is-3">
          <input-texto
            id="ibge"
            label="Código IBGE"
            :maxlength="this.pInputsMaxLength.ibge"
            :value="this.ibge"
            disabled
          />
        </div>
        <div v-if="this.modulo === 'cadastro-socio'" class="field column is-4">
          <pesquisa-pais
            :pCdPais="this.$parent.basico.paisCodigo"
            :pNmPais="this.$parent.pais"
            :jsonPaises="this.$parent.jPaises"
            :pUf="this.endereco.uf"
            :posicaoCod="this.$parent.posicaoCodPais"
            :posicaoNm="this.$parent.posicaoNmPais"
            :pModulo="'cadastro-socio'"
            :errosValidacao="this.$parent.erros"
            @carregarObjetoPais="this.$parent.carregarObjetoPais"
            @mudarUf="this.$parent.endereco.uf = $event"
            @mudarPosCod="this.$parent.posicaoCodPais = $event"
            @mudarPosNm="this.$parent.posicaoNmPais = $event"
          />
        </div>
      </div>
    </div>
  </div>

  <div v-if="this.importandoCep" class="modal animated is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <div class="modal-card-body">
        <h1 class="title">Buscando CEP</h1>
        <progress class="progress is-large is-info" max="100">60%</progress>
      </div>
    </div>
  </div>
</template>
<script>
import { cepService } from './cep-service.js'
import municipioUf from '../PesquisaMunicipioUF/index.vue'
import Endereco from './model/endereco-postal.js'
import { toast } from 'bulma-toast'
import jsonLog from '../../views/Socios/json/tipoLogradouro.json'
import { maska } from 'maska'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { mixin } from '../../util/mixin.js'
import ComboBox from '@alterdata/component-vue/ComboBox/index.vue'
import pesquisaPais from '../PesquisaPais/indexBasico.vue'

const ERROS = {
  uf: '',
  cdMunicipio: ''
}

export default {
  mixins: [mixin],
  components: {
    'municipio-uf': municipioUf,
    'input-texto': InputText,
    'combo-box': ComboBox,
    'pesquisa-pais': pesquisaPais
  },
  directives: {
    maska
  },
  props: {
    pEndereco: Endereco,
    pInputsMaxLength: Object,
    pModulo: String,
    pLogTipo: String,
    errosValidacao: Object,
    editando: Boolean,
    erroMunicipio: String,
    municipio_required: Boolean,
    disabled: Boolean,
    dataGroup: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      erros: ERROS,
      errosValid: this.errosValidacao,
      endereco: this.pEndereco,
      importandoCep: false,
      modulo: this.pModulo,
      logradouros: jsonLog.data,
      isInfo: false,
      municipioUfKey: 0
    }
  },
  methods: {
    async search (event) {
      const value = event.target.value.replace('-', '')
      if (value === '') {
        this.isInfo = false
        this.errosValid.cep = 'Informe o CEP'
        this.isValidCep = false
        return
      }

      this.isValidCep = true
      this.$emit('erroCEP', '')
      if (!/^[0-9]{8}$/.test(value)) {
        if (value.length < 8) {
          this.isInfo = false
          this.errosValid.cep = 'CEP inválido'
        }
        this.isValidCep = false
        return
      }
      let ep = null
      try {
        this.importandoCep = true
        ep = await cepService.list(value)

        if (ep === null) {
          this.isInfo = true
          this.errosValid.cep = 'Cep não localizado'
        }
      } catch (e) {
        toast({ message: 'Problemas ao importar o CEP', type: 'is-danger' })
      } finally {
        this.importandoCep = false
      }

      if (this.endereco.numero === null) {
        this.endereco.numero = ''
      }

      if (ep) {
        if (!this.editando) {
          this.endereco.enderecoCompleto = ''
        }
        if (
          this.modulo === 'cadastro-empresa' &&
          ep.logradouro.length > 38 - this.endereco.numero.length
        ) {
          this.endereco.logradouro = `${ep.logradouro.substring(
            0,
            38 - this.endereco.numero.length
          )}`
          this.endereco.enderecoCompleto = `${ep.logradouro}, ${this.endereco.numero}`
        } else {
          this.endereco.logradouro = `${ep.logradouro}`
        }

        if (this.modulo === 'cadastro-empresa' && ep.bairro.length > 15) {
          this.endereco.bairro = ep.bairro.substring(0, 15)
          this.endereco.bairroCompleto = ep.bairro
        } else {
          this.endereco.bairro = ep.bairro
          this.endereco.bairroCompleto = ep.bairro
        }
        if (this.modulo === 'cadastro-empresa') {
          this.municipioUfKey++
        }

        if (this.modulo === 'cadastro-socio') {
          const tipo = []

          JSON.parse(JSON.stringify(this.logradouros)).map((e) => {
            if (ep.logradouro.indexOf(e.nome) !== -1) {
              tipo.push(e)
            }
          })

          const response = tipo.reduce((a, b) =>
            a.nome.length >= b.nome.length ? a : b
          )

          this.logradouroTipo = response

          if (this.modulo === 'cadastro-socio' && ep.logradouro.length > 40) {
            this.endereco.logradouro = ep.logradouro
              .replace(response.nome, '')
              .trim()
              .substring(0, 40)
          } else {
            this.endereco.logradouro = ep.logradouro
              .replace(response.nome, '')
              .trim()
          }

          if (this.modulo === 'cadastro-socio' && ep.bairro.length > 20) {
            this.endereco.bairro = ep.bairro.substring(0, 20)
          } else {
            this.endereco.bairro = ep.bairro
          }
        }
        this.municipioUfKey++
        this.endereco.cep = ep.cep
        this.endereco.localidade = ep.localidade
        this.endereco.uf = ep.uf
        this.endereco.codigoIBGE = ep.ibge

        document.querySelector('#numero').focus()
      }
    },

    verificaCEP (e) {
      if (!e.target.value && this.endereco.uf !== 'EX') {
        this.errosValid.cep = 'Informe o CEP'
      }
    },

    clearCamposEndereco () {
      this.endereco.uf === 'EX'
        ? (this.endereco.cep = null)
        : (this.endereco.uf = null)
      this.endereco.logradouro = ''
      this.endereco.bairro = null
      this.endereco.localidade = null
      this.endereco.codigoIBGE = null
      this.endereco.numero = ''
      this.endereco.complemento = null
      this.endereco.logradouroTipo = null
    },
    carregarObjSelecionado (municipio) {
      this.endereco.localidade = municipio.nmMunicipio
      this.endereco.uf = municipio.uf
      this.endereco.codigoIBGE = municipio.cdMunicipio
      this.endereco.idMunicipio = municipio.idMunicipio
    }
  },
  watch: {
    endereco: {
      immediate: true,
      handler (val) {
        if (val.uf === 'EX' && this.errosValid.cep === 'Informe o CEP') {
          this.errosValid.cep = ''
        }
        this.$emit('carregarObjeto', this.endereco)
      },
      deep: true
    }
  },
  computed: {
    ibge () {
      if (this.erros[this.erroMunicipio] || this.endereco.localidade === '') {
        return ''
      }
      return this.endereco.codigoIBGE
    },
    logradouroTipo: {
      get () {
        const tipo = []

        if (this.endereco.logradouroTipo) {
          JSON.parse(JSON.stringify(this.logradouros)).map((e) => {
            if (this.endereco.logradouroTipo.indexOf(e.abv) !== -1) {
              tipo.push(e)
            }
          })

          return tipo.reduce((a, b) => (a.nome.length >= b.nome.length ? a : b))
        } else {
          return this.endereco.logradouroTipo
        }
      },
      set (newValue) {
        this.endereco.logradouroTipo = newValue.abv
      }
    }
  },

  created () {
    if (!this.endereco.numero) {
      this.endereco.numero = ''
    }
    if (!this.endereco.logradouro) {
      this.endereco.logradouro = ''
    }
  }
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.input-icon-container {
  display: flex;
  width: 100%;
}
#form-container {
  flex: 1 1 auto;
}

.is-size-8 {
  font-size: 0.74rem;
}
</style>
