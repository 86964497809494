<template>
    <div v-if="this.modalVisivel" id="listagemCnaeSecundario">
      <section class="modal-card-body">
        <div class="container">
          <alt-datatable :pStatus="this.status" :pDataSource="this.dataSource" :pData="this.cnaesSecudario"
            :pHideButtonMore="this.hideButtonMore" :paginacao="this.paginacao"
            :idDivUtilizadaPaginacao="'listagemCnaeSecundario'" :noIcon="true">
            <template v-slot:new-item>
              <button class="button is-light" @click="_cadastrarNovo">Novo</button>
            </template>
          </alt-datatable>
        </div>
      </section>
      <cadastro-Edicao-Cnae-Secundario :modalVisivel="this.abrirCadastroEdicao" :idEmpresa="this.itemEmpresa.cdempresa"
          :cnae="this.item" @cancel="this.abrirCadastroEdicao = false" @save="_atualizarListaAposCadastro"/>
    </div>
</template>

<script>
import Empresa from '../model/empresa.js'
import EmpresaCnaeSecundario from './model/empresa-cnae-secundario.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '../../../service.js'
import cadastroEdicaoCnaeSecundario from './cadastroEdicaoCnaeSecundario.vue'
import AltDatatable from '../../../components/alt-component/Datatable/index.vue'

export default {
  name: 'historicoNire',
  components: {
    'cadastro-Edicao-Cnae-Secundario': cadastroEdicaoCnaeSecundario,
    'alt-datatable': AltDatatable
  },
  props: {
    modalVisivel: Boolean,
    itemEmpresa: Empresa
  },

  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'referencia',
            label: 'Referência',
            format: this._retornaDataFormatada,
            style: 'width:30%'
          },
          {
            name: 'cnae',
            label: 'CNAE',
            style: 'width:20%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this._editar(item),
        delete: (item) => this._excluir(item),
        loadMore: null
      },
      cnaesSecudario: this.itemEmpresa.cnaesSecudario,
      abrirCadastroEdicao: false,
      item: new EmpresaCnaeSecundario(),
      editando: false,
      status: '',
      hideButtonMore: false,
      paginacao: false
    }
  },

  methods: {

    _cadastrarNovo () {
      this.editando = false
      this.item.referencia = null
      this.item.cnae = null
      this.item.empresaID = null
      this.item.id = null
      this._abrirFecharCadastroEdicao()
    },

    _retornaDataFormatada (data) {
      return utils.formatarDataParaExibicaoMesAno(utils.formatarDataParaExibicaoNoDataTable(data))
    },

    _abrirFecharCadastroEdicao () {
      this.abrirCadastroEdicao = !this.abrirCadastroEdicao
    },

    _atualizarListaAposCadastro (cnae) {
      if (!this.editando) {
        this.cnaesSecudario.push(cnae)
      } else {
        const itemIndex = this.cnaesSecudario.findIndex(item => item.id === cnae.id)
        this.cnaesSecudario.splice(itemIndex, 1, cnae)
      }
      this.editando = false
      this._abrirFecharCadastroEdicao()
      this._ordenarListaPorData()
    },

    async _excluir (cnae) {
      const resposta = await service.delete('/api/v1/cnae-secundario', cnae)
      if (resposta.ok) {
        const itemIndex = this.cnaesSecudario.findIndex(item => item.id === cnae.id)
        this.cnaesSecudario.splice(itemIndex, 1)
      }
    },

    _editar (cnae) {
      Object.assign(this.item, cnae)
      this.editando = true
      this.item.referencia = utils.formatarDataParaExibicao(this.item.referencia)
      this._abrirFecharCadastroEdicao()
    },

    _ordenarListaPorData () {
      this.cnaesSecudario.sort(compara)
      function compara (a, b) {
        return new Date(a.referencia).getTime() - new Date(b.referencia).getTime()
      }
    }
  },
  created () {
    this._ordenarListaPorData()
  }

}
</script>

<style>

</style>
