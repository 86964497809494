<template>
  <div id="spedECD" class="is-flex is-flex-direction-column is-desktop">
    <div class="column is-full">
      <div style="background-color: lightgoldenrodyellow; padding: 90px; padding-left: 20px; padding-right: 150px" v-if="!this.listagemVisivel">
        <p style="font-size: 14px;">Não existe nenhum auditor vinculado a essa empresa.</p>
        <br/>
        <p style="font-size: 14px;">Informe um auditor, ano da auditoria nos campos acima e clique em gravar vínculo</p>
        <p style="font-size: 14px;">para vincula-lo.</p>
      </div>
      <div id="auditores" v-show="this.listagemVisivel">
        <alt-datatable :pStatus="this.status" :pDataSource="this.dataSource" :pData="this.auditores"
        :pHideButtonMore="this.hideButtonMore" :pQuantidadeDeItems="this.page.total" :nextPage="this.nextPage" :pSearch="false"
        :idDivUtilizadaPaginacao="'auditores'" :disabled="this.itemEmpresa.empresaAtiva" :customStyle="'max-height: 32vh'"/>
      </div>
    </div>
  </div>
</template>

<script>
import AltDatatable from '@/components/alt-component/Datatable/index.vue'
import AuditorIndependente from './model/auditor-independente.js'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import Empresa from '../model/empresa.js'

export default {
  name: 'app',
  components: {
    'alt-datatable': AltDatatable
  },
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    editandoAuditor: Boolean,
    listaVinculosNovosEditados: Array,
    listagemVis: Boolean,
    pContadores: Object
  },
  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'nome',
            label: 'Nome do auditor independente',
            style: 'width:45%'
          },
          {
            name: 'codigoCVM',
            label: 'Código CVM',
            style: 'width:17%'
          },
          {
            name: 'ano',
            label: 'Ano de auditoria',
            style: 'width:17%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: (item) => this.excluir(item),
        loadMore: null
      },
      page: [],
      auditores: [],
      cadastrandoOuEditando: false,
      editando: false,
      item: new AuditorIndependente(),
      status: '',
      hideButtonMore: false,
      listagemVisivel: true,
      showTitle: false,
      pagina: 0
    }
  },
  watch: {
    listaVinculosNovosEditados: {
      handler: async function (val, old) {
        const item = val[val.length - 1]
        if (item) {
          const itemIndex = this.auditores.findIndex(itemAuditor => itemAuditor.id === item.id || itemAuditor.tempId === item.tempId)
          if (this.editandoAuditor) {
            this.auditores.splice(itemIndex, 1)
          }
          this.$emit('editAuditor', false)
          this.auditores.push(item)
          await this._carregarListagem()
        }
      },
      deep: true
    },
    listagemVis: {
      handler: function (val, old) {
        if (val) {
          this.listagemVisivel = this.listagemVis
        }
      }
    },
    auditores: {
      handler: function (val, old) {
        this.$emit('auditores', this.auditores)
      }
    }
  },
  methods: {
    async _carregarListagem () {
      this.status = 'is-loading'
      try {
        this.page = await service.get(`/api/v1/auditor-independente/empresa/${this.itemEmpresa.cdempresa}`)
        this.page.content.map(a => {
          const contadorObjeto = this.pContadores.find(contador => contador.id === a.contadorId)
          a.nome = contadorObjeto.nome
        })
        if (this.page.total === 0 && this.auditores.length === 0) {
          this.status = 'is-empty'
          this.listagemVisivel = false
        } else {
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          if (this.auditores.length === 0) {
            this.auditores = this.page.content
          }
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    async nextPage (pagina) {
      this.status = 'is-loading'
      try {
        this.auditores = []
        this.page = await service.get(`/api/v1/auditor-independente/empresa/${this.itemEmpresa.cdempresa}?page=${pagina}`)
        this.page.content.map(a => {
          const contadorObjeto = this.pContadores.find(contador => contador.id === a.contadorId)
          a.nome = contadorObjeto.nome
        })
        this.auditores = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    excluir (auditor) {
      const itemIndex = this.auditores.findIndex(item => item.id === auditor.id)
      this.auditores.splice(itemIndex, 1)
      if ((this.item.id || this.item.tempId) === (auditor.id || auditor.tempId)) {
        this.$parent.$refs.cadastro._limparCampos()
      }
      this.$emit('armazenarVinculoASerExcluido', auditor)
      if (this.auditores.length === 0) {
        this.listagemVisivel = false
      }
    },

    editar (auditor) {
      Object.assign(this.item, auditor)
      this.editando = true
      this.enviarEdicao()
    },

    cadastrarNovo () {
      this.editando = false
      this.item = new AuditorIndependente()
    },

    enviarEdicao () {
      this.$emit('editarAuditor',
        this.item,
        this.auditores)
      this.$parent.$refs.cadastro.edit = true
    }
  },

  created () {
    this._carregarListagem()
  }
}
</script>

<style lang="scss" scoped>
.panel.table-container.is-tableScrollable {
    height: 50px;
}
</style>
