<template>
<div id="abaObservacao">
    <div class="container" style="text-align: left;width:900px; height:300px">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="field column is-12" style="padding-bottom: 0rem;margin: 0;">
            <label class="label" for="observacao">Observação</label>
            <div class="control">
                <textarea id="observacao" class="textarea" v-model="this.observacao.observacoes" maxlength="1000"  style="height:210px"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import Socio from './model/socios.js'

export default {
  name: 'abaObservacao',
  props: {
    montarObjeto: Function,
    itemSocio: Socio
  },
  data () {
    return {
      observacao: {
        observacoes: this.itemSocio.observacao.observacoes
      }
    }
  },
  watch: {
    observacao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val, 'observação')
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    }
  }

}
</script>
