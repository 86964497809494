<template>
    <div v-if="this.modalVisivel" id="listagemCnae">
      <section class="modal-card-body">
        <div class="container"  style="width:105%;">
          <alt-datatable :pStatus="this.status" :pDataSource="this.dataSource" :pData="this.cnaes"
            :pHideButtonMore="this.hideButtonMore" :paginacao="this.paginacao"
            :idDivUtilizadaPaginacao="'listagemCnae'" :noIcon="true">
            <template v-slot:new-item>
              <button class="button is-light" @click="_cadastrarNovo">Novo</button>
            </template>
          </alt-datatable>
        </div>
      </section>
        <cadastro-Edicao-Cnae :modalVisivel="this.abrirCadastroEdicao" :itemEmpresa="this.itemEmpresa"
            :cnae="this.item" @cancel="this.abrirCadastroEdicao = false" @save="_atualizarListaAposCadastro"/>
        <modalAviso v-if='this.avisoVisivel' :textoAviso='this.textoAviso' @on-ok='this._abreFechaAviso'/>
    </div>
</template>

<script>
import Empresa from '../model/empresa.js'
import EmpresaHistorico from './model/empresa-historico.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '../../../service.js'
import cadastroEdicaoCnae from './cadastroEdicaoCnae.vue'
import AltDatatable from '../../../components/alt-component/Datatable/index.vue'
import modalAviso from '../../../components/ModalAviso/index.vue'

export default {
  name: 'historicoCNAE',
  components: {
    'cadastro-Edicao-Cnae': cadastroEdicaoCnae,
    'alt-datatable': AltDatatable,
    modalAviso
  },
  props: {
    modalVisivel: Boolean,
    itemEmpresa: Empresa,
    montarObjeto: Function
  },

  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'dataInicial',
            label: 'Referência',
            format: this._retornaDataFormatada,
            style: 'width:30%'
          },
          {
            name: 'cnae',
            label: 'CNAE',
            style: 'width:20%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this._editar(item),
        delete: (item) => this._excluir(item),
        loadMore: null
      },
      cnaes: this.itemEmpresa.cnaes,
      abrirCadastroEdicao: false,
      item: new EmpresaHistorico(),
      editando: false,
      status: '',
      hideButtonMore: false,
      avisoVisivel: false,
      textoAviso: null,
      paginacao: false
    }
  },

  methods: {

    _cadastrarNovo () {
      this.editando = false
      this.item.dataInicial = null
      this.item.cnae = null
      this.item.empresaID = null
      this.item.id = null
      this._abrirFecharCadastroEdicao()
    },

    _abrirFecharCadastroEdicao () {
      this.abrirCadastroEdicao = !this.abrirCadastroEdicao
    },

    _atualizarListaAposCadastro (cnae) {
      if (!this.editando) {
        this.cnaes.push(cnae)
      } else {
        const itemIndex = this.cnaes.findIndex(item => item.id === cnae.id)
        this.cnaes.splice(itemIndex, 1, cnae)
      }
      this.editando = false
      this._abrirFecharCadastroEdicao()
      this._ordenarListaPorData()
      this._atualizarEmpresaComCnaeMaisRecente()
    },

    _ordenarListaPorData () {
      this.cnaes.sort(compara)
      function compara (a, b) {
        return new Date(a.dataInicial).getTime() - new Date(b.dataInicial).getTime()
      }
    },

    _atualizarEmpresaComCnaeMaisRecente () {
      const ultimoCnae = this.cnaes[this.cnaes.length - 1]
      if (ultimoCnae.cnae !== this.itemEmpresa.codigoCNAE) {
        this.$emit('salvarCNAEEmpresa', ultimoCnae.cnae)
      }
    },

    async _excluir (cnae) {
      if (this._checarSePodeRealizarAExclusao()) {
        const resposta = await service.delete('/api/v1/empresa-historico', cnae)
        if (resposta.ok) {
          const itemIndex = this.cnaes.findIndex(item => item.id === cnae.id)
          this.cnaes.splice(itemIndex, 1)
        }
      } else {
        this.avisoVisivel = true
        this.textoAviso = 'É necessário manter pelo menos uma ocorrência no histórico do CNAE.'
      }
      this._ordenarListaPorData()
      this._atualizarEmpresaComCnaeMaisRecente()
    },

    _checarSePodeRealizarAExclusao () {
      return (this.cnaes.length > 1)
    },

    _editar (cnae) {
      Object.assign(this.item, cnae)
      this.editando = true
      this.item.dataInicial = utils.formatarDataParaExibicao(this.item.dataInicial)
      this._abrirFecharCadastroEdicao()
    },

    _retornaDataFormatada (data) {
      return utils.formatarDataParaExibicaoMesAno(utils.formatarDataParaExibicaoNoDataTable(data))
    },

    _abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    }
  },
  created () {
    for (const i in this.cnaes) {
      this.cnaes[i] = {
        ...this.cnaes[i],
        id: this.cnaes[i].empresaID + '-' + this.cnaes[i].dataInicial.replaceAll('-', '')
      }
    }
    this._ordenarListaPorData()
  }

}
</script>

<style>

</style>
