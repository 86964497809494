export default class Empresa {
  constructor () {
    this.cep = ''
    this.logradouro = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.localidade = ''
    this.idMunicipio = ''
    this.uf = ''
    this.codigoIBGE = ''
  }
}
