<template>
  <div class="field column is-12 p-0">
    <irrf />
  </div>
</template>

<script>
import irrf from '../../Irrf/index.vue'

export default {
  components: {
    irrf
  }
}
</script>

<style>
</style>
