export default class Contador {
  constructor () {
    this.id = null
    this.nome = null
    this.cpf = null
    this.rg = null
    this.orgaoExpedidorRG = null
    this.dataEmissaoRG = null
    this.crc = null
    this.ufCRC = null
    this.qualificacao = null
    this.dhp = null
    this.validadeDHP = null
    this.telefone = null
    this.email = null
    this.codigoCVMAuditor = null
  }

  static validar (contador, validadores, editando, erros) {
    const erro = false
    erros.cadastroContador = erro
    return erros
  }
}
