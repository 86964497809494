<template>
  <Modal
    v-if="this.modalVisivel"
    class="modalCnae"
    title="Cadastro CNAE"
    id="cadastroEdicaoCnaeSecundario"
    :active="this.modalVisivel"
    @close="_cancelar();"
    >
    <div class="columns is-multiline">
      <div class="field column is-6">
        <input-data
          id="dataNire"
          v-model="this.itemCNAE.dataInicial"
          label="Referência"
          type="month"
          :message="erros.data"
          :status="erros.data ? 'error' : ''"
          @update:erro="erros.data = $event"
        />
      </div>
      <div class="field column is-6">
        <input-texto
          id="codigo"
          label="CNAE"
          maxlength="7"
          v-model="this.itemCNAE.cnae"
          :modelValue="this.itemCNAE.cnae"
          :status="this.getStatus(erros.codigo)"
          :message="erros.codigo"
          @update:erro="erros.codigo = $event.message"
          @update:modelValue="this.itemCNAE.cnae = $event"
        >
          <template v-slot:addon>
            <a class="button" @click="abrirFecharPesquisaCNAE">
              <span class="icon"><span class="fas fa-search"></span></span>
            </a>
          </template>
        </input-texto>
      </div>
    </div>
    <template v-slot:footer>
      <ButtonAjuda style="margin-right: auto" />
      <Button
        class="button is-primary"
        @click="this._salvarCnae"
        caption="Gravar"
        style="margin-right: 1rem"
      />
      <Button class="button" @click="_cancelar()" caption="Cancelar" />
    </template>
  </Modal>
  <pesquisa-cnae
    :modalVisivel="abrirConsultaCNAES"
    @fechar="this.abrirFecharPesquisaCNAE"
    @carregarItemSelecionado2="_preencherCNAESelecionado"
  />
</template>

<script>
import { service } from '../../../service.js'
import EmpresaHistorico from './model/empresa-historico.js'
import PesquisaCNAE from './pesquisaCnae.vue'
import Empresa from '../model/empresa.js'

import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'

import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import ButtonAjuda from '../../../components/alt-component/ButtonAjuda/index.vue'

const ERROS = {
  codigo: '',
  data: ''
}

export default {
  name: 'cadastroEdicaoHistoricoNire',
  components: {
    'pesquisa-cnae': PesquisaCNAE,
    'input-texto': InputText,
    'input-data': InputDate,
    Modal,
    Button,
    ButtonAjuda
  },
  emits: ['salvarHistoricoCNAE', 'save', 'cancel'],
  props: {
    modalVisivel: Boolean,
    itemEmpresa: Empresa,
    cnae: EmpresaHistorico,
    cadastrandoPelaAbaGeral: Boolean
  },
  mixins: [mixin],
  data () {
    return {
      itemCNAE: this.cnae,
      abrirConsultaCNAES: false,
      erros: ERROS,
      idAntesDaEdicao: null
    }
  },

  methods: {
    async _salvarCnae () {
      this.itemCNAE.empresaID = this.itemEmpresa.cdempresa
      this.itemCNAE.dataInicial = this.formatar(this.itemCNAE.dataInicial)
      if (!this.cadastrandoPelaAbaGeral) {
        try {
          if (this._validarInformacoesCadastro()) {
            service
              .save('/api/v1/empresa-historico', this.itemCNAE)
              .then(this.responseToSave)
          }
        } catch (e) {
          Object.assign(this.erros, JSON.parse(e.message))
        }
      } else {
        try {
          if (this._validarInformacoesCadastro()) {
            this.$emit('salvarHistoricoCNAE', this.itemCNAE)
          }
        } catch (e) {
          Object.assign(this.erros, JSON.parse(e.message))
        }
      }
    },

    _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      this.erros = ERROS
      let ocorreuErro = false
      Object.assign(
        this.erros,
        EmpresaHistorico.validar(
          this.itemCNAE.cnae,
          this.itemCNAE.dataInicial,
          this.itemEmpresa.dataInicioAtividade
        )
      )
      if (this.erros.codigo || this.erros.data) {
        ocorreuErro = true
      }

      return !ocorreuErro
    },

    _limparRegistroDeErros () {
      this.erros.codigo = ''
      this.erros.data = ''
    },

    async responseToSave (response) {
      let json = await response.json()

      if (response.ok) {
        json = {
          ...json,
          id: this.idAntesDaEdicao
            ? this.idAntesDaEdicao
            : json.empresaID + '-' + json.dataInicial.replaceAll('-', '')
        }
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    formatar (data) {
      if (data && this.checarData(new Date(data))) {
        return new Date(data).toISOString()
      }
    },

    checarData (data) {
      return data instanceof Date && !isNaN(data)
    },

    abrirFecharPesquisaCNAE () {
      this.abrirConsultaCNAES = !this.abrirConsultaCNAES
    },

    _preencherCNAESelecionado (item) {
      this.itemCNAE.cnae = item.chamada.replace(/[^\d]+/g, '')
      this.abrirFecharPesquisaCNAE()
    },

    _cancelar () {
      this._limparRegistroDeErros()
      this.$emit('cancel')
    }
  },
  watch: {
    itemCNAE: {
      handler: function (val, old) {
        if (val && val.dataInicial) {
          this.itemCNAE.dataInicial =
            val.dataInicial.substring(0, 4) +
            '-' +
            val.dataInicial.substring(5, 7)
          if (!this.idAntesDaEdicao) {
            this.idAntesDaEdicao = this.cnae.id
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.alt-modal-card-title {
  margin-bottom: 0 !important;
}
</style>
