import { validadorDeInscricaoFederal } from '../../../util/InscricaoFederal.js'
import { utils } from '../../../util/utils.js'
import paisesBasico from '../json/paises.json'
import paisesDeclaracoes from '@packonline/packonline-reutilizaveis-frontend/Utils/Listas/Paises.json'
import { sociosService } from '../service/socios-service'
import InscricaoEstadual from '../../../util/inscricao-estadual/InscricaoEstadual.js'

export default class Socio {
  constructor (codigo = null) {
    this.id = null
    this.inscricaoCpfCnpj = null
    this.cdsocio = codigo
    this.nmsocio = null
    this.basico = {
      endereco: null,
      bairro: null,
      cidade: null,
      cep: null,
      uf: null,
      codigoIBGE: null,
      telefone: null,
      email: null,
      paisCodigo: null,
      complemento: null,
      numero: null,
      tipo: null,
      usuariosWmail: null
    }
    this.complementar = {
      numeroIdentidade: null,
      orgaoExpeditorIdentidade: null,
      dataEmissaoIdentidade: null,
      nomeResponsavel: null,
      cpfResponsavel: null,
      dataValidadeCNH: null,
      expedicaoCNH: null,
      orgaoEmissorCNH: null,
      numeroCNH: null,
      ufCNH: null,
      categoriaCNH: null,
      ufCTPS: null,
      serieCTPS: null,
      numeroCTPS: null,
      residenciaRecursoFGTS: null,
      residenciaPropria: null,
      numeroCBONovo: null,
      nomeProfissao: null,
      deficienteReabilitado: null,
      deficiente: null,
      deficienteTipo: null,
      estadoCivil: null,
      regimeMatrimonio: null,
      numeroDependentes: null,
      qualificacaoRepresentanteLegal: null
    }
    this.declaracoes = {
      dataNascimento: null,
      ufNascimento: null,
      codigoIBGENascimento: null,
      paisCodigoNascimento: null,
      nacionalidadeCodigo: null,
      dataChegada: null,
      dataNaturalizacao: null,
      casadoBrasil: null,
      filhosBrasil: null,
      sexo: null,
      racaCor: null,
      grauDeInstrucao: null,
      nomeMae: null,
      nomePai: null,
      paisCodigoESocialNascimento: null,
      paisCodigoESocialNacionalidade: null,
      tempoResidencia: null,
      condicao: null
    }
    this.observacao = {
      observacoes: null
    }
  }

  static async validar (socio, valid, editando) {
    let erros = {}
    erros = await this.validarTelaPrincipal(socio, erros, editando)
    erros = this.validarAbaBasico(socio, erros)
    erros = this.validarAbaComplementar(socio, valid, erros)
    // erros = this.validarAbaObservacoes(socio, erros)
    erros = this.validarAbaDeclaracoes(socio, valid, erros)
    // erros = this.validarAbaEmpresaVinculada(socio, erros)
    return erros
  }

  static async validarTelaPrincipal (socio, erros, editando) {
    let erro = false

    if (!socio.nmsocio) {
      erros.nome = 'Informe o nome do sócio'
      erro = true
    }
    if (!socio.inscricaoCpfCnpj) {
      erros.inscricaoCpfCnpj = 'Informe o CNPJ/CPF'
      erro = true
    } else {
      if (!validadorDeInscricaoFederal.validar(socio.inscricaoCpfCnpj)) {
        erros.inscricaoCpfCnpj = 'CNPJ/CPF inválido'
        erro = true
      } else {
        socio.inscricaoCpfCnpj = socio.inscricaoCpfCnpj.replace(/[./-]/g, '')
      }
    }

    if (!editando) {
      const retorno = await this.validarCodigoSocio(socio.cdsocio, erros)
      if (!retorno) {
        erro = true
      }
    }

    erros.telaPrincipal = erro
    return erros
  }

  static validarAbaBasico (socio, erros) {
    let erro = false

    if (socio.basico.uf !== 'EX') {
      if (!socio.basico.cep) {
        erros.cep = 'Informe o CEP'
        erro = true
      } else if (socio.basico.cep.length < 9) {
        erros.cep = 'CEP inválido'
        erro = true
      }
    } else {
      if (socio.basico.cep) {
        if (socio.basico.cep.length < 9) {
          erros.cep = 'CEP inválido'
          erro = true
        }
      }
    }

    if (socio.basico.email != null) {
      if (!utils.isEmail(socio.basico.email)) {
        erros.email = 'E-mail inválido'
        erro = true
      }
    }

    if (socio.basico.usuariosWmail != null) {
      if (!utils.isEmail(socio.basico.usuariosWmail)) {
        erros.usuariosWmail = 'E-mail inválido'
        erro = true
      }
    }

    if (socio.basico.paisCodigo) {
      const response = utils.findCountry(socio.basico.paisCodigo, paisesBasico.data)
      if (response === undefined) {
        erros.paisCodigo = 'País não encontrado'
        erro = true
      }
    }
    erros.abaBasico = erro
    return erros
  }

  static validarAbaComplementar (socio, valid, erros) {
    let erro = false

    if (socio.complementar.cpfResponsavel) {
      if (!validadorDeInscricaoFederal.validarCPF(socio.complementar.cpfResponsavel)) {
        erros.cpfResponsavel = 'CPF inválido'
        erro = true
      }
    }

    if (socio.complementar.numeroCNH || socio.complementar.ufCNH || socio.complementar.categoriaCNH ||
      socio.complementar.expedicaoCNH || socio.complementar.dataValidadeCNH || socio.complementar.orgaoEmissorCNH) {
      if (this._existeCampoCNHSemPreenchimento(socio)) {
        if (!socio.complementar.numeroCNH) {
          erros.numeroCNH = 'Deve ser informado'
        }
        if (!socio.complementar.ufCNH) {
          erros.ufCNH = 'Deve ser informado'
        }
        if (!socio.complementar.categoriaCNH) {
          erros.categoriaCNH = 'Deve ser informado'
        }
        if (!socio.complementar.expedicaoCNH) {
          erros.expedicaoCNH = 'Deve ser informado'
        }
        if (!socio.complementar.dataValidadeCNH) {
          erros.dataValidadeCNH = 'Deve ser informado'
        }
        if (!socio.complementar.orgaoEmissorCNH) {
          erros.orgaoEmissorCNH = 'Deve ser informado'
        }

        erro = true
      }
    }

    if (socio.complementar.numeroCBONovo) {
      if (!socio.complementar.nomeProfissao) {
        erros.numeroCBONovo = 'CBO inválido'
        erro = true
      }
    }

    if (socio.complementar.ufCTPS || socio.complementar.serieCTPS || socio.complementar.numeroCTPS) {
      if (this._existeCampoCTPSSemPreenchimento(socio)) {
        if (!socio.complementar.ufCTPS) {
          erros.ufCTPS = 'Deve ser informado'
        }
        if (!socio.complementar.serieCTPS) {
          erros.serieCTPS = 'Deve ser informado'
        }
        if (!socio.complementar.numeroCTPS) {
          erros.numeroCTPS = 'Deve ser informado'
        }

        erro = true
      }
    }

    if (socio.complementar.deficiente) {
      if (!socio.complementar.deficienteTipo) {
        erros.deficienteTipo = 'Deve ser informado'
        erro = true
      }
    }

    if (valid.validadorDataEmissao === false) {
      erros.dataEmissaoIdentidade = 'Informe uma data válida'
      erro = true
    }

    if (valid.validadorDataExpedicao === false) {
      erros.expedicaoCNH = 'Informe uma data válida'
      erro = true
    }

    if (valid.validadorDataValidade === false) {
      erros.dataValidadeCNH = 'Informe uma data válida'
      erro = true
    }

    if (this._validarNumeroIdentidade(socio, erros)) {
      erro = true
    }

    erros.abaComplementar = erro
    return erros
  }

  static _existeCampoCNHSemPreenchimento (socio) {
    return !(socio.complementar.numeroCNH && socio.complementar.ufCNH && socio.complementar.categoriaCNH &&
      socio.complementar.expedicaoCNH && socio.complementar.dataValidadeCNH && socio.complementar.orgaoEmissorCNH)
  }

  static _existeCampoCTPSSemPreenchimento (socio) {
    return !(socio.complementar.ufCTPS && socio.complementar.serieCTPS && socio.complementar.numeroCTPS)
  }

  static _validarNumeroIdentidade (socio, erros) {
    let erro = false
    if (socio.inscricaoCpfCnpj && socio.inscricaoCpfCnpj.length > 12) {
      if (socio.basico.uf) {
        if (socio.complementar.numeroIdentidade) {
          const res = new InscricaoEstadual().validarIE(socio.basico.uf, socio.complementar.numeroIdentidade)
          if (!res) {
            erros.inscricaoEstadual = 'Informe uma inscrição estadual válida ou digite ISENTO'
            erro = true
          }
        }
      }
    } else if (!socio.inscricaoCpfCnpj) {
      if (!socio.basico.uf) {
        erros.inscricaoEstadual = 'Selecione a UF antes de preencher a inscrição estadual'
      }
    }
    return erro
  }

  static validarAbaDeclaracoes (socio, valid, erros) {
    let erro = false
    const paisesNormalizados = this.normalizarListaDePaises()

    if (socio.declaracoes.dataChegada) {
      if (!utils.isDataValida(new Date(socio.declaracoes.dataChegada))) {
        erros.dataChegada = 'Informe uma data válida'
        erro = true
      }
    }
    if (socio.declaracoes.dataNaturalizacao) {
      if (!utils.isDataValida(new Date(socio.declaracoes.dataNaturalizacao))) {
        erros.dataNaturalizacao = 'Informe uma data válida'
        erro = true
      }
    }

    if (valid.validadorDataChegada === false) {
      erros.dataChegada = 'Informe uma data válida'
      erro = true
    }

    if (valid.validadorDataNaturalizacao === false) {
      erros.dataNaturalizacao = 'Informe uma data válida'
      erro = true
    }

    if (validadorDeInscricaoFederal.validarCPF(socio.inscricaoCpfCnpj)) {
      if (!socio.declaracoes.sexo) {
        erros.sexo = 'Informe o sexo'
        erro = true
      }

      if (!socio.declaracoes.racaCor) {
        erros.racaCor = 'Informe a raça e cor'
        erro = true
      }

      if (!socio.declaracoes.grauDeInstrucao) {
        erros.grauDeInstrucao = 'Informe o grau de instrução'
        erro = true
      }

      if (!socio.declaracoes.paisCodigoESocialNacionalidade) {
        erros.paisCodigoESocialNacionalidade = 'Informe o país da nacionalidade'
        erro = true
      } else {
        if (socio.declaracoes.paisCodigoESocialNacionalidade !== 105) {
          const res = utils.findCountry(socio.declaracoes.paisCodigoESocialNacionalidade, paisesNormalizados.data)
          if (!res) {
            erros.paisCodigoESocialNacionalidade = 'País não encontrado'
            erro = true
          } else {
            if (!socio.declaracoes.tempoResidencia) {
              erros.tempoResidencia = 'Informe o tempo de residência'
              erro = true
            }
            if (!socio.declaracoes.condicao) {
              erros.condicao = 'Informe a condição'
              erro = true
            }
          }
        }
      }

      if (socio.declaracoes.paisCodigoESocialNascimento) {
        const res = utils.findCountry(socio.declaracoes.paisCodigoESocialNascimento, paisesNormalizados.data)
        if (!res) {
          erros.paisCodigoESocialNascimento = 'País não encontrado'
          erro = true
        }
      } else {
        erros.paisCodigoESocialNascimento = 'Informe o país de nascimento'
        erro = true
      }

      if (!socio.declaracoes.dataNascimento) {
        erros.dataNascimento = 'Informe uma data válida'
        erro = true
      } else {
        const dataAtual = new Date()
        if (!utils.isDataValida(new Date(socio.declaracoes.dataNascimento))) {
          erros.dataNascimento = 'Informe uma data válida'
          erro = true
        }
        if (new Date(socio.declaracoes.dataNascimento) > dataAtual) {
          erros.dataNascimento = 'Data deve ser menor que a data atual'
          erro = true
        }
        if (!socio.declaracoes.nacionalidadeCodigo) {
          erros.nacionalidadeCodigo = 'Informe a nacionalidade'
          erro = true
        }
      }
    }

    erros.abaDeclaracoes = erro
    return erros
  }

  static async validarCodigoSocio (codigo, erros) {
    let erro = false
    try {
      const cod = await sociosService.get(`/api/v1/socio/${codigo}`)
      if (cod) {
        erros.codigo = 'Código já cadastrado'
        erro = true
        return !erro
      } else {
        erros.codigo = ''
        return !erro
      }
    } catch (error) {
      erros.codigo = ''
      return !erro
    }
  }

  static async proximoCodigo () {
    const resposta = await sociosService.get('/api/v1/socio-proximo-codigo')
    return resposta.codigo
  }

  static normalizarListaDePaises () {
    return {
      data: paisesDeclaracoes.map(pais => ({
        cod: Number(pais.codigo),
        nmpais: pais.nome
      }))
    }
  }
}
