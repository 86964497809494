<template>
  <div v-if="this.modalVisivel" id="pesquisaSocio">
    <div v-if="page.total === 0">
        <div
          class="is-flex is-align-items-center is-justify-content-center"
          style="height: 55px; width: 200px"
        >
          <div class="box is-flex is-align-items-center is-justify-content-center" style="background-color: lightgoldenrodyellow; width: 100%; height: 50%;">
            <p class="is-size-6">Não existem dados para visualizar</p>
          </div>
        </div>
      </div>
    <pesquisa-padrao
    customSearch
    ref="pesquisaSocios"
    :titulo="'Pesquisa Sócios'"
    :propDataSource="this.dataSource"
    :propItens="this.socios"
    :pHideButtonMore="this.hideButtonMore"
    :pstatus="this.status"
    :pQuantidadeDeItems="this.page.total"
    :customHighlighters="this.customHighlighters"
    :nextPage="this._carregarListagem"
    :botaoCadastro="this.botaoCadastro"
    @carregarItemSelecionado="this.carregarItemSelecionado"
    @fechar="fecharPesquisa"
    @search="aoPesquisar"/>
  </div>
</template>

<script>
import PesquisaPadrao from '../../../components/Pesquisa'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'

export default {
  name: 'pesquisaSocio',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },
  mixin: [mixinFocoPrimeiroElementoDataTable, mixinPesquisa],
  props: {
    modalVisivel: Boolean,
    agrupamento: String,
    botaoCadastro: Object
  },
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'cdsocio',
            label: 'Código',
            style: 'width:15%',
            format: this._formatarCodigo
          },
          {
            name: 'nmsocio',
            label: 'Nome',
            style: 'width:85%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      socios: [],
      page: [],
      termoPesquisado: '',
      status: '',
      pagina: 0,
      shortkeys: shortkeys,
      hideButtonMore: false
    }
  },

  methods: {
    async _carregarListagem (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.page = await service.get(`/api/v1/socio?page=${pagina}`)
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this.socios = this.page.content
          this.status = ''
        } catch {
          this.status = 'is-danger'
        }
      }
    },
    async buscar (search, pagina) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.socios = []
          const page = await service.get(
            `/api/v1/socio/search?q=${encodeURIComponent(search)}&page=${pagina}&sort=cdsocio`
          )
          this.page = page
          this.pagina = pagina
          this.socios = this.page.content
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },
    fecharPesquisa () {
      this.$emit('fechar')
    },
    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },

    carregarItemSelecionado (socio) {
      this.$emit('carregarItemSelecionado', socio)
    },

    ordenarGridPorCodigo () {
      this.socio.sort(function (a, b) {
        if (a.cdsocio === b.cdsocio) {
          return 0
        } else {
          return a.cdsocio > b.cdsocio ? 1 : -1
        }
      })
    },
    ligarAtalhos () {
      const section = 'pesquisaSocio'
      this.addShortcut(section, this.shortkeys.KEY_BUSCAR, this.focarNaPesquisa)
      this.addShortcut(section, this.shortkeys.KEY_ESC, this.fecharPesquisa)
    },
    _formatarCodigo (valor) {
      return utils.strZero(valor, 5)
    }
  },
  watch: {
    async modalVisivel (val) {
      if (val) {
        await this.aoPesquisar('')
      }
    }
  },
  beforeUnmount () {
    this.setActiveSection('')
  },

  created () {
    this.termoPesquisado = ''
    this.ligarAtalhos()
    this._carregarListagem()
  }
}
</script>

<style scoped>

tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}

</style>
