import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { CNPJ_CPF_INVALIDO, CODIGO_INVALIDO, VALOR_INVALIDO } from '../../../../util/message.js'
import qualificacaoAssinante from '../data/qualificacaoAssinante.json'
import { validadorDeInscricaoFederal } from '../../../../util/InscricaoFederal'

export default class Socemp {
  constructor () {
    this.idEmpresaSocio = null
    this.codigoSocio = null
    this.alCapital = null
    this.valorCapitalSocio = null
    this.stGerente = null
    this.stResponsavelCgc = null
    this.valorProLabore = null
    this.codigoIndex = null
    this.dataAdmissao = null
    this.dataDemissao = null
    this.codigoPhdDos = null
    this.numeroCbo = null
    this.tipoIdentificadorResponsavel = null
    this.stEntraRais = null
    this.codigoRetencaoIrrf = null
    this.numeroCboNovo = null
    this.nmProcurador = null
    this.nrCpfProcurador = null

    // Aba Receita Federal
    this.alCapitalVotante = null
    this.stQualificacao = null
    this.stQualificacaoSped = null
    this.ecfEcdQualificacaoCodigo = null
    this.qualificacaoSpedComplemento = null

    this.principal_Ecf = null
    this.motivo_Desligamento = null
    this.nomeSocio = null
  }

  static validar (objetoVinculo, objetoEmpresa, itemSocio) {
    let erros = {}
    erros = this.validarAbaBasico(objetoVinculo, erros, objetoEmpresa)
    erros = this.validarAbaReceitaFederal(itemSocio, objetoVinculo, erros)
    erros = this.validarAbaProcurador(objetoVinculo, erros)
    return erros
  }

  static validarAbaBasico (objetoVinculo, erros, objetoEmpresa) {
    let erro = false
    if (!objetoVinculo.codigoSocio) {
      erros.codigoSocio = 'Informe o sócio'
      erro = true
    }

    if (!objetoVinculo.dataAdmissao) {
      erros.dataAdmissao = 'Informe a data de admissão'
      erro = true
    } else {
      erro = !this.validarDataAdmissao(objetoVinculo.dataAdmissao, objetoEmpresa.dataInicioAtividade, erros)
    }

    if (objetoVinculo.dataDemissao) {
      erro = !this.validarDataDemissao(objetoVinculo.dataDemissao, objetoVinculo.dataAdmissao, objetoEmpresa.dataInicioAtividade, erros)
    }

    if (!objetoVinculo.codigoRetencaoIrrf) {
      erros.codigoRetencaoIrrf = 'Informe o código de retenção'
      erro = true
    }

    if (!objetoVinculo.numeroCboNovo) {
      erros.numeroCboNovo = 'Informe o o número do CBO'
      erro = true
    }

    erros.abaBasicoSocemp = erro
    return erros
  }

  static validarDataAdmissao (dataAdmissao, dataInicioAtividade, erros) {
    if (new Date(dataAdmissao) < new Date('1900-01-01')) {
      // erros.dataAdmissao = 'Data inválida'
      return false
    } else {
      if (!(dataAdmissao >= utils.formatarDataParaExibicao(dataInicioAtividade))) {
        erros.dataAdmissao = 'Informe uma data superior ou igual ao início da atividade da empresa'
        return false
      } else {
        return true
      }
    }
  }

  static validarDataDemissao (dataDemissao, dataAdmissao, dataInicioAtividade, erros) {
    if (new Date(dataDemissao) < new Date('1900-01-01')) {
      // erros.dataDemissao = 'Data inválida'
      return false
    } else {
      if (!(dataDemissao >= utils.formatarDataParaExibicao(dataInicioAtividade))) {
        erros.dataDemissao = 'Informe uma data superior ou igual ao início da atividade da empresa'
        return false
      } else {
        if ((!(dataDemissao >= dataAdmissao))) {
          erros.dataDemissao = 'Informe uma data superior ou igual a data de admissão'
          return false
        } else {
          return true
        }
      }
    }
  }

  static validarSomatorioTotalDasPorcentagensDosValoresDeCapital (socioEmEdicao, listaSociosVinculados, alCapital, editando, nomeSocio) {
    let sociosPorcentagens = ''
    let textoAviso = null
    let valorLido = 0
    let soma = 0
    listaSociosVinculados.forEach(item => {
      valorLido = parseFloat(item.alCapital) || 0
      if (parseInt(socioEmEdicao) === parseInt(item.codigoSocio)) {
      } else {
        if (soma === 0) {
          soma += parseFloat(alCapital) + parseFloat(item.alCapital) || 0
          if ((parseFloat(alCapital) > 0) && (parseFloat(socioEmEdicao) !== parseFloat(item.codigoSocio))) {
            sociosPorcentagens = nomeSocio + ': ' + parseFloat(alCapital).toString().replace('.', ',') + '%</br>'
          }
        } else {
          if (parseFloat(valorLido) > 0) {
            soma += parseFloat(valorLido)
          }
        }
        if (parseFloat(valorLido) > 0) {
          sociosPorcentagens = sociosPorcentagens + item.nomeSocio + ': ' + parseFloat(valorLido).toString().replace('.', ',') + '%</br>'
        }
      }
    })
    if (soma > 100) {
      textoAviso = `Com este valor informado, a soma dos percentuais dos sócios vinculados  a empresa ultrapassa <b>100%</b>.<br>
      Confira abaixo o valor informado para os sócios:</br>`
      textoAviso = `${textoAviso}</br> ${sociosPorcentagens}`
    }
    return textoAviso
  }

  static validarAbaReceitaFederal (itemSocio, socEmp = Socemp, erros = {}) {
    let erro = false
    if (socEmp.alCapitalVotante) {
      let porcentagemVot = ''
      if (socEmp.alCapitalVotante.toString().includes(',')) {
        porcentagemVot = parseFloat(socEmp.alCapitalVotante.replace(',', '.'))
      } else {
        porcentagemVot = socEmp.alCapitalVotante.toFixed(2)
      }
      if (!(porcentagemVot >= 0.0 || porcentagemVot <= 100.0)) {
        erros.alCapitalVotante = VALOR_INVALIDO
        erro = true
      }
    }

    if (socEmp.ecfEcdQualificacaoCodigo) {
      const arrCode = []
      if (itemSocio.cdsocio) {
        if (itemSocio.basico.paisCodigo) {
          if (itemSocio.basico.paisCodigo === 105) {
            if (itemSocio.inscricaoCpfCnpj.length === 11) {
              arrCode.push(...[1, 2, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18])
            } else {
              arrCode.push(...[3, 4, 18])
            }
          } else {
            if (itemSocio.inscricaoCpfCnpj.length === 11) {
              arrCode.push(...[5, 6, 14, 18])
            } else {
              arrCode.push(...[7, 8, 18])
            }
          }
        } else {
          socEmp.ecfEcdQualificacaoCodigo = ''
          erro = true
        }
      }
      if (!arrCode.find(e => Number(e) === Number(socEmp.ecfEcdQualificacaoCodigo))) {
        erros.ecfEcdQualificacaoCodigo = CODIGO_INVALIDO
        erro = true
      }
    }
    if (socEmp.stQualificacaoSped && !qualificacaoAssinante.find(e => Number(e.codigo) === Number(socEmp.stQualificacaoSped))) {
      erros.stQualificacaoSped = CODIGO_INVALIDO
      erro = true
    }

    erros.abaReceitaFederal = erro
    return erros
  }

  static validarAbaProcurador (socEmp = Socemp, erros = {}) {
    let erro = false

    if (socEmp.nrCpfProcurador && !socEmp.nmProcurador) {
      erros.nmProcurador = 'Informe o nome do procurador'
      erro = true
    }

    if (socEmp.nmProcurador && !socEmp.nrCpfProcurador) {
      erros.nrCpfProcurador = 'Informe o CNPJ/CPF'
      erro = true
    }

    if (socEmp.nrCpfProcurador && !validadorDeInscricaoFederal.validar(socEmp.nrCpfProcurador)) {
      erros.nrCpfProcurador = CNPJ_CPF_INVALIDO
      erro = true
    }

    erros.abaProcurador = erro
    return erros
  }
}
