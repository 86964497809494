<template>
  <div
    v-if="this.abaVisivel"
    class="is-flex is-flex-direction-column is-desktop"
  >
    <div class="column is-full">
      <div id="socemps">
        <DataTableBasico
          delete-button-caption="Remover"
          :pStatus="status"
          :key="dataKey"
          :pDataSource="dataSource"
          :pData="socemps"
          :pHideButtonMore="false"
          :div-style="divComputed"
          :pQuantidadeDeItems="page.total"
          :nextPage="_carregarListagem"
          :pSortedBy="'cdsocio'"
          :paginacao="false"
          :modal-delecao-visivel="() => false"
          :idDivUtilizadaPaginacao="'socemps'"
          :disabled="itemEmpresa.empresaAtiva"
          custom-search
          @search="aoPesquisar"
        >
          <template v-slot:new-item>
            <Button id="botaoVinculo" :disabled="itemEmpresa.empresaAtiva" class="button" :title="shortkeys.MSG_KEY_NOVO" @click="cadastrarNovo"  @keydown.shift.tab.prevent="abaHandler('logomarca', null, '#input-file-input')">Novo vínculo</Button>
          </template>
          <template #status="{ item }">
            <div>
              <Tag
                v-if="
                  item.dataDemissao === null ||
                  item.dataDemissao === '' ||
                  item.dataDemissao === undefined
                "
                caption="Ativo"
                class="is-info is-light"
                style="width: 50%"
              />
              <Tag v-else caption="Desligado" class="is-neutral is-light" />
            </div>
          </template>
        </DataTableBasico>
      </div>
    </div>
  </div>
  <ModalMessage
    ref="foco"
    datagroup="-modal-excluir-socio-vinculado"
    ok-caption="Remover sócio vinculado"
    cancel-type="primary"
    :message="mensagem"
    :active="showModalExclusao"
    @close="aoFechar"
  />
</template>

<script>

import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Socemp from './model/socemp'
import Button from '@alterdata/component-vue/Button'
import Tag from '@alterdata/component-vue/Tag'
import Empresa from '../model/empresa.js'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { searchJson } from '@packonline/packonline-reutilizaveis-frontend/Utils/search-json'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'

const API_HISTORICO_RETENCAO = '/api/v1/historico-retencao'

export default {
  name: 'app',
  components: {
    DataTableBasico,
    Tag,
    Button,
    ModalMessage
  },
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    exibindoCadastroSecundario: Boolean,
    listaVinculosNovosEditados: Array
  },
  mixins: [mixin, mixinPesquisa, mixinModalSaida, mixinFocoPrimeiroElementoDataTable],
  inject: ['abaHandler', 'setActiveSection', 'addShortcut', 'atualizarHistoricosDeRetencaoDoBackend'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'codigoSocio',
            label: 'Código',
            format: this._formatarCodigoSocemp,
            style: 'width:15%'
          },
          {
            name: 'nomeSocio',
            label: 'Nome',
            style: 'width:45%'
          },
          {
            name: 'status',
            label: 'Status',
            style: 'width:17%;text-align:center',
            styleItems: 'text-align: center',
            slot: true
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: null
      },
      page: [],
      socemps: [],
      socempsSearch: [],
      socios: [],
      cadastrandoOuEditando: false,
      editando: false,
      item: new Socemp(),
      status: '',
      hideButtonMore: false,
      listagemVisivel: true,
      showTitle: false,
      pagina: 0,
      indexadores: [],
      dataKey: 0,
      termoPesquisado: '',
      preDelete: undefined,
      shortkeys: shortkeys,
      showModalExclusao: false,
      historicosDeRetencao: []
    }
  },
  watch: {
    listaVinculosNovosEditados: {
      handler: function (val, old) {
        const arrEditado = utils.cloneDeep(this.socemps)
        val.forEach((item) => {
          const itemIndex = arrEditado.findIndex(
            (itemSocemp) => itemSocemp.codigoSocio === item.codigoSocio
          )
          if (itemIndex > -1) {
            arrEditado.splice(itemIndex, 1)
          }
          arrEditado.push(item)
        })
        const arrOrdenado = arrEditado.sort((a, b) => a.codigoSocio - b.codigoSocio)
        this.socemps = utils.cloneDeep(arrOrdenado)
        this.$root.dadosAlterados = true
      },
      deep: true
    },
    abaVisivel: {
      handler: function (val) {
        if (val) {
          if (this.socemps.length > 0) {
            this.focarNoPrimeiroElementoDataTable()
          } else {
            this.focarNoBotao()
          }
        }
      },
      flush: 'post'
    }
  },
  computed: {
    divComputed () {
      return this.socemps.length > 0 ? '' : 'height: -20px'
    },
    mensagem () {
      if (this.preDelete) {
        return `Deseja remover o sócio vinculado "${utils.strZero(this.preDelete.codigoSocio, 5)} - ${this.preDelete.nomeSocio}" desta empresa?`
      }
      return ''
    }
  },
  methods: {
    async _carregarListagem (pagina = 0) {
      this.pagina = pagina
      this.status = 'is-loading'
      this.socemps = []
      try {
        const result = await service.get(
          `/api/v1/socemp/empresa/sem-page/${this.itemEmpresa.cdempresa}`
        )
        if (result.length === 0) {
          this.socemps = []
          this.status = ''
        } else {
          this.socemps = result
          this.socempsSearch = utils.cloneDeep(this.socemps)
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },
    _formatarCodigoSocemp (codigo) {
      return utils.strZero(codigo, 5)
    },
    prepararExclusao (item) {
      this.preDelete = item
      this.definirAtalhoModalExclusao()
      this.showModalExclusao = true
      this.$nextTick(() => {
        this.selecionarCancelarOuGravarModal()
      })
    },
    aoFechar (event) {
      if (event === 'ok') {
        this.excluir(this.preDelete)
      }
      this.showModalExclusao = false
      this.setActiveSection('listagemSociosVinculados')
    },
    async excluir (socemp) {
      if (this.item.codigoSocio === socemp.codigoSocio) {
        const cancelar = document.querySelector('#cancelar_vinculo_socio')
        if (cancelar) {
          cancelar.click()
        }
      }
      const itemIndex = this.socemps.findIndex(
        (item) => item.codigoSocio === socemp.codigoSocio
      )
      const arrayEdicao = utils.cloneDeep(this.socemps)
      arrayEdicao.splice(itemIndex, 1)
      this.socemps = utils.cloneDeep(arrayEdicao)
      this.$root.dadosAlterados = true
      if (this.socemps.length < 1) {
        this.page.total = 0
        this.dataKey++
      }
      this._ordenarGridPorCodigo()
      this.$emit('armazenarVinculoASerExcluido', socemp)
    },

    editar (socemp) {
      this.item = new Socemp()
      Object.assign(this.item, socemp)
      this.editando = true
      this.abrirFecharEdicao()
    },

    cadastrarNovo () {
      this.editando = false
      this.item = new Socemp()
      this.abrirFecharEdicao()
    },

    abrirFecharEdicao () {
      this._abrirFecharCadastroVinculo()
    },

    _atualizarListaAposCadastro () {
      this._carregarListagem()
      this.abrirFecharEdicao()
    },

    _ordenarGridPorCodigo () {
      this.socios.sort(function (a, b) {
        return a.cdsocio < b.cdsocio ? -1 : a.cdsocio > b.cdsocio ? 1 : 0
      })
    },
    aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      this.buscar(this.termoPesquisado)
    },
    buscar (pesquisa) {
      this.status = 'is-loading'
      try {
        this.socemps = []
        if (!pesquisa) {
          this.socemps = utils.cloneDeep(this.socempsSearch)
          this.status = ''
        } else {
          const result = searchJson.searchInJsonSemPage(pesquisa, this.socempsSearch)
          this.socemps = result
          this.status = ''
        }
      } catch (err) {
        this.statstatususModal = 'is-danger'
      }
    },

    _abrirFecharCadastroVinculo () {
      this.$emit(
        'onAbrirCadastroVinculoSocio',
        'Vínculo empresas X sócios',
        this.item,
        this.indexadores,
        this.socemps,
        this.historicosDeRetencao
      )
    },

    async _carregarIndexadores () {
      service.get('/api/v1/indexadores').then((response) => {
        const page = response
        this.indexadores = page.content
      })
    },
    focarNoBotao () {
      this.$nextTick(() => {
        const botao = document.querySelector('button#botaoVinculo')
        if (botao) {
          botao.focus()
        }
      })
    },
    fecharModal () {
      this.showModalExclusao = false
      this.setActiveSection('listagemSociosVinculados')
    },
    definirAtalhoModalExclusao () {
      const section = 'modalExclusaoSociosVinculados'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => {
        this.fecharModal()
      })
    },
    definirAtalhoListagemSocios () {
      const section = 'listagemSociosVinculados'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrarNovo)
      this.addShortcut(section, shortkeys.KEY_BUSCAR, this.focarNaPesquisa)
    },

    async _buscarHistoricosDeRetencaoNoBackend () {
      const url = `${API_HISTORICO_RETENCAO}/por-empresa/${this.itemEmpresa.cdempresa}`

      try {
        this.historicosDeRetencao = await service.get(url)
        if (this.historicosDeRetencao) {
          this.historicosDeRetencao.map((item) => {
            item.dataAlteracao = item.dataAlteracao ? item.dataAlteracao : ''
            item.codigoRetencaoAnterior = item.codigoRetencaoAnterior ? item.codigoRetencaoAnterior.toString().padStart(4, '0') : ''
            item.codigoRetencaoAtual = item.codigoRetencaoAtual ? item.codigoRetencaoAtual.toString().padStart(4, '0') : ''
            item.codigoPaisAnterior = item.codigoPaisAnterior ? item.codigoPaisAnterior.toString().padStart(3, '0') : ''
            item.codigoPais = item.codigoPais ? item.codigoPais.toString().padStart(3, '0') : ''
            return item
          })
        }
      } catch (err) {
        this.historicosDeRetencao = []
      }
      this.$emit('atualizarHistoricosDeRetencaoDoBackend', this.historicosDeRetencao)
    }
  },

  created () {
    this._carregarListagem()
    this._carregarIndexadores()
    this.definirAtalhoListagemSocios()
    this._buscarHistoricosDeRetencaoNoBackend()
  }

}
</script>

<style lang="scss" scoped>
</style>
