<template>
  <a href="https://ajuda.alterdata.com.br/x/8cAfC" target="_blank">
    <Button class="button" caption="Ajuda" icon="question-circle" tabindex="-1"/>
  </a>
</template>

<script>
import Button from '@alterdata/component-vue/Button/index.vue'

export default {
  components: {
    Button
  }
}
</script>
