<template>
  <ToastAviso
    v-if="exibeAviso"
    :type="statusTeste"
    :title="statusTeste === 'warning' ? titulo : ''"
    @avisoVisivel="exibeAviso = false"
  >
    <div v-if="statusTeste === 'success'">
      <p>{{ subTitulo }}</p>
    </div>
  </ToastAviso>
  <div v-show="this.abaVisivel" id="abaBasico">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div class="field column is-4" style="padding-bottom: 0rem; margin: 0">
          <validador-cpf-cnpj
            id="cnpj"
            v-model="this.basico.numeroCGC"
            type="CNPJ/CPF"
            :message="this.erros.cnpj"
            :status="this.getStatus(this.erros.cnpj)"
            :required="this.editando"
            :disabled="this.itemEmpresa.empresaAtiva || this.jaEnviouESocial"
            @keydown.shift.tab.prevent=""
            @update:erro="this.erros.cnpj = $event.message"
          />
          <p
            class="is-italic"
            style="font-size: 0.75rem; margin-top: -4px"
            v-if="this.jaEnviouESocial && this.erros.cnpj !== mensagemValidacaoESocial"
          >
          {{mensagemValidacaoESocial}}
          </p>
        </div>
        <div class="field column is-8" style="padding-bottom: 0rem; margin: 0">
          <button
            class="button"
            v-show="this._mostraBotaoImportarCNPJ()"
            v-bind:class="{ 'is-loading': spinRequest }"
            style="position: absolute; top: 33px"
            :disabled="this.erros.cnpj ? true : false"
            @click="this._executarConsultaCNPJ(this.basico.numeroCGC)"
          >
            <span class="icon"><span class="fas fa-file-import"></span></span>
            &nbsp;&nbsp;&nbsp;&nbsp; Importar dados cadastrais da RFB
          </button>
        </div>
        <div class="field column is-2" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="codigo"
            label="Código"
            data-group="dtgp-codigo-empresa"
            @update:erro="this.erros.cdempresa = $event.message"
            :status="this.getStatus(this.erros.cdempresa)"
            :message="this.erros.cdempresa"
            v-model="this.basico.cdempresa"
            :key="key"
            maxlength="5"
            :disabled="this.editando || this.itemEmpresa.empresaAtiva"
            @keypress="this.onlyNumber()"
            @change="this._retornarCodigoEmpresaFormatado"
            @blur="this._validarCodigo"
            @update:modelValue="this.basico.cdempresa = $event"
            :modelValue="his.basico.cdempresa"
            required
          />
        </div>
        <div class="field column is-10" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="razao"
            label="Nome"
            :message="erros.nome"
            @update:erro="this.erros.nome = $event.message"
            :status="this.getStatus(this.erros.nome)"
            maxlength="150"
            v-model="this.basico.nomeCompleto"
            @update:modelValue="this.basico.nomeCompleto = $event"
            :modelValue="this.basico.nomeCompleto"
            @change="this.aplicarNomeCompleto()"
            :disabled="this.itemEmpresa.empresaAtiva"
            required
          />
        </div>
        <div class="field column is-7" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="nomeFantasia"
            label="Nome fantasia"
            :message="erros.nomeFantasia"
            @update:erro="this.erros.nomeFantasia = $event.message"
            :status="this.getStatus(this.erros.nomeFantasia)"
            @update:modelValue="this.basico.nomeFantasia = $event"
            maxlength="50"
            v-model="this.basico.nomeFantasia"
            :modelValue="this.basico.nomeFantasia"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div class="field column is-5" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="atividade"
            label="Atividade"
            @update:erro="this.erros.descricaoAtividade = $event.message"
            :status="this.getStatus(this.erros.descricaoAtividade)"
            maxlength="30"
            @update:modelValue="this.basico.descricaoAtividade = $event"
            :modelValue="this.basico.descricaoAtividade"
            v-model="this.basico.descricaoAtividade"
            :message="this.erros.descricaoAtividade"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <checkbox
          v-if="this.editando"
          type="checkbox"
          class="field column is-12"
          caption="Empresa desativada"
          v-model="this.basico.empresaAtiva"
        />
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="endereco" style="font-size: 1rem"
            ><strong>Endereço</strong></label
          >
        </div>
        <endereco-postal
          :pEndereco="this.endereco"
          :editando="this.editando"
          :pInputsMaxLength="this.inputsMaxLength"
          :pModulo="'cadastro-empresa'"
          :errosValidacao="this.erros"
          :erroMunicipio="'municipioBasico'"
          :municipio_required="true"
          @erroCEP="this.erros.cep = $event"
          @carregarObjeto="this.carregarObjetoEndereco"
          @exitLogradouro="this.validarTamanhoDoLogradouro"
          @exitNumero="this.validarTamanhoDoNumero"
          :disabled="this.itemEmpresa.empresaAtiva"
          @change-municipio="this.endereco.localidade"
          :autoExec="1"
          :key="key"
        />
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="contato" style="font-size: 1rem"
            ><strong>Contato</strong></label
          >
        </div>
        <div class="field column is-3" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="telefone"
            maxlength="11"
            minlength="10"
            label="Telefone"
            @update:erro="this.erros.telefone = $event.message"
            :status="this.getStatus(this.erros.telefone)"
            type="text"
            v-model="this.basico.telefone"
            :message="erros.telefone"
            :value="this.basico.telefone"
            @focusout.prevent="this.limpaCaracteres"
            @change.prevent="this.limpaCaracteres"
            :modelValue="this.basico.telefone"
            @update:modelValue="this.basico.telefone = $event"
            @keypress="this.onlyNumber($event, true)"
            required
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
        <div
          class="field column is-3 is-hidden"
          style="padding-bottom: 0rem; margin: 0"
        >
          <label class="label" for="fax">Fax</label>
          <div class="control">
            <input
              id="fax"
              class="input"
              type="text"
              v-model="this.basico.numeroFax"
            />
          </div>
          <p class="help is-danger">{{ erros.fax }}</p>
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="email"
            name="email"
            label="E-mail"
            data-group="email-cadastro-basico"
            @keydown.prevent.enter.exact="$emit('navToAbaGeral')"
            :maxlength="this.inputsMaxLength.email"
            @update:erro="this.erros.email = $event.message"
            :status="this.getStatus(this.erros.email)"
            type="text"
            v-model="this.basico.nomeEmail"
            :message="erros.email"
            :value="this.basico.nomeEmail"
            :modelValue="this.basico.nomeEmail"
            @update:modelValue="this.basico.nomeEmail = $event"
            @keypress="this.onlyEmail()"
            @blur="this.isEmail"
            :disabled="this.itemEmpresa.empresaAtiva"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@alterdata/component-vue/Checkbox/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import enderecoPostal from '../../components/EnderecoPostal/index.vue'
import ToastAviso from '../../components/ToastAviso'
import ValidadorCpfCnpj from '../../components/ValidadorCpfCnpj/index.vue'
import { validadorDeInscricaoFederal } from '../../util/InscricaoFederal.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import Empresa from './model/empresa.js'
import { empresaService as service } from './service/empresa-service'
// import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import ConsultaCNPJ from './ConsultaCNPJ/consultaCNPJ.js'

export default {
  name: 'abaBasico',

  components: {
    Checkbox,
    'endereco-postal': enderecoPostal,
    'campo-texto': InputText,
    'validador-cpf-cnpj': ValidadorCpfCnpj,
    ToastAviso
  },
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa,
    errosValidacao: Object,
    editando: Boolean,
    montarObjetoApiCnpj: Function
  },
  mixins: [mixin],
  data () {
    return {
      mostraBotaoCnpj: false,
      spinRequest: false,
      botaoConsultaCNPJ: Boolean,
      basico: {
        numeroCGC: this._retornarCPFCNPJFormatado(this.itemEmpresa.numeroCGC),
        cdempresa: this._retornarCodigoFormatado(this.itemEmpresa.cdempresa),
        nome: this.itemEmpresa.nome,
        nomeCompleto: this.itemEmpresa.nomeCompleto
          ? this.itemEmpresa.nomeCompleto
          : this.itemEmpresa.nome,
        nomeFantasia: this.itemEmpresa.nomeFantasia,
        descricaoAtividade: this.itemEmpresa.descricaoAtividade,
        empresaAtiva: this.itemEmpresa.empresaAtiva,
        cep: this.itemEmpresa.cep,
        logradouro: '',
        numero: '',
        enderecoCompleto: this.itemEmpresa.enderecoCompleto,
        enderecoComplemento: this.itemEmpresa.enderecoComplemento,
        bairro: this.itemEmpresa.bairro,
        bairroCompleto: this.itemEmpresa.bairroCompleto,
        uf: this.itemEmpresa.uf,
        cidade: this.itemEmpresa.cidade,
        idMunicipio: this.itemEmpresa.idMunicipio,
        cdMunicipio: this.itemEmpresa.cdMunicipio,
        telefone: this.itemEmpresa.telefone,
        numeroFax: this.itemEmpresa.numeroFax,
        nomeEmail: this.itemEmpresa.nomeEmail,
        erroLogradouroOuNumero: false
      },
      endereco: {
        cep: this.itemEmpresa.cep,
        logradouro: this.itemEmpresa.logradouro,
        numero: this.itemEmpresa.numero,
        enderecoCompleto: this.itemEmpresa.enderecoCompleto,
        complemento: this.itemEmpresa.enderecoComplemento,
        bairro: this.itemEmpresa.bairro,
        bairroCompleto: this.itemEmpresa.bairroCompleto
          ? this.itemEmpresa.bairroCompleto
          : this.itemEmpresa.bairro,
        uf: this.itemEmpresa.uf,
        localidade: this.itemEmpresa.cidade,
        idMunicipio: this.itemEmpresa.idMunicipio,
        cdMunicipio: this.itemEmpresa.cdMunicipio
      },
      inputsMaxLength: {
        logradouro: 38,
        numero: 10,
        bairro: 50,
        complemento: 60,
        email: 255
      },
      erros: this.errosValidacao,
      key: 0,
      jaEnviouESocial: false,
      mensagemValidacaoESocial: 'Esta empresa já foi enviada para o eSocial, portanto não é possível efetuar a alteração do CNPJ/CPF'
    }
  },
  emits: {
    navToAbaGeral: null
  },
  computed: {
    logradouro: function () {
      return this.endereco.logradouro
    },
    numero: function () {
      return this.endereco.numero
    }
  },
  watch: {
    basico: {
      handler: function (val, old) {
        if (val.numeroFax) {
          this.erros.fax = ''
        }

        if (val.nome) {
          this.erros.nome = ''
        }

        if (val.cep) {
          this.erros.cep = ''
        }

        if (val.logradouro) {
          if (this.erros.logradouro) {
            if (this.erros.logradouro.length < 60) {
              this.erros.logradouro = ''
            }
          }
        }

        if (val.bairro) {
          this.erros.bairro = ''
        }

        if (val.uf) {
          this.erros.uf = ''
        }
        this.montarObjeto(val)
      },
      deep: true
    },
    telefone: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.telefone = ''
        }
      }
    },
    nomeEmail: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.email = ''
        }
      }
    },
    numeroCGC: {
      immediate: true,
      handler (val) {
        if (val) {
          this.erros.cnpj = ''
        }
      }
    }
  },
  methods: {
    async _executarConsultaCNPJ (cnpj) {
      if (!cnpj) {
        return false
      }
      const limpaCnpj = cnpj.replace(/[^\d]+/g, '')
      const consulta = new ConsultaCNPJ(limpaCnpj)
      this.spinRequest = true
      await consulta._ConsultaCNPJ(
        limpaCnpj,
        this.resetaCampos,
        this.resetBotao,
        this.atribuiDadosPagosAosCampos,
        this.atribuiDadosGratuitosAosCampos,
        this.adicionarNotificacao,
        service
      )
    },
    resetBotao () {
      this.botaoConsultaCNPJ = true
      this.spinRequest = false
    },
    atribuiDadosPagosAosCampos (objetoJson) {
      this.basico.nomeFantasia = objetoJson.contribuinte.nomeFantasia.substring(
        0,
        50
      )
      this.basico.nomeCompleto = objetoJson.contribuinte.razaoSocial.substring(
        0,
        50
      )
      this.endereco.logradouro = objetoJson.contribuinte.lougradouro.substring(
        0,
        38
      )
      this.endereco.numero = objetoJson.contribuinte.numero.substring(0, 10)
      this.endereco.complemento = objetoJson.contribuinte.complemento.substring(
        0,
        60
      )
      this.endereco.uf = objetoJson.contribuinte.uf
      this.endereco.idMunicipio = objetoJson.contribuinte.idMunicipio
      this.endereco.cep = objetoJson.contribuinte.cep.replace('.', '')
      this.basico.telefone = objetoJson.contribuinte.telefone
        .replaceAll(/[^\d]+/g, '')
        .substring(0, 11)
      this.basico.nomeEmail = objetoJson.contribuinte.email.substring(0, 255)
      this.endereco.bairroCompleto = objetoJson.contribuinte.bairro.substring(
        0,
        50
      )
      this.carregarNomeMunicipioViaApi(
        this._capitalize(objetoJson.contribuinte.municipio)
      )
      this.basico.descricaoAtividade =
        objetoJson.contribuinte.atividadePrincipal.descricao.substring(0, 30)
      this.montarObjetoApiCnpj(objetoJson)
      this.key += 1
      this.aplicarBairroCompleto()
      this.aplicarNomeCompleto()
      this.montarObjetoBasicoComEndereco()
      this.spinRequest = false
    },
    atribuiDadosGratuitosAosCampos (objetoJson) {
      this.basico.nomeFantasia = utils.campoVazio(
        objetoJson.fantasia.substring(0, 50)
      )
        ? ''
        : objetoJson.fantasia.substring(0, 50)
      this.basico.nomeCompleto = utils.campoVazio(
        objetoJson.nome.substring(0, 50)
      )
        ? ''
        : objetoJson.nome.substring(0, 50)
      this.endereco.logradouro = objetoJson.logradouro.substring(0, 38)
      this.endereco.numero = objetoJson.numero.substring(0, 10)
      this.endereco.complemento = objetoJson.complemento.substring(0, 60)
      this.endereco.uf = objetoJson.uf
      this.endereco.idMunicipio = objetoJson.municipio
      this.endereco.cep = objetoJson.cep.replace('.', '')
      this.basico.telefone = objetoJson.telefone
        .replaceAll(/[^\d]+/g, '')
        .substring(0, 11)
      this.basico.nomeEmail = objetoJson.email.substring(0, 255)
      this.endereco.bairroCompleto = objetoJson.bairro.substring(0, 50)
      this.carregarNomeMunicipioViaApi(this._capitalize(objetoJson.municipio))
      const descricao = []
      objetoJson.atividade_principal.forEach((p) => {
        descricao.push(p.text)
      })

      this.basico.descricaoAtividade = descricao[0].substring(0, 30)
      this.montarObjetoApiCnpj(objetoJson)
      this.key += 1
      this.aplicarBairroCompleto()
      this.aplicarNomeCompleto()
      this.montarObjetoBasicoComEndereco()
      // this.$forceUpdate()
      this.spinRequest = false
    },

    resetaCampos () {
      this.basico.nomeFantasia = ''
      this.basico.nomeCompleto = ''
      this.endereco.logradouro = ''
      this.endereco.numero = ''
      this.endereco.complemento = ''
      this.endereco.uf = ''
      this.endereco.idMunicipio = ''
      this.endereco.cep = ''
      this.basico.telefone = ''
      this.basico.nomeEmail = ''
      this.endereco.bairroCompleto = ''
      this.endereco.localidade = ''
      this.basico.descricaoAtividade = ''
      this.key += 1
    },
    async carregarNomeMunicipioViaApi (nomeMunicipio) {
      if (nomeMunicipio) {
        const response = await service.get(
          `/api/v1/municipio/nome-municipio-sem-acento?nmMunicipio=${nomeMunicipio}`
        )
        this.endereco.codigoIBGE = response.codMunicipioIbge
        this.endereco.localidade = response.nmMunicipio
      }
    },
    _capitalize (string) {
      string = string.toLowerCase()
      let retorno = ''
      const separa = string.split(' ')
      for (const element of separa) {
        if (
          element === 'da' ||
          element === 'de' ||
          element === 'do'
        ) {
          retorno =
            retorno + element.charAt(0).toLowerCase() + element.slice(1) + ' '
        } else {
          retorno =
            retorno + element.charAt(0).toUpperCase() + element.slice(1) + ' '
        }
      }
      return retorno.trim()
    },
    _mostraBotaoImportarCNPJ () {
      if (this.basico.numeroCGC) {
        const dadoRecebido = this.basico.numeroCGC.replace(/[^\d]+/g, '')
        if (dadoRecebido.length === 11) {
          this._validarCPFCNPJBotao()
          if (!this.erros.cnpj) {
            return this.mostraBotaoCnpj
          }
        }
        if (dadoRecebido.length === 14) {
          this._validarCPFCNPJBotao()
          if (!this.erros.cnpj) {
            return !this.mostraBotaoCnpj
          }
        }
      }
    },
    setFocusMunicipio () {
      const input = document.querySelector('input#municipio')
      if (input && input.focus) {
        input.focus()
      }
    },
    _retornarCodigoFormatado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },

    _retornarCodigoEmpresaFormatado (e) {
      this.basico.cdempresa = this._retornarCodigoFormatado(e.target.value)
      this.key += 1
    },

    async _validarCodigo (codigo) {
      const zeros = ['0', '00', '000', '0000', '00000']
      try {
        if (codigo.target._value === '') {
          this.erros.cdempresa = 'Informe o código'
          return null
        }
        if (zeros.includes(codigo.target._value)) {
          this.erros.cdempresa = 'Código inválido'
          return null
        }
        const cod = await service.get(`/api/v1/empresa/${codigo.target.value}`)
        this.$emit('verificarCodigo', cod)
        if (cod) {
          this.erros.cdempresa = 'Código já cadastrado'
        } else {
          if (!this.erros.cdempresa) {
            this.erros.cdempresa = ''
          }
        }
      } catch (err) {
        return false
      }
    },

    onlyNumber (e, noPoint = false) {
      return utils.onlyNumber(e, noPoint)
    },

    onlyEmail (e) {
      return utils.emailFormat(e)
    },

    isEmail (e) {
      const email = utils.isEmail(e.target.value)
      if (!email) {
        if (!this.erros.email) {
          this.erros.email = 'E-mail inválido'
        }
      }
    },

    isTelefone (e) {
      const tel = utils.isTelefone(e.target.value)
      if (!tel) {
        if (!this.erros.telefone) {
          this.erros.telefone =
            e.target.value !== '' ? 'Telefone inválido' : 'Informe o telefone'
        }
      }
    },

    limpaCaracteres (e) {
      this.erros.telefone = ''
      this.basico.telefone = e.target.value.replaceAll(/[^\d]+/g, '')
      this.isTelefone({ target: { value: this.basico.telefone } })
    },

    _aplicarMascaraCPFCNPJ () {
      this.basico.numeroCGC = this._retornarCPFCNPJFormatado(
        this.basico.numeroCGC
      )
    },
    _retornarCPFCNPJFormatado (inscricao) {
      if (inscricao) {
        inscricao = inscricao.replace(/[^0-9]+/g, '')
        if (inscricao.length === 14) {
          return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricao)
        }
        if (inscricao.length === 11) {
          return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricao)
        }
      } else {
        return null
      }
    },

    aplicarNomeCompleto () {
      if (this.basico.nomeCompleto) {
        if (this.basico.nomeCompleto.length <= 50) {
          this.basico.nome = this.basico.nomeCompleto
        } else {
          this.basico.nome = this.basico.nomeCompleto.substring(0, 50)
        }
      } else {
        this.basico.nome = this.basico.nomeCompleto
      }
    },

    aplicarBairroCompleto (bairro) {
      if (bairro) {
        if (bairro.length <= 15) {
          this.basico.bairroCompleto = bairro
          this.basico.bairro = bairro
        } else {
          if (bairro.length <= 50) {
            this.basico.bairroCompleto = bairro
          } else {
            this.basico.bairroCompleto = bairro.substring(0, 50)
          }
          this.basico.bairro = bairro.substring(0, 15)
        }
      } else {
        this.basico.bairro = null
        this.basico.bairroCompleto = null
      }
    },

    carregarObjetoEndereco (endereco) {
      Object.assign(this.endereco, endereco)
      this.montarObjetoBasicoComEndereco()
    },
    montarObjetoBasicoComEndereco () {
      this.basico.cep = this.endereco.cep
      this.basico.logradouro = this.endereco.logradouro
      this.basico.enderecoCompleto = this.endereco.enderecoCompleto
      this.basico.numero = this.endereco.numero
      this.basico.enderecoComplemento = this.endereco.complemento
      this.aplicarBairroCompleto(this.endereco.bairroCompleto)
      this.basico.uf = this.endereco.uf
      this.basico.cidade = this.endereco.localidade
      this.basico.idMunicipio = this.endereco.idMunicipio
      this.basico.codigoIBGE = this.endereco.codigoIBGE
    },
    carregarNumeroLogradouro () {
      if (
        this.itemEmpresa.logradouro &&
        this.itemEmpresa.logradouro.includes(',')
      ) {
        var el = this.itemEmpresa.logradouro.split(',')
        if (el.length > 1) {
          this.basico.logradouro = el[0]
          this.basico.numero = el[1].replace(' ', '')
          this.endereco.logradouro = el[0]
          this.endereco.numero = el[1].replace(' ', '')
        }
      }
    },
    validarTamanhoDoLogradouro () {
      const endero = `${
        this.endereco.logradouro ? this.endereco.logradouro : ''
      }, ${this.endereco.numero ? this.endereco.numero : ''}`
      if (endero.length === 40) {
        this.erros.logradouro =
          'A soma dos campos Logradouro e Número deve ter no máximo 38 caracteres.'
        this.erros.numero = '⠀⠀⠀'
        setTimeout(() => {
          this.erros.logradouro = ''
          this.erros.numero = ''
        }, 10000)
        this.erroLogradouroOuNumero = true
      }
      this.erroLogradouroOuNumero = false
    },
    validarTamanhoDoNumero () {
      const endero = `${
        this.endereco.logradouro ? this.endereco.logradouro : ''
      }, ${this.endereco.numero ? this.endereco.numero : ''}`
      if (endero.length === 40) {
        this.erros.logradouro =
          'A soma dos campos Logradouro e Número deve ter no máximo 38 caracteres.'
        this.erros.numero = '⠀⠀⠀'
        setTimeout(() => {
          this.erros.logradouro = ''
          this.erros.numero = ''
        }, 10000)
        this.erroLogradouroOuNumero = true
      }
      this.erroLogradouroOuNumero = false
    },
    _validarCPFCNPJBotao () {
      if (
        this.erros.cnpj !==
        'O conteúdo do campo foi removido, pois continha caracteres inválidos.'
      ) {
        if (
          this.basico.numeroCGC === '' ||
          this.basico.numeroCGC === null ||
          this.basico.numeroCGC === undefined
        ) {
        } else {
          if (!validadorDeInscricaoFederal.validar(this.basico.numeroCGC)) {
          } else {
            this.erros.cnpj = ''
            this._aplicarMascaraCPFCNPJ()
          }
        }
      }
    },
    _validarCPFCNPJ () {
      if (
        this.erros.cnpj !==
        'O conteúdo do campo foi removido, pois continha caracteres inválidos.'
      ) {
        if (
          this.basico.numeroCGC === '' ||
          this.basico.numeroCGC === null ||
          this.basico.numeroCGC === undefined
        ) {
          this.erros.cnpj = 'Informe o CNPJ/CPF'
        } else {
          if (!validadorDeInscricaoFederal.validar(this.basico.numeroCGC)) {
            this.erros.cnpj = 'CNPJ/CPF inválido'
          } else {
            this.erros.cnpj = ''
            this._aplicarMascaraCPFCNPJ()
          }
        }
      }
    },

    inicializarErros () {
      this.erros.logradouro = ''
      this.erros.numero = ''
    },

    async verificarSeAEmpresaEnviouDadosESocial () {
      if (this.editando) {
        await service.get(
          `/api/v1/empresa/verificar-dados-e-social?codigo=${Number(this.basico.cdempresa)}`
        ).then(result => {
          this.jaEnviouESocial = result
        })
      }
    }
  },

  mounted () {
    this.carregarNumeroLogradouro()
    this.inicializarErros()
    this.verificarSeAEmpresaEnviouDadosESocial()
    setTimeout(() => {
      if (this.exibirFechar !== false && this.modalVisivel) {
        this.exibirFechar = this.item.empresaAtiva
      }
      this.focarNoPrimeiroInput('abaBasico')
    }, 250)
  }
}
</script>
<style scoped>
#municipio {
  width: 10%;
}

.is-size-6.is-italic {
  font-size: 0.85rem !important;
  font-weight: 400 !important;
}
</style>
