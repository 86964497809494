<template>
  <div id="abaComplementaSocio">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <label class="label column is-12" for="documentos" style="font-size: 1rem; padding-bottom: 0rem; margin: 0">
          <strong>{{ this.cpfInformado ? 'Identidade' : 'Documentos' }}</strong>
        </label>
        <div
          v-if="this.cpfInformado"
          class="field column is-5"
          style="width: 415px"
        >
          <input-texto
            id="identidade"
            maxlength="15"
            label="Número"
            v-model="this.complementar.numeroIdentidade"
            :status="this.getStatus(erros.inscricaoEstadual)"
            :message="erros.inscricaoEstadual"
            @update:erro="erros.inscricaoEstadual = $event.message"
            @keypress="this.inscricaoEstadualIdentidadeFormat()"
          />
        </div>
        <div v-else class="field column is-5" style="width: 415px">
          <inscricao-estadual-verificador
            label="Inscrição estadual"
            @change-inscricao-estadual="this.verificaInscricaoEstadual"
            :uf="this.itemSocio.basico.uf"
            :p-inscricaoEstadual="this.complementar.numeroIdentidade"
            :erros="this.erros"
            maxlength="15"
          />
        </div>
        <div
          :class="
            this.cpfInformado
              ? 'field column is-6 is-hidden'
              : 'field column is-6'
          "
        >
          <validador-cpf-cnpj
            id="cpfResponsavel"
            type="CPF"
            label="CPF do responsável"
            v-model="this.complementar.cpfResponsavel"
            :status="this.getStatus(this.erros.cpfResponsavel)"
            :message="erros.cpfResponsavel"
            @update:erro="erros.cpfResponsavel = $event.message"
          />
        </div>
        <div
          :class="
            this.cpfInformado
              ? 'field column is-11 is-hidden'
              : 'field column is-11'
          "
        >
          <input-texto
            id="nomeResponsavel"
            maxlength="40"
            label="Nome do responsável"
            v-model="this.complementar.nomeResponsavel"
            :message="this.erros.nomeResponsavel"
            :status="this.getStatus(this.erros.nomeResponsavel)"
            @update:erro="this.erros.nomeResponsavel = $event.message"
          />
        </div>
        <div
          :class="
            !this.cpfInformado
              ? 'field column is-3 is-hidden'
              : 'field column is-2'
          "
          style="width: 282px"
        >
          <input-texto
            id="orgaoExpedidor"
            maxlength="10"
            label="Órgão expedidor"
            v-model="this.complementar.orgaoExpeditorIdentidade"
            :status="this.getStatus(this.erros.orgaoExpeditorIdentidade)"
            :message="this.erros.orgaoExpeditorIdentidade"
            @update:erro="this.erros.orgaoExpeditorIdentidade = $event.message"
          />
        </div>
        <div
          :class="
            !this.cpfInformado
              ? 'field column is-3 is-hidden'
              : 'field column is-3'
          "
          style="width: 190px"
        >
          <input-data
            id="dataEmissao"
            class="input"
            label="Data de expedição"
            v-model="this.complementar.dataEmissaoIdentidade"
            :model-value="this.complementar.dataEmissaoIdentidade"
            :status="this.erros.dataEmissaoIdentidade ? 'error' : ''"
            :message="erros.dataEmissaoIdentidade"
            @update:valid="this.$emit('validarDataEmissao', $event)"
            @update:erro="this.erros.dataEmissaoIdentidade = $event"
            @update:model-value="
              this.complementar.dataEmissaoIdentidade = $event
            "
          />
        </div>
        <div
          :class="
            !this.cpfInformado
              ? 'columns is-multiline is-hidden'
              : 'columns is-multiline'
          "
          style="padding-left: 0.75rem"
        >
          <label class="label column is-12" for="carteira" style="font-size: 1rem; padding-bottom: 0rem; margin: 0">
            <strong>Carteira de motorista</strong>
          </label>
          <div class="field column is-2">
            <input-texto
              id="numero"
              maxlength="14"
              label="Número"
              v-model="this.complementar.numeroCNH"
              :message="erros.numeroCNH"
              :status="this.getStatus(erros.numeroCNH)"
              @update:erro="this.erros.numeroCNH = $event.message"
            />
          </div>
          <div class="field column is-2">
            <input-texto
              id="orgaoEmissor"
              maxlength="14"
              label="Órgão expedidor"
              v-model="this.complementar.orgaoEmissorCNH"
              :message="erros.orgaoEmissorCNH"
              :status="this.getStatus(erros.orgaoEmissorCNH)"
              @update:erro="this.erros.orgaoEmissorCNH = $event.message"
            />
          </div>
          <div class="field column is-2" style="width: 90px">
            <label class="label" style="margin-bottom: 0;" for="uf">UF</label>
            <div id="uf" class="select">
              <select v-model="this.complementar.ufCNH">
                <option></option>
                <option v-for="uf in this.UFs" :key="uf">{{ uf.nome }}</option>
              </select>
            </div>
            <p class="help is-danger">{{ erros.ufCNH }}</p>
          </div>
          <div class="field column is-2" style="width: 90px">
            <label class="label" style="margin-bottom: 0;" for="categoria">Categoria</label>
            <div id="categoria" class="select">
              <select v-model="this.complementar.categoriaCNH">
                <option></option>
                <option v-for="cat in this.categorias" :key="cat">
                  {{ cat.nome }}
                </option>
              </select>
            </div>
            <p class="help is-danger">{{ erros.categoriaCNH }}</p>
          </div>
          <div class="field column is-3" style="width: 190px">
            <input-data
              id="dataExpedicao"
              class="input"
              label="Data de expedição"
              v-model="this.complementar.expedicaoCNH"
              :model-value="this.complementar.expedicaoCNH"
              :status="this.erros.expedicaoCNH ? 'error' : ''"
              :message="erros.expedicaoCNH"
              @update:valid="this.$emit('validarDataExpedicao', $event)"
              @update:erro="this.erros.expedicaoCNH = $event"
              @update:model-value="this.complementar.expedicaoCNH = $event"
            />
          </div>
          <div class="field column is-3" style="width: 190px">
            <input-data
              id="dataValidade"
              class="input"
              label="Data de validade"
              v-model="this.complementar.dataValidadeCNH"
              :model-value="this.complementar.dataValidadeCNH"
              :status="this.getStatus(this.erros.dataValidadeCNH)"
              :message="erros.dataValidadeCNH"
              @update:valid="this.$emit('validarDataValidade', $event)"
              @update:erro="this.erros.dataValidadeCNH = $event"
              @update:model-value="this.complementar.dataValidadeCNH = $event"
            />
          </div>
          <label class="label column is-12" for="carteiraTrabalho" style="font-size: 1rem; padding-bottom: 0rem; margin: 0;">
            <strong>Carteira de trabalho</strong>
          </label>
          <div class="field column is-3">
            <input-texto
              id="numeroCarteiraTrabalho"
              maxlength="11"
              label="Número"
              v-model="this.complementar.numeroCTPS"
              :message="this.erros.numeroCTPS"
              :status="this.getStatus(this.erros.numeroCTPS)"
              @update:erro="this.erros.numeroCTPS = $event.message"
            />
          </div>
          <div class="field column is-2">
            <input-texto
              id="serie"
              maxlength="4"
              label="Série"
              v-model="this.complementar.serieCTPS"
              :message="erros.serieCTPS"
              :status="this.getStatus(this.erros.serieCTPS)"
              @update:erro="this.erros.serieCTPS = $event.message"
            />
          </div>

          <div class="field column is-2" style="width: 90px">
            <label class="label" style="margin-bottom: 0;" for="ufCarteiraTrabalho">UF</label>
            <div id="ufCarteiraTrabalho" class="select">
              <select v-model="this.complementar.ufCTPS">
                <option></option>
                <option v-for="uf in this.UFs" :key="uf">{{ uf.nome }}</option>
              </select>
            </div>
            <p class="help is-danger">{{ erros.ufCTPS }}</p>
          </div>
          <div class="column is-12">
            <div class="columns is-flex is-align-items-end">
              <div class="column is-2" style="padding-right: 4px">
                <input-texto
                  id="cdCBO"
                  label="CBO 2002"
                  v-model="this.complementar.numeroCBONovo"
                  maxlength="6"
                  :status="this.getStatus(erros.numeroCBONovo)"
                  @keyup="this.parouDeDigitar"
                  @keypress="this.onlyNumber()"
                  @update:erro="erros.numeroCBONovo = $event.message"
                  @blur="this._buscarCBODigitado()"
                  @change="this._buscarCBODigitado()"
                >
                  <template v-slot:addon>
                    <a
                      class="button is-outlined"
                      :class="{
                        'is-info':
                          this.getStatus(erros.numeroCBONovo) === 'info',
                        'is-danger':
                          this.getStatus(erros.numeroCBONovo) === 'error'
                      }"
                      @click="_abrirFecharPesquisaCBO"
                    >
                      <span class="icon"
                        ><span class="fas fa-search"></span
                      ></span>
                    </a>
                  </template>
                </input-texto>
              </div>
              <div
                class="column is-5"
                style="padding-left: 0; padding-right: 0"
              >
                <input-texto
                  id="profissao"
                  disabled
                  v-model="this.complementar.nomeProfissao"
                />
              </div>
              <div class="column">
                <div
                  class="is-inline-flex is-align-items-center"
                  style="height: 40px"
                >
                  <div class="control mr-2">
                    <label class="checkbox">
                      <input
                        id="deficiente"
                        type="checkbox"
                        v-model="this.complementar.deficiente"
                      />
                      Deficiente
                    </label>
                  </div>
                  <div class="control">
                    <label class="checkbox">
                      <input
                        id="reabilitado"
                        type="checkbox"
                        v-model="this.complementar.deficienteReabilitado"
                        :disabled="!this.complementar.deficiente"
                      />
                      Reabilitado
                    </label>
                  </div>
                </div>
              </div>
              <div class="column" style="margin-bottom: 6px">
                <div class="control">
                  <label class="label" style="margin-bottom: 0;" for="tipoDeficiencia"
                    >Tipo de deficiência</label
                  >
                  <div id="tipoDeficiencia" class="select">
                    <select
                      v-model="this.complementar.deficienteTipo"
                      :disabled="!this.complementar.deficiente"
                    >
                      <option></option>
                      <option
                        v-for="def in this.deficiencias"
                        :key="def.codigo"
                        v-bind:value="def.codigo"
                      >
                        {{ def.deficiencia }}
                      </option>
                    </select>
                    <p class="help is-danger">{{ erros.deficienteTipo }}</p>
                  </div>
                </div>
              </div>
            </div>
            <p
              style="position: relative; bottom: 25px; width: 150px"
              class="help"
              :class="{
                'is-danger': getStatus(erros.numeroCBONovo) === 'error',
                'is-info': getStatus(erros.numeroCBONovo) === 'info'
              }"
            >
              {{ erros.numeroCBONovo }}
            </p>
          </div>
          <!-- <label
            class="label column is-12"
            for="infoCasaPropria"
            style="
              font-size: 1rem;
              padding-bottom: 0rem;
              margin: 0;
              padding-left: 0.8rem;
            "
            >Informações de casa própria</label
          > -->
          <label class="label column is-12" for="infoCasaPropria" style="font-size: 1rem;padding-bottom: 0rem;margin: 0;padding-left: 0.8rem;">
            <strong>Informações de casa própria</strong>
          </label>
          <div class="field column is-5">
            <label
              class="checkbox"
              style="
                padding-right: 1rem;
                padding-left: 0.1rem;
              "
            >
              <input
                id="infoCasaPropria"
                type="checkbox"
                v-model="this.complementar.residenciaPropria"
              />
              Possui casa própria
            </label>
            <label class="checkbox">
              <input
                id="recursoFGTS"
                type="checkbox"
                v-model="this.complementar.residenciaRecursoFGTS"
                :disabled="!this.complementar.residenciaPropria"
              />
              Adquirida com recurso do FGTS
            </label>
          </div>
          <label class="label column is-12" for="estadoCivil" style="font-size: 1rem; padding-bottom: 0rem;">
            <strong>Estado civil</strong>
          </label>
          <div
            class="field"
            style="
              width: 160px;
              padding-left: 0.8rem;
              padding-right: 0.8rem;
            "
          >
            <label class="label" style="margin-bottom: 0;" for="situacao">Situação</label>
            <div id="situacao" class="select">
              <select v-model="this.complementar.estadoCivil">
                <option></option>
                <option
                  v-for="s in this.situacoes"
                  :key="s.codigo"
                  v-bind:value="s.codigo"
                >
                  {{ s.situacao }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="field"
            style="
              width: 240px;
              padding-left: 0.8rem;
              padding-top: 0.75rem;
              padding-right: 0.8rem;
            "
          >
            <label class="label" style="margin-bottom: 0;" for="regimeMatrimonio"
              >Regime de matrimônio</label
            >
            <div id="regimeMatrimonio" class="select">
              <select
                v-model="this.complementar.regimeMatrimonio"
                :disabled="this.complementar.estadoCivil != 'C'"
              >
                <option></option>
                <option
                  v-for="r in this.regimesMatrimoniais"
                  :key="r.regime"
                  v-bind:value="r.regime"
                >
                  {{ r.regime }}
                </option>
              </select>
              <p class="help is-danger">{{ erros.regimeMatrimonio }}</p>
            </div>
          </div>
          <div
            class="field"
            style="
              width: 180px;
              padding-left: 0.8rem;
              padding-top: 0.75rem;
              padding-right: 0.8rem;
            "
          >
            <input-texto
              id="numeroDependentes"
              label="Número de dependentes"
              v-model="this.complementar.numeroDependentes"
              :message="erros.numeroDependentes"
              :status="this.getStatus(erros.numeroDependentes)"
              @update:erro="this.erros.numeroDependentes = $event.message"
              @keypress="this.onlyNumber()"
            />
          </div>
        </div>
        <label class="label column is-12" for="ECF" style="font-size: 1rem; padding-bottom: 0rem; margin: 0">
          <strong>ECF</strong>
        </label>
        <div class="field column is-6">
          <label class="label" style="margin-bottom: 0;" for="qualificacao"
            >Qualificação do representante legal</label
          >
          <div id="qualificacao" class="select">
            <select v-model="this.complementar.qualificacaoRepresentanteLegal">
              <option></option>
              <option
                v-for="q in this.qualificacoes"
                :key="q.codigo"
                v-bind:value="q.codigo"
              >
                {{ q.qualificacao }}
              </option>
            </select>
          </div>
          <p class="help is-danger"></p>
        </div>
      </div>
    </div>
    <pesquisa-cbo
      :modalVisivel="abrirConsultaCBO"
      @fechar="this._abrirFecharPesquisaCBO"
      @carregarItemSelecionado="this._preencherCBOSelecionado"
    />
  </div>
</template>

<script>
import { maska } from 'maska'
import jsonUfs from '../../components/PesquisaMunicipioUF/ufs.json'
import PesquisaCBO from '../../components/CBO/pesquisaCBO.vue'
import { buscarCBO } from '../../components/CBO/buscarCBO.js'
import Socio from './model/socios.js'
import { utils } from '../../util/utils.js'
import { mixin } from '../../util/mixin'
import { validadorDeInscricaoFederal } from '../../util/InscricaoFederal.js'
import InscricaoEstadualVerificador from '../../components/InscricaoEstadual/index.vue'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import ValidadorCpfCnpj from '../../components/ValidadorCpfCnpj/index.vue'

export default {
  name: 'abaGeral',
  directives: {
    maska
  },
  components: {
    'pesquisa-cbo': PesquisaCBO,
    'inscricao-estadual-verificador': InscricaoEstadualVerificador,
    'input-data': InputDate,
    'input-texto': InputText,
    'validador-cpf-cnpj': ValidadorCpfCnpj
  },
  props: {
    errosValidacao: Object,
    editando: Boolean,
    itemSocio: Socio,
    montarObjeto: Function,
    cpfInformado: Boolean
  },
  mixins: [mixin],

  data () {
    return {
      complementar: {
        numeroIdentidade: this.itemSocio.complementar.numeroIdentidade,
        orgaoExpeditorIdentidade:
          this.itemSocio.complementar.orgaoExpeditorIdentidade,
        dataEmissaoIdentidade: utils.formatarDataParaExibicao(
          this.itemSocio.complementar.dataEmissaoIdentidade
        ),
        nomeResponsavel: this.itemSocio.complementar.nomeResponsavel,
        cpfResponsavel: this.itemSocio.complementar.cpfResponsavel,
        dataValidadeCNH: utils.formatarDataParaExibicao(
          this.itemSocio.complementar.dataValidadeCNH
        ),
        expedicaoCNH: utils.formatarDataParaExibicao(
          this.itemSocio.complementar.expedicaoCNH
        ),
        orgaoEmissorCNH: this.itemSocio.complementar.orgaoEmissorCNH,
        numeroCNH: this.itemSocio.complementar.numeroCNH,
        ufCNH: this.itemSocio.complementar.ufCNH,
        categoriaCNH: this.itemSocio.complementar.categoriaCNH,
        ufCTPS: this.itemSocio.complementar.ufCTPS,
        serieCTPS: this.itemSocio.complementar.serieCTPS,
        numeroCTPS: this.itemSocio.complementar.numeroCTPS,
        residenciaRecursoFGTS:
          this.itemSocio.complementar.residenciaRecursoFGTS,
        residenciaPropria: this.itemSocio.complementar.residenciaPropria,
        numeroCBONovo: this.itemSocio.complementar.numeroCBONovo,
        nomeProfissao: this.itemSocio.complementar.nomeProfissao,
        deficienteReabilitado:
          this.itemSocio.complementar.deficienteReabilitado,
        deficiente: this.itemSocio.complementar.deficiente,
        deficienteTipo: this.itemSocio.complementar.deficienteTipo,
        estadoCivil: this.itemSocio.complementar.estadoCivil,
        regimeMatrimonio: this.itemSocio.complementar.regimeMatrimonio,
        numeroDependentes: this.itemSocio.complementar.numeroDependentes,
        qualificacaoRepresentanteLegal:
          this.itemSocio.complementar.qualificacaoRepresentanteLegal
      },
      abrirConsultaCBO: false,
      erros: this.errosValidacao,
      UFs: jsonUfs.data,
      categorias: [
        { nome: 'A' },
        { nome: 'B' },
        { nome: 'C' },
        { nome: 'D' },
        { nome: 'E' },
        { nome: 'AB' },
        { nome: 'AC' },
        { nome: 'AD' },
        { nome: 'AE' }
      ],
      deficiencias: [
        { codigo: '1', deficiencia: 'Motora' },
        { codigo: '2', deficiencia: 'Visual' },
        { codigo: '3', deficiencia: 'Auditiva' },
        { codigo: '4', deficiencia: 'Mental' }
      ],
      qualificacoes: [
        { codigo: '1', qualificacao: 'Procurador' },
        { codigo: '2', qualificacao: 'Curador' },
        { codigo: '3', qualificacao: 'Mãe' },
        { codigo: '4', qualificacao: 'Pai' },
        { codigo: '5', qualificacao: 'Tutor' },
        { codigo: '6', qualificacao: 'Outro' }
      ],
      situacoes: [
        { codigo: 'S', situacao: 'Solteiro' },
        { codigo: 'C', situacao: 'Casado' },
        { codigo: 'D', situacao: 'Divorciado' },
        { codigo: 'V', situacao: 'Viúvo' },
        { codigo: 'U', situacao: 'União Estável' },
        { codigo: 'O', situacao: 'Outros' }
      ],
      regimesMatrimoniais: [
        { regime: 'Comunhão parcial de bens' },
        { regime: 'Comunhão total de bens' },
        { regime: 'Separação total de bens' }
      ],
      timer: undefined
    }
  },
  watch: {
    complementar: {
      handler: function (val, old) {
        if (
          val.cpfResponsavel &&
          !this.cpfInformado &&
          val.cpfResponsavel.replace(/[^\d]+/g, '').length === 11
        ) {
          if (!validadorDeInscricaoFederal.validar(val.cpfResponsavel)) {
            this.erros.cpfResponsavel = 'CPF inválido'
          } else {
            this.erros.cpfResponsavel = ''
          }
        } else {
          this.erros.cpfResponsavel = ''
        }

        if (!val.residenciaPropria) {
          this.complementar.residenciaRecursoFGTS = false
        }

        if (!val.deficiente) {
          this.complementar.deficienteReabilitado = false
          this.complementar.deficienteTipo = ''
        }

        if (val.estadoCivil !== 'C') {
          this.complementar.regimeMatrimonio = ''
        }

        if (val.numeroIdentidade) {
          if (this.cpfInformado) {
            if (!utils.identidadeValida(val.numeroIdentidade)) {
              this.complementar.numeroIdentidade = null
            }
          } else {
            if (!utils.inscricaoEstadualValida(val.numeroIdentidade)) {
              this.complementar.numeroIdentidade = null
            }
          }
        }

        this.montarObjeto(val, 'complementar')
      },
      deep: true
    },
    cpfInformado: {
      handler: function (val, old) {
        const aux = new Socio()
        Object.assign(this.complementar, aux.complementar)
      },
      deep: true
    },
    'complementar.numeroCBONovo': {
      handler: function (val) {
        this.removeErrorCBO()
      }
    }
  },
  methods: {
    _abrirFecharPesquisaCBO () {
      this.abrirConsultaCBO = !this.abrirConsultaCBO
    },

    removeErrorCBO () {
      if (this.erros.numeroCBONovo) {
        this.erros.numeroCBONovo = ''
      }
    },

    parouDeDigitar (e) {
      var charCode = e.which ? e.which : e.keyCode
      var digitando = false
      clearTimeout(this.timer)

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        digitando = true
        this.timer = setTimeout(() => {
          this._buscarCBODigitado()
        }, 500)
      }

      if (
        e.target.value !== null &&
        digitando === false &&
        e.target.value !== ''
      ) {
        this.timer = setTimeout(() => {
          this._buscarCBODigitado()
        }, 500)
      }
    },

    _preencherCBOSelecionado (item) {
      this.complementar.numeroCBONovo = item.id
      this.complementar.nomeProfissao = item.nome
      this._abrirFecharPesquisaCBO()
    },

    verificaInscricaoEstadual (e) {
      this.complementar.numeroIdentidade = e
    },

    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    inscricaoEstadualIdentidadeFormat (e) {
      if (this.cpfInformado) {
        return utils.identidadeFormat(e)
      } else {
        return utils.inscricaoEstadualFormat(e)
      }
    },

    _aplicarMascaraCPFCNPJ () {
      this.complementar.cpfResponsavel = this._retornarCPFCNPJFormatado(
        this.complementar.cpfResponsavel
      )
    },

    _retornarCPFCNPJFormatado (inscricao) {
      if (inscricao) {
        inscricao = inscricao.replace(/[^0-9]+/g, '')
        if (inscricao.length === 14) {
          return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricao)
        } else {
          if (inscricao.length === 11) {
            return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricao)
          } else {
            return inscricao
          }
        }
      } else {
        return null
      }
    },

    _buscarCBODigitado () {
      this.complementar.nomeProfissao = buscarCBO.executar(
        this.complementar.numeroCBONovo
      ).nome
      if (this.complementar.numeroCBONovo) {
        if (!this.complementar.nomeProfissao) {
          this.erros.numeroCBONovo = 'CBO inválido'
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
