<template>
  <div class="field column is-12 p-0">
    <Socio />
  </div>
</template>

<script>
import Socio from '../../Socios/index.vue'

export default {
  components: {
    Socio
  }
}
</script>

<style>
</style>
