import { Service } from '../../../service'

class ContadorService extends Service {
  async delete (url, item, id, param) {
    const init = {
      headers: this.headers,
      method: 'DELETE'
    }
    let idDelete = null
    if (id) {
      idDelete = id
    } else {
      idDelete = item.id
    }
    const res = fetch(this.api + url + '/' + idDelete + `?confirmacao=${param}`, init)
    return res
  }
}

export const contadorService = new ContadorService()
