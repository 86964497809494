<template>
  <campo-texto
    id="inscricaoEstadual"
    v-model="this.inscricaoEstadual"
    :label="this.exibeLabel ? 'Inscrição estadual' : ''"
    :maxlength="this.maxlength"
    :status="statusMessage"
    :message="this.erros.inscricaoEstadual"
    :disabled="desabilitar || this.disabled"
    :required="required"
    @focus="this.ufExist(), (this.erro.inscricaoEstadual = '')"
    @blur="this.handleOnBlur"
    @update:erro="this.erro.inscricaoEstadual = $event.message;"
    @keypress="this.handleKeyPress"
  />
</template>

<script>
import InscricaoEstadual from '../../util/inscricao-estadual/InscricaoEstadual'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { utils } from '../../util/utils'
import { mixin } from '../../util/mixin'

const SELECIONE_UF = 'Selecione a UF antes de preencher a inscrição estadual'
const INFORME_IE = 'Informe uma inscrição estadual válida ou digite ISENTO'

export default {
  name: 'InscricaoEstadualVerificador',
  props: {
    pInscricaoEstadual: String,
    erros: Object,
    uf: String,
    exibeLabel: Boolean,
    required: Boolean,
    maxlength: {
      type: Number,
      default: 16
    },
    disabled: Boolean
  },
  components: {
    'campo-texto': InputText
  },
  mixins: [mixin],
  data () {
    return {
      inscricaoEstadual: this.pInscricaoEstadual,
      erro: this.erros,
      desabilitar: false,
      help: 'is-danger',
      mapMessage: new Map()
    }
  },
  computed: {
    statusMessage () {
      if (this.erros.inscricaoEstadual === SELECIONE_UF) {
        return 'info'
      }
      return this.getStatus(this.erros.inscricaoEstadual)
    }
  },
  methods: {
    _verificaInscricaoEstadual () {
      if (this.uf) {
        const inscricaoEstadual = new InscricaoEstadual()
        if (this.inscricaoEstadual) {
          const res = inscricaoEstadual.validarIE(
            this.uf,
            this.inscricaoEstadual
          )
          this.$emit('change-inscricao-estadual', this.inscricaoEstadual)
          return res
        } else {
          this.$emit('change-inscricao-estadual', null)
        }
        return false
      } else {
        this.erro.inscricaoEstadual = SELECIONE_UF
        return false
      }
    },
    handleKeyPress (e) {
      this._onlyIE(e)
      this.verificaInscricaoEstadual(false, false)
    },
    handleOnBlur () {
      if (this.inscricaoEstadual) {
        const regex = /^[\w./-]+$/
        if (!regex.test(this.inscricaoEstadual)) {
          this.erro.inscricaoEstadual =
            'O conteúdo do campo foi removido, pois continha caracteres inválidos.'
          this.inscricaoEstadual = ''
          this.$emit('change-inscricao-estadual', null)
          return null
        }
      }
      this.verificaInscricaoEstadual(false)
    },
    ufExist () {
      if (!this.uf) {
        this.desabilitar = true
        this.erro.inscricaoEstadual = SELECIONE_UF
        this.help = 'is-info'
      } else {
        this.desabilitar = false
        this.help = 'is-danger'
      }
    },
    verificaInscricaoEstadual (changeUf, informaErro = true) {
      this.erro.inscricaoEstadual = ''
      const res = this._verificaInscricaoEstadual()
      if (!res) {
        if (this.uf) {
          this._defineErros(changeUf, informaErro)
        } else {
          this.erro.inscricaoEstadual = informaErro ? SELECIONE_UF : ''
        }
      } else {
        this.erro.inscricaoEstadual = ''
      }
    },
    _defineErros (changeUf, informaErro) {
      if (this.inscricaoEstadual) {
        this.erro.inscricaoEstadual = informaErro ? INFORME_IE : ''
      } else {
        if (this.required) {
          if (changeUf) {
            this.erro.inscricaoEstadual = ''
          } else {
            this.erro.inscricaoEstadual = informaErro ? INFORME_IE : ''
          }
        }
      }
    },
    _onlyIE (e) {
      utils.onlyForRegex(e, /^[\w./-]+$/)
    }
  },
  watch: {
    uf () {
      this.desabilitar = false
      this.erro.inscricaoEstadual = ''
      if (this.help !== 'is-danger') {
        this.help = 'is-danger'
      }
      this.verificaInscricaoEstadual(true)
    }
  },
  mounted () {
    this.ufExist()
    this.mapMessage.set('is-info', 'info')
    this.mapMessage.set('is-danger', 'error')
    this.mapMessage.set('is-success', 'success')
  }
}
</script>

<style scoped></style>
