export const utilsScoped = {
  ligarDesligarElementos (show) {
    const titleElement = document.querySelector('h1.title')
    const arrowsElement = document.querySelector('div.arrows')
    const tabsElement = document.querySelector('div.tabs')
    const container = document.querySelector('div.container div.container')
    const divColumns = document.querySelector('div.columns div.field')

    if (show) {
      if (titleElement) {
        titleElement.style.display = ''
      }
      if (arrowsElement) {
        arrowsElement.style.display = ''
      }
      if (tabsElement) {
        tabsElement.style.display = ''
      }
      if (container) {
        container.classList.add('container')
      }
      divColumns.classList.remove('px-0')
      divColumns.classList.remove('py-0')
    } else {
      titleElement.style.display = 'none'
      arrowsElement.style.display = 'none'
      tabsElement.style.display = 'none'
      if (container) {
        container.classList.remove('container')
      }
      divColumns.classList.add('px-0')
      divColumns.classList.add('py-0')
    }
  }
}
