import { searchInJson } from '../../util/searchInJson'
import { utils } from '../../util/utils'
export const mixinPesquisaPais = {

  data () {
    return {
      totalPages: 0
    }
  },

  methods: {
    buscar (pesquisa, pagina) {
      this.status = 'is-loading'
      try {
        if (pagina === -1) {
          pagina = 0
        }
        this.paises = []
        const result = searchInJson.findBySearch(pesquisa, this.jsonPaises.data, pagina)
        this.page = result.content || []
        this.listaPaisesTamanho = result.total
        this.paises = this.page
        this.totalPages = result.totalPages
        this.status = ''
      } catch (err) {
        this.status = 'is-danger'
      }
    },

    carregar (pagina = 0) {
      this.status = 'is-loading'
      if (this.termoPesquisado) {
        this.buscar(this.termoPesquisado, pagina)
      } else {
        try {
          this.page = JSON.parse(JSON.stringify(this.jsonPaises.data))
          if (this.page.length === 0) {
            this.status = 'is-empty'
            this.paises = []
          } else {
            this.page = utils.paginate(
              JSON.parse(JSON.stringify(this.jsonPaises.data)),
              pagina + 1,
              20
            )
            this.listaPaisesTamanho = this.jsonPaises.data.length
            this.paises = this.page
            this.status = ''
          }
          this.totalPages = null
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      this.carregar()
    }
  },

  mounted () {
    this.listaPaisesTamanho = this.jsonPaises.data.length
  }
}
