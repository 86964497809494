import { service } from '../../../service.js'

class Operacoes {
  armazenarNaListaOAuditorIndependente (objeto, listaAuditoresVinculadosParaMandarParaOBackend, cdempresa, editando) {
    if (objeto.id || (editando && objeto.tempId)) {
      this.removerVinculoDaLista(objeto, listaAuditoresVinculadosParaMandarParaOBackend)
    }
    objeto.empresaId = parseInt(cdempresa, 10)
    listaAuditoresVinculadosParaMandarParaOBackend.push(objeto)
  }

  armazenarNaListaOVinculoDeAuditorASerExcluido (objeto, listaAuditoresVinculadosParaExcluirNoBackend, listaAuditoresVinculadosParaMandarParaOBackend) {
    if (objeto.id) {
      this.removerVinculoDaLista(objeto, listaAuditoresVinculadosParaMandarParaOBackend)
    }
    listaAuditoresVinculadosParaExcluirNoBackend.push(objeto)
  }

  atualizarListaDeVinculos (empresa, listaAuditoresVinculadosParaMandarParaOBackend) {
    listaAuditoresVinculadosParaMandarParaOBackend.forEach(item => {
      if (item.empresaId !== parseInt(empresa.cdempresa, 10)) {
        item.empresaId = parseInt(empresa.cdempresa, 10)
      }
    })
  }

  removerVinculoDaLista (objeto, listaAuditoresVinculadosParaMandarParaOBackend) {
    const itemIndex = listaAuditoresVinculadosParaMandarParaOBackend.findIndex(item => item.id === objeto.id || item.tempId === objeto.tempId)
    if (itemIndex > -1) {
      listaAuditoresVinculadosParaMandarParaOBackend.splice(itemIndex, 1)
    }
  }

  salvarVinculoDeAuditoresEmpresa (listaAuditoresVinculadosParaMandarParaOBackend) {
    if (listaAuditoresVinculadosParaMandarParaOBackend.length > 0) {
      service.save('/api/v1/auditor-independente/vinculos', listaAuditoresVinculadosParaMandarParaOBackend)
    }
  }

  excluirNoBackEndVinculosDeAuditores (listaAuditoresVinculadosParaExcluirNoBackend) {
    listaAuditoresVinculadosParaExcluirNoBackend.forEach(async item => {
      if (item.id) {
        await service.delete('/api/v1/auditor-independente', item)
      }
    })
  }
}

export const operacoesVinculoAuditor = new Operacoes()
