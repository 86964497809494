<template>
  <div class="is-flex is-flex-direction-column is-desktop" id="indexadores">
    <div class="column is-full">
      <page-title v-if="this.showTitle" >Indexadores</page-title>
      <div class="columm is-multiline"  >
          <div id="indexadores">
          <DataTableBasico
            ref="datatable"
            customSearch
            :key="datatableKey"
            :pStatus="this.status"
            :pDataSource="this.dataSource"
            :pData="this.indexadores"
            :pHideButtonMore="this.hideButtonMore"
            :pBotoesExternos="this.botoesExternosDataTable"
            :cliqueBotaoExterno="this._abrirListagemCotacoes"
            :pQuantidadeDeItems="this.page.total"
            :nextPage="this.carregar"
            :idDivUtilizadaPaginacao="'indexadores'"
            :modalDelecaoVisivel="this.verificarIndice"
            :customHighlighters="customHighlighters"
            @search="aoPesquisar"
            :desativarAtalhos="this.desativarAtalhosDataTable"
          >
            <template v-slot:new-item>
              <button :title="shortkeys.MSG_KEY_NOVO" data-group="button-novo" class="button is-primary" @click="this.cadastrar()">
                Novo
              </button>
            </template>
          </DataTableBasico>
        </div>
      </div>
    </div>
  </div>

  <indexador-edicao
    v-if="this.cadastrandoOuEditando"
    :item="this.item"
    :novoCadastro="this.novoCadastro"
    @cancel="this.cadastrandoOuEditando = false"
    @save="this.atualizar"
  />
  <ModalMessage ref="focoModalExclusao" ok-caption="Excluir indexador" cancel-type="primary" :message="mensagem" :active="mostrarModal" @close="aoFechar" />
</template>

<script>
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { indexadorService as service } from './service/indexador-service.js'
import Indexador from './model/indexador.js'
import IndexadorEdicao from './IndexadorEdicao.vue'
import IndexadorCotacao from '../IndexadorCotacao/index.vue'
import PageTitle from '../../components/PageTitle/index.vue'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import { toast } from 'bulma-toast'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'

export default {
  components: {
    IndexadorEdicao,
    'page-title': PageTitle,
    ModalMessage,
    DataTableBasico
  },
  mixins: [mixin, mixinFocoPrimeiroElementoDataTable, mixinPesquisa],
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'codigo',
            label: 'Código'
          },
          {
            name: 'nome',
            label: 'Descrição'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: null
      },
      botoesExternosDataTable: {
        botoes: [
          {
            id: '1',
            icone: '<span class="fas fa-dollar-sign"  data-group="button-cotacao" </span>  ',
            nome: 'Cotações'
          }
        ]
      },
      page: [],
      indexadores: [],
      cadastrandoOuEditando: false,
      novoCadastro: false,
      item: new Indexador(),
      status: '',
      hideButtonMore: false,
      idIndexadorTelaCotacao: null,
      showTitle: false,
      tituloCadastroSecundario: 'Cotações',
      exibirCadastroSecundario: false,
      abrirListagemCotacoes: false,
      tamanhoTela: 'column is-12',
      pagina: 0,
      indexador: null,
      mostrarModal: false,
      modalDelecao: true,
      preDelete: undefined,
      termoPesquisado: '',
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Indexadores',
      desativarAtalhosDataTable: false
    }
  },
  watch: {
    cadastrandoOuEditando (val) {
      this.desativarAtalhosDataTable = val
    },
    mostrarModal (val) {
      this.desativarAtalhosDataTable = val
    },
    abrirListagemCotacoes (val) {
      this.desativarAtalhosDataTable = val
    },
    '$route' (val) {
      val.fullPath === '/cadastros/indexadores' ? this._definirAtalhosListagem() : this.setActiveSection('')
    }
  },
  computed: {
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir o indexador "${this.preDelete.codigo} - ${this.preDelete.nome}"?`
      } else {
        return 'Deseja excluir o indexador ?'
      }
    }
  },
  methods: {
    async carregar (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.indexadores = []
          this.page = await service.get(`/api/v1/indexadores?page=${pagina}`)
          this.indexadores = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } catch {
          this.status = 'is-danger'
        }
      }
    },
    async buscar (search, pagina = 0) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.indexadores = []
          const page = await service.get(
            `/api/v1/indexadores/search?q=${encodeURIComponent(
              search
            )}&page=${pagina}`
          )
          this.page = page
          this.pagina = pagina
          this.indexadores = this.page.content
          this.status = ''
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
          this._ordenarGridPorCodigo()
        } else {
          await this.carregar()
          if (this.page.total === 0) {
            this.status = ''
          }
        }
        this.status = ''
        this.focarNaPesquisa()
      } catch {
        this.status = 'is-danger'
      }
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this.carregar()
    },
    async verificarIndice (indice) {
      const indexador = await service.get(`/api/v1/indexadores/${indice.codigo}`)
      this.indexador = indexador.total > 0
      return false
    },
    prepararExclusao (item) {
      if (this.indexador) {
        this.excluirIndexador(item)
      } else {
        this.mostrarModal = true
        this._definirAtalhoModalExclusao()
        this.preDelete = item
        this.$nextTick(() => {
          this.$refs.focoModalExclusao.buttonFocus({ type: 'cancel' })
        })
      }
    },
    async aoFechar (event) {
      this.setActiveSection('listagemIndexadores')
      if (event === 'ok') {
        await this.excluirIndexador(this.preDelete)
      } else {
        this.mostrarModal = false
      }
    },
    async excluirIndexador (indexador) {
      const resposta = await service.delete('/api/v1/indexadores', indexador.codigo)
      if (resposta.ok) {
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        const itemIndex = this.indexadores.findIndex(
          (item) => item.codigo === indexador.codigo
        )

        this.indexadores.splice(itemIndex, 1)
        const qtd = this.indexadores.length
        if (qtd === 0) {
          this.carregar()
          this.focarNoPrimeiroElementoDataTable()
        }
        this._ordenarGridPorCodigo()
        this.focarNoPrimeiroElementoDataTable()
        this.mostrarModal = false
      }
    },

    cadastrar () {
      this.item = new Indexador(this.proximoCodigo())
      this.novoCadastro = true
      this.abrirFecharEdicao()
      this.setActiveSection('cadastroIndexadores')
    },

    editar (indexador) {
      Object.assign(this.item, indexador)
      this.novoCadastro = false
      this.abrirFecharEdicao()
      this.setActiveSection('cadastroIndexadores')
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
    },

    atualizar (indexador) {
      this.carregar(this.pagina)
      this.abrirFecharEdicao()
    },

    proximoCodigo () {
      try {
        return utils.strZero(
          (
            parseInt(this.indexadores[this.indexadores.length - 1].codigo, 10) +
            1
          ).toString(),
          5
        )
      } catch (e) {
        return '1'.padStart(5, '0')
      }
    },

    _ordenarGridPorCodigo () {
      this.indexadores.sort(function (a, b) {
        if (a.codigo === b.codigo) {
          return 0
        } else {
          return a.codigo > b.codigo ? 1 : -1
        }
      })
    },

    _fecharCadastroSecundario () {
      this.$root.paramentrosQuickView.componenteSlotQuickView = false
      this.idIndexadorTelaCotacao = null
      this.exibirCadastroSecundario = false
      this._fecharTodosOsCadastrosSecundarios()
    },

    _fecharTodosOsCadastrosSecundarios () {
      this.abrirListagemCotacoes = false
      this.exibirCadastroSecundario = false
    },
    _abrirListagemCotacoes (indexador) {
      this.idIndexadorTelaCotacao = indexador.codigo
      this.abrirListagemCotacoes = !this.abrirListagemCotacoes
      this.exibirCadastroSecundario = !this.exibirCadastroSecundario
      const { paramentrosQuickView } = this.$root
      if (!paramentrosQuickView.componenteSlotQuickView) {
        this.limparParametrosQuickView()
        paramentrosQuickView.componenteSlotQuickView = (
          <IndexadorCotacao
            modalVisivel={this.abrirListagemCotacoes}
            idIndexador={this.idIndexadorTelaCotacao}
            fecharQuickview={this._fecharCadastroSecundario}
          />
        )
        paramentrosQuickView.eventoCancelar = this._fecharCadastroSecundario
        paramentrosQuickView.ocultarBotaoGravar = true
        paramentrosQuickView.tituloTela = 'Cotações > ' + indexador.nome
        paramentrosQuickView.estilo = 'position: fixed;top: 85%;right: 15px;width: 30%;z-index: 1002;'
        paramentrosQuickView.minimizado = false
        paramentrosQuickView.tituloBotaoFechar = 'Fechar'
        paramentrosQuickView.ocultarBotaoContinuarCadastrando = true
        paramentrosQuickView.larguraQuickView = '35vw'
        paramentrosQuickView.atalhoBotaoFechar = shortkeys.MSG_KEY_ESC
      } else {
        this.limparParametrosQuickView()
      }
    },

    _definirAtalhosListagem () {
      const section = 'listagemIndexadores'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_BUSCAR, this.focarNaPesquisa)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrar)
      this.addShortcut(section, shortkeys.KEY_MENUC, () => this._abrirListagemCotacoes(this.dataSource.itensSelected))
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _definirAtalhoModalExclusao () {
      const section = 'modalExclusaoIndexadores'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => { this.aoFechar() })
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }
  },

  mounted () {
    this.$router.currentRoute.value.fullPath === '/indexador'
      ? (this.showTitle = true)
      : (this.showTitle = false)
    this.carregar()
    this._definirAtalhosListagem()
  },

  unmounted () {
    this.setActiveSection('')
  }
}
</script>
