<template>
  <form id="empresa-vinculo-de-socio-procurador">
    <div class="columns is-multiline">
      <div class="column is-12">
        <p class="is-size-6"><strong>Informações do procurador</strong></p>
        <input-text
          id="nomeProcurador"
          label="Nome do procurador"
          v-model="socio.nmProcurador"
          maxlength="40"
          :message="this.errors.nmProcurador"
          :status="getStatus(this.errors.nmProcurador)"
          @blur="handleBlur"
          @input="handleInputNmProcurador"
          @update:modelValue="this.errors.nmProcurador = ''"
          @update:erro="this.errors.nmProcurador = $event.message"
          :required="this.socio.nrCpfProcurador !== '' && this.socio.nrCpfProcurador !== null"
          :key="this.key"
        />
      </div>
      <div class="column is-6">
        <validador-cpf-cnpj
          id="cpfProcurador"
          v-model="socio.nrCpfProcurador"
          :message="this.errors.nrCpfProcurador"
          :status="getStatus(this.errors.nrCpfProcurador)"
          @input="handleInput"
          @update:erro="this.errors.nrCpfProcurador = $event.message"
          :required="this.socio.nmProcurador !== '' && this.socio.nmProcurador !== null"
          :key="this.key"
        />
      </div>
    </div>
  </form>
</template>

<script>
import ValidadorCpfCnpj from '../../../components/ValidadorCpfCnpj'
import InputText from '@alterdata/component-vue/InputText'
import Socemp from './model/socemp'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { INFORME_CPF_CNPJ } from '../../../util/message'

const INFORME_NOME = 'Informe o nome do procurador'

export default {
  name: 'AbaProcurador',
  components: {
    'validador-cpf-cnpj': ValidadorCpfCnpj,
    'input-text': InputText
  },
  props: {
    montarObjeto: Object,
    errosValidacao: Object,
    itemSocemp: Socemp,
    editando: Boolean
  },
  data () {
    return {
      socio: this.itemSocemp,
      errors: this.errosValidacao,
      key: 0
    }
  },
  mixins: [mixin],
  methods: {
    handleInput () {
      this.errors.nrCpfProcurador = ''
      if (!this.socio.nrCpfProcurador && this.errors.nmProcurador === INFORME_NOME) {
        this.errors.nmProcurador = ''
      }
    },
    handleInputNmProcurador () {
      if (!this.socio.nmProcurador && this.errors.nrCpfProcurador === INFORME_CPF_CNPJ) {
        this.errors.nrCpfProcurador = ''
      }
    },
    handleBlur () {
      if (this.socio.nrCpfProcurador && !this.socio.nmProcurador) {
        this.errors.nmProcurador = INFORME_NOME
      }
    },
    focarPrimeiroElemento () {
      setTimeout(() => {
        const input = document.querySelector('input#nomeProcurador')
        if (input) {
          input.focus()
        } else {
          this.focarPrimeiroElemento()
        }
      }, 40)
    }
  },
  watch: {
    type () {
      this.errors.nrCpfProcurador = ''
    }
  },
  beforeMount () {
    const cpfCnpj = this.socio.nrCpfProcurador
    if (cpfCnpj) {
      this.type = utils.clearMaskNumber(cpfCnpj).length > 11 ? 'CNPJ' : 'CPF'
    }
  },
  mounted () {
    this.focarPrimeiroElemento()
  }
}
</script>

<style></style>
