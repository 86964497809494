export default class EmpresaHistorico {
  constructor () {
    this.dataInicial = null
    this.cnae = null
    this.empresaID = null
    this.id = null
  }

  static validar (codigo, data, dataInicioAtividade) {
    const erros = {}
    if (!codigo) {
      erros.codigo = 'Deve ser informado'
    }

    if (!data) {
      erros.data = 'Deve ser informado'
    } else {
      const dataCNAE = new Date(data)
      const dataInicioAtividadeEmpresa = new Date(dataInicioAtividade)
      dataCNAE.setUTCDate(1)
      dataInicioAtividadeEmpresa.setUTCDate(1)
      if (dataCNAE.toISOString() < dataInicioAtividadeEmpresa.toISOString()) {
        erros.data = 'Deve ser igual ou maior ao início da atividade da empresa'
      }
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }
}
