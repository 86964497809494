export default class EmpresaCnaeSecundario {
  constructor () {
    this.referencia = null
    this.cnae = null
    this.empresaID = null
    this.id = null
  }

  static validar (codigo, data) {
    const erros = {}
    if (!codigo) {
      erros.codigo = 'Deve ser informado'
    }

    if (!data) {
      erros.data = 'Deve ser informado'
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }
}
