<template>
<div v-if="this.abaVisivel" id="abaLogomarca">
    <div class="container" style="width:800px">
      <div class="columns is-multiline">
        <div
          v-if="!this.itemEmpresa.empresaAtiva"
          class="file"
          style="padding-right: 0.5rem"
        >
          <label class="file-label">
            <input
              ref="inputFileInput"
              id="input-file-input"
              class="file-input"
              type="file"
              name="resume"
              accept=".jpg, .png, .jpeg"
              @keydown.prevent.enter.exact="abaHandler('sócios', null, '#botaoVinculo')"
              @keydown.prevent.shift.tab.prevent="abaHandler('observacao', null, '#observacao')"
              @change="this.arquivoSelecionado"
            />
            <span class="file-cta">
              <span class="icon"><span class="fas fa-file-image"></span></span>
              <span class="file-label">Escolher imagem</span>
            </span>
          </label>
        </div>
        <button v-if="this.figura !== 'data:image/png;base64,null' && this.figura !== null && !this.itemEmpresa.empresaAtiva" id="button-excluir-imagem" tabindex="2" class="button" @click="exibirModalExclusao" @keydown.prevent.enter.exact="irParaProximaAbaVertical">Excluir imagem</button>
        <div class="column is-12">
          <div v-show="this.erro" class="notification is-danger">
            <button class="delete" @click="this.erro = ''"></button>
            {{ this.erro }}
          </div>
          <figure v-show="!this.erro && (this.figura !== 'data:image/png;base64,null' && this.figura !== null)" class="image is-128x128">
            <img :src="this.figura" alt="logomarca">
          </figure>
        </div>
      </div>
    </div>
      <div
              style="
                background-color: lightgoldenrodyellow;
                padding: 20px;
                padding-left: 1px;
                padding-right: 120px;
              "
              v-if="this.exibirAviso"
            >
              <p :style="!this.itemEmpresa.empresaAtiva ? 'font-size: 14px; margin-left: 20px; margin-bottom: 20px' : 'font-size: 14px; margin-left: 20px;'">
                Não existe logomarca salva para essa empresa.
              </p>
              <p v-if="!this.itemEmpresa.empresaAtiva" style="font-size: 14px; margin-left: 20px">
                <label class="file-label">
              Clique em
                  <input class="file-input" type="file" name="resume" accept=".jpg, .png, .jpeg" @change="this.arquivoSelecionado" >
                  <span class="file-cta " style="margin-inline: 10px;margin-bottom: 10px;">
                  Escolher imagem
                </span>
                  para salvar uma logomarca.
                </label>
              </p>
              <p v-if="!this.itemEmpresa.empresaAtiva" style="font-size: 14px; margin-left: 20px">
                As dimensões da imagem devem ter no máximo 256 X 256, e devem ser do tipo JPEG ou PNG.
              </p>
            </div>
</div>
  <div v-if="this.modalConfirmacaoVisivel" class="modal animated fadeIn is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Certo da exclusão?</p>
        </header>
        <footer class="modal-card-foot">
            <button class="button" @click="() => {this.excluirFigura(), this.exibirModalExclusao()}">Sim</button>
            <button class="button is-primary" @click="() => this.exibirModalExclusao()">Não</button>
        </footer>
    </div>
  </div>
</template>

<script>
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import Empresa from './model/empresa.js'

export default {
  name: 'abaLogomarca',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemEmpresa: Empresa
  },
  inject: ['abaHandler'],
  data () {
    return {
      logoMarca: {
        imglogotipoempresa: this.itemEmpresa.imglogotipoempresa,
        formatoLogomarca: this.itemEmpresa.formatoLogomarca
      },
      figura: 'data:image/png;base64,' + this.itemEmpresa.imglogotipoempresa,
      erro: '',
      modalConfirmacaoVisivel: false,
      exibirAviso: null
    }
  },
  methods: {
    arquivoSelecionado (event) {
      const reader = new FileReader()
      const file = event.target.files[0]
      if (file.size < 1) {
        this.erro = 'O arquivo esta com tamanho inválido, selecione outra imagem.'
        setTimeout(() => {
          this.excluirFigura()
          this.erro = ''
        }, 2500)
      } else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        reader.readAsDataURL(file)
        reader.onloadend = async () => {
          if (file.type === 'image/png') {
            this.logoMarca.formatoLogomarca = 'TdxPNGImage'
          } else if (file.type === 'image/jpeg') {
            this.logoMarca.formatoLogomarca = 'TJPEGImage'
          }
          this.logoMarca.imglogotipoempresa = reader.result
          this.logoMarca.imglogotipoempresa = this.logoMarca.imglogotipoempresa.substring(this._retornaPosicaoParaSalvarAStringEmBase64(this.logoMarca.imglogotipoempresa))
          this.erro = ''
          this.figura = reader.result
          this.montarObjeto(this.logoMarca)
          await this._validarImagem(reader.result, this.excluirFigura)
        }
      } else {
        this.erro = 'Formato inválido de imagem, apenas PNG e JPEG são aceitos'
      }
    },
    _retornaPosicaoParaSalvarAStringEmBase64 (string) {
      let retorno = -1
      for (let i = 0; i < string.length; i++) {
        if (string[i] === ',') {
          retorno = i + 1
          break
        }
      }
      return retorno
    },

    exibirModalExclusao () {
      this.modalConfirmacaoVisivel = !this.modalConfirmacaoVisivel
    },

    excluirFigura () {
      this.logoMarca.imglogotipoempresa = null
      this.logoMarca.formatoLogomarca = null
      this.figura = null
      this.montarObjeto(this.logoMarca)
    },

    async _validarImagem (imagem, excluirFig) {
      var image = new Image()
      image.src = imagem
      image.onload = () => {
        if ((image.width > 256) || (image.height > 256)) {
          excluirFig()
          this.erro = 'As dimensões da imagem são maiores que o permitido. A imagem deve ter até 256x256. Por favor, ajuste a proporção da imagem ou selecione outra e tente novamente'
        }
      }
    }
  },
  watch: {
    figura: {
      handler: function (val, old) {
        if (val) {
          this.exibirAviso = false
        }
      }
    },
    abaVisivel (val) {
      if (val) {
        this.$nextTick(() => {
          const div = document.querySelector('div.file')
          if (div) {
            div.focus()
          }
        })
      }
    }
  },
  unmounted () {
    this.erro = ''
  },
  mounted () {
    if (utils.converterImgParaTamanho(this.figura) < 1) {
      console.log('Imagem inválida excluida')
      this.excluirFigura()
    }

    this.exibirAviso = this.figura === 'data:image/png;base64,null' || this.figura === null
  }
}
</script>

<style scoped>
.botao-de-dentro {

}
</style>
