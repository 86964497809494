<template>
  <div>
    <div class="columns is-multiline">
      <div class="field column is-7" style="padding-bottom: 0rem;margin: 0;">
          <input-search
            :id="'buscaAuditor'"
            :limpar="this.cleanInput"
            :items="this.pContadores"
            :minInputLength="1"
            :valorExibido="this.auditoresNomes"
            :editando="this.itemAuditor"
            :errosValidacao="this.erros"
            @selecionarItem="this.atribuirAuditor($event)"
            @atribuir="this.atribuirAuditor"
            @onBlur="this.atribuirAuditor"
            @onFocus="true"
            @onInput="this.erros.nomeAuditor = ''"
            @keyup.exact.tab="this.atribuirAuditor"
            @limparCampo="this.cleanInput = $event"
            :message="this.erros.nomeAuditor"
            :status="this.getStatus(this.erros.nomeAuditor)"
            :label="'Selecione o auditor'"
            :icon="'search'"
            ref="input"
            :disabled="this.itemEmpresa.empresaAtiva"/>
      </div>
      <div class="field column is-2">
          <campo-texto
          id="anoAuditoria"
          v-model="this.auditorObjeto.ano"
          class="input"
          label="Ano de auditoria"
          maxlength="4"
          :message="this.erros.ano"
          :status="this.erros.ano? 'error' : ''"
          @keypress="this.onlyNumber($event, true)"
          @focusout.prevent="this.isNumber"
          @change.prevent="this.isNumber"
          :disabled="this.itemEmpresa.empresaAtiva"
          />
    </div>
      <div class="field column is-3">
        <button
          style="top: 1.3rem" @click="this._vincularAuditor" :disabled="this.itemEmpresa.empresaAtiva" class="button">Gravar vínculo</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputSearch from '@/components/InputSearch/index.vue'
import AuditorIndependente from './model/auditor-independente'
import Empresa from '../model/empresa'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'

export default {
  data () {
    return {
      contadores: this.pContadores,
      auditorObjeto: {
        empresaId: null,
        contadorId: null,
        nome: null,
        codigoCVM: null,
        ano: null
      },
      auditor: new AuditorIndependente(),
      auditorSelecao: null,
      erros: this.errosValidacao,
      cleanInput: false,
      oldItemAuditor: new AuditorIndependente(),
      edit: false
    }
  },
  mixins: [mixin],
  props: {
    itemEmpresa: Empresa,
    itemAuditor: AuditorIndependente,
    pContadores: Object,
    auditores: Array,
    errosValidacao: Object
  },
  components: {
    'input-search': InputSearch,
    'campo-texto': InputText
  },
  watch: {
    auditorObjeto: {
      handler: function (val) {
        if (val.ano) {
          this.erros.ano = ''
        }

        if (val.nome) {
          this.erros.nomeAuditor = ''
        }
      },
      deep: true
    },
    itemAuditor: {
      handler: function (val) {
        if (val) {
          this.auditorObjeto = this.itemAuditor
          if (this.itemAuditor && this.edit) {
            Object.assign(this.oldItemAuditor, this.itemAuditor)
            this.edit = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    auditoresNomes (e) {
      return e.nome
    },
    async _vincularAuditor () {
      if (await this._validarInformacoesCadastro()) {
        this.auditorObjeto.tempId = this.auditorObjeto.tempId || 0
        if (this.auditorObjeto.tempId === 0) {
          this.auditorObjeto.tempId = Math.floor(Math.random() * 200)
        }
        Object.assign(this.auditor, this.auditorObjeto)
        this.$emit('armazenarVinculo', this.auditor)
        Object.assign(this.auditorObjeto, new AuditorIndependente())
        delete this.auditorObjeto.tempId
        this.cleanInput = true
        this.$parent.$refs.listagem.listagemVisivel = true
      }
    },
    async atribuirAuditor (auditorSelecionado) {
      if (auditorSelecionado.input) {
        this.auditorSelecao = auditorSelecionado
      }
      if (!auditorSelecionado.id && auditorSelecionado.items) {
        this.auditorObjeto.empresaId = this.itemEmpresa.cdempresa
        this.auditorObjeto.contadorId = auditorSelecionado.items[0].id
        if (auditorSelecionado.items[0].codigoCVMAuditor) {
          this.auditorObjeto.codigoCVM = auditorSelecionado.items[0].codigoCVMAuditor
        }
        this.auditorObjeto.nome = auditorSelecionado.items[0].nome
      }

      if (!auditorSelecionado.input) {
        if (!this.auditorObjeto) {
          this.auditorObjeto.empresaId = this.itemEmpresa.cdempresa
          this.auditorObjeto.contadorId = auditorSelecionado.items[0].id
          if (auditorSelecionado.items[0].codigoCVMAuditor) {
            this.auditorObjeto.codigoCVM = auditorSelecionado.items[0].codigoCVMAuditor
          }
          this.auditorObjeto.nome = auditorSelecionado.items[0].nome
        }
      }
    },
    async _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      let ocorreuErro = false
      this.erros = this.errosValidacao
      if (!this.auditorSelecao) {
        this.auditorSelecao = {
          input: ''
        }
      }
      Object.assign(await AuditorIndependente.validar(this.erros, this.auditorObjeto, this.auditorSelecao, this.auditores, this.itemAuditor, this.oldItemAuditor))
      if (this.erros.auditor) {
        ocorreuErro = true
      }
      return !ocorreuErro
    },

    _limparRegistroDeErros () {
      this.erros.nomeAuditor = ''
      this.erros.ano = ''
      this.erros.auditor = ''
    },

    _limparCampos () {
      this.auditorObjeto.empresaId = null
      this.auditorObjeto.contadorId = null
      this.auditorObjeto.nome = null
      this.auditorObjeto.codigoCVM = null
      this.auditorObjeto.ano = null
      this.auditorSelecao = null
      this._limparRegistroDeErros()
      this.cleanInput = true
      this.edit = false
    },

    onlyNumber (e, noPoint = false) {
      return utils.onlyNumber(e, noPoint)
    },

    isNumber (e) {
      this.auditorObjeto.ano = e.target.value.replaceAll(/[^\d]+/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
