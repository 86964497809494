<template>
  <div id="cadastroSecundario">
    <div v-if="quickview" style="height:100%">
            <div class="card-content" style="height:81%;padding-left:unset;padding-right:0rem; padding-top: 0rem">
              <slot></slot>
        </div>
    </div>
    <!--  isso foi feito para não quebrar lugares que tem o quickview antigo do projeto quando terminarmos de fazer os quickviews remover todo esse trech de v-else do código -->
    <div v-else class="container" style="height:100%">
        <div class="card" style="height:100%">
            <header class="card-header" style="height: 96px;">
              <div class="notification" style="width: -webkit-fill-available;">
                <button class="delete" @click="this.$emit('fecharCadastroSecundario')"></button>
                <h4 class="title is-4">
                  {{this.tituloTela}}
                </h4>
              </div>
            </header>
            <div class="card-content" style="height:81%;padding-left:unset;padding-right:0rem; padding-top: 0rem">
              <slot></slot>
            </div>
            <footer v-if="!this.ocultarBotaoFechar" class="modal-card-foot is-justify-content-flex-end">
              <div class="buttons is-right">
                  <button class="button" @click="this.$emit('fecharCadastroSecundario')">Fechar</button>
              </div>
            </footer>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cadastroSecundario',

  props: {
    tituloTela: String,
    ocultarBotaoFechar: Boolean,
    quickview: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
    }
  }
}
</script>
