<template>
    <div class="is-flex is-flex-direction-column is-desktop">
    <div class="column is-full">
      <div id="empresaVinculada">
        <DataTableBasico
          ref="datatablebasico"
          :key="datakey"
          :p-status="status"
          :p-data-source="dataSource"
          :p-data="empresas"
          :p-hide-button-more="hideButtonMore"
          :next-page="_carregarListagem"
          :p-quantidade-de-items="totalPage"
          :mensagemDePesquisaVazia="message"
          :pShowButtons="false"
          :pSearch="false"
          :custom-search="false"
          :ativarAtalhoDelete="false"
          :ativarAtalhoEnter="false"
          :paginacao="false"
        >
          <template #status="{item}">
            <div class="is-flex is-justify-content-center">
              <Tag v-if="!item.dataDemissao" caption="Ativo" class="is-info is-light"/>
              <Tag v-else caption="Desligado" class="is-neutral is-light"/>
            </div>
          </template>
        </DataTableBasico>
      </div>
    </div>
  </div>
</template>

<script>
import Socio from './model/socios'
import { service } from '../../service'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Tag from '@alterdata/component-vue/Tag/index.vue'
import { utils } from '../../util/utils'

export default {
  props: {
    itemSocio: Socio
  },
  components: {
    DataTableBasico,
    Tag
  },
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'status',
            label: 'Status',
            style: 'width:8%; text-align: center;',
            slot: true,
            styleItems: 'text-align: center;'
          },
          {
            name: 'idEmpresaSocio',
            label: 'Código',
            style: 'width:7%',
            format: this._retornarCodigoFormartado
          },
          {
            name: 'nomeEmpresa',
            label: 'Empresa',
            style: 'width:30%'
          },
          {
            name: 'alCapital',
            label: '% Capital',
            style: 'width:8%; text-align: right;',
            format: this.retornarPorcentagem,
            styleItems: 'text-align: right;'
          },
          {
            name: 'tipoIdentificadorResponsavel',
            label: 'Tipo sócio',
            style: 'width:9%',
            format: this.retornarTipo
          },
          {
            name: 'stResponsavelCgc',
            label: 'Responsável CNPJ',
            style: 'width:12%',
            format: this.retornarCNPJ
          }
        ],
        selected: null,
        cursor: null,
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      page: [],
      empresas: [],
      status: '',
      hideButtonMore: false,
      showTitle: false,
      pagina: 0,
      message: '',
      datakey: 0,
      totalPage: 0
    }
  },
  methods: {
    async _carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      try {
        this.empresas = []
        this.page = await service.get(`/api/v1/socemp/socio/sem-page/${this.itemSocio.cdsocio}`)
        this.totalPage = this.page.length
        this.page.map(async e => {
          const empresa = await service.get(`/api/v1/empresa/${e.idEmpresaSocio}`)
          e.nomeEmpresa = empresa.nomeCompleto
        })
        this.empresas = this.page
        this.status = ''
        this.pagina = pagina
        if (this.totalPage === 0) {
          this.status = ''
          this.message = 'Este sócio não está vinculado a nenhuma empresa do sistema'
        }
        this.datakey++
      } catch {
        this.status = ''
        this.message = 'Este sócio não está vinculado a nenhuma empresa do sistema'
      }
    },
    retornarTipo (tipo) {
      switch (tipo) {
        case 'S': return 'Sócio'
        case 'D': return 'Diretor'
        case 'P': return 'Presidente'
      }
    },
    retornarCNPJ (cnpj) {
      return cnpj ? 'Sim' : 'Não'
    },
    retornarPorcentagem (capital) {
      capital = capital ? capital.toFixed(2).replace('.', ',') : ''
      return capital
    },
    _retornarCodigoFormartado (codigo) {
      if (codigo) {
        return utils.strZero(codigo.toString(), 5)
      } else {
        return null
      }
    }
  },
  async mounted () {
    await this._carregarListagem()
  }
}
</script>

<style lang="scss" scoped>

</style>
