<template>
  <InputLabel v-bind="{ label, required: $attrs.required }" />
  <div :data-group="getGroupName()">
    <div style="height: 32px" class="is-flex">
      <div :style="{ width: $attrs.widthDiv }">
        <InputText
          v-bind="{ status, ...$attrs, onClick: {} }"
          :modelValue="modelValue"
          :maxlength="$attrs.maxlength"
          :disabled="inputDisabled"
          @update:modelValue="handleUpdateModel"
          @blur="handleBlur"
          @update:erro="$emit('update:erro', $event)"
          :data-group="getGroupName() + '-input-text'"
        >
          <template #addon>
            <Button
            tabindex = "-1"
              @click="$emit('click')"
              :class="[
                'is-outlined',
                {
                  'is-danger': status === 'error',
                  'is-info': status === 'info' && !$attrs.disabled
                }
              ]"
              icon-only="search"
              type="secondary"
              :disabled="$attrs.disabled"
            />
          </template>
        </InputText>
      </div>
      <div class="ml-2 is-flex-grow-1">
        <InputText :value="props.externalValue ? props.externalValue : value" disabled />
      </div>
    </div>
    <InputMessage :status="status" :message="message" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, watch, useAttrs } from 'vue'
import { ref } from '@vue/reactivity'
import InputLabel from '@alterdata/component-vue/InputBase/InputLabel.vue'
import InputText from '@alterdata/component-vue/InputText'
import Button from '@alterdata/component-vue/Button'
import InputMessage from '@alterdata/component-vue/InputBase/InputMessage.vue'

const emit = defineEmits(['update:modelValue', 'update:erro'])

const props = defineProps({
  label: String,
  message: String,
  status: String,
  modelValue: String,
  list: {
    type: Array,
    default: () => [{ codigo: 1, valor: 'valor' }]
  },
  identifier: {
    type: String,
    default: 'codigo'
  },
  nameProperty: {
    type: String,
    default: 'valor'
  },
  messageError: {
    type: String,
    default: 'Item não encontrado'
  },
  externalValue: {
    type: String,
    default: ''
  },
  inputDisabled: {
    type: Boolean,
    default: false
  }
})

const evento = ref(false)

const value = ref('')

const attrs = useAttrs()

function handleUpdateModel (event) {
  emit('update:erro', { message: '' })
  const result = props.list.find(
    // eslint-disable-next-line eqeqeq
    (e) => e[props.identifier] == event
  )
  if (result) {
    value.value = result[props.nameProperty]
    evento.value = false
  } else {
    value.value = ''
    // eslint-disable-next-line eqeqeq
    evento.value = event
  }
  emit('update:modelValue', event)
}

const getGroupName = () => {
  const removeDiacricts = (text) =>
    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const replaceSpecialChars = (text) =>
    text.replace(/[!@^&|_/\\#,+()$~%.'";:*?<>{}[\]\s]+/g, '-')
  const normalize = (text) =>
    replaceSpecialChars(removeDiacricts(text.toLowerCase()))
  if (attrs.dataGroup) {
    return attrs.dataGroup
  }
  if (attrs.label) {
    return normalize(attrs.label)
  }
  return attrs.id
}

function handleBlur () {
  if (evento.value) {
    emit('update:erro', { message: props.messageError })
  }
}

function setValue () {
  const result = props.list.find(
    // eslint-disable-next-line eqeqeq
    (e) => e[props.identifier] == props.modelValue
  )
  if (result) {
    value.value = result[props.nameProperty]
  }
}
watch(
  () => props.list,
  () => {
    setValue()
  }
)

watch(
  () => props.modelValue,
  () => {
    handleUpdateModel(props.modelValue)
  }
)

onMounted(() => {
  setValue()
})
</script>

<style scoped></style>
