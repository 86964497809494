<template>
  <div
    v-if="this.modalVisivel"
    class="is-flex is-flex-direction-column is-desktop"
  >
  <PageTitle data-group="page-socios" class="py-0">{{!this.editando ? "Cadastro" : "Edição"}} de sócios</PageTitle>
    <div class="column is-full py-0" style="margin-top:-5px">
      <div class="columns is-multiline" style="overflow: hidden;">
        <div class="field column is-2" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            v-if="!this.editando"
            id="codigoSocio"
            label="Código"
            :status="this.getStatus(erros.codigo)"
            @update:erro="erros.codigo = $event.message"
            @blur="this._validarCodigo"
            @keypress="this.onlyNumber(erros.codigo)"
            @change="this._retornarCodigoEmpresaFormatado"
            :message="erros.codigo"
            v-model="this.codSocio"
            maxlength="5"
            required
            :key="key"
          />
          <campo-texto
            v-else
            v-model="this.codSocio"
            id="codigoSocio"
            label="Código"
            disabled
          />
        </div>
        <div class="field column is-10" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="nomeSocio"
            :message="erros.nome"
            @update:erro="
              (this.erros.nome = $event.message), (this.nome = this.nomeSocio)
            "
            label="Nome"
            v-model="this.nmsocio"
            :data-group="'nome-input-socio'"
            required
            :status="this.getStatus(this.erros.nome)"
            maxlength="60"
            @keydown="this.erros.nome = ''"
            @blur="this._filtraMudancas()"
          />
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin: 0; margin-bottom: 30px"
        >
          <validador-cpf-cnpj
            :style="this.infoToastCPFVisivel ? 'border-color: blue' : ''"
            id="cpfOuCnpj"
            width="50%"
            v-model="this.cpfOuCnpj"
            :status="this.getStatus(erros.inscricaoCpfCnpj)"
            :message="erros.inscricaoCpfCnpj"
            @blur="this.validarSeCPFCNPJJaFoiCadastrado"
            data-group="socio"
            @update:erro="erros.inscricaoCpfCnpj = $event.message"
            required
          />
        </div>
        <div v-if="this.modalVisivel" class="columns container" id="cadastroEdicao">
          <div class="column is-2">
            <menu-vertical
              :pItens="this.itensMenuVertical"
              :pActiveLink="this.activeLink"
              @ativarAba="this._ativarAba"
              :pMod="'cadastro-socio'"
            ></menu-vertical>
          </div>
          <div class="column is-10" style="padding-left:0rem">
            <div class="columns is-multiline" style="padding-left: 1rem">
              <div :class="this.tamanhoTela">
                <section class="modal-card-body" style="padding-bottom: 60px">
                  <div class="columns is-multiline">
                    <div class="field column is-12">
                      <basico
                        v-if="basicoVisivel"
                        :montarObjeto="_montarObjetoSocio"
                        :itemSocio="socio"
                        :errosValidacao="erros"
                        @navToAbaComplementar="() => {_ativarAba('complementar'); this.ativarInputParaAba()}"
                      />
                      <complementar
                        v-if="complementarVisivel"
                        :montarObjeto="_montarObjetoSocio"
                        :itemSocio="socio"
                        :errosValidacao="erros"
                        :cpfInformado="_checarSeInscricaoECPF()"
                        @validarDataEmissao="
                          this.validadores.validadorDataEmissao = $event
                        "
                        @validarDataExpedicao="
                          this.validadores.validadorDataExpedicao = $event
                        "
                        @validarDataValidade="
                          this.validadores.validadorDataValidade = $event
                        "
                      />
                      <observacao
                        v-if="observacoesVisivel"
                        :montarObjeto="_montarObjetoSocio"
                        :itemSocio="socio"
                      />
                      <keep-alive v-if="this._checarSeInscricaoECPF()">
                        <declaracoes
                          v-if="declaracoesVisivel"
                          :montarObjeto="_montarObjetoSocio"
                          :itemSocio="socio"
                          :errosValidacao="erros"
                          @validarDataChegada="
                            this.validadores.validadorDataChegada = $event
                          "
                          @validarDataNaturalizacao="
                            this.validadores.validadorDataNaturalizacao = $event
                          "
                        />
                      </keep-alive>
                      <declaracoes
                        v-else-if="declaracoesVisivel"
                        :montarObjeto="_montarObjetoSocio"
                        :itemSocio="socio"
                        :errosValidacao="erros"
                        @validarDataChegada="
                          this.validadores.validadorDataChegada = $event
                        "
                        @validarDataNaturalizacao="
                          this.validadores.validadorDataNaturalizacao = $event
                        "
                      />
                      <keep-alive>
                        <empresas v-if="empresasVisivel" :itemSocio="socio" />
                      </keep-alive>
                    </div>
                  </div>
                </section>
                <footer
                  class="modal-card-foot is-justify-content-flex-end"
                  style="bottom: 25px"
                >
                  <div class="column is-4">
                    <div
                      class="checkbox"
                      style="botton: -30px; left: 10px"
                      v-if="this.mostrarCheckboxS2205"
                      :key="this.keyCheck"
                    >
                      <label
                        class="checkbox"
                        style="padding-bottom: 0rem; margin: 0"
                      >
                        <input
                          type="checkbox"
                          :checked="this.agendamentoS2205"
                          v-model="this.agendamentoS2205"
                        />
                        Agendar alterações para o eSocial
                      </label>
                      <div class="help is-italic">
                        <p>Insere automaticamente a data de envio das</p>
                        <p>alterações ao criar o agendamento do <b>eSocial</b></p>
                      </div>
                    </div>
                  </div>

                  <div class="buttons" style="margin-right: 30px">
                    <Button class="button is-primary" :data-group="'btn-gravar-socio'" :title="shortkeys.MSG_KEY_GRAVAR" @click="this._salvarSocio">
                      Gravar
                    </Button>
                    <Button class="button" :title="shortkeys.MSG_KEY_ESC" :data-group="'btn-cancelar-socio'"  @click="this._clicarCancelar">
                      Cancelar
                    </Button>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVertical from '../../components/MenuVertical/index.vue'
import basico from './abaBasico.vue'
import complementar from './abaComplementar.vue'
import observacao from './abaObservacao.vue'
import empresas from './abaEmpresasVinculadas.vue'
import declaracoes from './abaDeclaracoes.vue'
import Socio from './model/socios.js'
import SociosAuxiliar from './model/sociosAuxiliar.js'
import Snapshot from './model/snapshot.js'
import { sociosService } from './service/socios-service'
import { validadorDeInscricaoFederal } from '@packonline/packonline-reutilizaveis-frontend/Utils/InscricaoFederal.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { maska } from 'maska'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { mixinAjuda } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-ajuda'
import InputText from '@alterdata/component-vue/InputText'
import carregarSocioAoEditar from './func/carregarSocioAoEditar.js'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import ValidadorCpfCnpj from '../../components/ValidadorCpfCnpj/index.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast.js'
import jsonLog from '../../views/Socios/json/tipoLogradouro.json'
import Button from '@alterdata/component-vue/Button/index.vue'
import PageTitle from '@packonline/packonline-reutilizaveis-frontend/components/PageTitle.vue'

const ERROS = {
  telaPrincipal: '',
  abaBasico: '',
  abaComplementar: '',
  abaDeclaracoes: '',
  cpfOuCnpj: '',
  telefone: '',
  email: '',
  nome: '',
  municipio: '',
  paisCodigo: '',
  cep: '',
  inscricaoEstadual: '',
  cpfResponsavel: '',
  numeroCNH: '',
  ufCNH: '',
  categoriaCNH: '',
  expedicaoCNH: '',
  dataValidadeCNH: '',
  orgaoEmissorCNH: '',
  ufCTPS: '',
  serieCTPS: '',
  numeroCTPS: '',
  deficienteTipo: '',
  regimeMatrimonio: '',
  dataEmissaoIdentidade: '',
  codigo: '',
  numeroCBONovo: ''
}

export default {
  props: {
    modalVisivel: Boolean,
    item: Socio,
    cadastrarNovo: Function,
    editando: Boolean,
    codigoSocio: String,
    focarBasico: Function,
    linkAjudaProp: {
      type: String,
      default: ''
    }
  },
  components: {
    basico,
    complementar,
    observacao,
    empresas,
    declaracoes,
    'menu-vertical': MenuVertical,
    'campo-texto': InputText,
    'validador-cpf-cnpj': ValidadorCpfCnpj,
    Button,
    PageTitle
  },
  directives: {
    maska
  },
  mixins: [mixin, mixinModalSaida, mixinAjuda, mixinRouterGuardian],
  inject: ['setActiveSection', 'addShortcut'],
  beforeRouteLeave (to, from, next) {
    this.beforeRoute(to, from, next)
  },
  data () {
    return {
      tiposLogradouros: jsonLog.data,
      s2205: [
        {
          cep: null,
          nome: null,
          numero: null,
          logradouro: null,
          endereco: null,
          complemento: null,
          bairro: null,
          uf: null,
          codigoIBGE: null,
          telefone: null,
          email: null,
          deficienteTipo: null,
          deficienciaAntiga: null,
          reabilitado: null,
          estadoCivilSituacao: null,
          sexo: null,
          racaCor: null,
          grauDeInstrucao: null,
          paisCodigoESocialNacionalidade: null,
          tempoResidencia: null,
          condicao: null
        }
      ],
      shortkeys: shortkeys,
      houveMudancas: false,
      nome: null,
      cdIBGE: null,
      keyCheck: 0,
      mostrarCheckboxS2205: false,
      agendamentoS2205: false,
      checkAgendamento: false,
      codSocio: this.codigoSocio,
      cpfOuCnpj: null,
      nmsocio: null,
      basicoVisivel: true,
      complementarVisivel: false,
      observacoesVisivel: false,
      declaracoesVisivel: false,
      empresasVisivel: false,
      socio: new Socio(),
      sociosAuxiliar: new SociosAuxiliar(),
      snapshot: new Snapshot(),
      jsonResposta: null,
      erros: ERROS,
      activeLink: 'basico',
      itensMenuVertical: null,
      validadores: {
        validadorDataChegada: null,
        validadorDataNaturalizacao: null,
        validadorDataEmissao: null,
        validadorDataExpedicao: null,
        validadorDataValidade: null
      },
      key: 0,
      infoToastCPFVisivel: false,
      linkAjuda: this.linkAjudaProp,
      itemOriginal: new Socio()
    }
  },
  computed: {
    CPForCNPJ () {
      let cpforcnpj = ''
      if (this.cpfOuCnpj) {
        cpforcnpj = this.cpfOuCnpj.replace(/[^\d]+/g, '')
      }
      return cpforcnpj.length === 11 ? 'CPF' : 'CNPJ'
    },
    dadosAlteradosRoot () {
      return this.$root.dadosAlterados
    },
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    }
  },

  watch: {
    verificandoDadosAlteradosRoot: {
      handler (val) {
        if (val) {
          this._verificarModificacaoNoSocio()
        }
      }
    },
    socio: {
      handler: function (val, old) {
        if (val && val.inscricaoCpfCnpj) {
          this._checarSeInscricaoECPF()
          this.nome = this.nmsocio
          this._filtraMudancas()
        }
      },
      deep: true
    },
    cpfOuCnpj: {
      immediate: true,
      handler: function (val, old) {
        this._exibeCheckBoxS2205()

        if (val) {
          this.erros.inscricaoCpfCnpj = ''
        }
        if (this._checarSeInscricaoECPF()) {
          this.itensMenuVertical = [
            {
              active: true,
              links: [
                {
                  descricao: 'Básico',
                  url: 'basico',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Complementar',
                  url: 'complementar',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Declarações',
                  url: 'declaracoes',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Observações',
                  url: 'observacoes',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Empresas vinculadas',
                  url: 'empresas',
                  validacao: { isValido: false }
                }
              ]
            }
          ]
        } else {
          const dec = new Socio()
          Object.assign(this.socio.declaracoes, dec.declaracoes)
          this.limparErrosDeclaracoes()
          this.itensMenuVertical = [
            {
              active: true,
              links: [
                {
                  descricao: 'Básico',
                  url: 'basico',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Complementar',
                  url: 'complementar',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Observações',
                  url: 'observacoes',
                  validacao: { isValido: false }
                },
                {
                  descricao: 'Empresas vinculadas',
                  url: 'empresas',
                  validacao: { isValido: false }
                }
              ]
            }
          ]
          if (this.declaracoesVisivel) {
            this._ativarAba('basico')
          }
        }
      }
    },
    editando: {
      handler: function (val, old) {
        this.codSocio = this._retornarCodigoFormartado(this.codigoSocio)
        Object.assign(this.sociosAuxiliar, this.item)
        this.s2205.deficienciaAntiga =
          this.sociosAuxiliar.complementar.deficienteTipo
        this.houveMudancas = false
        if (val) {
          this.nmsocio = this.item.nmsocio
          this.cpfOuCnpj = this.item.inscricaoCpfCnpj
          carregarSocioAoEditar(this.socio, this.item)
          this.itemOriginal = utils.cloneDeep(this.item)
        } else {
          this.nmsocio = null
          this.cpfOuCnpj = null
          Object.assign(this.socio, new Socio())
        }
      }
    }
  },

  methods: {
    async _filtraMudancas () {
      await this._empresaSocio(this.codSocio)
      this._montarObjetoSocio()
      let strArrayAdd = ''

      // Parte identificação:
      const user = utils.campoVazio(this.$root.usuarioLogado)
        ? 'SUPERVISOR'
        : this.$root.usuarioLogado
      var cabecalho =
        '{"Usuario":"' +
        user +
        '","tabela": 1,"descricao":"(' +
        this.codSocio +
        ') ' +
        this.sociosAuxiliar.nmsocio +
        '","inclusao":false,\n'
      strArrayAdd = cabecalho + '"alteracoes": ['

      if (this.sociosAuxiliar.nmsocio !== this.nmsocio) {
        this.s2205.nmsocio = this.nmsocio
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Nome',
            'nmSocio',
            this.sociosAuxiliar.nmsocio,
            this.s2205.nmsocio,
            this.sociosAuxiliar.nmsocio,
            this.s2205.nmsocio
          )
      }
      if (this.socio.declaracoes.sexo) {
        if (
          this.sociosAuxiliar.declaracoes.sexo !== this.socio.declaracoes.sexo
        ) {
          this.s2205.sexo = this.socio.declaracoes.sexo
          strArrayAdd =
            strArrayAdd +
            this._montaLinha(
              'Sexo',
              'sexo',
              this.sociosAuxiliar.declaracoes.sexo,
              this.s2205.sexo,
              this._traduzGenero(this.sociosAuxiliar.declaracoes.sexo),
              this._traduzGenero(this.s2205.sexo)
            )
        }
      }

      if (
        this.sociosAuxiliar.declaracoes.racaCor !==
          this.socio.declaracoes.racaCor &&
        this.socio.declaracoes.racaCor
      ) {
        this.s2205.racaCor = this.socio.declaracoes.racaCor
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Raca e Cor',
            'raca_cor',
            this.sociosAuxiliar.declaracoes.racaCor,
            this.s2205.racaCor,
            this._traduzRacaCor(this.sociosAuxiliar.declaracoes.racaCor),
            this._traduzRacaCor(this.s2205.racaCor)
          )
      }

      if (
        this.sociosAuxiliar.complementar.estadoCivil !==
          this.socio.complementar.estadoCivil &&
        this.socio.complementar.estadoCivil
      ) {
        this.s2205.estadoCivilSituacao = this.socio.complementar.estadoCivil
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Situacao',
            'dsestadocivil',
            this.sociosAuxiliar.complementar.estadoCivil,
            this.s2205.estadoCivilSituacao,
            this._traduzEstadoCivil(
              this.sociosAuxiliar.complementar.estadoCivil
            ),
            this._traduzEstadoCivil(this.s2205.estadoCivilSituacao)
          )
      }
      if (
        this.sociosAuxiliar.declaracoes.grauDeInstrucao !==
          this.socio.declaracoes.grauDeInstrucao &&
        this.socio.declaracoes.grauDeInstrucao
      ) {
        this.s2205.grauDeInstrucao = this.socio.declaracoes.grauDeInstrucao
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Grau de Instrucao',
            'codigo_instrucao',
            this.sociosAuxiliar.declaracoes.grauDeInstrucao,
            this.s2205.grauDeInstrucao,
            this._traduzGrauInstrucao(
              this.sociosAuxiliar.declaracoes.grauDeInstrucao
            ),
            this._traduzGrauInstrucao(this.s2205.grauDeInstrucao)
          )
      }

      if (
        (this.sociosAuxiliar.basico.cep !== this.socio.basico.cep &&
          this.socio.basico.cep) ||
        (this.sociosAuxiliar.basico.cep && !this.socio.basico.cep)
      ) {
        this.s2205.cep = this.socio.basico.cep
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Cep',
            'nrCep',
            this.sociosAuxiliar.basico.cep,
            this.s2205.cep,
            this.sociosAuxiliar.basico.cep,
            this.s2205.cep
          )
      }
      if (
        (this.sociosAuxiliar.basico.tipo !== this.socio.basico.tipo &&
          this.socio.basico.tipo) ||
        (this.sociosAuxiliar.basico.tipo && !this.socio.basico.tipo)
      ) {
        this.s2205.logradouro = this.socio.basico.tipo
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Logradouro',
            'endereco_tipo',
            this.sociosAuxiliar.basico.tipo,
            this.s2205.logradouro,
            this.buscaTipoLogradouro(this.sociosAuxiliar.basico.tipo),
            this.buscaTipoLogradouro(this.s2205.logradouro)
          )
      }

      if (
        (this.sociosAuxiliar.basico.endereco !== this.socio.basico.endereco &&
          this.socio.basico.endereco) ||
        (this.sociosAuxiliar.basico.endereco && !this.socio.basico.endereco)
      ) {
        this.s2205.endereco = this.socio.basico.endereco
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Rua',
            'DsEndereco',
            this.sociosAuxiliar.basico.endereco,
            this.s2205.endereco,
            this.sociosAuxiliar.basico.endereco,
            this.s2205.endereco
          )
      }

      if (
        (this.sociosAuxiliar.basico.numero !== this.socio.basico.numero &&
          this.socio.basico.numero) ||
        (this.sociosAuxiliar.basico.numero && !this.socio.basico.numero)
      ) {
        this.s2205.numero = this.socio.basico.numero
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Numero',
            'endereco_numero',
            this.sociosAuxiliar.basico.numero,
            this.s2205.numero,
            this.sociosAuxiliar.basico.numero,
            this.s2205.numero
          )
      }

      if (
        (this.sociosAuxiliar.basico.complemento !==
          this.socio.basico.complemento &&
          this.socio.basico.complemento) ||
        (this.sociosAuxiliar.basico.complemento &&
          !this.socio.basico.complemento)
      ) {
        this.s2205.complemento = this.socio.basico.complemento
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Complemento',
            'endereco_complemento',
            this.sociosAuxiliar.basico.complemento,
            this.s2205.complemento,
            this.sociosAuxiliar.basico.complemento,
            this.s2205.complemento
          )
      }

      if (
        (this.sociosAuxiliar.basico.bairro !== this.socio.basico.bairro &&
          this.socio.basico.bairro) ||
        (this.sociosAuxiliar.basico.bairro && !this.socio.basico.bairro)
      ) {
        this.s2205.bairro = this.socio.basico.bairro
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Bairro',
            'NmBairro',
            this.sociosAuxiliar.basico.bairro,
            this.s2205.bairro,
            this.sociosAuxiliar.basico.bairro,
            this.s2205.bairro
          )
      }
      if (this.sociosAuxiliar.basico.codigoIBGE) {
        const cdIbge = this.sociosAuxiliar.basico.codigoIBGE.toString()
        if (
          cdIbge !== this.socio.basico.codigoIBGE &&
          this.socio.basico.codigoIBGE
        ) {
          this.s2205.codigoIBGE = this.socio.basico.codigoIBGE
          strArrayAdd =
            strArrayAdd +
            this._montaLinha(
              'Codigo IBGE',
              'endereco_municipio_codigo',
              this.sociosAuxiliar.basico.codigoIBGE,
              this.s2205.codigoIBGE,
              this.sociosAuxiliar.basico.codigoIBGE,
              this.s2205.codigoIBGE
            )
        }
      }

      if (
        this.sociosAuxiliar.basico.uf !== this.socio.basico.uf &&
        this.socio.basico.uf
      ) {
        this.s2205.uf = this.socio.basico.uf
        this.s2205.uf = this.socio.basico.uf
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'UF',
            'CdUF',
            this.sociosAuxiliar.basico.uf,
            this.s2205.uf,
            this.sociosAuxiliar.basico.uf,
            this.s2205.uf
          )
      }

      if (
        this.sociosAuxiliar.complementar.deficienteTipo !==
        this.socio.complementar.deficienteTipo
      ) {
        this.s2205.deficienteTipo = this.socio.complementar.deficienteTipo
        const defAntiga = await this._traduzirDeficiencia(
          this.s2205.deficienciaAntiga
        )
        const defNova = await this._traduzirDeficiencia(
          this.s2205.deficienteTipo
        )
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Tipo deficiencia',
            'deficiente_tipo',
            this.sociosAuxiliar.complementar.deficienteTipo,
            this.s2205.deficienteTipo,
            defAntiga,
            defNova
          )
      }
      if (
        this.sociosAuxiliar.complementar.deficienteReabilitado !==
        this.socio.complementar.deficienteReabilitado
      ) {
        this.s2205.reabilitado = this.socio.complementar.deficienteReabilitado
          ? 'Sim'
          : 'Não'
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Reabilitado',
            'deficiente_reabilitado',
            this.sociosAuxiliar.complementar.deficienteReabilitado,
            !this.s2205.deficienteTipo
              ? 'Não informado'
              : this.s2205.deficienteTipo,
            this.sociosAuxiliar.complementar.deficienteReabilitado
              ? 'Sim'
              : 'Nao',
            this.s2205.reabilitado
          )
      }
      if (
        (this.sociosAuxiliar.basico.telefone !== this.socio.basico.telefone &&
          this.socio.basico.telefone) ||
        (this.sociosAuxiliar.basico.telefone && !this.socio.basico.telefone)
      ) {
        this.s2205.telefone = this.socio.basico.telefone
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'DDD Telefone',
            'NrTelefone',
            this.sociosAuxiliar.basico.telefone,
            this.s2205.telefone,
            this.sociosAuxiliar.basico.telefone,
            this.s2205.telefone
          )
      }
      if (
        (this.sociosAuxiliar.basico.email !== this.socio.basico.email &&
          this.socio.basico.email) ||
        (this.sociosAuxiliar.basico.email && !this.socio.basico.email)
      ) {
        this.s2205.email = this.socio.basico.email
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'E-mail',
            'nmEMail',
            this.sociosAuxiliar.basico.email,
            this.s2205.email,
            this.sociosAuxiliar.basico.email,
            this.s2205.email
          )
      }

      if (
        this.sociosAuxiliar.declaracoes.paisCodigoESocialNacionalidade !==
          this.socio.declaracoes.paisCodigoESocialNacionalidade &&
        this.socio.declaracoes.paisCodigoESocialNacionalidade
      ) {
        this.s2205.paisCodigoESocialNacionalidade =
          this.socio.declaracoes.paisCodigoESocialNacionalidade
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Pais nacionalidade',
            'esocial_pais_nacionalidade',
            this.sociosAuxiliar.declaracoes.paisCodigoESocialNacionalidade,
            this.s2205.paisCodigoESocialNacionalidade,
            this.sociosAuxiliar.declaracoes.paisCodigoESocialNacionalidade,
            this.s2205.paisCodigoESocialNacionalidade
          )
      }
      if (
        this.sociosAuxiliar.declaracoes.tempoResidencia !==
          this.socio.declaracoes.tempoResidencia &&
        this.socio.declaracoes.tempoResidencia
      ) {
        this.s2205.tempoResidencia = this.socio.declaracoes.tempoResidencia
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Tempo Residencia',
            'estrangeiro_tempo_residencia',
            this.sociosAuxiliar.declaracoes.tempoResidencia,
            this.s2205.tempoResidencia,
            this.sociosAuxiliar.declaracoes.tempoResidencia,
            this.s2205.tempoResidencia
          )
      }
      if (
        this.sociosAuxiliar.declaracoes.condicao !==
        this.socio.declaracoes.condicao
      ) {
        this.s2205.condicao = this.socio.declaracoes.condicao
          ? this.socio.declaracoes.condicao
          : 'Não informado'
        strArrayAdd =
          strArrayAdd +
          this._montaLinha(
            'Condicao',
            'estrangeiro_condicao',
            this.sociosAuxiliar.declaracoes.condicao
              ? this.sociosAuxiliar.declaracoes.condicao
              : 'Não informado',
            this.s2205.condicao,
            this.sociosAuxiliar.declaracoes.condicao
              ? this.sociosAuxiliar.declaracoes.condicao
              : 'Não informado',
            this.s2205.condicao
          )
      }
      const tamanhoString = strArrayAdd.length
      strArrayAdd = strArrayAdd.substring(0, tamanhoString - 2) + ']}'
      this.snapshot.socio_id = parseInt(this.codSocio, 10)
      this.snapshot.empresa_id = this.empresaDoSocio
      this.snapshot.registro = ''
      this.snapshot.recibo = ''
      this.snapshot.dados = strArrayAdd

      this.snapshot.prazo_id = ''
      this.snapshot.empresa_matriz_id = this.empresaDoSocio

      return this.snapshot
    },
    ativarInputParaAba () {
      let idNextAba = this._checarSeInscricaoECPF() ? '#identidade' : '#inscricaoEstadual'
      idNextAba = (!this.socio.basico.uf && !this._checarSeInscricaoECPF()) ? '#cpfResponsavel' : idNextAba
      setTimeout(() => {
        this.focarNextInput(idNextAba)
      }, 50)
    },
    buscaTipoLogradouro (tipo) {
      const listaTiposLogradouros = JSON.parse(
        JSON.stringify(this.tiposLogradouros)
      )
      const tipoEncontrado = {
        abv: null,
        nome: null
      }
      Object.assign(
        tipoEncontrado,
        listaTiposLogradouros.find((cbo) => cbo.abv === tipo)
      )
      return tipoEncontrado.nome
    },
    _traduzirDeficiencia (deficiencia) {
      switch (parseInt(deficiencia)) {
        case 1:
          return 'Motora'
        case 2:
          return 'Visual'
        case 3:
          return 'Auditiva'
        case 4:
          return 'Mental'
        default:
          return ''
      }
    },
    _traduzGrauInstrucao (grauInstrucao) {
      switch (grauInstrucao) {
        case 1:
          return 'Analfabeto'
        case 2:
          return 'Até a 4a serie incompleta do ensino fundamental'
        case 3:
          return '4a serie completa do ensino fundamental'
        case 4:
          return 'Da 5a a 8a serie do ensino fundamental'
        case 5:
          return 'Ensino fundamental completo'
        case 6:
          return 'Ensino médio incompleto'
        case 7:
          return 'Ensino medio completo'
        case 8:
          return 'Educacao Superior incompleta'
        case 9:
          return 'Educacao Superior completa'
        case 10:
          return 'Pos Graduacao'
        case 11:
          return 'Mestrado'
        case 12:
          return 'Doutorado'
      }
    },
    _traduzEstadoCivil (estadoCivil) {
      switch (estadoCivil) {
        case 'C':
          return 'Casado'
        case 'V':
          return 'Viuvo'
        case 'S':
          return 'Solteiro'
        case 'D':
          return 'Divorciado'
        case 'U':
          return 'Uniao Estavel'
        case 'O':
          return 'Outros'
      }
    },
    _traduzGenero (genero) {
      if (genero === 'F') {
        return 'Feminino'
      } else {
        return 'Masculino'
      }
    },
    _traduzRacaCor (racaCor) {
      switch (racaCor) {
        case 1:
          return 'Indigena'
        case 2:
          return 'Branca'
        case 3:
          return 'Negra'
        case 6:
          return 'Amarela'
        case 8:
          return 'Parda'
        default:
          return 'Nao Informado'
      }
    },
    _montaLinha (
      descricao,
      campo,
      valorAntigo,
      valor,
      descricaoValorAntigo,
      descricaoValor
    ) {
      if (campo === 'endereco_municipio_codigo' && valor === valorAntigo) {
        return
      }
      if (!valorAntigo) {
        valorAntigo = 'Não informado'
        descricaoValorAntigo = 'Não informado'
      }
      const linhaRegistro =
        '{"Descricao":"' +
        descricao +
        '","Campo": "' +
        campo +
        '", "ValorAntigo": "' +
        valorAntigo +
        '", "Valor": "' +
        valor +
        '", "DescricaoValorAntigo": "' +
        descricaoValorAntigo +
        '", "DescricaoValor": "' +
        descricaoValor +
        '"},\n'
      this.houveMudancas = true
      return linhaRegistro
    },
    async _salvaAgendamento () {
      const endPoint = '/api/v1/socio/esocial-salva-agendamento/'
      try {
        const response = await sociosService.save(endPoint, this.snapshot)
        await this.responseToSave(response)
      } catch (e) {
        //  console.log('Erro:', e)
      }
    },
    async _empresaSocio (idSocio) {
      const endpoint = `/api/v1/socio/empresa-do-socio/${parseInt(idSocio)}`
      try {
        return sociosService.get(endpoint).then((res) => {
          this.empresaDoSocio = res
        })
      } catch (e) {
        // console.log('Erro:', e)
      }
    },
    async _exibeCheckBoxS2205 () {
      if (!this.codSocio) {
        return false
      }
      // pesquisa se o checkbox para marcação fica visivel.
      await this._consultarAgendamentoS2205(parseInt(this.codSocio))
      this.keyCheck = this.keyCheck++
      // não ha empresas vinculadas, não mostra checkbox
      const endpoint = '/api/v1/socio/agendamento-automatico-esocial'
      try {
        sociosService.get(endpoint).then((res) => {
          this.agendamentoS2205 = res === 1
        })
      } catch (e) {
        //  console.log('Erro:', e)
      }
    },
    async _consultarAgendamentoS2205 (idSocio) {
      if (idSocio) {
        const endpoint = `/api/v1/socio/esocial-marca-chk-agendamento/${idSocio}`
        try {
          return sociosService.get(endpoint).then((ret) => {
            this.mostrarCheckboxS2205 = ret
          })
        } catch (e) {
          //   console.log('Erro:', e)
        }
      }
    },
    _retornarCodigoEmpresaFormatado (e) {
      this.codSocio = this._retornarCodigoFormartado(e.target.value)
      this.key += 1
    },

    _checarSeInscricaoECPF () {
      if (this.cpfOuCnpj) {
        const res = this.cpfOuCnpj.replace(/[^\d]+/g, '').length === 11
        if (res) {
          this.erros.inscricaoEstadual = ''
        }
        return res
      } else {
        return false
      }
    },

    onlyNumber (e) {
      return utils.onlyNumber(e)
    },

    _retornarCodigoFormartado (codigo) {
      if (codigo) {
        return utils.strZero(codigo.toString(), 5)
      } else {
        return null
      }
    },

    _atribuirFalseParaTodasAsAbas () {
      this.basicoVisivel = false
      this.complementarVisivel = false
      this.observacoesVisivel = false
      this.declaracoesVisivel = false
      this.empresasVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'basico':
          this.basicoVisivel = true
          break
        case 'complementar':
          this.complementarVisivel = true
          break
        case 'observacoes':
          this.observacoesVisivel = true
          break
        case 'declaracoes':
          this.declaracoesVisivel = true
          break
        case 'empresas':
          this.empresasVisivel = true
          break
      }
      this.activeLink = aba
    },

    _fecharTela () {
      this.itemOriginal = null
      this._ativarAba('basico')
    },

    _verificarModificacaoNoSocio () {
      let item = utils.cloneDeep(this.socio)
      let itemOriginal = utils.cloneDeep(this.itemOriginal)

      if (itemOriginal) {
        itemOriginal.basico.endereco = itemOriginal.basico.endereco ? itemOriginal.basico.endereco : ''
        itemOriginal.basico.numero = itemOriginal.basico.numero ? itemOriginal.basico.numero : ''
        item.nmsocio = utils.cloneDeep(this.nmsocio)
        item.inscricaoCpfCnpj = utils.clearMaskNumber(this.cpfOuCnpj)
        item.basico.codigoIBGE = item.basico.codigoIBGE ? Number(item.basico.codigoIBGE) : item.basico.codigoIBGE

        item.complementar.deficienteReabilitado = item.complementar.deficienteReabilitado === null
          ? false
          : item.complementar.deficienteReabilitado

        itemOriginal.complementar.deficienteReabilitado = itemOriginal.complementar.deficienteReabilitado === null
          ? false
          : itemOriginal.complementar.deficienteReabilitado

        item.complementar.residenciaRecursoFGTS = item.complementar.residenciaRecursoFGTS === null
          ? false
          : item.complementar.residenciaRecursoFGTS

        itemOriginal.complementar.residenciaRecursoFGTS = itemOriginal.complementar.residenciaRecursoFGTS === null
          ? false
          : itemOriginal.complementar.residenciaRecursoFGTS

        itemOriginal.declaracoes.codigoIBGENascimento = itemOriginal.declaracoes.codigoIBGENascimento !== null
          ? itemOriginal.declaracoes.codigoIBGENascimento.toString()
          : itemOriginal.declaracoes.codigoIBGENascimento

        item.declaracoes.codigoIBGENascimento = item.declaracoes.codigoIBGENascimento !== null
          ? item.declaracoes.codigoIBGENascimento.toString()
          : item.declaracoes.codigoIBGENascimento

        item.complementar.dataEmissaoIdentidade = utils.formatarCampoDataParaEnvioAoBackend(item.complementar.dataEmissaoIdentidade)
        itemOriginal.complementar.dataEmissaoIdentidade = utils.formatarCampoDataParaEnvioAoBackend(item.complementar.dataEmissaoIdentidade)

        item.declaracoes.dataNascimento = utils.formatarCampoDataParaEnvioAoBackend(item.declaracoes.dataNascimento)
        itemOriginal.declaracoes.dataNascimento = utils.formatarCampoDataParaEnvioAoBackend(itemOriginal.declaracoes.dataNascimento)

        item = this.formatarCamposVaziosDeSocioParaComparacao(item)
        itemOriginal = this.formatarCamposVaziosDeSocioParaComparacao(itemOriginal)
        delete item.cdsocio
        delete itemOriginal.cdsocio

        const existeModificacao = !utils.isTotalmenteEqual(item, itemOriginal)
        if (existeModificacao) {
          this.$root.dadosAlterados = true
        } else {
          this.$root.dadosAlterados = false
        }
      }
      return { item, itemOriginal }
    },

    formatarCamposVaziosDeSocioParaComparacao (objeto) {
      for (const chave in objeto) {
        if (objeto[chave] !== null && typeof objeto[chave] === 'object') {
          this.formatarCamposVaziosDeSocioParaComparacao(objeto[chave])
        } else if (objeto[chave] !== null && objeto[chave].length === 0) {
          objeto[chave] = null
        }
      }
      return objeto
    },

    _clicarCancelar () {
      this._verificarModificacaoNoSocio()

      if (this.dadosAlteradosRoot) {
        this.$root.mostrarModalDadosAlterados = true
      } else {
        this._limparRegistroDeErros()
        this._limparValidadores()
        this._fecharTela()
        this.$emit('cancel')
      }
    },

    _montarObjetoSocio (objeto, aba) {
      switch (aba) {
        case 'básico':
          Object.assign(this.socio.basico, objeto)
          break
        case 'complementar':
          Object.assign(this.socio.complementar, objeto)
          break
        case 'declarações':
          Object.assign(this.socio.declaracoes, objeto)
          break
        case 'observação':
          Object.assign(this.socio.observacao, objeto)
          break
      }
      if (!this.itemOriginal) {
        this.itemOriginal = utils.cloneDeep(this.item)
      }
    },
    async _salvarSocio () {
      this.socio.cdsocio = parseInt(this.codSocio, 10)
      this.socio.nmsocio = this.nmsocio
      this.socio.inscricaoCpfCnpj = this.cpfOuCnpj
      if (await this._validarInformacoesCadastro()) {
        await this._enviarParaBackend()
        if (this.editando) {
          await sociosService.post(
            `/api/v1/integracao-e-contador/integrar-socio-econtador-por-socio/${this.socio.cdsocio}`
          )
        }
        if (this._isObjetoVazioSemErros(this.erros)) {
          this.dadosInalterados()
          this._clicarSalvar()
          if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
            const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
            sessionStorage.removeItem('rotaClicadaNoMenuLateral')
            this.$router.push(`${rotaClicadaNoMenuLateral}`)
          } else {
            this.direcionaParaRotaNoMesmoCadastro()
          }
        }
      }
      this.dadosInalterados()
    },
    async _enviarParaBackend () {
      this._formatarCamposParaEnvioAoBackend()
      const response = await sociosService.save('/api/v1/socio', this.socio, this.editando)
      await this.responseToSave(response)
      if (this.houveMudancas) {
        if (this.mostrarCheckboxS2205) {
          this.snapshot.comAgendamento = this.agendamentoS2205 ? 1 : 0
          await this._salvaAgendamento(this.snapshot)
          this.houveMudancas = false
          this.$emit('cancel')
        }
      }
    },

    async responseToSave (response) {
      this.jsonResposta = await response.json()
      if (response.status === 422) {
        this.erros = this.jsonResposta
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: this.jsonResposta.message })
      }
    },

    _clicarSalvar () {
      this._fecharTela()
      this.$emit('save', this.jsonResposta)
    },
    _isObjetoVazioSemErros (objeto) {
      return Object.values(objeto).every(value => !value)
    },

    _validarCPFCNPJ () {
      this.socio.inscricaoCpfCnpj = this.cpfOuCnpj.toString()
      if (
        this.socio.inscricaoCpfCnpj === '' ||
        this.socio.inscricaoCpfCnpj === null
      ) {
        this.erros.cpfOuCnpj = 'Informe o CNPJ/CPF'
      } else {
        if (!validadorDeInscricaoFederal.validar(this.socio.inscricaoCpfCnpj)) {
          this.erros.cpfOuCnpj = 'CNPJ/CPF inválido'
        } else {
          this.erros.cpfOuCnpj = ''
          this._aplicarMascaraCPFCNPJ()
        }
      }
    },

    async validarSeCPFCNPJJaFoiCadastrado () {
      let cpfOuCnpj
      try {
        cpfOuCnpj = await sociosService.get(
          `/api/v1/socio/cpf-cnpj/${this.cpfOuCnpj.replace(/[./-]/g, '')}`
        )
      } catch {
        cpfOuCnpj = []
      }
      cpfOuCnpj = cpfOuCnpj.filter((s) => {
        return s.cdsocio !== this.socio.cdsocio
      })

      if (cpfOuCnpj.length > 0) {
        this.adicionarNotificacao(
          new Toast({
            tipo: 'info',
            titulo: `Já existe um sócio cadastrado com este ${this.CPForCNPJ}.`,
            subtitulo: 'Você pode concluir o cadastro normalmente.'
          })
        )
      }
    },

    _aplicarMascaraCPFCNPJ () {
      this.socio.inscricaoCpfCnpj = this._retornarCPFCNPJFormatado(
        this.socio.inscricaoCpfCnpj
      )
    },

    _retornarCPFCNPJFormatado (inscricao) {
      if (inscricao) {
        inscricao = inscricao.replace(/[^0-9]+/g, '')
        if (inscricao.length === 14) {
          return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricao)
        }
        if (inscricao.length === 11) {
          return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricao)
        }
      } else {
        return null
      }
    },

    async _validarInformacoesCadastro () {
      this._limparRegistroDeErros()
      let ocorreuErro = false
      this.erros = ERROS
      const retornoErros = await Socio.validar(
        this.socio,
        this.validadores,
        this.editando
      )
      Object.assign(this.erros, retornoErros)
      if (this.erros.telaPrincipal) {
        ocorreuErro = true
        return !ocorreuErro
      } else {
        if (this.erros.abaBasico) {
          this._ativarAba('basico')
          ocorreuErro = true
          return !ocorreuErro
        } else {
          if (this.erros.abaComplementar) {
            this._ativarAba('complementar')
            ocorreuErro = true
            return !ocorreuErro
          } else {
            if (this.erros.abaDeclaracoes) {
              this._ativarAba('declaracoes')
              ocorreuErro = true
              return !ocorreuErro
            }
            return !ocorreuErro
          }
        }
      }
    },

    _formatarCamposParaEnvioAoBackend () {
      this.socio.inscricaoCpfCnpj = this.socio.inscricaoCpfCnpj.replace(
        /[^0-9]+/g,
        ''
      )
      if (this.socio.complementar.cpfResponsavel !== null) {
        this.socio.complementar.cpfResponsavel =
          this.socio.complementar.cpfResponsavel.replace(/[^0-9]+/g, '')
      }
      if (this.socio.complementar.dataEmissaoIdentidade !== null) {
        this.socio.complementar.dataEmissaoIdentidade =
          utils.formatarCampoDataParaEnvioAoBackend(
            this.socio.complementar.dataEmissaoIdentidade
          )
      }
      if (this.socio.complementar.dataValidadeCNH !== null) {
        this.socio.complementar.dataValidadeCNH =
          utils.formatarCampoDataParaEnvioAoBackend(
            this.socio.complementar.dataValidadeCNH
          )
      }
      if (this.socio.complementar.expedicaoCNH !== null) {
        this.socio.complementar.expedicaoCNH =
          utils.formatarCampoDataParaEnvioAoBackend(
            this.socio.complementar.expedicaoCNH
          )
      }
      this.socio.declaracoes.dataNascimento =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.socio.declaracoes.dataNascimento
        )
      this.socio.declaracoes.dataChegada =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.socio.declaracoes.dataChegada
        )
      this.socio.declaracoes.dataNaturalizacao =
        utils.formatarCampoDataParaEnvioAoBackend(
          this.socio.declaracoes.dataNaturalizacao
        )
    },

    _limparRegistroDeErros () {
      this.erros.telaPrincipal = ''
      this.erros.abaBasico = ''
      this.erros.inscricaoCpfCnpj = ''
      this.erros.telefone = ''
      this.erros.email = ''
      this.erros.nome = ''
      this.erros.cdMunicipio = ''
      this.erros.paisCodigo = ''
      this.erros.cep = ''
      this.erros.usuariosWmail = ''
      this.erros.inscricaoEstadual = ''
      this.erros.cpfResponsavel = ''
      this.erros.numeroCNH = ''
      this.erros.ufCNH = ''
      this.erros.categoriaCNH = ''
      this.erros.expedicaoCNH = ''
      this.erros.dataEmissaoIdentidade = ''
      this.erros.dataValidadeCNH = ''
      this.erros.orgaoEmissorCNH = ''
      this.erros.ufCTPS = ''
      this.erros.serieCTPS = ''
      this.erros.numeroCTPS = ''
      this.erros.deficienteTipo = ''
      this.erros.regimeMatrimonio = ''
      this.erros.codigo = ''
      this.erros.numeroCBONovo = ''
      this.erros.complemento = ''
      this.erros.numero = ''
      this.erros.municipioBasicoSocio = ''
      this.erros.bairro = ''
      this.erros.logradouro = ''
      this.limparErrosDeclaracoes()
    },

    _limparValidadores () {
      this.validadores.validadorDataChegada = null
      this.validadores.validadorDataNaturalizacao = null
      this.validadores.validadorDataEmissao = null
      this.validadores.validadorDataExpedicao = null
      this.validadores.validadorDataValidade = null
    },

    limparErrosDeclaracoes () {
      this.erros.dataNascimento = ''
      this.erros.ufNascimento = ''
      this.erros.codigoIBGENascimento = ''
      this.erros.paisCodigoNascimento = ''
      this.erros.nacionalidadeCodigo = ''
      this.erros.dataChegada = ''
      this.erros.dataNaturalizacao = ''
      this.erros.casadoBrasil = ''
      this.erros.filhosBrasil = ''
      this.erros.sexo = ''
      this.erros.racaCor = ''
      this.erros.grauDeInstrucao = ''
      this.erros.nomeMae = ''
      this.erros.nomePai = ''
      this.erros.paisCodigoESocialNascimento = ''
      this.erros.paisCodigoESocialNacionalidade = ''
      this.erros.tempoResidencia = ''
      this.erros.condicao = ''
    },

    async _validarCodigo (codigo) {
      if (codigo.target.value) {
        Socio.validarCodigoSocio(codigo.target.value, this.erros)
      }
    },
    sairSemSalvar () {
      this.$emit('cancel')
      this.dadosInalterados()
      this._limparRegistroDeErros()
      this._limparValidadores()
      this._fecharTela()
      this.dadosInalterados()
      if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
        const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
        sessionStorage.removeItem('rotaClicadaNoMenuLateral')
        this.$router.push(`${rotaClicadaNoMenuLateral}`)
      } else {
        this.direcionaParaRotaNoMesmoCadastro()
      }
      this.itemOriginal = null
    },
    ligarAtalhosCadastroSocios () {
      const section = 'cadastroSocios'
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarSocio)
      this.addShortcut(section, shortkeys.KEY_ESC, this._clicarCancelar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },
    direcionaParaRotaNoMesmoCadastro () {
      if (this.$router.currentRoute.value.path.includes('/socios')) {
        this.$router.push('/cadastros/socios')
      }
    }
  },
  mounted () {
    this.ligarAtalhosCadastroSocios()
    const { parametrosModalSalvarMudancas } = this.$root
    parametrosModalSalvarMudancas.eventoSalvar = this._salvarSocio
    parametrosModalSalvarMudancas.eventoSair = this.sairSemSalvar
    this.dadosInalterados()
    this.itemOriginal = new Socio()
  },
  unmounted () {
    this.itemOriginal = new Socio()
  }
}
</script>

<style>
</style>
