<template>
  <form id="empresa-vinculo-de-socio-receita-federal">
    <div class="columns is-multiline">
      <div class="column is-12">
        <p class="is-size-6"><strong>ECD / ECF</strong></p>
        <div class="column is-5 pl-0 pr-0 pt-0">
          <input-texto
            id="alCapitalVotante"
            :modelValue="socEmp.alCapitalVotante"
            label="% do capital votante"
            :message="erros.alCapitalVotante"
            :status="getStatus(erros.alCapitalVotante)"
            @blur="handleBlur"
            @input="handleInput"
            @keypress="handleInput"
          />
        </div>
        <input-addon
          id="qualificacao"
          widthDiv="30%"
          label="Qualificação"
          nameProperty="descricao"
          maxlength="2"
          v-model="socEmp.ecfEcdQualificacaoCodigo"
          dataGroup="qualificacao-pesquisa"
          :disabled="this.checkSocio()"
          :messageError="CODIGO_INVALIDO"
          :list="getQualificacoes"
          :message="this.erros.ecfEcdQualificacaoCodigo"
          :status="getStatus(this.erros.ecfEcdQualificacaoCodigo, 'sócio')"
          @click="pesquisaEcfEcdQualificacaoCodigo = true"
          @update:erro="this.erros.ecfEcdQualificacaoCodigo = $event.message"
          @keypress="onlyNumber($event, true)"
        />
      </div>
      <div class="column is-12">
        <input-addon
          id="qualificacao-assinante"
          widthDiv="30%"
          nameProperty="descricao"
          label="Qualificação do assinante / função"
          maxlength="3"
          v-model="socEmp.stQualificacaoSped"
          :messageError="CODIGO_INVALIDO"
          :status="getStatus(erros.stQualificacaoSped)"
          :message="erros.stQualificacaoSped"
          :list="qualificacaoAssinante"
          @click="pesquisaQualificacaoAssinante = true"
          @blur="handleBlurStQualificacaoSped"
          @update:erro="erros.stQualificacaoSped = $event.message"
          dataGroup="qualificacao-assinante-pesquisa"
          @keypress="onlyNumber($event, true)"
        />
      </div>
      <div class="column is-12">
        <input-texto
          id="complemento-qualificacao"
          label="Complemento da qualificação do assinante"
          maxlength="15"
          v-model.trim="socEmp.qualificacaoSpedComplemento"
          :status="getStatus(erros.qualificacaoSpedComplemento)"
          :message="erros.qualificacaoSpedComplemento"
          @update:erro="erros.qualificacaoSpedComplemento = $event.message"
          @update:modelValue="erros.qualificacaoSpedComplemento = ''"
          @keydown.prevent.enter="$emit('mudar-pagina', 'procurador')"
        />
      </div>
    </div>
  </form>
  <alt-pesquisa
    v-if="pesquisaEcfEcdQualificacaoCodigo"
    id="pesquisa-qualificacao"
    titulo="Pesquisa qualificação"
    customSearch
    :propDataSource="this.dataSource"
    :tabindex="-1"
    :propItens="getQualificacoes"
    :pQuantidadeDeItems="this.getQualificacoes.length"
    :paginacao="true"
    :next-page="buscarQualificacao"
    @fechar="aoCancelar('pesquisa-qualificacao')"
    @carregarItemSelecionado="selectQualify"
    @search="aoPesquisar('pesquisa-qualificacao', $event)"
  />
  <alt-pesquisa
    v-if="pesquisaQualificacaoAssinante"
    id="pesquisa-assinante"
    customSearch
    titulo="Pesquisa qualificação do assinante / função"
    :tabindex="-1"
    :propDataSource="this.dataSource"
    :propItens="this.qualificacaoAssinante"
    :pQuantidadeDeItems="this.qualificacaoAssinante.length"
    :paginacao="true"
    :next-page="buscarAssinante"
    @fechar="aoCancelar('pesquisa-assinante')"
    @carregarItemSelecionado="selectSubscriberQualificationFunction"
    @search="aoPesquisar('pesquisa-assinante', $event)"
  />
</template>

<script>
import InputText from '@alterdata/component-vue/InputText'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import InputAddon from '../../../components/InputAddon'
import qualificacaoDIPJ from './data/qualificacaoDIPJ.json'
import qualificacaoECF from './data/qualificacaoECF.json'
import qualificacaoAssinante from './data/qualificacaoAssinante.json'
import {
  CODIGO_INVALIDO,
  VALOR_INVALIDO,
  CONTEUDO_INVALIDO
} from '../../../util/message'
import Socemp from './model/socemp'
import Empresa from '../../Empresa/model/empresa'
import Socio from '../../Socios/model/socios'
import Pesquisa from '../../../components/Pesquisa/index.vue'
import { searchInJson } from '../../../util/searchInJson'

export default {
  name: 'abaReceitaFederal',
  components: {
    'input-texto': InputText,
    'input-addon': InputAddon,
    'alt-pesquisa': Pesquisa
  },
  props: {
    montarObjeto: Object,
    errosValidacao: Object,
    itemEmpresa: Empresa,
    itemSocemp: Socemp,
    itemSocio: Socio,
    editando: Boolean
  },
  data () {
    return {
      socEmp: this.itemSocemp,
      erros: this.errosValidacao,
      qualificacaoDIPJ,
      qualificacaoECF,
      qualificacaoAssinante,
      getQualificacoes: [],
      CODIGO_INVALIDO,
      pesquisaEcfEcdQualificacaoCodigo: false,
      pesquisaQualificacaoAssinante: false,
      dataSource: {
        columns: [
          { name: 'codigo', label: 'Código', style: 'width: 10%' },
          { name: 'descricao', label: 'Descrição' }
        ]
      },
      termoPesquisado: ''
    }
  },
  watch: {
    itemSocio: {
      handler: function (val) {
        this.getQualificacoes = this.getQualificacao()
      },
      deep: true
    }
  },
  mixins: [mixin],
  methods: {
    getQualificacao () {
      const arrCode = []
      if (this.itemSocio.cdsocio) {
        if (this.itemSocio.basico.paisCodigo === 105) {
          if (this.itemSocio.inscricaoCpfCnpj.length === 11) {
            arrCode.push(...[1, 2, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18])
          } else {
            arrCode.push(...[3, 4, 18])
          }
        } else {
          if (this.itemSocio.inscricaoCpfCnpj.length === 11) {
            arrCode.push(...[5, 6, 14, 18])
          } else {
            arrCode.push(...[7, 8, 18])
          }
        }
      } else {
        return this.qualificacaoECF
      }
      return this.onlyCodes(this.qualificacaoECF, arrCode)
    },
    handleBlur (event) {
      this.erros.alCapitalVotante = ''
      event.preventDefault()
      const { value } = event.target
      if (value) {
        const valueFloat = parseFloat(value.replace(',', '.')).toFixed(2)
        if (valueFloat >= 0.0 && valueFloat <= 100.0) {
          this.socEmp.alCapitalVotante = ''
          this.socEmp.alCapitalVotante =
            valueFloat > 99.99 ? '100,00' : valueFloat.replace('.', ',')
        } else {
          this.erros.alCapitalVotante = VALOR_INVALIDO
        }
      }
    },
    handleInput (event) {
      const theEvent = event || window.event
      const { value } = theEvent.target
      if (value) {
        this.erros.alCapitalVotante = ''
        const regex =
          /^100$|^100,[0]{1,2}$|^[0-9]{1,2}$|^[0-9]{1,2},[0-9]{1,2}$/
        const valueFloat = parseFloat(value).toFixed(2)
        if (regex.test(value) && valueFloat >= 0.0 && valueFloat <= 100.0) {
          this.socEmp.alCapitalVotante = ''
          this.socEmp.alCapitalVotante = value
        } else {
          if (!value.endsWith(',')) {
            theEvent.returnValue = false
            if (theEvent.preventDefault) {
              theEvent.preventDefault()
              theEvent.target.value = this.socEmp.alCapitalVotante
            }
          }
        }
      }
    },
    handleBlurStQualificacaoSped () {
      // eslint-disable-next-line eqeqeq
      if (this.socEmp.stQualificacaoSped == 1) {
        this.socEmp.stQualificacaoSped = '001'
      }
    },

    checkSocio () {
      let disabled = false
      if (!this.itemSocio.cdsocio || !this.itemSocemp.codigoSocio) {
        disabled = true
        this.erros.ecfEcdQualificacaoCodigo =
          'Selecione o sócio antes de informar a qualificação'
      } else if (!this.itemSocio.basico.paisCodigo) {
        disabled = true
        this.erros.ecfEcdQualificacaoCodigo =
          'Informe o país de residência no cadastro deste sócio para selecionar uma qualificação'
      } else if (
        this.erros.ecfEcdQualificacaoCodigo !== CODIGO_INVALIDO &&
        this.erros.ecfEcdQualificacaoCodigo !== CONTEUDO_INVALIDO
      ) {
        this.erros.ecfEcdQualificacaoCodigo = ''
      }
      return disabled
    },

    selectQualify (event) {
      this.socEmp.ecfEcdQualificacaoCodigo = event.codigo
      this.pesquisaEcfEcdQualificacaoCodigo = false
      if (this.termoPesquisado) {
        this.termoPesquisado = ''
        this.getQualificacoes = this.getQualificacao()
      }
    },
    selectSubscriberQualificationFunction (event) {
      this.socEmp.stQualificacaoSped = event.codigo
      this.pesquisaQualificacaoAssinante = false
      if (this.termoPesquisado) {
        this.termoPesquisado = ''
        this.qualificacaoAssinante = qualificacaoAssinante
      }
    },
    aoPesquisar (pesquisaId, event) {
      this.termoPesquisado = event.trim()
      if (pesquisaId === 'pesquisa-qualificacao') {
        // pesquisa-qualificacao
        this.buscarQualificacao()
      } else {
        // pesquisa-assinante
        this.buscarAssinante()
      }
    },

    buscarQualificacao (pagina = 0) {
      if (this.termoPesquisado) {
        const result = searchInJson.findBySearch(
          this.termoPesquisado,
          this.getQualificacao(),
          pagina
        )
        this.getQualificacoes = result.content
      } else {
        this.getQualificacoes = this.getQualificacao()
      }
    },

    buscarAssinante (pagina = 0) {
      if (this.termoPesquisado) {
        const result = searchInJson.findBySearch(
          this.termoPesquisado,
          qualificacaoAssinante,
          pagina
        )
        this.qualificacaoAssinante = result.content
      } else {
        this.qualificacaoAssinante = qualificacaoAssinante
      }
    },

    aoCancelar (pesquisaId) {
      if (pesquisaId === 'pesquisa-qualificacao') {
        this.pesquisaEcfEcdQualificacaoCodigo = false
        if (this.termoPesquisado) {
          this.termoPesquisado = ''
          this.getQualificacoes = this.getQualificacao()
        }
      } else {
        this.pesquisaQualificacaoAssinante = false
        if (this.termoPesquisado) {
          this.termoPesquisado = ''
          this.qualificacaoAssinante = qualificacaoAssinante
        }
      }
    },
    focarPrimeiroElemento () {
      setTimeout(() => {
        const input = document.querySelector('input#alCapitalVotante')
        if (input) {
          input.focus()
        } else {
          this.focarPrimeiroElemento()
        }
      }, 40)
    }
  },
  beforeMount () {
    this.getQualificacoes = this.getQualificacao()
  },
  mounted () {
    if (this.itemSocemp && this.socEmp.alCapitalVotante) {
      this.socEmp.alCapitalVotante = parseFloat(this.socEmp.alCapitalVotante)
        .toFixed(2)
        .replace('.', ',')
    }
    this.focarPrimeiroElemento()
  }
}
</script>
