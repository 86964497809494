<template>
  <div class="is-flex is-flex-direction-column is-desktop" id="socios">
    <div class="column is-full">
      <page-title v-if="this.showTitle" >Sócios</page-title>
      <div id="socios" v-show="this.listagemVisivel">
        <alt-datatable
          ref="datatable"
          customSearch
          :pStatus="this.status"
          :key="datatableKey"
          :pDataSource="this.dataSource"
          :pData="this.socios"
          :pHideButtonMore="this.hideButtonMore"
          :pQuantidadeDeItems="this.page.total"
          :nextPage="this._carregarListagem"
          :idDivUtilizadaPaginacao="'socios'"
          :modalDelecaoVisivel="this.verificarVinculo"
          :desativar-atalhos="mostrarModal"
          @search="aoPesquisar"
          :customHighlighters="this.customHighlighters"
        >
          <template v-slot:new-item>
            <Button class="button is-primary" @click="cadastrarNovo" :title="shortkeys.MSG_KEY_NOVO" data-group="btnNovoSocio">
              Novo
            </Button>

            <button class="button is-secondary ml-2" @click="abrirFecharGerarRelatorioSocios()" data-group="btn-gerar-relatorio">
              Gerar relatório
            </button>
          </template>
        </alt-datatable>
      </div>
      <tela-Socios-Cadastro
        :modalVisivel="cadastrandoOuEditando"
        :fecharModal="_fecharModal"
        :editando="editando"
        @cancel="abrirFecharEdicao"
        :item="this.item"
        @save="_atualizarListaAposCadastro"
        :codigoSocio="this.codigoSocio"
        :link-ajuda-prop="linkAjuda"
      />
    </div>
  </div>
  <ModalMessage ok-caption="Excluir sócio" cancel-type="primary" :message="mensagem" :active="mostrarModal" @close="aoFechar" />

  <geracao-relatorio-socios
    v-if="this.gerandoRelatorioSocios"
    @cancel="this.gerandoRelatorioSocios = false"
  />
</template>

<script>
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { toast } from 'bulma-toast'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { mixinAjuda } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-ajuda'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import { utilsScoped } from '@/models/utilsScoped'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Socio from './model/socios.js'
import cadastroSocios from './cadastroSocios.vue'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast.js'
import Button from '@alterdata/component-vue/Button/index.vue'
import PageTitle from '@packonline/packonline-reutilizaveis-frontend/components/PageTitle.vue'
import GeracaoRelatorioSocios from './GeracaoRelatorioSocios.vue'

export default {
  name: 'app',
  components: {
    'tela-Socios-Cadastro': cadastroSocios,
    'alt-datatable': DataTableBasico,
    'page-title': PageTitle,
    ModalMessage,
    Button,
    'geracao-relatorio-socios': GeracaoRelatorioSocios
  },
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'cdsocio',
            label: 'Código',
            format: this._formatarCodigo
          },
          {
            name: 'nmsocio',
            label: 'Nome'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: null
      },
      page: [],
      socios: [],
      cadastrandoOuEditando: false,
      gerandoRelatorioSocios: false,
      editando: null,
      item: new Socio(),
      status: '',
      hideButtonMore: false,
      listagemVisivel: true,
      showTitle: false,
      pagina: 0,
      codigoSocio: null,
      tentativaDeDeletarSocioVinculado: false,
      vinculo: null,
      preDelete: undefined,
      mostrarModal: false,
      termoPesquisado: '',
      datatableKey: 0,
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+S%C3%B3cios'
    }
  },

  mixins: [mixin, mixinFocoPrimeiroElementoDataTable, mixinPesquisa, mixinModalSaida, mixinAjuda],

  computed: {
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir o sócio "${utils.strZero(this.preDelete.cdsocio, 5)} - ${this.preDelete.nmsocio}"?`
      } else {
        return 'Deseja excluir o sócio ?'
      }
    }
  },
  watch: {
    mostrarModal (val) {
      val ? this.setActiveSection('modalExclusaoSocio') : this.setActiveSection('listagemSocios')
    },
    cadastrandoOuEditando (val) {
      if (val) {
        this.setActiveSection('cadastroSocios')
        utilsScoped.ligarDesligarElementos(false)
      } else {
        this.setActiveSection('listagemSocios')
        utilsScoped.ligarDesligarElementos(true)
      }
    },
    '$route.fullPath' (val) {
      if (val.includes('/cadastros/socios')) {
        this.setActiveSection('listagemSocios')
      }
    }
  },
  methods: {
    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },
    async _carregarListagem (pagina) {
      if (this.termoPesquisado) {
        this.status = 'is-loading'
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.socios = []
          this.page = await service.get(`/api/v1/socio?page=${pagina}`)
          this.socios = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } catch (e) {
          this.status = 'is-danger'
        }
      }
    },

    _formatarCodigo (codigo) {
      return utils.strZero(codigo, 5)
    },
    async buscar (search, pagina = 0) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.socios = []
          const page = await service.get(
            `/api/v1/socio/search?q=${encodeURIComponent(
              search
            )}&page=${pagina}`
          )
          this.page = page
          this.pagina = pagina
          this.socios = this.page.content
          this.status = ''
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
          this._ordenarGridPorCodigo()
        } else {
          await this._carregarListagem()
          if (this.page.total === 0) {
            this.status = ''
          }
        }
        this.status = ''
      } catch (e) {
        this.status = 'is-danger'
      }
    },
    async prepararExclusao (item) {
      await this.verificarVinculo(item)
      if (this.vinculo) {
        this.excluir(item)
      } else {
        this.mostrarModal = true
        this.preDelete = item
        this.focarEmQualquerElemento('div.buttons button[data-group="button-cancelar"]')
        this.$nextTick(() => {
          this.selecionarCancelarOuGravarModal()
        })
      }
    },
    async excluir (socio) {
      socio.id = socio.cdsocio
      if (this.vinculo) {
        this.adicionarNotificacao(
          new Toast({
            tipo: 'warning',
            titulo:
              'Este sócio não pode ser excluído, pois está vinculado a uma ou mais empresas.',
            subtitulo:
              'Verifique as vinculações no cadastro de sócios, aba Empresas vinculadas.'
          })
        )
        return
      }
      const resposta = await service.delete('/api/v1/socio', socio)
      if (resposta.ok) {
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        const itemIndex = this.socios.findIndex(
          (item) => item.cdsocio === socio.cdsocio
        )
        this.socios.splice(itemIndex, 1)
        this._ordenarGridPorCodigo()
        this.mostrarModal = false
        if (this.page.content.length === 0) {
          this._carregarListagem()
        }
      }
    },

    async verificarVinculo (socio) {
      const vinculo = await service.get(`/api/v1/socemp/socio/${socio.cdsocio}`)
      this.vinculo = vinculo.total > 0
      return false
    },

    async aoFechar (event) {
      if (event === 'ok') {
        await this.excluir(this.preDelete)
      } else {
        this.mostrarModal = false
      }
    },

    editar (socio) {
      this.page.total = null
      this.codigoSocio = socio.cdsocio
      Object.assign(this.item, socio)
      this.editando = true
      this.abrirFecharEdicao()
    },

    async cadastrarNovo () {
      this.codigoSocio = await this.proximoCodigo()
      this.item = new Socio()
      this.editando = false
      this.page.total = null
      this.abrirFecharEdicao()
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
      if (!this.cadastrandoOuEditando) {
        this.editando = null
        this.limparPesquisaDataTable()
      }
      this.listagemVisivel = !this.listagemVisivel
    },

    abrirFecharGerarRelatorioSocios () {
      this.gerandoRelatorioSocios = !this.gerandoRelatorioSocios
    },

    limparPesquisaDataTable () {
      if (!this.listagemVisivel) {
        this.termoPesquisado = ''
        if (this.$refs.datatable.$refs.search) {
          this.$refs.datatable.$refs.search.value = ''
        }
        this.datatableKey++
        this._carregarListagem()
      }
    },

    _atualizarListaAposCadastro () {
      this._carregarListagem(this.pagina)
      this.abrirFecharEdicao()
    },

    _ordenarGridPorCodigo () {
      this.socios.sort(function (a, b) {
        return a.cdsocio < b.cdsocio ? -1 : a.cdsocio > b.cdsocio ? 1 : 0
      })
    },
    ligarAtalhosListagemSocios () {
      const section = 'listagemSocios'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrarNovo)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },

    ligarAtalhoModalExclusao () {
      const section = 'modalExclusaoSocio'
      this.addShortcut(section, shortkeys.KEY_ESC, () => { this.mostrarModal = false })
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
    },

    async proximoCodigo () {
      return await Socio.proximoCodigo()
    }
  },

  created () {
    this.$router.currentRoute.value.fullPath === '/socio'
      ? (this.showTitle = true)
      : (this.showTitle = false)
    this._carregarListagem()
    this.ligarAtalhoModalExclusao()
    this.ligarAtalhosListagemSocios()
  }
}
</script>

<style>
</style>
