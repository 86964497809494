<template>
  <Modal style="z-index: 1001;"
    title="Cadastro de sócio"
    :active="this.cadastrandoOuEditando"
    :size="large"
    width="100%"
    id="cascaCadastroSocio"
    @close="() => this._fecharEdicao()"
  >
    <CadastroSocios
      :modalVisivel="cadastrandoOuEditando"
      :editando="false"
      @cancel="_fecharEdicao"
      @save="_recuperarCodigoSalvoEFecharATela"
      :codigoSocio="proximoCodigo"
    />
  </Modal>
</template>

<script>
import CadastroSocios from '../../../Socios/cadastroSocios.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'

export default {
  name: 'app',
  components: {
    CadastroSocios,
    Modal
  },
  props: {
    fecharModal: Function,
    proximoCodigo: String
  },
  data () {
    return {
      cadastrandoOuEditando: true
    }
  },
  methods: {
    _fecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
      this.fecharModal()
    },
    _recuperarCodigoSalvoEFecharATela (resposta) {
      this.$emit('recuperarCodigoDeSocioSalvo', resposta.cdsocio)
      this._fecharEdicao()
    }

  }
}

</script>
