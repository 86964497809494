<template>
  <div class="is-flex is-flex-direction-column is-desktop">
    <div class="column is-full">
      <page-title v-if="this.showTitle && this.listagemVisivel">Empresas</page-title>
      <div id="empresas" v-show="this.listagemVisivel">
        <DataTableBasico
          ref="datatablebasico"
          customSearch
          :key="datatableKey"
          :pStatus="this.status"
          :pDataSource="this.dataSource"
          :pData="this.empresas"
          :pHideButtonMore="this.hideButtonMore"
          :pQuantidadeDeItems="this.page.total"
          :nextPage="this._carregarListagem"
          :modalDelecaoVisivel="() => false"
          :idDivUtilizadaPaginacao="'empresas'"
          :desativar-atalhos="mostrarModal"
          :customHighlighters="customHighlighters"
          @search="aoPesquisar"
        >
          <template #statusEmpresa="{ item }">
            <div v-if="item.statusEmpresa === 'Ativa'">
              <Tag
                style="min-width: 87%"
                caption="Ativa"
                class="is-info is-light"
              />
            </div>
            <div v-else-if="item.statusEmpresa === 'Inativa'">
              <Tag
                style="min-width: 87%"
                caption="Inativa"
                class="is-neutral is-light"
              />
            </div>
            <div v-else-if="item.statusEmpresa === 'À concluir'">
              <Tag
                style="min-width: 87%"
                caption="À concluir"
                class="is-neutral"
              />
            </div>
            <div v-else>
              <Tag
                style="min-width: 87%"
                caption="Encerrada"
                class="is-neutral is-light"
              />
            </div>
          </template>
          <template #empresaNoContabil="{ item }">
            <div class="is-flex is-justify-content-flex-start is-gap">
              <Tag
                data-group="tagContabil"
                v-if="item.empresaNoContabil"
                style="color: #D85808; background-color: #F8DBC8;"
                caption="Contábil"
              />
              <Tag
                data-group="tagDP"
                v-if="item.empresaNoDP"
                style="color: #2950FF; background-color: #C8D1FE;"
                caption="DP"
              />
              <Tag
                data-group="tagFiscal"
                v-if="item.empresaNoFiscal"
                style="color: #7A38FF; background-color: #DDCCFF;"
                caption="Fiscal"
              />
            </div>
          </template>
          <template v-slot:new-item>
            <Button class="button is-primary" @click="cadastrarNovo" :title="shortkeys.MSG_KEY_NOVO" data-group="btnNovaEmpresa">
              Novo
            </Button>
          </template>
        </DataTableBasico>
      </div>
      <tela-Edicao-Cadastro
        ref="cadastro"
        :modalVisivel="cadastrandoOuEditando"
        :plano="itemEdicao"
        :fecharModal="_fecharModal"
        :editando="editando"
        :item="this.item"
        :itemAuxiliar="this.itemAux"
        @cancel="abrirFecharEdicao"
        @save="_atualizarListaAposCadastro"
        @close="abrirFecharEdicao"
        :objetoEmpresaOriginal="this.item"
        :mostrarCardsParam="mostrarCards"
      />
    </div>
  </div>
  <ModalMessage ok-caption="Excluir empresa" cancel-type="primary" :message="mensagem" :active="mostrarModal" @close="aoFechar" />
</template>

<script>
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { empresaService as service } from './service/empresa-service.js'
import { toast } from 'bulma-toast'
import { validadorDeInscricaoFederal } from '@packonline/packonline-reutilizaveis-frontend/Utils/InscricaoFederal.js'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import { utilsScoped } from '@/models/utilsScoped'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
import Button from '@alterdata/component-vue/Button/index.vue'
import cadastroEdicao from './cadastroEdicao.vue'
import Empresa from './model/empresa.js'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Tag from '@alterdata/component-vue/Tag'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import PageTitle from '@packonline/packonline-reutilizaveis-frontend/components/PageTitle.vue'

export default {
  name: 'app',
  components: {
    'tela-Edicao-Cadastro': cadastroEdicao,
    DataTableBasico,
    'page-title': PageTitle,
    Tag,
    ModalMessage,
    Button
  },
  inject: ['setActiveSection', 'addShortcut'],
  mixins: [mixinRouterGuardian, mixin, mixinPesquisa, mixinFocoPrimeiroElementoDataTable, mixinModalSaida],
  provide () {
    return {
      direcionaAjuda: this.direcionaAjuda
    }
  },
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'statusEmpresa',
            label: 'Status',
            style: 'width:9%; padding-left:2.3%',
            slot: true
          },
          {
            name: 'cdempresa',
            label: 'Código',
            format: this._formatarCodigoEmpresa,
            style: 'width:5%; padding-inline: 0',
            styleItems: 'padding-inline: 0'
          },
          {
            name: 'nome',
            label: 'Nome',
            style: 'width:27%'
          },
          {
            name: 'numeroCGC',
            label: 'CNPJ/CPF',
            style: 'width:14%',
            format: this._formatarCPFCNPJ
          },
          {
            name: 'tipocalculoImpostoRenda',
            label: 'Cálculo de IR',
            style: 'width:13%',
            format: this._retornarTextoTipoCalculoIR
          },
          {
            name: 'empresaNoContabil',
            label: 'Módulos vinculados',
            style: 'width: 16%;',
            slot: true
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: null
      },
      page: [],
      empresas: [],
      todasAsEmpresas: [],
      cadastrandoOuEditando: false,
      editando: false,
      item: new Empresa(),
      itemAux: new Empresa(),
      status: '',
      hideButtonMore: false,
      listagemVisivel: true,
      mostrarModal: false,
      preDelete: undefined,
      showTitle: false,
      pagina: 0,
      termoPesquisado: '',
      datatableKey: 0,
      mostrarCards: false,
      existemVinculosDeOutrosModulosParaEssaEmpresa: null,
      shortkeys: shortkeys
    }
  },
  computed: {
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    },
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir a empresa "${utils.strZero(this.preDelete.cdempresa, 5)} - ${this.preDelete.nome}"?`
      } else {
        return 'Deseja excluir a empresa ?'
      }
    }
  },
  watch: {
    verificandoDadosAlteradosRoot (val) {
      if (val && this.itemAux) {
        this.verificarModificaoNoItem()
      }
    },
    cadastrandoOuEditando (val) {
      if (val) {
        this.setActiveSection('telaCards')
      } else {
        this.setActiveSection('listagemEmpresas')
      }
    },
    mostrarModal (val) {
      if (val) {
        this.definirAtalhoModalExclusao()
      } else {
        this.setActiveSection('listagemEmpresas')
      }
    }
  },

  methods: {
    async _carregarListagem (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.empresas = []
          this.page = await service.get(
            `/api/v1/empresa/listagem?page=${pagina}`
          )
          this.empresas = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    _formatarCodigoEmpresa (codigo) {
      return utils.strZero(codigo, 5)
    },
    _formatarCPFCNPJ (valor) {
      if (!valor) {
        return ''
      }
      const inscricaoFederal = valor.replace(/[^\d]+/g, '')
      if (inscricaoFederal.length === 11) {
        return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricaoFederal)
      } else if (inscricaoFederal.length === 14) {
        return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricaoFederal)
      }
    },
    async prepararExclusao (item) {
      await this.verificarVinculacaoEmOutrosModulos(item)
      if (this.existemVinculosDeOutrosModulosParaEssaEmpresa) {
        this.excluir(item)
      } else {
        this.$nextTick(() => {
          this.selecionarCancelarOuGravarModal()
        })
        this.mostrarModal = true
        this.preDelete = item
      }
    },
    async excluir (empresa) {
      if (this.existemVinculosDeOutrosModulosParaEssaEmpresa) {
        this.adicionarNotificacao(
          new Toast({
            tipo: 'warning',
            titulo:
              'Esta empresa não poderá ser excluída, pois está vinculada a um ou mais módulos (Contábil, DP, Fiscal)',
            subtitulo:
              'Você pode desativá-la. Para isso, edite o cadastro inicial e marque a opção "Empresa Desativada"'
          })
        )
        return
      }

      const resposta = await service.delete(
        '/api/v1/empresa',
        empresa.cdempresa
      )
      if (resposta.ok) {
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        const itemIndex = this.empresas.findIndex(
          (item) => item.cdempresa === empresa.cdempresa
        )
        this.empresas.splice(itemIndex, 1)
        this._ordenarGridPorCodigo()
        this.mostrarModal = false
        if (this.page.content.length === 0) {
          this._carregarListagem()
        }
      }
    },

    async editar (empresa) {
      this.limparPesquisa()
      let edicaoEmpresa = []
      edicaoEmpresa = await service.get(`/api/v1/empresa-cadastros-vinculados/id/${empresa.cdempresa}`)
      empresa = edicaoEmpresa
      Object.assign(this.item, empresa)
      Object.assign(this.itemAux, empresa)
      this.editando = true
      this.abrirFecharEdicao()
    },

    verificarModificaoNoItem () {
      let itemOriginal = JSON.parse(JSON.stringify(this.itemAux))
      let item = JSON.parse(JSON.stringify(this.item))
      item = this.formatarItemParaVerificacao(item)
      itemOriginal = this.formatarItemParaVerificacao(itemOriginal)

      const existeModificacao = !utils.isTotalmenteEqual(item, itemOriginal)

      if (existeModificacao) {
        this.$root.dadosAlterados = true
      } else {
        this.$root.dadosAlterados = false
      }
    },
    async aoFechar (event) {
      if (event === 'ok') {
        await this.excluir(this.preDelete)
      } else {
        this.mostrarModal = false
      }
    },

    formatarItemParaVerificacao (item) {
      item.dataInicioAtividade =
        utils.formatarCampoDataParaEnvioAoBackend(
          item.dataInicioAtividade
        )
      item.dataFimAtividade = utils.formatarCampoDataParaEnvioAoBackend(
        item.dataFimAtividade
      )
      item.dataRegistroJuntaComercial =
        utils.formatarCampoDataParaEnvioAoBackend(
          item.dataRegistroJuntaComercial
        )
      item.escritorioDataEntrada =
        utils.formatarCampoDataParaEnvioAoBackend(
          item.escritorioDataEntrada
        )
      item.escritorioDataSaida =
        utils.formatarCampoDataParaEnvioAoBackend(
          item.escritorioDataSaida
        )
      item.dataExpedicaoNire =
        utils.formatarCampoDataParaEnvioAoBackend(
          item.dataExpedicaoNire
        )
      item.dtconvempresa = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtconvempresa
      )
      item.dtiniservcont = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtiniservcont
      )
      item.dtfimservcont = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtfimservcont
      )
      item.dtiniservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtiniservinfo
      )
      item.dtfimservinfo = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtfimservinfo
      )
      item.dtNascimento = utils.formatarCampoDataParaEnvioAoBackend(
        item.dtNascimento
      )
      if (item.numeroCGC) {
        item.numeroCGC = item.numeroCGC.replace(/[^0-9]+/g, '')
      }

      delete item.codigoIBGE
      delete item.cnaeFiscal
      delete item.codigoInscricaoSusep
      delete item.dataConversao
      delete item.fax
      delete item.fimContabilidade
      delete item.fimInformatica
      delete item.inicioContabilidade
      delete item.inicioInformatica
      delete item.inscSuframa
      delete item.numeroJunta
      delete item.registroCartorio
      delete item.registroOAB
      delete item.erroLogradouroOuNumero

      const val = (item.valorCapital / 1).toFixed(2).replace('.', ',')
      item.valorCapital = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      item.cdempresa = parseInt(item.cdempresa, 10)
      item.idMunicipio = parseInt(item.idMunicipio, 10)
      if (String(item.logradouro).includes(',')) {
        item.numero = item.logradouro.substring(item.logradouro.indexOf(',') + 1, item.logradouro.length).trim()
        item.logradouro = item.logradouro.substring(0, item.logradouro.indexOf(','))
      }
      if (item.bairro && !item.bairroCompleto) {
        item.bairroCompleto = item.bairro
      }
      if (item.nome && !item.nomeCompleto) {
        item.nomeCompleto = item.nome
      }
      Object.keys(item).forEach(function (attr) {
        if (!item[attr]) {
          item[attr] = item[attr] === 0 ? 0 : null
        }
      })
      return item
    },

    limparPesquisa () {
      this.termoPesquisado = ''
      if (this.page.total > 0) {
        this.$nextTick(() => {
          const { datatable } = this.$refs.datatablebasico.$refs
          if (datatable) {
            const { search } = datatable.$refs
            if (search) {
              search.value = ''
            }
          }
          this.datatableKey++
          this._carregarListagem()
        })
      }
    },

    async cadastrarNovo () {
      this.editando = false
      this.statusAtual = undefined
      const proxCodigo = await this.proximoCodigo()
      this.item = new Empresa(proxCodigo)
      this.itemAux = new Empresa(proxCodigo)
      this.formatarItemAuxiliarParaNovoCadastro()
      this.limparPesquisa()
      utilsScoped.ligarDesligarElementos(false)
      this.abrirFecharEdicao()
    },
    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
      this.dadosInalterados()
      if (this.cadastrandoOuEditando) {
        this.listagemVisivel = !this.listagemVisivel
      } else {
        this.listagemVisivel = true
        utilsScoped.ligarDesligarElementos(true)
        if (sessionStorage.getItem('rotaClicadaNoMenuLateral') !== null) {
          const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
          sessionStorage.removeItem('rotaClicadaNoMenuLateral')
          this.$router.push(`${rotaClicadaNoMenuLateral}`)
        } else {
          this.$router.push('/cadastros/empresas')
        }
      }
    },
    formatarItemAuxiliarParaNovoCadastro () {
      this.itemAux.lancamentoAutomatico = true
      this.itemAux.centroDeCusto = true
      this.itemAux.logradouro = ''
      this.itemAux.numero = ''
    },

    async verificarVinculacaoEmOutrosModulos (empresa) {
      if (empresa.cdempresa) {
        try {
          const vinculos = await service.get(`/api/v1/empresa/checa-vinculo/${empresa.cdempresa}`)
          delete vinculos.wphd
          this.existemVinculosDeOutrosModulosParaEssaEmpresa = Object.values(vinculos).some((vinculo) => vinculo === true)
        } catch {
          this.existemVinculosDeOutrosModulosParaEssaEmpresa = false
        }
        return false
      }
    },

    _atualizarListaAposCadastro (empresa) {
      this._carregarListagem(this.pagina)
    },

    _ordenarGridPorCodigo () {
      this.empresas.sort(function (a, b) {
        return a.cdempresa < b.cdempresa
          ? -1
          : a.cdempresa > b.cdempresa
            ? 1
            : 0
      })
    },

    async buscar (search, pagina = 0) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.empresas = []
          const page = await service.get(`/api/v1/empresa/filter?busca=${encodeURIComponent(search)}&page=${pagina}`)
          this.page = page
          this.pagina = pagina
          this.empresas = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          if (this.page.total === 0) {
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } else {
          await this._carregarListagem()
        }
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim().replaceAll(['.', '-', '/'], '')
      await this._carregarListagem()
    },

    _retornarTextoTipoCalculoIR (tipo) {
      const tipos = {
        S: 'Simples',
        R: 'Real',
        L: 'Presumido',
        A: 'Arbitrado',
        I: 'Imunes',
        T: 'Isentas',
        M: 'MEI',
        X: 'Pessoa Física'
      }

      return tipos[tipo] !== undefined ? tipos[tipo] : ''
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils
        .removeAcentos(stringfied)
        .toLowerCase()
        .indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },

    _retornarCodigoEmpresaFormatado (codigo) {
      return utils.strZero(codigo, 5)
    },

    async proximoCodigo () {
      const resposta = await service.get('/api/v1/empresa-proximo-codigo')
      return resposta.codigo
    },

    async buscarEmpresa () {
      const { id } = this.$route.params
      if (id) {
        const url = `/api/v1/empresa-cadastros-vinculados/${id}`
        const empresa = await service.get(url)
        if (empresa) {
          this.item = empresa
          this.editando = true
          Object.assign(this.itemAux, empresa)
          this.abrirFecharEdicao()
        }
      }
    },
    direcionaAjuda () {
      this.$nextTick(() => {
        window.open('https://ajuda.alterdata.com.br/display/POD/Cadastrar+Empresa ', '_blank').focus()
      })
    },

    ligarAtalhosListagemEmpresas () {
      const section = 'listagemEmpresas'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrarNovo)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },
    definirAtalhoModalExclusao () {
      const section = 'modalExclusaoEmpresa'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => {
        this.mostrarModal = false
      })
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
    }
  },

  created () {
    this.showTitle = this.$router.currentRoute.value.fullPath === '/empresa'
    this._carregarListagem()
    this.ligarAtalhosListagemEmpresas()
    this.editando = false
    this.datatableKey++
  },

  async mounted () {
    await this.buscarEmpresa()
  },

  beforeRouteLeave (to, from, next) {
    if (!this.$root.mostrarModalDadosAlterados) {
      this.beforeRoute(to, from, next)
      this.$root.rotaPara = to.fullPath
    } else {
      next()
    }
  }
}
</script>

<style>
.is-gap {
  gap: 0.5rem;
}
</style>
