import { service } from '../../../../service'
export default class AuditorIndependente {
  constructor () {
    this.id = null
    this.empresaId = null
    this.contadorId = null
    this.nome = null
    this.codigoCVM = null
    this.ano = null
  }

  static async validar (erros, objetoVinculo, objetoSelecao, auditores, editando, editandoAntigo) {
    let erro = false

    if (objetoSelecao.input) {
      let nome
      try {
        nome = await service.get(`/api/v1/contador/nome/${objetoSelecao.input}`)
      } catch {
        nome = null
      }
      if (!nome) {
        erros.nomeAuditor = 'Auditor não encontrado'
        erro = true
      }
    } else {
      if (objetoVinculo.nome) {
        let nome
        try {
          nome = await service.get(`/api/v1/contador/nome/${objetoVinculo.nome}`)
        } catch {
          nome = null
        }
        if (!nome) {
          erros.nomeAuditor = 'Auditor não encontrado'
          erro = true
        }
      } else {
        erros.nomeAuditor = 'Auditor não encontrado'
        erro = true
      }
    }

    if (objetoVinculo.ano) {
      if (objetoVinculo.ano < 1900) {
        erros.ano = 'Informe um ano válido'
        erro = true
      }
    } else {
      erros.ano = 'Informe o ano'
      erro = true
    }

    if ((objetoSelecao.input || objetoVinculo.nome) && objetoVinculo.ano) {
      let audit = []
      try {
        audit = auditores.filter(a => {
          return !!((a.nome === objetoSelecao.input || a.nome === objetoVinculo.nome) && a.ano === parseInt(objetoVinculo.ano))
        })

        if (editando) {
          audit = audit.filter(a => {
            return !(a.nome === editandoAntigo.nome && a.ano === parseInt(editandoAntigo.ano))
          })
        }
      } catch {
      }
      if (editando) {
        if (!(((objetoSelecao.input || objetoVinculo.nome) === editandoAntigo.nome) && (objetoVinculo.ano === editandoAntigo.ano))) {
          if (audit.length > 0) {
            erros.ano = 'Auditor já cadastrado para o ano informado'
            erro = true
          }
        }
      } else {
        if (audit.length > 0) {
          erros.ano = 'Auditor já cadastrado para o ano informado'
          erro = true
        }
      }
    }

    erros.auditor = erro
    return erros
  }
}
