<template>
  <div class="field column is-12 p-0">
    <empresa ref="empresa" />
  </div>
</template>

<script>
import empresa from '../../Empresa/index.vue'

export default {
  components: {
    empresa
  }
}
</script>

<style>
</style>
