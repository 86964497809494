<template>
  <div :key="this.keyQueTrocaJuntoComARota" class="container">
    <div class="column is-full">
      <page-title>Início > Cadastros</page-title>
      <div class="columns is-multiline">
      <div class="field column is-12">
        <TabGroup :key="this.keyQueTrocaJuntoComARota" ref="tabGroup" @change="ativarAba($event.current.caption)">
          <Tab dataGroup="tab-empresas" :active="this.empresasVisivel"  name="empresas" caption="Empresas">
            <empresas/>
          </Tab>
          <Tab dataGroup="tab-socios" :active="this.sociosVisivel" name="socios" caption="Sócios">
            <socios/>
          </Tab>
          <Tab dataGroup="tab-indexadores" :active="this.indexadoresVisivel" name="indexadores" caption="Indexadores">
            <indexador/>
          </Tab>
          <Tab dataGroup="tab-irrf" :active="this.irrfVisivel" name="irrf" caption="IRRF">
            <irrf/>
          </Tab>
          <Tab dataGroup="tab-feriados" :active="this.feriadosVisivel" name="feriados" caption="Feriados">
            <feriados/>
          </Tab>
        </TabGroup>
      </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import empresas from '../Menu/empresa/index.vue'
import indexador from '../Menu/indexador/index.vue'
import irrf from '../Menu/irrf/index.vue'
import feriados from '../Menu/feriado/index.vue'
import socios from '../Menu/socio/index.vue'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import PageTitle from '../../components/PageTitle/index.vue'
import TabGroup from '@alterdata/component-vue/TabGroup'
import Tab from '@alterdata/component-vue/Tab'

export default {
  name: 'cadastroEdicao',
  components: {
    empresas,
    indexador,
    irrf,
    feriados,
    socios,
    TabGroup,
    Tab,
    'page-title': PageTitle
  },

  data () {
    return {
      empresasVisivel: true,
      sociosVisivel: false,
      indexadoresVisivel: false,
      irrfVisivel: false,
      feriadosVisivel: false,
      listagemVisivel: null,
      keyQueTrocaJuntoComARota: this.$route.params.name
    }
  },
  mixins: [mixinRouterGuardian],

  methods: {
    atualizarKeyParaReconstruirComponentesQuemUsamEla () {
      this.keyQueTrocaJuntoComARota = this.$route.params.name
    },
    _atribuirFalseParaTodasAsAbas () {
      this.empresasVisivel = false
      this.sociosVisivel = false
      this.indexadoresVisivel = false
      this.irrfVisivel = false
      this.feriadosVisivel = false
    },
    ativarAba (aba) {
      if (this.$refs.cadastro) {
        if (!this.listagemVisivel) {
          this.$refs.cadastro.$refs.empresa.verificarModificaoNoItem()
        }
      }
      if (this.$root.dadosAlterados && !this.$root.mostrarModalDadosAlterados) {
        this.$root.mostrarModalDadosAlterados = true
        switch (aba) {
          case 'Sócios':
          case 'socios':
            this.$root.rotaPara = '/cadastros/socios'
            break
          case 'Indexadores':
          case 'indexadores':
            this.$root.rotaPara = '/cadastros/indexadores'
            break
          case 'IRRF':
          case 'irrf':
            this.$root.rotaPara = '/cadastros/irrf'
            break
          case 'Feriados':
          case 'feriados':
            this.$root.rotaPara = '/cadastros/feriados'
            break
          default:
            this.$root.rotaPara = '/cadastros'
        }
      } else {
        this.$root.mostrarModalDadosAlterados = false
        this._atribuirFalseParaTodasAsAbas()
        this.$root.paramentrosQuickView.componenteSlotQuickView = null
        switch (aba) {
          case 'Empresas':
          case 'empresas':
            this.empresasVisivel = true
            this.$router.push('/cadastros/empresas')
            break
          case 'Sócios':
          case 'socios':
            this.sociosVisivel = true
            this.$router.push('/cadastros/socios')
            break
          case 'Indexadores':
          case 'indexadores':
            this.indexadoresVisivel = true
            this.$router.push('/cadastros/indexadores')
            break
          case 'IRRF':
          case 'irrf':
            this.irrfVisivel = true
            this.$router.push('/cadastros/irrf')
            break
          case 'Feriados':
          case 'feriados':
            this.feriadosVisivel = true
            this.$router.push('/cadastros/feriados')
            break
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.cadastro) {
      this.listagemVisivel = this.$refs.cadastro.$refs.empresa.listagemVisivel
      if (!this.listagemVisivel) {
        this.$refs.cadastro.$refs.empresa.verificarModificaoNoItem()
      }
    }
    if (!this.$root.mostrarModalDadosAlterados) {
      this.beforeRoute(to, from, next)
      this.$root.rotaPara = to.fullPath
    } else {
      next()
    }
  },
  beforeMount () {
    if (this.$route.params.name) {
      this.activeLink = this.$route.params.name
    }
    this.ativarAba(this.activeLink)
    this.empresaVisivel = true
  },
  watch: {
    '$route.params.name' () {
      this.ativarAba(this.$route.params.name)
      this.atualizarKeyParaReconstruirComponentesQuemUsamEla()
    }
  }
}
</script>

<style scoped>
</style>
