// Definição da classe consultaCNPJ

import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast'
export default class ConsultaCNPJ {
  constructor (cnpj) {
    this.cnpj = cnpj
  }

  async consultar () {
    let rejectCallback
    try {
      const url = `https://receitaws.com.br/v1/cnpj/${this.cnpj}?callback=handleData`

      const dataPromise = new Promise((resolve, reject) => {
        rejectCallback = reject
        window.handleData = (data) => {
          delete window.handleData
          resolve(data)
        }

        window.handleDataError = (error) => {
          delete window.handleData
          reject(new Error(error.message))
        }
      })

      const script = document.createElement('script')
      script.src = url

      script.onerror = () => {
        rejectCallback()
      }

      document.body.appendChild(script)
      const data = await dataPromise
      return data
    } catch (error) {
      if (error.message.includes('429')) {
        throw new Error('Too many requests. Please try again later.') // Lança uma exceção personalizada
      } else {
        throw error
      }
    }
  }

  async _ConsultaCNPJ (cnpj, resetaCampos, resetBotao, atribuiDadosPagosAosCampos, atribuiDadosGratuitosAosCampos, adicionarNotificacao, service) {
    try {
      if (!cnpj) {
        return false
      }

      resetaCampos()
      const dadosReceita = await service.consultaCnpj(
        `/api/v1/empresa/cnpj/${cnpj}`,
        cnpj
      )
      if (dadosReceita?.contribuinte?.retornoErro === '') {
        if ((dadosReceita.message?.substr(0, 3) === '404') || (dadosReceita.contribuinte?.razaoSocial === '')) {
          adicionarNotificacao(
            new Toast({
              tipo: 'warning',
              titulo: 'Não foi possível completar a importação de dados da RFB',
              subtitulo: `
        <div>
          <p>
          O CNPJ não foi encontrado na base de dados do site da Receita. Verifique se digitou corretamente.
          </p>
          <br />
        </div>
      `
            })
          )
          resetBotao()
          return
        }
        atribuiDadosPagosAosCampos(dadosReceita)
        resetBotao()
        return
      } else {
        try {
          const dadosReceitaLivre = await this.consultar()
          if (dadosReceitaLivre.message) {
            if (dadosReceitaLivre.message === 'CNPJ rejeitado pela Receita Federal') {
              adicionarNotificacao(
                new Toast({
                  tipo: 'warning',
                  titulo: 'Não foi possível completar a importação de dados da RFB',
                  subtitulo: `
            <div>
              <p>
              O CNPJ não foi encontrado na base de dados do site da Receita. Verifique se digitou corretamente.
              </p>
              <br />
            </div>
          `
                })
              )
              resetBotao()
              return
            } else if (dadosReceitaLivre.message === 'CNPJ inválido') {
              adicionarNotificacao(
                new Toast({
                  tipo: 'warning',
                  titulo: 'Não foi possível completar a importação de dados da RFB',
                  subtitulo: `
              <div>
                <p>
                O CNPJ é inválido. Verifique se digitou corretamente.
                </p>
                <br />
              </div>
            `
                })
              )
              resetBotao()
              return
            }
          }
          atribuiDadosGratuitosAosCampos(dadosReceitaLivre)
          resetBotao()
        } catch (error) {
          adicionarNotificacao(
            new Toast({
              tipo: 'warning',
              titulo: 'Não foi possível completar a importação de dados da RFB.',
              subtitulo: `
              <div>
                <p>
                  Enviamos tentativas de comunicação, porém o site da receita não está respondendo. Por favor, tente novamente em alguns instantes.
                </p>
                <br />
              </div>
            `
            })
          )
        }
      }
    } catch (erro) {
      adicionarNotificacao(
        new Toast({
          tipo: 'warning',
          titulo: 'Não foi possível completar a importação de dados da RFB.',
          subtitulo: `
          <div>
            <p>
              Enviamos tentativas de comunicação, porém o site da receita não está respondendo. Por favor, tente novamente mais tarde.
            </p>
            <br />
          </div>
        `
        })
      )
    } finally {
      resetBotao()
    }
  }
}
